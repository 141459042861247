import React from 'react';

import { Grid } from '@mui/material';

import { FormDatePicker } from '../../../components/Form/DatePicker';
import { FormInputNumber } from '../../../components/Form/FormInputNumber';

const BasicDataForm = ({ control }) => (
	<Grid container spacing={2}>
		<Grid item xs={12} md={12 / 5}>
			<FormDatePicker
				name='service_date_init'
				control={control}
				label='Fecha de inicio'
				componentProps={{
					'data-testid': 'dateValue',
				}}
				rules={{ required: 'Valor requerido' }}
			/>
		</Grid>
		<Grid item xs={12} md={12 / 5}>
			<FormInputNumber
				name='service_duration'
				control={control}
				data-testid='service_duration'
				label='Duración del servicio (en meses)'
				required
				requiredText='Duración es requerido'
				max={100}
				min={1}
			/>
		</Grid>
		<Grid item xs={12} md={12 / 5}>
			<FormInputNumber
				name='social_security'
				control={control}
				data-testid='social_security'
				label='Coste de la seguridad social (%)'
				required
				requiredText='Seguridad social es requerido'
				max={100}
				min={0}
			/>
		</Grid>
		<Grid item xs={12} md={12 / 5}>
			<FormInputNumber
				name='absenteeism_rate'
				control={control}
				required
				requiredText='Porcentaje de absentismo es requerido'
				data-testid='absenteeism_rate'
				label='Porcentaje de absentismo (%)'
				max={100}
				min={0}
			/>
		</Grid>
		<Grid item xs={12} md={12 / 5}>
			<FormInputNumber
				name='substitution_percentage'
				control={control}
				required
				requiredText='Porcentaje de sustitución'
				data-testid='substitution_percentage'
				label='Porcentaje de sustitución (%)'
				max={100}
				min={0}
			/>
		</Grid>
	</Grid>
);

export default BasicDataForm;
