import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles, withTheme } from '@material-ui/core/styles';

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: 'primary',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'primary',
		},
		'& .MuiOutlinedInput-root': {
			'&.Mui-focused fieldset': {
				borderColor: 'primary',
			},
		},
	},
})(TextField);

class AppInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	onEnterPress = (ev) => {
		const { onEnter, value } = this.props;

		if (ev.key === 'Enter' && onEnter) {
			onEnter(value);
			ev.preventDefault();
		}
	};

	render() {
		const { ...props } = this.props;

		return (
			<CssTextField
				variant='outlined'
				onKeyPress={(ev) => this.onEnterPress(ev)}
				{...props}
			/>
		);
	}
}

export default withTheme(AppInput);
