import React from 'react';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Card, Tab, Tabs } from '@mui/material';

import IsLoading from '../../../components/IsLoading/IsLoading';
import CategoryInfo from '../CategoryInfo';
import Title from '../Title';
import useAgreementCategories from './useAgreementCategories';

const AgreementCategories = ({ agreementId, agreementData }) => {
	const {
		value,
		setValue,
		loadingCategories,
		categories,
		tabsHeight,
		resizeTabsComponent,
		ref,
	} = useAgreementCategories({ agreementId });

	return (
		<IsLoading conditional={loadingCategories} width={60}>
			<Box width='100%' mt={4} mb={2} textAlign='left'>
				{Title(`Tablas salariales ${agreementData?.salary_year || ''}`)}
				<Card
					sx={{
						display: 'flex',
						mt: 2,
						p: 2,
						minHeight: '50px',
					}}
					width='100%'
				>
					{categories?.length > 0 ? (
						<TabContext value={value}>
							<Tabs
								scrollButtons
								allowScrollButtonsMobile
								orientation='vertical'
								variant='scrollable'
								value={value}
								onChange={(event, newValue) => setValue(newValue)}
								aria-label='Categorías'
								sx={{
									borderRight: '1px solid',
									borderColor: 'rgba(0, 0, 0, 0.12)',
									width: '25%',
									height: `${tabsHeight}px`,
								}}
							>
								{categories.map((category, index) => (
									<Tab
										label={category.professional_category}
										value={`${index}`}
										key={category.id}
									/>
								))}
							</Tabs>
							<Box width='100%' ref={ref}>
								{categories.map((category, index) => (
									<TabPanel value={`${index}`} key={category.id}>
										<CategoryInfo
											agreementId={agreementId}
											categoryId={category.id}
											resizeTabs={resizeTabsComponent}
										/>
									</TabPanel>
								))}
							</Box>
						</TabContext>
					) : (
						'No existen categorías'
					)}
				</Card>
			</Box>
		</IsLoading>
	);
};
export default AgreementCategories;
