import React from 'react';

import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';

import { RolePermissionInventory } from '../../components/Allowed/Allowed';
import Else from '../../components/Compounds/If/Else';
import If from '../../components/Compounds/If/If';
import Then from '../../components/Compounds/If/Then';
import IsLoading from '../../components/IsLoading/IsLoading';
import { StyledButton } from '../../components/StyledComponents/Button';
import InventoryModal from './Modal';
import InventoryTable from './Table';
import useInventory from './useInventory';

const Inventory = () => {
	const {
		openModal,
		setOpenModal,
		edit,
		setEdit,
		deleteInventory,
		inventoryId,
		setInventoryId,
		inventories,
		loadingInventories,
		setReloadInventories,
	} = useInventory();

	return (
		<Box width='100%'>
			<InventoryModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				edit={edit}
				setEdit={setEdit}
				inventoryId={inventoryId}
				setReloadInventories={setReloadInventories}
			/>
			<RolePermissionInventory allowed={['create']}>
				<Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
					<StyledButton
						variant='outlined'
						onClick={() => setOpenModal(true)}
						startIcon={<Add color='primary' />}
						sx={{ mb: 3, mt: 4, color: 'text.primary' }}
					>
						Añadir nuevo
					</StyledButton>
				</Box>
			</RolePermissionInventory>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Box width='100%'>
					<IsLoading conditional={loadingInventories}>
						<If conditional={inventories?.length > 0}>
							<Then>
								<InventoryTable
									rows={inventories}
									setInventoryId={setInventoryId}
									setEdit={setEdit}
									deleteInventory={deleteInventory}
									setOpenModal={setOpenModal}
								/>
							</Then>
							<Else>No existen datos para mostrar</Else>
						</If>
					</IsLoading>
				</Box>
			</Box>
		</Box>
	);
};
export default Inventory;
