import React from 'react';

import AddIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import EventIcon from '@mui/icons-material/Event';
import {
	Box,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Slide,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';

import group from '../../../../../assets/imgs/group3.png';
import { AppInputMUI } from '../../../../../components';
import AppAutoComplete from '../../../../../components/AppAutoComplete/AppAutoComplete';
import DialogComponent from '../../../../../components/Dialog/Dialog';
import DialogLabelTypography from '../../../../../components/DialogLabelTypography';
import TimePickerFromTo from '../../../../../components/Simulations/Workload/TimePickerFromTo';
import ToggleWeekOptions from '../../../../../components/Simulations/Workload/ToggleWeekOptions';
import { StyledIconButton } from '../../../../../components/StyledComponents/IconButton';
import { StyledToggleButton } from '../../../../../components/StyledComponents/ToggleButton';
import DateRange from '../../DateRange';
import TaskFrequency from './TaskFrequency';
import useTaskByHourModal from './useTaskByHourModal';

const labelTypography = (label) => (
	<Typography color='black' variant='caption' sx={{ fontWeight: 800 }}>
		{label}
	</Typography>
);

const FormControlLabelOption = ({ value, label }) => (
	<FormControlLabel
		value={value}
		control={<Radio />}
		sx={{ fontSize: '12px' }}
		label={labelTypography(label)}
	/>
);

const TaskByHourModal = ({
	openModal,
	setOpenModal,
	initialValues,
	setInitialValues,
	setRefresh,
	edit,
	setEdit,
}) => {
	const {
		centers,
		containerRef,
		days,
		errorCenters,
		errorPositions,
		errorTaskHour,
		errorTaskName,
		errorSelectedDaysValidation,
		firstTimeSlot,
		handleCloseModal,
		handleSave,
		handleSetHour,
		holidaysChecked,
		applyOnHolidays,
		setApplyOnHolidays,
		hour,
		interval,
		listRangeWith,
		listRangeWithout,
		// maxWidth,
		numberInterval,
		numberSimultaneousPeople,
		periodChecked,
		positions,
		secondTimeSlot,
		selectedCenter,
		selectedPosition,
		setDays,
		setFirstTimeSlot,
		setHolidaysChecked,
		setInterval,
		setNumberInterval,
		setNumberSimultaneousPeople,
		setPeriodChecked,
		setPeriodsWithoutWork,
		setPeriodsWithWork,
		setSecondTimeSlot,
		setSelectedCenter,
		setSelectedPosition,
		setSimultaneousChecked,
		setTaskInterval,
		setTaskName,
		setTimeSlot,
		setTypeC,
		setWeekDaysChecked,
		showTypeC,
		simultaneousChecked,
		taskInterval,
		taskName,
		timeSlot,
		typeC,
		weekDaysChecked,
		minDate,
		maxDate,
		loadingPositions,
		hoursDifference,
		minutesApart,
		updateNumberInterval,
	} = useTaskByHourModal({
		edit,
		setEdit,
		initialValues,
		setInitialValues,
		setRefresh,
		setOpenModal,
	});

	const form = () => (
		<Box sx={{ display: 'flex' }}>
			<Box width='100%'>
				<Box width='100%' ref={containerRef}>
					<Grid container spacing={2}>
						<Grid container item md={6} xs={12}>
							<AppAutoComplete
								value={selectedCenter}
								setValue={setSelectedCenter}
								label='Nombre Centro'
								options={centers.data}
								optionLabel='name'
								errorText='Seleccione un centro'
								error={errorCenters}
							/>
						</Grid>
						<Grid container item md={6} xs={12}>
							<AppInputMUI
								fullWidth
								type='text'
								label='Nombre Tarea'
								name='taskName'
								value={taskName}
								onChange={(event) => setTaskName(event.target.value)}
								variant='outlined'
								size='small'
								inputProps={{ 'aria-label': 'taskName' }}
								error={errorTaskName}
								helperText={errorTaskName ? 'Nombre requerido.' : ''}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2} mt={2} sx={{ alignItems: 'center' }}>
						<Grid item md={5} xs={12}>
							<AppAutoComplete
								value={selectedPosition}
								setValue={setSelectedPosition}
								label='Puesto'
								options={positions}
								optionLabel='name'
								errorText='Seleccione un puesto'
								error={errorPositions}
								autoCompleteProps={{ disabled: loadingPositions }}
							/>
						</Grid>
						<Grid item md={5} xs={12}>
							<FormControlLabel
								label={labelTypography('Nº mínimo de personas simultáneas')}
								control={
									<Checkbox
										checked={simultaneousChecked}
										onChange={(event) =>
											setSimultaneousChecked(event.target.checked)
										}
										inputProps={{
											'aria-label': 'Checkbox',
										}}
									/>
								}
							/>
						</Grid>
						<Grid item md={2} xs={12}>
							<Box item display={simultaneousChecked ? 'inline' : 'none'}>
								<AppInputMUI
									fullWidth
									label='Nº'
									type='number'
									name='serviceDuration'
									value={numberSimultaneousPeople}
									onChange={(event) =>
										setNumberSimultaneousPeople(
											event.target.value < 0 ? 0 : event.target.value
										)
									}
									inputProps={{
										'data-testid': 'serviceDuration',
									}}
									size='small'
								/>
							</Box>
						</Grid>
					</Grid>
					<Box mt={2}>
						<Tooltip title='Selecciona como se distribuyen las horas de esta tarea'>
							<Typography
								sx={{ fontWeight: '800', fontSize: '18px' }}
								id='modal-modal-title'
								variant='subtitle1'
								component='div'
								color='primary'
							>
								¿Cada cuánto se realiza la tarea?
							</Typography>
						</Tooltip>
						<FormControl component='fieldset'>
							<RadioGroup
								aria-label='gender'
								defaultValue='fixed_period'
								name='radio-buttons-group'
								value={taskInterval}
								onChange={(val) => {
									setTaskInterval(val.target.value);
								}}
							>
								<FormControlLabelOption
									value='fixed_period'
									label='Las horas son fijas por día, semana, mes o año'
								/>
								<FormControlLabelOption
									value='repeated_x_times_over_period'
									label='Hay un nº de veces por día, semana, mes o año'
								/>
								<FormControlLabelOption
									value='repeated_over_x_period'
									label='Se realiza cada X días, semanas o meses'
								/>
							</RadioGroup>
						</FormControl>
					</Box>
					<Box sx={{ display: 'flex', mt: 2 }}>
						<Grid container spacing={4}>
							<Grid container item md={4} xs={12}>
								<Typography
									sx={{ fontWeight: '800', fontSize: '12px', width: '100%' }}
									id='modal-modal-title'
									variant='subtitle2'
									component='div'
								>
									Tiempo de la tarea
								</Typography>
								<TextField
									size='small'
									sx={{ mt: '3px', width: '100%' }}
									value={hour}
									onChange={(newValue) => {
										handleSetHour(newValue.target.value);
									}}
									placeholder='hh:mm'
									error={errorTaskHour}
									inputProps={{ 'aria-label': 'hourTask' }}
									helperText={errorTaskHour ? 'Formato hh ó hh:mm.' : ''}
								/>
							</Grid>
							<Grid container item md={8} xs={12}>
								<TaskFrequency
									taskInterval={taskInterval}
									value={interval}
									setValue={(val) => setInterval(val)}
									numberInterval={numberInterval}
									setNumberInterval={(val) => setNumberInterval(val)}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box mt={2}>
						<FormControlLabel
							label={labelTypography('Seleccionar tramo horario recomendado')}
							control={
								<Checkbox
									checked={timeSlot}
									onChange={(event) => setTimeSlot(event.target.checked)}
									inputProps={{
										'aria-label': 'recommendedTimeSlot',
									}}
								/>
							}
						/>
						<Grid container item xs={12}>
							<Grid
								container
								item
								mt={2}
								sx={{
									alignItems: 'center',
									display: timeSlot ? 'flex' : 'none',
								}}
								md={6}
								xs={12}
							>
								<TimePickerFromTo
									hourFirst={firstTimeSlot}
									setHourFirst={(val) => setFirstTimeSlot(val)}
									hourSecond={secondTimeSlot}
									setHourSecond={(val) => setSecondTimeSlot(val)}
									dataTestId1='hourFrom'
									dataTestId2='hourTo'
								/>
							</Grid>
							<Grid
								container
								item
								mt={2}
								sx={{
									alignItems: 'center',
									display:
										timeSlot &&
										(taskInterval === 'fixed_period' ||
											taskInterval === 'repeated_x_times_over_period') &&
										interval === 'day'
											? 'flex'
											: 'none',
								}}
								md={6}
								xs={12}
							>
								{hoursDifference && minutesApart
									? `${hoursDifference}:${minutesApart} horas / día`
									: null}
								<StyledIconButton
									onClick={updateNumberInterval}
									variant='contained'
									color='primary'
									aria-label='addDate'
									data-testid='addDate'
									sx={{
										alignItems: 'center',
										display:
											timeSlot && hoursDifference && minutesApart
												? 'flex'
												: 'none',
									}}
								>
									<AddIcon />
								</StyledIconButton>
							</Grid>
						</Grid>
					</Box>
					<Box
						mt={2}
						data-testid='show_type_c_box'
						display={showTypeC ? 'block' : 'none'}
					>
						<FormControlLabel
							label={labelTypography(
								'Cubrir presencialidad en todo el tramo horario'
							)}
							control={
								<Checkbox
									inputProps={{ 'data-testid': 'type_c_checkbox' }}
									checked={typeC}
									onChange={(event) => setTypeC(event.target.checked)}
								/>
							}
						/>
					</Box>
					<Box mt={2} mb={2}>
						<ToggleWeekOptions days={days} setDays={setDays} />
					</Box>
					<Box
						mt={2}
						mb={2}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<Box>
							<FormControlLabel
								label={
									<Tooltip
										placement='right'
										title='Se planificarán los días de la semana seleccionados, también cuando caigan en festivo.'
									>
										{labelTypography('Aplica en festivos')}
									</Tooltip>
								}
								control={
									<Checkbox
										checked={applyOnHolidays}
										onChange={(event) =>
											setApplyOnHolidays(event.target.checked)
										}
										inputProps={{
											'aria-label': 'applyHolidaysCheckbox',
										}}
									/>
								}
							/>
						</Box>
						<Box>
							<FormControlLabel
								label={
									<Tooltip
										placement='right'
										title='Aplica en los 14 festivos del año. De forma adicional, puede aplicar en los días de la semana seleccionados (sean festivos o no).'
									>
										{labelTypography('Todos los festivos')}
									</Tooltip>
								}
								control={
									<Checkbox
										checked={holidaysChecked}
										onChange={(event) =>
											setHolidaysChecked(event.target.checked)
										}
										inputProps={{
											'aria-label': 'applyAllHolidaysCheckbox',
										}}
									/>
								}
							/>
						</Box>
						<Box>
							<FormControlLabel
								label={labelTypography('Lunes-Viernes')}
								control={
									<Checkbox
										checked={weekDaysChecked}
										onChange={(event) =>
											setWeekDaysChecked(event.target.checked)
										}
									/>
								}
							/>
						</Box>
						<Box>
							<StyledToggleButton
								value='check'
								selected={periodChecked}
								onChange={() => {
									setPeriodChecked(!periodChecked);
								}}
								style={{
									textTransform: 'none',
									fontWeight: 800,
									fontSize: '12px',
									paddingLeft: '20px',
									paddingRight: '20px',
								}}
								size='small'
							>
								<EventIcon sx={{ marginRight: 0.4 }} />
								Período con/sin tarea
							</StyledToggleButton>
						</Box>
					</Box>
					<Box sx={{ width: '100%' }}>
						<DialogLabelTypography
							label={errorSelectedDaysValidation}
							color='error'
						/>
					</Box>
					<Box
						sx={{
							display: periodChecked ? 'flex' : 'none',
							overflow: 'hidden',
							background: '#F7F7F8',
						}}
						mt={2}
						ref={containerRef}
					>
						<Grid container justifyContent='center' mt={2} mb={2}>
							<Slide in={periodChecked} mountOnEnter unmountOnExit>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'top',
										width: 'fit-content',
										'& hr': {
											mx: 0.5,
										},
										ml: 2,
									}}
								>
									<Box sx={{ width: '50%', mr: 1 }} elevation={4}>
										<Typography
											align='left'
											sx={{ fontWeight: 'bold', ml: 1 }}
											id='modal-modal-title'
											variant='subtitle1'
											component='div'
											color='primary'
										>
											Periodo sin tarea
										</Typography>

										<Box sx={{ mt: 3 }}>
											<DateRange
												setDateRangeList={(e) => setPeriodsWithoutWork(e)}
												listRange={listRangeWithout}
												minDate={minDate}
												maxDate={maxDate}
											/>
										</Box>
									</Box>
									<Divider
										color='black'
										orientation='vertical'
										variant='middle'
										flexItem
									/>
									<Box sx={{ width: '45%', ml: 2 }} elevation={4}>
										<Typography
											align='left'
											sx={{ fontWeight: 'bold', ml: 1 }}
											id='modal-modal-title'
											variant='subtitle1'
											component='div'
											color='primary'
										>
											Periodo con tarea
										</Typography>
										<Box sx={{ mt: 3 }}>
											<DateRange
												setDateRangeList={(e) => setPeriodsWithWork(e)}
												listRange={listRangeWith}
												minDate={minDate}
												maxDate={maxDate}
											/>
										</Box>
									</Box>
								</Box>
							</Slide>
						</Grid>
					</Box>
				</Box>
			</Box>
		</Box>
	);

	return (
		<DialogComponent
			open={openModal}
			onClose={handleCloseModal}
			title='Añadir tarea'
			content={form()}
			acceptText='Añadir y cerrar'
			acceptAndContinueText={<u>Continuar añadiendo tareas</u>}
			cancelText='Cancelar'
			onAccept={() => handleSave(false)}
			onAcceptAndContinue={() => handleSave(true)}
			onCancel={handleCloseModal}
			fullWidth
			maxWidth='md'
			withAvatar
			avatar={<img alt='group' src={group} style={{ width: 50 }} />}
		/>
	);
};
export default TaskByHourModal;
