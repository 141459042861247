import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import { setSnackbar } from '../../../store/actions/inventory';
import { createInventory, getInventory, updateInventory } from '../ApiServices';

export default function useInventoryModal({
	setOpenModal,
	setEdit,
	edit,
	inventoryId,
	setReloadInventories,
}) {
	const dispatch = useDispatch();
	const { auth } = useSelector((state) => state);

	const [loadingInventory, setLoadingInventory] = useState(false);

	const inventoryTypes = [
		{ id: uuidv4(), label: 'Por coste salarial %', value: 'SALARY_PERCENT' },
		{
			id: uuidv4(),
			label: 'Cantidad fija por trabajador',
			value: 'AMOUNT_BY_WORKER',
		},
		{
			id: uuidv4(),
			label: 'Cantidad fija mensual',
			value: 'MONTHLY_AMOUNT',
		},
		{
			id: uuidv4(),
			label: 'Cantidad unitaria',
			value: 'MONTHLY_UNIT_COST',
		},
	];

	const defaultValues = {
		id: uuidv4(),
		name: '',
		cost_type: 'SALARY_PERCENT',
		amount: '',
	};

	const { handleSubmit, control, reset, setValue, watch } = useForm({
		defaultValues,
	});

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		if (edit) {
			try {
				setLoadingInventory(true);
				const inventoryData = await getInventory({ inventoryId });
				setValue('id', inventoryData?.id);
				setValue('name', inventoryData?.name);
				setValue('cost_type', inventoryData?.cost_type);
				setValue('amount', inventoryData?.amount);
				setLoadingInventory(false);
			} catch (e) {
				setLoadingInventory(false);
				setSnackBarMessage(e?.message);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit, inventoryId]);

	const createOrUpdate = async (values) => {
		const payload = values;
		payload.amount = parseFloat(values.amount);
		payload.workspace_id = auth?.workSpace?.id;
		try {
			if (edit) {
				await updateInventory({
					inventoryId,
					payload,
				});
				setEdit(false);
				setReloadInventories((prev) => !prev);
			} else {
				await createInventory({ payload });
				setReloadInventories((prev) => !prev);
			}
		} catch (e) {
			setSnackBarMessage(e?.message);
		}
	};

	const resetForm = () => {
		setOpenModal(false);
		setEdit(false);
		reset(defaultValues);
	};

	const onSubmit = (values) => {
		createOrUpdate(values);
		resetForm();
	};

	const getAmountText = (costType) => {
		switch (costType) {
			case 'AMOUNT_BY_WORKER':
				return 'Euros/trabajador';
			case 'MONTHLY_AMOUNT':
				return 'Euros/mes';
			case 'MONTHLY_UNIT_COST':
				return 'Euros por unidad';
			default:
				return '';
		}
	};

	return {
		handleSubmit,
		control,
		onSubmit,
		inventoryTypes,
		resetForm,
		loadingInventory,
		watch,
		getAmountText,
	};
}
