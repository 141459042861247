import React from 'react';

import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	Typography,
} from '@mui/material';

import DialogLabelTypography from '../../DialogLabelTypography';
import TimePickerFromTo from './TimePickerFromTo';

const CheckRange = (hourFirst, hourSecond) => {
	const hourFirstNull = hourFirst !== null;
	const hourSecondNull = hourSecond !== null;
	return hourFirstNull && hourSecondNull;
};

const GetHoursRange = (hourFirst, hourSecond) => {
	const hourFirstDate = new Date(hourFirst).setMilliseconds(0);
	let hourSecondDate = new Date(hourSecond).setMilliseconds(0);
	if (hourFirst > hourSecond) {
		const secondHourGetDay = new Date(hourSecond).getDate();
		hourSecondDate = new Date(hourSecond).setDate(secondHourGetDay + 1);
	}
	const secondsDiff = hourSecondDate - hourFirstDate;
	return secondsDiff / 1000 / 3600;
};

const compareDates = (
	hourFirst,
	hourSecond,
	firstSplitTurn,
	secondSplitTurn
) => {
	const firstDatesNotNullValidation = CheckRange(hourFirst, hourSecond);
	const secondDatesNotNullValidation = CheckRange(
		firstSplitTurn,
		secondSplitTurn
	);

	let hoursRange = 0;
	const hoursLimit = 9;

	if (firstDatesNotNullValidation) {
		hoursRange += GetHoursRange(hourFirst, hourSecond);
	}

	if (secondDatesNotNullValidation) {
		hoursRange += GetHoursRange(firstSplitTurn, secondSplitTurn);
	}

	return (
		hoursRange > hoursLimit &&
		'¡Ojo! Has creado un turno de más de 9 horas, asegúrate que el convenio lo permita.'
	);
};

const ShiftSchedule = ({
	firstSplitTurn,
	setFirstSplitTurn,
	secondSplitTurn,
	setSecondSplitTurn,
	hourFirst,
	setHourFirst,
	hourSecond,
	setHourSecond,
	checked,
	setChecked,
}) => (
	<Box mt={3}>
		<Typography
			sx={{ fontWeight: 'bold', fontSize: '12px', color: 'black' }}
			id='modal-modal-title'
			variant='subtitle2'
			component='div'
		>
			Horario
		</Typography>
		<Grid container item mt='3px' sx={{ alignItems: 'center' }}>
			<Grid container item md={8} xs={12} mr={1} sx={{ alignItems: 'center' }}>
				<TimePickerFromTo
					hourFirst={hourFirst}
					setHourFirst={(val) => setHourFirst(val)}
					hourSecond={hourSecond}
					setHourSecond={(val) => setHourSecond(val)}
					dataTestId1='schedule-id-1'
					dataTestId2='schedule-id-2'
				/>
				<Grid
					container
					item
					mt={2}
					mr={1}
					sx={{
						alignItems: 'center',
						display: checked ? 'flex' : 'none',
					}}
				>
					<TimePickerFromTo
						hourFirst={firstSplitTurn}
						setHourFirst={(val) => setFirstSplitTurn(val)}
						hourSecond={secondSplitTurn}
						setHourSecond={(val) => setSecondSplitTurn(val)}
						dataTestId1='schedule-split-id-1'
						dataTestId2='schedule-split-id-2'
					/>
				</Grid>
			</Grid>
			<Grid item md={3} xs={12} ml={2}>
				<FormControlLabel
					label={
						<DialogLabelTypography
							sx={{ fontWeight: 800, fontSize: '11px' }}
							color='black'
							label='Turno partido'
						/>
					}
					control={
						<Checkbox
							checked={checked}
							onChange={(event) => {
								setChecked(event.target.checked);
								setFirstSplitTurn(null);
								setSecondSplitTurn(null);
							}}
							inputProps={{ 'data-testid': 'addSplitTurnCheckbox' }}
						/>
					}
				/>
			</Grid>
		</Grid>
		<Grid mt='3px'>
			<Typography variant='body1' color='error'>
				{compareDates(hourFirst, hourSecond, firstSplitTurn, secondSplitTurn)}
			</Typography>
		</Grid>
	</Box>
);
export default ShiftSchedule;
