import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

class Breadcrumb extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
		};
	}

	renderLinks() {
		const { routes = [] } = this.props;
		// sample routes: [{name: "Home", href: "/"}, {name: "Projects", href: "/projects"}]
		const fontSize = 14;
		return routes.map(({ name, href }) =>
			href ? (
				<Link
					key={href}
					color='secondary'
					onClick={() => this.setState({ redirectTo: href })}
					style={{ cursor: 'pointer', fontSize }}
				>
					{name}
				</Link>
			) : (
				<Typography
					key={`${name}_current`}
					color='textPrimary'
					style={{ fontSize }}
				>
					{name}
				</Typography>
			)
		);
	}

	render() {
		const { redirectTo } = this.state;
		if (redirectTo) return <Redirect push to={redirectTo} />;

		return (
			<Breadcrumbs
				separator={<NavigateNextIcon fontSize='small' />}
				aria-label='breadcrumb'
			>
				{this.renderLinks()}
			</Breadcrumbs>
		);
	}
}

export default withTheme(Breadcrumb);
