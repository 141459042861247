export default function simulations(state = null, simulation) {
	const {
		type,
		fetching,
		simulations,
		currentSimulation,
		basicData,
		centrosList,
	} = simulation;
	switch (type) {
		case 'SET_SIMULATIONS_LIST':
			return { ...state, list: simulations };
		case 'RESET_SIMULATIONS_LIST':
			return { fetching: true, list: [] };
		case 'SET_CURRENT_SIMULATION':
			return { ...state, currentSimulation };
		case 'RESET_CURRENT_SIMULATION':
			return { ...state, currentSimulation: {} };
		case 'SET_FETCHING_STATE_SIMULATIONS':
			return { ...state, fetching };
		case 'SET_BASIC_DATA':
			return { ...state, basicData };
		case 'SET_CENTROS_LIST':
			return { ...state, centrosList };
		default:
			return state;
	}
}
