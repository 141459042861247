import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import translator from 'utilities/translator/translator';
import { v4 as uuidv4 } from 'uuid';

import {
	getCategories,
	getContractTypes,
} from '../../../../Services/Api/Simulation';
import {
	createSurrogate,
	getSurrogate,
	updateSurrogate,
} from '../../../../Services/Api/Surrogate';
import { setSnackbar } from '../../../../store/actions/createSimulation';
import { groupBy } from '../../../../utilities/utils';

const journeyOptions = [
	{ id: 1, label: 'Horas/semana', value: 'hourPerWeek' },
	{ id: 2, label: 'Porcentaje', value: 'percent' },
];

const parseDataToSubmit = (data, initialValues) => {
	const values = {
		id: initialValues ? initialValues : uuidv4(),
		center_id:
			data?.center?.center_group?.toString() === data?.center?.id
				? null
				: data?.center?.id || null,
		category_id: data?.categoryPosition?.id || null,
		center_group:
			data?.center?.center_group &&
			data?.center?.center_group?.toString() === data?.center?.id
				? data?.center?.center_group
				: null,
		working_day: data?.journeyValue ? Number(data.journeyValue) : null,
		value_metric_working_day: data?.journey === 'percent' ? 'PERCENT' : 'FIXED',
		contract_type_id: data?.contractType?.id || null,
		salary_year: data?.salaryYear ? Number(data.salaryYear) : null,
		annual_bonuses: data?.annualBonuses ? Number(data.annualBonuses) : null,
		beginning_year: data?.seniorityDate
			? moment(data?.seniorityDate).format('YYYY-MM-DD')
			: null,
	};
	return values;
};

const getAgreementFromCenter = (center) => ({
	uid: center.agreement?.id || center.agreement?.uid || null,
	...center.agreement,
});

const defaultsValues = (centers) => ({
	center: centers?.length === 1 ? centers[0] : null,
	agreement: centers?.length > 0 ? getAgreementFromCenter(centers[0]) : null,
	categoryPosition: null,
	salaryYear: '',
	annualBonuses: '',
	contractType: null,
	seniorityDate: null,
	journey: 'hourPerWeek',
	journeyValue: '',
});

export default function useSurrogateModal({
	setOpenModal,
	setReloadSurrogates,
	surrogateId,
	setSurrogateId,
}) {
	const { simulationId, centers, agreements, basicData } = useSelector(
		(state) => state.createSimulation
	);
	const dispatch = useDispatch();

	const [edit, setEdit] = useState(false);
	const [uploadingSurrogate, setUploadingSurrogate] = useState(false);
	const [loadingSurrogate, setLoadingSurrogate] = useState(false);
	const [categories, setCategories] = useState([]);
	const [centersData, setCentersData] = useState([]);
	const [contractTypes, setContractTypes] = useState([]);
	const defaultValues = defaultsValues(centers?.data);
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		reset,
		clearErrors,
	} = useForm({
		defaultValues,
	});
	const [isPossibleRetirement, setIsPossibleRetirement] = useState(false);

	const {
		loading: loadingContractTypes,
		data: contractTypeData,
		error: errorContractTypes,
	} = getContractTypes();

	useEffect(() => {
		if (!loadingContractTypes) {
			const contractTemp = contractTypeData.map((contract) => {
				contract.label = `${contract.contract_key}-${contract.description}`;
				return contract;
			});
			setContractTypes(contractTemp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingContractTypes]);

	const dispatchErrorMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const getCategoryList = async (agreementId) => {
		try {
			const response = await getCategories({ agreementId });
			if (response?.status === 200) {
				setCategories(response.data);
				return response.data;
			}
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(message);
		}
	};

	const setFormDefaultValues = async (surrogate) => {
		const journeySelector =
			surrogate?.value_metric_working_day === 'PERCENT'
				? 'percent'
				: 'hourPerWeek';

		const defaultContractType = surrogate?.contract_type
			? {
					...surrogate.contract_type,
					label: `${surrogate.contract_type.contract_key}-${surrogate.contract_type.description}`,
			  }
			: null;

		const surrogateAgreement =
			surrogate?.agreement && agreements?.data?.length > 0
				? agreements.data.filter(
						(option) => surrogate.agreement.id === option.uid
				  )[0]
				: null;

		const centerGroupValue = surrogate?.center_group
			? {
					name: surrogate.center_group.toString(),
					id: surrogate.center_group.toString(),
					isCenterGroup: true,
					center_group: surrogate.center_group,
					center_group_label: 'Agrupacion',
			  }
			: null;

		const centerValue = surrogate?.center ? surrogate.center : centerGroupValue;

		if (surrogate && surrogateAgreement?.uid) {
			getCategoryList(surrogateAgreement.uid);
		} else if (defaultValues?.agreement?.id) {
			getCategoryList(defaultValues.agreement.id);
		}

		setValue('center', surrogate ? centerValue : defaultValues.center);
		setValue(
			'agreement',
			surrogate ? surrogateAgreement : defaultValues.agreement
		);
		setValue(
			'categoryPosition',
			surrogate?.category
				? {
						...surrogate.category,
						uid: surrogate.category.id,
						professional_category: surrogate.category.name,
				  }
				: null
		);
		setValue('salaryYear', surrogate?.salary_year || '');
		setValue('annualBonuses', surrogate?.annual_bonuses || '');
		setValue('contractType', defaultContractType);
		setValue('seniorityDate', surrogate?.beginning_year || null);
		setValue('journey', journeySelector);
		setValue('journeyValue', surrogate?.working_day || '');
	};

	useEffect(() => {
		if (
			!surrogateId &&
			centers?.data.length > 0 &&
			centers?.data[0]?.agreement?.id
		) {
			getCategoryList(centers.data[0].agreement.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialDataState = async ({ surrogateId }) => {
		try {
			setLoadingSurrogate(true);

			const response = await getSurrogate({
				simulationId,
				surrogateId,
			});

			await setFormDefaultValues(response);

			setLoadingSurrogate(false);
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(message);
			setLoadingSurrogate(false);
		}
	};

	useEffect(() => {
		if (errorContractTypes) {
			dispatchErrorMessage(errorContractTypes?.message);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorContractTypes]);

	useEffect(() => {
		let response = centers?.data || [];
		const filtered = centers.data.filter((center) => center.center_group);
		const groupedCenters = Object.keys(groupBy(filtered, 'center_group'));
		const groupedCentersWidthData = groupedCenters.map((center) => ({
			name: center,
			id: center,
			uid: center,
			isCenterGroup: true,
			center_group: parseInt(center, 10),
			center_group_label: 'Agrupación',
			agreement:
				getAgreementFromCenter(
					centers.data.filter(
						(option) => center === option?.center_group?.toString()
					)[0]
				) || null,
		}));
		response = response.concat(groupedCentersWidthData);
		setCentersData(response);
	}, [centers]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		if (watch('agreement')?.uid && !loadingSurrogate) {
			const categories = await getCategoryList(watch('agreement')?.uid);
			if (categories) {
				const categoryMatch = categories.find(
					(category) => getValues('categoryPosition')?.id === category?.id
				);

				setValue('categoryPosition', categoryMatch || null);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('agreement')]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		if (watch('center')?.agreement) {
			const centersAgreement = watch('center').agreement;
			const agreementFind = agreements.data.filter(
				(option) => centersAgreement.id === option.uid
			)[0];
			const agreementObject = {
				id: agreementFind?.uid || agreementFind?.id,
				...agreementFind,
			};
			setValue('agreement', agreementObject);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('center')]);

	useEffect(() => {
		if (surrogateId) {
			initialDataState({ surrogateId });
			setEdit(true);
		} else {
			setEdit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surrogateId]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		const year = new Date(watch('seniorityDate')).getFullYear();
		const simulationDate = new Date(basicData.startDate);
		simulationDate.setMonth(
			simulationDate.getMonth() + basicData.serviceDuration
		);
		const simulationYear = simulationDate.getFullYear();

		if (watch('seniorityDate') && simulationYear - year > 35) {
			setIsPossibleRetirement(true);
		} else {
			setIsPossibleRetirement(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('seniorityDate')]);

	const closeDialog = () => {
		setFormDefaultValues();
		setOpenModal(false);
		setEdit(false);
		setSurrogateId('');
		clearErrors();
	};

	const onSubmit = async (data) => {
		const valuesToEdit = parseDataToSubmit(data, surrogateId);
		const valuesToCreate = parseDataToSubmit(data, null);
		setUploadingSurrogate(true);
		try {
			if (edit) {
				await updateSurrogate({
					simulationId,
					surrogateId,
					payload: valuesToEdit,
				});
			} else {
				await createSurrogate({
					simulationId,
					payload: valuesToCreate,
				});
			}
			closeDialog();
			setReloadSurrogates();
			setUploadingSurrogate(false);
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(translator(message));
			setUploadingSurrogate(false);
		}
	};

	const onSubmitAndContinue = async (data) => {
		const valuesToEdit = parseDataToSubmit(data, surrogateId);
		const valuesToCreate = parseDataToSubmit(data, null);
		setUploadingSurrogate(true);
		try {
			if (edit) {
				await updateSurrogate({
					simulationId,
					surrogateId,
					payload: valuesToEdit,
				});
				setEdit(false);
			} else {
				await createSurrogate({
					simulationId,
					payload: valuesToCreate,
				});
			}
			setReloadSurrogates();
			reset(defaultValues);
			setUploadingSurrogate(false);
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(translator(message));
			setUploadingSurrogate(false);
		}
	};

	return {
		uploadingSurrogate,
		loadingSurrogate,
		control,
		centersData,
		agreements,
		categories,
		journeyOptions,
		loadingContractTypes,
		contractTypes,
		handleSubmit,
		onSubmit,
		onSubmitAndContinue,
		watch,
		closeDialog,
		isPossibleRetirement,
	};
}
