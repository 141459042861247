/* URLS to fetch */
export const getLocalitiesUrl = 'location/municipality';
export const getUserAgreementsUrl = 'agreement';
export const checkConnectionUrl = 'auth/check_connection';
export const getAgreementUrl = (agreementId) => `/agreement/${agreementId}`;
export const duplicateAgreementUrl = () => `/agreement/duplicate`;
export const getUrlToDownloadCentersUrl = (simulationId) =>
	`/simulation/${simulationId}/center/excel/download`;
export const uploadCentersUrl = (simulationId) =>
	`/simulation/${simulationId}/center/excel/upload`;
export const putCentersUrl = (simulationId) =>
	`/simulation/${simulationId}/center`;
export const getCategoriesUrl = (agreementId) =>
	`/agreement/${agreementId}/category`;
export const getCategoryUrl = (agreementId, categoryId) =>
	`/agreement/${agreementId}/category/${categoryId}`;
export const uploadSurrogatesUrl = (simulationId) =>
	`/files_server/simulation/${simulationId}/subrogates`;
export const downloadSurrogatesUrl = (simulationId) =>
	`/simulation/${simulationId}/subrogate/download_url`;
export const uploadSurrogatesStatusUrl = (simulationId) =>
	`/files_server/simulation/${simulationId}/subrogates/upload_status`;
export const getSurrogateUrl = (simulationId, surrogateId) =>
	`/simulation/${simulationId}/subrogate/${surrogateId}`;
export const getSurrogatesUrl = (simulationId) =>
	`/simulation/${simulationId}/subrogate`;
export const getContractTypesUrl = () => `/contract/types`;
export const getSimulationCentersUrl = (simulationId) =>
	`/simulation/${simulationId}/center`;
export const getSimulationUrl = (simulationId) => `/simulation/${simulationId}`;
export const getValidateSimulationUrl = (simulationId) =>
	`/simulation/${simulationId}/validate`;
export const getPositionsUrl = (simulationId) =>
	`/simulation/${simulationId}/position`;
export const getCategoryPositionsUrl = (simulationId) =>
	`/simulation/${simulationId}/category_position_relation `;
export const deleteShiftUrl = (simulationId, shiftId) =>
	`/simulation/${simulationId}/fixed_work_shift/${shiftId}`;
export const getFixedWorkShiftListUrl = (simulationId) =>
	`/simulation/${simulationId}/fixed_work_shift `;
export const getListTaskByHoursUrl = (simulationId) =>
	`/simulation/${simulationId}/variable_work `;
export const createTaskByHoursUrl = (simulationId) =>
	`/simulation/${simulationId}/variable_work `;
export const createSurrogateUrl = (simulationId) =>
	`/simulation/${simulationId}/subrogate `;
export const deleteTasksByHourUrl = (simulationId, variableWorkId) =>
	`/simulation/${simulationId}/variable_work/${variableWorkId}`;
export const getTaskByHourUrl = (simulationId, variableWorkId) =>
	`/simulation/${simulationId}/variable_work/${variableWorkId}`;
export const getFixedWorkShiftUrl = (simulationId, fixedWorkShiftId) =>
	`/simulation/${simulationId}/fixed_work_shift/${fixedWorkShiftId} `;
export const updateFixedWorkShiftUrl = (simulationId, fixedWorkShiftId) =>
	`/simulation/${simulationId}/fixed_work_shift/${fixedWorkShiftId} `;
export const launchSimulationUrl = (simulationId) =>
	`/simulation/${simulationId}/launch`;
export const duplicateSimulationUrl = () => `/simulation/duplicate`;
export const convertToSimulationUrl = (previousStudyId) =>
	`/simulation/from_previous_study/${previousStudyId}`;

/* URLS to fetch Simulations - Material Cost */
export const getMaterialCostsUrl = (simulationId) =>
	`/simulation/${simulationId}/material_cost`;
export const deleteMaterialCostUrl = (simulationId, materialCostId) =>
	`/simulation/${simulationId}/material_cost/${materialCostId}`;
export const createMaterialCostUrl = (simulationId) =>
	`simulation/${simulationId}/material_cost`;
export const updateMaterialCostsUrl = (simulationId, materialCostId) =>
	`/simulation/${simulationId}/material_cost/${materialCostId}`;
export const getMaterialCostByIdUrl = (simulationId, materialCostId) =>
	`/simulation/${simulationId}/material_cost/${materialCostId}`;

export const simulationLaunchedUrl = (projectId, simulationId) =>
	`/projects/${projectId}/simulations/${simulationId}`;
export const simulationSocialSecurityUrl = (projectId, simulationId) =>
	`/social_security_cost/${projectId}/${simulationId}`;

/* URLS to fetch Inventory */
export const getInventoriesUrl = () => `/material_cost`;
export const createInventoryUrl = () => `/material_cost`;
export const updateInventoryUrl = (inventoryId) =>
	`/material_cost/${inventoryId}`;
export const getInventoryUrl = (inventoryId) => `/material_cost/${inventoryId}`;
export const deleteInventoryUrl = (inventoryId) =>
	`/material_cost/${inventoryId}`;

/* URLS to fetch Agreement */
export const createAgreementUrl = () => `/agreement`;
export const getAgreementByIdUrl = (agreementId) => `/agreement/${agreementId}`;
export const updateAgreementUrl = (agreementId) => `/agreement/${agreementId}`;
export const getAgreementUpdateUrlByIdUrl = (agreementId) =>
	`/agreement/${agreementId}/pdf`;
export const getAgreementDownloadUrlByIdUrl = (agreementId) =>
	`/agreement/${agreementId}/pdf`;

/* Solution */
export const getSolutionParametersByIdUrl = (simulationId) =>
	`/solution/${simulationId}/parameters`;
export const getSolutionDefaultParametersByIdUrl = (simulationId) =>
	`/solution/${simulationId}/default_parameters`;
export const updateSolutionParametersUrl = (simulationId) =>
	`/solution/${simulationId}/parameters`;
export const solutionUploadStatusParametersUrl = (simulationId) =>
	`/solution/${simulationId}/parameters/status`;
export const getSolutionExcelUrl = (simulationId) =>
	`/simulation/${simulationId}/solution_url`;
export const getSolutionEmployeesUrl = (simulationId) =>
	`/solution/${simulationId}/parameters/employees`;

/* Profile */
export const getProfileUrl = () => `/user/profile`;
export const updateProfileUrl = () => `/user/profile`;
export const updatePasswordUrl = () => `/user/change_password`;

/* App */

export const getUserSimulationCounterUrl = () => `/user/workspace`;
export const getUserPermissionsUrl = (id) => `/role/${id}`;
export const getWorkSpacesUrl = () => `/user/workspaces`;
export const getWorkSpacesByIdUrl = (workSpaceId) =>
	`/workspace/${workSpaceId}`;
export const getUsersByWorkSpacesUrl = (workSpacesId) =>
	`/workspace/${workSpacesId}/user`;
export const getRolesUrl = () => `/role`;
export const updateUserRoleUrl = (workspace_id, user_id) =>
	`/workspace/${workspace_id}/user/${user_id}`;

/* Project */
export const getProjectsUrl = () => `/project`;

/* Login */
export const getTokenUrl = () => `/auth/token`;

/* Previous Study */
export const getPreviousStudiesUrl = () => `/previous_study`;
export const getPreviousStudyUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}`;
export const launchPreviousStudyUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/launch`;
export const launchPreviousStudyAsyncUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/launch_async`;
export const previousStudyLaunchedUrl = (projectId, previousStudyId) =>
	`/previous_solution/${projectId}/${previousStudyId}`;
export const getPreviousStudySurrogatesUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/subrogate`;
export const createSurrogatePreviousStudyUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/subrogate `;
export const getSurrogatePreviousStudyUrl = (previousStudyId, surrogateId) =>
	`/previous_study/${previousStudyId}/subrogate/${surrogateId}`;
export const downloadSurrogatesPreviousStudyUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/subrogate/download_url`;
export const uploadSurrogatesPreviousStudyUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/subrogate/upload_url`;
export const uploadSurrogatesStatusPreviousStudyUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/subrogate/upload_status`;
export const getMaterialCostsPreviousStudyIdUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/material_cost`;
export const deleteMaterialCostPreviousStudyUrl = (
	previousStudyId,
	materialCostId
) => `/previous_study/${previousStudyId}/material_cost/${materialCostId}`;
export const getPreviousStudyUrlDownloadUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/solution_url`;
export const getPreviousStudyMaterialCostByIdUrl = (
	previousStudyId,
	materialCostId
) => `/previous_study/${previousStudyId}/material_cost/${materialCostId}`;
export const createPreviousStudyMaterialCostUrl = (previousStudyId) =>
	`previous_study/${previousStudyId}/material_cost`;

/* Previous Study Solution */
export const getPreviousStudyParametersByIdUrl = (previousStudyId) =>
	`/previous_study/${previousStudyId}/parameters`;
export const getPreviousStudyParametersByIdUrlAsync = (previousStudyId) =>
	`/previous_study/${previousStudyId}/parameters_async`;

/* Simulation OCR */
export const getSimulationOcrGetUploadUrlUrl = (simulationId) =>
	`/simulation_ocr/${simulationId}/pdf_url`;

export const startOcrExecutionUrl = (simulationId) =>
	`/simulation_ocr/${simulationId}/execution`;

export const getStatusOcrSimulationUrl = (simulationId) =>
	`/simulation_ocr/${simulationId}/status`;

export const uploadSubrogatesCandidatesUrl = (simulationId) =>
	`/simulation/${simulationId}/subrogate/candidates`;

export const getSUbrogateWithErrorWorkingDayUrl = (simulationId) =>
	`/simulation_ocr/${simulationId}/subrogate_error/working_day`;

export const getSUbrogateWithErrorCategoriesUrl = (simulationId) =>
	`/simulation_ocr/${simulationId}/subrogate_error/category`;
