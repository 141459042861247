import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { AssignmentInd } from '@mui/icons-material';
import { Box, Grid, List } from '@mui/material';

import { Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import {
	duplicateAgreement,
	getAgreements,
	removeAgreement,
} from '../../Services/Api/Api';
import { WhiteTitleContainer } from '../../components';
import { RolePermissionAgreement } from '../../components/Allowed/Allowed';
import AppCard from '../../components/AppCard/AppCard';
import Else from '../../components/Compounds/If/Else';
import If from '../../components/Compounds/If/If';
import Then from '../../components/Compounds/If/Then';
import IsLoading from '../../components/IsLoading/IsLoading';
import SearchBar from '../../components/SearchBar/SearchBar';
import { StyledButton } from '../../components/StyledComponents/Button';
import { ViewContainer } from '../../components/StyledContainers';
import useIsMounted from '../../hooks/useIsMounted';
import { showSnackbar } from '../../store/actions/snackbar';
import { get_permission } from '../../utilities/utils';

const MessageContainer = styled.div`
	padding: 100px;
`;

const CollectiveAgreements = ({ history }) => {
	const { auth } = useSelector((state) => state);
	const [queryString, setQueryString] = useState('');
	const [refresh, setRefresh] = useState(true);
	const dispatch = useDispatch();
	const { isMounted } = useIsMounted();
	const [thereIsAgreements, setThereIsAgreements] = useState(false);
	const [loadingAgreements, setLoadingAgreements] = useState(true);
	const [agreements, setAgreements] = useState([]);

	const getAgreementsList = async () => {
		setLoadingAgreements(true);
		try {
			const response = await getAgreements(queryString, auth?.workSpace?.id);
			if (isMounted) {
				setAgreements(response);
				setLoadingAgreements(false);

				if (response.length > 0) {
					setThereIsAgreements(true);
				}
			}
		} catch (e) {
			setLoadingAgreements(false);
			dispatch(
				showSnackbar({
					severity: 'error',
					message: e.message || 'Error desconocido en el servidor',
				})
			);
		}
	};

	const deleteAgreement = async (agreementId) => {
		setLoadingAgreements(true);
		try {
			await removeAgreement(agreementId, auth?.workSpace?.id);
			getAgreementsList();
		} catch (e) {
			dispatch(
				showSnackbar({
					severity: 'error',
					message: e.message || 'Error desconocido en el servidor',
				})
			);
		}
	};

	const duplicateAgreementAction = async (idx) => {
		setLoadingAgreements(true);
		const newId = uuidv4();
		try {
			const response = await duplicateAgreement(
				idx,
				newId,
				auth?.workSpace?.id
			);
			if (response.status === 200) {
				history.push(`/agreement-form/${newId}`);
			} else {
				setLoadingAgreements(false);
			}
		} catch (e) {
			setLoadingAgreements(false);
			dispatch(
				showSnackbar({
					severity: 'error',
					message: e.message || 'Error desconocido en el servidor',
				})
			);
		}
	};

	useEffect(() => {
		setLoadingAgreements(true);
		getAgreementsList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryString]);

	const newAgreementButton = () => (
		<StyledButton
			size='medium'
			variant='outlined'
			sx={{
				color: 'primary.main',
				borderColor: 'primary.main',
				textTransform: 'inherit',
			}}
			onClick={() => history.push('/agreement-form/ ')}
		>
			<AddIcon />
			Nuevo convenio
		</StyledButton>
	);

	const searchBar = () => (
		<SearchBar
			onType
			onSearch={(queryString) => {
				setQueryString(queryString);
				setRefresh(!refresh);
			}}
		/>
	);

	const messageBox = (message = '', subMessage = '') => (
		<Box>
			<MessageContainer>
				<Typography variant='h5' color='secondary'>
					{message}
				</Typography>
			</MessageContainer>
			<If conditional={subMessage}>
				<Then>
					<Typography color='secondary'>{subMessage}</Typography>
				</Then>
			</If>
		</Box>
	);

	const render = () => {
		const header = (
			<WhiteTitleContainer
				routes={[{ name: 'Inicio', href: '/' }, { name: 'Convenios' }]}
				title='Convenios'
				rightSide={
					<Grid item container spacing={2} justifyContent='flex-end'>
						<Grid item>{searchBar()}</Grid>
						<RolePermissionAgreement allowed={['create']}>
							<Grid item>{newAgreementButton()}</Grid>
						</RolePermissionAgreement>
					</Grid>
				}
				twoField
			/>
		);

		return (
			<div>
				{header}
				<ViewContainer>
					<IsLoading conditional={loadingAgreements}>
						<If conditional={agreements?.length === 0}>
							<Then>
								<If conditional={thereIsAgreements}>
									<Then>
										{messageBox(
											'Ningún convenio cumple los criterios de búsqueda',
											'Vuelve a intentarlo'
										)}
									</Then>
									<Else>
										{messageBox(
											'No hay ningún convenio todavía',
											'Comienza creando uno'
										)}
									</Else>
								</If>
							</Then>
							<Else>
								<List
									style={{
										overflow: 'auto',
										paddingLeft: 5,
										paddingRight: 5,
									}}
								>
									{agreements?.map((element, idx) => (
										<AppCard
											key={element.uid}
											element={element}
											leftIcon={<AssignmentInd />}
											typeActions={
												get_permission(auth?.permissions, 'agreement', [
													'delete',
													'update',
													'create',
												])
													? 'editDelete'
													: 'none'
											}
											idx={idx}
											onClick={(idx) =>
												history.push(`/agreement/${agreements[idx].uid}`)
											}
											onDelete={(idx) =>
												element.update_permissions
													? deleteAgreement(agreements[idx].uid)
													: undefined
											}
											onEdit={(idx) =>
												history.push(`/agreement-form/${agreements[idx].uid}`)
											}
											onDuplicate={(idx) =>
												duplicateAgreementAction(agreements[idx].uid)
											}
										/>
									))}
								</List>
							</Else>
						</If>
					</IsLoading>
				</ViewContainer>
			</div>
		);
	};

	return render();
};

export default CollectiveAgreements;
