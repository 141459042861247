import React, { Component, Fragment } from 'react';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVert from '@material-ui/icons/MoreVert';

class AppSimpleList extends Component {
	state = {
		openMenu: null,
	};

	renderIcon = () => {
		const { leftIcon } = this.props;

		const iconList = leftIcon ? (
			<ListItemAvatar>
				<Avatar>{leftIcon}</Avatar>
			</ListItemAvatar>
		) : null;

		return iconList;
	};

	renderActions = (idx) => {
		const { typeActions, customAction, onDelete, onEdit } = this.props;
		const { openMenu } = this.state;

		if ((!typeActions || typeActions === 'delete') && onDelete) {
			return (
				<IconButton
					edge='end'
					aria-label='delete'
					onClick={() => onDelete(idx)}
				>
					<DeleteIcon />
				</IconButton>
			);
		}

		if (typeActions === 'editDelete' && onDelete && onEdit) {
			return (
				<Fragment>
					<IconButton
						edge='end'
						aria-label='more'
						onClick={(event) =>
							this.setState({ openMenu: event.currentTarget })
						}
					>
						<MoreVert />
					</IconButton>
					<Menu
						id='simple-menu'
						anchorEl={openMenu}
						keepMounted
						open={Boolean(openMenu)}
						onClose={() => this.setState({ openMenu: null })}
					>
						<MenuItem
							onClick={() => {
								onDelete(idx);
								this.setState({ openMenu: null });
							}}
						>
							<DeleteIcon />
							Delete
						</MenuItem>
						<MenuItem
							onClick={() => {
								onEdit(idx);
								this.setState({ openMenu: null });
							}}
						>
							<EditIcon />
							Edit
						</MenuItem>
					</Menu>
				</Fragment>
			);
		}

		if (typeActions === 'none') {
			return null;
		}

		return customAction || null;
	};

	renderItems = () => {
		const { items, onClick, emptyMessage } = this.props;
		const listItems = [];

		if (items.length === 0) {
			return <Typography>{emptyMessage || 'No hay elementos'}</Typography>;
		}

		items.forEach((element, idx) => {
			listItems.push(
				<ListItem
					button={onClick ? true : false}
					onClick={onClick ? () => onClick(idx) : null}
				>
					{this.renderIcon()}
					<ListItemText
						primary={element.text}
						secondary={element.secondary ? element.secondary : null}
					/>
					<ListItemSecondaryAction>
						{this.renderActions(idx)}
					</ListItemSecondaryAction>
				</ListItem>
			);
		});

		return listItems;
	};

	render() {
		const { dense } = this.props;

		return (
			<List dense={dense} style={{ maxHeight: 200, overflow: 'auto' }}>
				{this.renderItems()}
			</List>
		);
	}
}

export default withTheme(AppSimpleList);
