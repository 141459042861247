import React from 'react';
import { Controller } from 'react-hook-form';

import esLocale from 'date-fns/locale/es';
import moment from 'moment';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControl, TextField } from '@mui/material';

const isValidDateValue = (strDate) => {
	const year = moment(strDate).year();
	if (year) {
		return year > 999 || 'El año debe tener 4 dígitos';
	}
	return strDate !== null ? 'Fecha invalida' : true;
};

export const FormDatePicker = ({
	name,
	control,
	label,
	rules = {},
	componentProps = {},
	inputProps = {},
	...othersProps
}) => (
	<FormControl size='small' fullWidth>
		<Controller
			name={name}
			control={control}
			rules={{ validate: isValidDateValue, ...rules }}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
					<DatePicker
						label={label}
						value={value}
						onChange={onChange}
						inputFormat='dd/MM/yyyy'
						inputProps={{
							placeholder: 'dd/mm/aaaa',
							'aria-label': label,
							...inputProps,
						}}
						{...othersProps}
						renderInput={(params) => (
							<TextField
								size='small'
								{...params}
								error={!!error}
								{...componentProps}
								helperText={error ? error.message : null}
							/>
						)}
					/>
				</LocalizationProvider>
			)}
		/>
	</FormControl>
);
