import agreements from './agreements';
import auth from './auth';
import content from './content';
import createSimulation from './createSimulation';
import error from './error';
import events from './events';
import inventoryStore from './inventory';
import previousStudy from './previousStudy';
import profile from './profile';
import projects from './projects';
import simulations from './simulations';
import snackbar from './snackbar';

// this is the list of final reducers
const rootReducer = {
	auth,
	error,
	projects,
	simulations,
	events,
	content,
	profile,
	agreements,
	createSimulation,
	inventoryStore,
	snackbar,
	previousStudy,
};

export default rootReducer;
