import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { setSnackbar } from '../../../../../store/actions/createSimulation';
import { mondayToFridayDays } from '../../../../../utilities/const';
import {
	clearSeconds,
	MinutesAndHoursDifference,
} from '../../../../../utilities/date';
import { getHourFromStringTimeHour } from '../../../../../utilities/utils';
import { validEmptyField } from '../../../../../utilities/validations';
import useGetPositionsByCenter from '../../useGetPositionsByCenter';
import { createTaskByHours, updateTaskByHour } from '../ApiServices';

export default function useTaskByHourModal({
	edit,
	setEdit,
	initialValues,
	setInitialValues,
	setRefresh,
	setOpenModal,
}) {
	const dispatch = useDispatch();
	const { centers, simulationId, basicData } = useSelector(
		(state) => state.createSimulation
	);

	const containerRef = useRef(null);
	const minDate = basicData?.startDate ? new Date(basicData.startDate) : null;
	const maxDate =
		basicData?.startDate && basicData?.serviceDuration
			? new Date(
					new Date(basicData.startDate).setMonth(
						new Date(basicData.startDate).getMonth() + basicData.serviceDuration
					)
			  )
			: null;

	const [days, setDays] = useState([]);
	const [errorCenters, setErrorCenters] = useState(false);
	const [errorPositions, setErrorPositions] = useState(false);
	const [errorTaskHour, setErrorTaskHour] = useState(false);
	const [errorSelectedDaysValidation, setErrorSelectedDaysValidation] =
		useState('');
	const [errorTaskName, setErrorTaskName] = useState(false);
	const [firstTimeSlot, setFirstTimeSlot] = useState(null);
	const [firstTimeEdit, setFirstTimeEdit] = useState(true);
	const [holidaysChecked, setHolidaysChecked] = useState(false);
	const [applyOnHolidays, setApplyOnHolidays] = useState(false);
	const [hour, setHour] = useState('');
	const [interval, setInterval] = useState('');
	const [listRangeWith, setListRangeWith] = useState([]);
	const [listRangeWithout, setListRangeWithout] = useState([]);
	const [maxWidth, setMaxWidth] = useState('sm');
	const [numberInterval, setNumberInterval] = useState(1);
	const [numberSimultaneousPeople, setNumberSimultaneousPeople] = useState(1);
	const [periodChecked, setPeriodChecked] = useState(false);
	const [periodsWithoutWork, setPeriodsWithoutWork] = useState([]);
	const [periodsWithWork, setPeriodsWithWork] = useState([]);
	const [secondTimeSlot, setSecondTimeSlot] = useState(null);
	const [selectedCenter, setSelectedCenter] = useState(
		centers?.data?.length === 1 ? centers.data[0] : null
	);
	const [selectedPosition, setSelectedPosition] = useState(null);
	const [showTypeC, setShowTypeC] = useState(false);
	const [simultaneousChecked, setSimultaneousChecked] = useState(false);
	const [taskInterval, setTaskInterval] = useState('fixed_period');
	const [taskName, setTaskName] = useState('');
	const [timeSlot, setTimeSlot] = useState(false);
	const [typeC, setTypeC] = useState(false);
	const [weekDaysChecked, setWeekDaysChecked] = useState(false);
	const [firstEdition, setFirstEdition] = useState(true);
	const [hoursDifference, setHoursDifference] = useState(null);
	const [minutesApart, setMinutesApart] = useState(null);

	const { loading: loadingPositions, data: positions } =
		useGetPositionsByCenter({ center: selectedCenter, simulationId });

	useEffect(() => {
		if (positions?.length >= 1 && selectedCenter) {
			if (edit && firstEdition) {
				setFirstEdition(false);
			} else if (initialValues?.center !== selectedCenter) {
				setSelectedPosition(positions?.length === 1 ? positions[0] : null);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [positions]);

	const updateTimeRecommendedSchedule = () => {
		const newFirstTime = clearSeconds(firstTimeSlot);
		const newSecondTime = clearSeconds(secondTimeSlot);
		if (newSecondTime < newFirstTime) {
			newSecondTime.setDate(newSecondTime.getDate() + 1);
		}
		if (secondTimeSlot && firstTimeSlot) {
			const { hours, minutes } = MinutesAndHoursDifference(
				newFirstTime,
				newSecondTime
			);
			setHoursDifference(hours);
			setMinutesApart(minutes);
		} else {
			setHoursDifference(null);
			setMinutesApart(null);
		}
	};

	useEffect(() => {
		updateTimeRecommendedSchedule();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [secondTimeSlot, firstTimeSlot]);

	const updateNumberInterval = () => {
		setHour(`${hoursDifference}:${minutesApart}`);
	};

	useEffect(() => {
		if (edit) {
			setFirstEdition(true);
			setSelectedCenter(initialValues?.center || null);
			setSelectedPosition(initialValues?.position);
			setTaskName(initialValues?.name);
			setInterval(initialValues?.frequency?.period);
			setTaskInterval(initialValues?.frequency?.period_type);
			setHolidaysChecked(initialValues?.work_in_holidays);
			setApplyOnHolidays(initialValues?.weekday_work_in_holidays);
			setDays(initialValues?.weekdays || []);
			if (initialValues?.recommended_time_slot !== null) {
				setTimeSlot(initialValues?.recommended_time_slot !== null);
				setFirstTimeSlot(
					initialValues?.recommended_time_slot?.time_init
						? getHourFromStringTimeHour(
								initialValues?.recommended_time_slot?.time_init
						  )
						: null
				);
				setSecondTimeSlot(
					initialValues?.recommended_time_slot?.time_end
						? getHourFromStringTimeHour(
								initialValues?.recommended_time_slot?.time_end
						  )
						: null
				);
			}
			const numOfWorkers = initialValues?.number_of_workers;
			setSimultaneousChecked(numOfWorkers && numOfWorkers > 0);
			setNumberSimultaneousPeople(numOfWorkers ? numOfWorkers : 1);
			setHour(
				`${initialValues?.work_time?.hours}:${
					initialValues?.work_time?.minutes < 10
						? `0${initialValues?.work_time?.minutes}`
						: initialValues?.work_time?.minutes
				}`
			);
			setListRangeWithout(initialValues?.periods_without_work || []);
			setListRangeWith(initialValues?.periods_with_work || []);
			setNumberInterval(initialValues?.frequency?.amount || 1);
			setPeriodsWithoutWork(initialValues?.periods_without_work || []);
			setPeriodsWithWork(initialValues?.periods_with_work || []);
			setPeriodChecked(
				initialValues?.periods_with_work?.length > 0 ||
					initialValues?.periods_without_work?.length > 0
			);
			setTypeC(initialValues.fill_complete_time_slot || false);
		} else {
			setFirstEdition(true);
			setSelectedCenter(
				(initialValues?.center?.length > 0 && initialValues?.center[0]) ||
					(centers?.data?.length === 1 ? centers.data[0] : null)
			);
			setSelectedPosition(
				(initialValues?.position?.length > 0 && initialValues?.position[0]) ||
					(positions?.data?.length === 1 ? positions.data[0] : null)
			);
			setTaskName('');
			setTaskInterval('fixed_period');
			setInterval(() => 'week');
			setHolidaysChecked(false);
			setApplyOnHolidays(false);
			setDays([]);
			setTimeSlot(false);
			setFirstTimeSlot(null);
			setSecondTimeSlot(null);
			setSimultaneousChecked(false);
			setNumberSimultaneousPeople(1);
			setHour('');
			setNumberInterval(1);
			setListRangeWithout([]);
			setListRangeWith([]);
			setTypeC(false);
			setPeriodsWithoutWork([]);
			setPeriodsWithWork([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, edit]);

	useEffect(() => {
		if (weekDaysChecked) {
			setDays(mondayToFridayDays);
		}
	}, [weekDaysChecked]);

	useEffect(() => {
		if (!mondayToFridayDays.every((val) => days.includes(val))) {
			setWeekDaysChecked(false);
		}
	}, [days]);

	useEffect(() => {
		setMaxWidth(periodChecked ? 'lg' : 'sm');
	}, [periodChecked]);

	useEffect(() => {
		if (taskInterval === 'fixed_period' && interval === 'day') {
			setShowTypeC(true);
		} else {
			setShowTypeC(false);
		}
	}, [taskInterval, interval]);

	useEffect(() => {
		if (!edit || (edit && !firstTimeEdit)) {
			if (taskInterval === 'fixed_period') {
				setInterval(() => 'week');
			} else {
				setInterval(() => 'day');
			}
		} else {
			setFirstTimeEdit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskInterval]);

	const handleSetHour = (value) => {
		const formatMatch = /^([0-9]?[0-9]?[0-9]?[0-9])((.|:)([0-5][0-9]))?$/.test(
			value
		);
		if (!formatMatch) {
			setErrorTaskHour(!formatMatch);
		} else {
			setErrorTaskHour(!formatMatch);
		}
		setHour(value);
	};

	const clearForm = () => {
		setPeriodsWithWork([]);
		setPeriodsWithoutWork([]);
		setPeriodChecked(false);
	};

	const handleResetModal = () => {
		setFirstTimeEdit(true);
		setEdit(false);
		clearForm();
		setSelectedPosition(null);
		setSelectedCenter(null);
		setFirstEdition(true);
	};

	const handleCloseModal = () => {
		handleResetModal();
		setOpenModal(false);
	};

	const ValidateForm = () => {
		let isValid = true;
		if (!validEmptyField(selectedCenter, setErrorCenters)) isValid = false;
		if (!validEmptyField(selectedPosition, setErrorPositions)) isValid = false;
		if (!validEmptyField(taskName, setErrorTaskName)) isValid = false;
		if (!validEmptyField(hour, setErrorTaskHour)) isValid = false;
		if (errorTaskHour) isValid = false;
		if (days.length !== 0 || holidaysChecked) {
			setErrorSelectedDaysValidation('');
		} else {
			isValid = false;
			setErrorSelectedDaysValidation('Selecciona algún día de la semana.');
		}
		if (applyOnHolidays && days.length === 0) {
			isValid = false;
			setErrorSelectedDaysValidation('Selecciona algún día de la semana.');
		} else if (days.length !== 0 || holidaysChecked) {
			setErrorSelectedDaysValidation('');
		}
		return isValid;
	};

	const handleCreateTaskByHour = async (values, continueAdding) => {
		const payload = values;
		try {
			if (edit) {
				await updateTaskByHour({
					simulationId,
					tasksByHourId: payload?.id,
					payload,
				});
				setRefresh((prev) => !prev);
			} else {
				await createTaskByHours({
					simulationId,
					tasksByHourId: payload?.id,
					payload,
				});
				setRefresh((prev) => !prev);
			}
			if (continueAdding) {
				handleResetModal();
			} else {
				handleCloseModal();
			}
		} catch (e) {
			dispatch(
				setSnackbar({
					openSnackbar: true,
					duration: 6000,
					severity: 'error',
					message: e?.message,
				})
			);
		}

		setInitialValues({});
	};

	const createSubmitObject = () => ({
		id: initialValues?.id || uuidv4(),
		center_id: selectedCenter?.id,
		position_id: selectedPosition?.id,
		name: taskName,
		work_in_holidays: holidaysChecked,
		weekday_work_in_holidays: applyOnHolidays,
		number_of_workers: simultaneousChecked
			? parseInt(numberSimultaneousPeople, 10)
			: null,
		weekdays: days,
		work_time: {
			hours: hour?.split(':')[0] ? parseInt(hour.split(':')[0], 10) : 0,
			minutes: hour?.split(':')[1] ? parseInt(hour.split(':')[1], 10) : 0,
		},
		frequency: {
			period_type: taskInterval,
			period: interval,
			amount: parseInt(numberInterval, 10),
		},
		periods_without_work: periodsWithoutWork,
		periods_with_work: periodsWithWork,
		recommended_time_slot:
			firstTimeSlot && secondTimeSlot && timeSlot
				? {
						time_init: moment(firstTimeSlot).format('HH:mm'),
						time_end: moment(secondTimeSlot).format('HH:mm'),
				  }
				: null,
		fill_complete_time_slot:
			taskInterval === 'fixed_period' && interval === 'day' ? typeC : false,
	});

	const handleSave = (continueAdding) => {
		setErrorPositions(false);
		if (ValidateForm()) {
			handleCreateTaskByHour(createSubmitObject(), continueAdding);
		}
	};

	return {
		centers,
		containerRef,
		days,
		errorCenters,
		errorPositions,
		errorTaskHour,
		errorTaskName,
		errorSelectedDaysValidation,
		firstTimeSlot,
		handleCloseModal,
		handleSave,
		handleSetHour,
		holidaysChecked,
		applyOnHolidays,
		setApplyOnHolidays,
		hour,
		interval,
		listRangeWith,
		listRangeWithout,
		maxWidth,
		numberInterval,
		numberSimultaneousPeople,
		periodChecked,
		positions,
		secondTimeSlot,
		selectedCenter,
		selectedPosition,
		setDays,
		setFirstTimeSlot,
		setHolidaysChecked,
		setInterval,
		setNumberInterval,
		setNumberSimultaneousPeople,
		setPeriodChecked,
		setPeriodsWithoutWork,
		setPeriodsWithWork,
		setSecondTimeSlot,
		setSelectedCenter,
		setSelectedPosition,
		setSimultaneousChecked,
		setTaskInterval,
		setTaskName,
		setTimeSlot,
		setTypeC,
		setWeekDaysChecked,
		showTypeC,
		simultaneousChecked,
		taskInterval,
		taskName,
		timeSlot,
		typeC,
		weekDaysChecked,
		minDate,
		maxDate,
		loadingPositions,
		hoursDifference,
		minutesApart,
		updateNumberInterval,
	};
}
