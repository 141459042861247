import React, { useState } from 'react';

import { AddCircleTwoTone } from '@mui/icons-material';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Autocomplete,
	TextField,
	createFilterOptions,
	Box,
} from '@mui/material';

import PositionsDialog from '../../../containers/Simulation/Workload/PositionsDialog';
import { StyledBoxContainer } from '../../StyledComponents/BoxContainer';
import { ContentTableCell } from '../../StyledComponents/ContentTableCell';
import HeaderTableCell from '../../StyledComponents/HeaderTableCell';
import { StyledIcon } from '../../StyledComponents/Icon';

const filter = createFilterOptions();

const WorkloadTable = ({
	rows,
	positions,
	setRowPositionName,
	updatePositionsList,
}) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogRow, setDialogRow] = useState({});

	const handleDialogOpen = (rowIndex) => () => {
		setDialogRow(rowIndex);
		setDialogOpen(true);
	};

	const handleChange = (index, value) => {
		let tempValue = '';
		if (value && value?.inputValue) {
			tempValue = value.inputValue;
		} else if (value && value?.name) {
			tempValue = value.name;
		} else {
			tempValue = value ? value : '';
		}
		setRowPositionName(index, tempValue);
	};

	return (
		<Box>
			<StyledBoxContainer>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow>
							<HeaderTableCell>Convenio</HeaderTableCell>
							<HeaderTableCell>Categoría</HeaderTableCell>
							<HeaderTableCell>Puesto</HeaderTableCell>
							<HeaderTableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => (
							<TableRow
								key={row?.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<ContentTableCell sx={{ width: '25%' }}>
									{row?.agreement_name || '-'}
								</ContentTableCell>
								<ContentTableCell sx={{ width: '25%' }}>
									{row?.category_name || '-'}
								</ContentTableCell>
								<TableCell sx={{ width: '45%' }}>
									<Autocomplete
										freeSolo
										selectOnFocus
										clearOnBlur
										handleHomeEndKeys
										value={row?.positions[0]?.name && row.positions[0].name}
										onChange={(event, newInputValue) => {
											handleChange(index, newInputValue);
										}}
										id='controllable-states-demo'
										options={positions}
										filterOptions={(options, params) => {
											const filtered = filter(options, params);
											const { inputValue } = params;
											const isExisting = options.some(
												(option) => inputValue === option.name
											);
											if (inputValue !== '' && !isExisting) {
												filtered.push({
													name: inputValue,
												});
											}
											return filtered;
										}}
										getOptionLabel={(option) => {
											if (typeof option === 'string') {
												return option;
											}
											if (option?.inputValue) {
												return option.inputValue;
											}
											return option.name;
										}}
										/* eslint-disable react/jsx-props-no-spreading */
										renderInput={(params) => (
											<TextField
												{...params}
												label={
													row?.positions[0]?.name ? '' : 'Seleccione un puesto'
												}
											/>
										)}
									/>
								</TableCell>
								<TableCell sx={{ width: '5%' }}>
									<StyledIcon
										onClick={handleDialogOpen(index)}
										data-testid='button_add_positions'
									>
										<AddCircleTwoTone
											color={row?.positions.length > 1 ? 'primary' : '#a6a6a6'}
										/>
									</StyledIcon>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</StyledBoxContainer>
			<PositionsDialog
				dialogOpen={dialogOpen}
				setDialogOpen={(val) => setDialogOpen(val)}
				row={rows[`${dialogRow}`]}
				positionList={positions}
				updatePositionsList={updatePositionsList}
			/>
		</Box>
	);
};

export default WorkloadTable;
