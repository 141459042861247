export default function useTaskByHourTable() {
	const isValidMessage = (row) =>
		row?.weekdays?.length > 0 ||
		row?.exists_recommended_time_slot ||
		row?.work_in_holidays ||
		row?.exists_period_with_or_wo_work;

	const tooltipText = (
		weekdays_working,
		exists_recommended_time_slot,
		work_in_holidays,
		exists_period_with_or_wo_work,
		weekday_work_in_holidays
	) => {
		const weekDays = [];
		const dayFormat = {
			monday: 'L',
			tuesday: 'M',
			wednesday: 'X',
			thursday: 'J',
			friday: 'V',
			saturday: 'S',
			sunday: 'D',
		};

		weekdays_working.forEach((day) => {
			if (dayFormat[day]) {
				weekDays.push(dayFormat[day]);
			}
		});
		if (work_in_holidays) {
			weekDays.push(' F');
		}
		if (exists_recommended_time_slot) {
			weekDays.push(' Existe un horario recomendado');
		}
		if (exists_period_with_or_wo_work) {
			weekDays.push(' Hay fechas con/sin esta tarea');
		}
		if (weekday_work_in_holidays) {
			weekDays.push(' (AF)');
		}
		return weekDays?.join(' ').trim();
	};

	const time = (workTime) =>
		`${workTime.hours}h ${
			workTime.minutes !== '00' ? `${workTime.minutes}m` : ''
		}`;

	const getFrequencyString = (period) => {
		switch (period) {
			case 'day':
				return 'día';
			case 'week':
				return 'semana';
			case 'month':
				return 'mes';
			case 'year':
				return 'año';
			default:
				return 'día';
		}
	};

	const getFrequencyXPeriodString = (period) => {
		switch (period) {
			case 'day':
				return 'días';
			case 'week':
				return 'semanas';
			case 'month':
				return 'meses';
			case 'year':
				return 'años';
			default:
				return 'días';
		}
	};

	const Frequency = (info, workTime) => {
		if (info.period_type === 'fixed_period') {
			return `${time(workTime)} / ${getFrequencyString(info.period)}`;
		}
		if (info.period_type === 'repeated_over_x_period') {
			return `${time(workTime)} cada ${info.amount} ${getFrequencyXPeriodString(
				info.period
			)}`;
		}
		if (info.period_type === 'repeated_x_times_over_period') {
			return `${time(workTime)} ${info.amount} veces / ${getFrequencyString(
				info.period
			)}`;
		}
	};

	return {
		isValidMessage,
		tooltipText,
		Frequency,
	};
}
