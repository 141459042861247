import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForever from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	Grid,
	IconButton,
	Typography,
	Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CardComponent = styled(Card)((props) => ({
	minHeight: '230px',
	textAlign: 'left',
	cursor: 'pointer',
	color: 'black',
	'& .MuiCardContent-root .MuiBox-root': {
		'& .cardTitleDivider': {
			color: props.theme.palette.text.secondary,
		},
	},
	'&:hover': {
		background:
			'linear-gradient(	135deg, rgba(135, 16, 193, 1) 15%, rgba(102, 49, 213, 1) 30%, rgba(86, 66, 223, 1) 54%,	rgba(68, 82, 231, 1) 71%, rgba(52, 98, 242, 1) 87%, rgba(36, 114, 252, 1) 100%		)',
		color: props.theme.palette.common.white,
		'& .MuiCardContent-root .MuiBox-root': {
			'& .cardTitleDivider': {
				color: props.theme.palette.common.white,
			},
			'& .MuiTypography-root': {
				color: `${props.theme.palette.common.white}`,
			},
		},
		'& .MuiCardActions-root': {
			'& .MuiButtonBase-root': {
				backgroundColor: 'white !important',
				'& .MuiSvgIcon-root': {
					color: props.theme.palette.primary.main,
				},
			},
		},
	},
	borderBottom: `4px solid ${props.status_color ? props.status_color : 'none'}`,
}));

const StyledCardContent = styled(CardContent)`
	min-height: 180px;
`;

const StyledTitle = styled(Typography)`
	font-size: 16px;
	font-weight: 700;
	// color: black;
`;

const IconButtonComponent = ({ onClick, label = 'iconButton', children }) => (
	<IconButton
		variant='outlined'
		aria-label={label}
		size='small'
		onClick={() => onClick()}
		sx={{
			backgroundColor: 'grey.iconBackground',
			borderRadius: '50%',
			zIndex: '10',
		}}
	>
		{children}
	</IconButton>
);

export const StyledCard = ({ onClick, children, ...rest }) => (
	<CardComponent {...rest} status_color={children?.status_color}>
		<StyledCardContent onClick={() => children.onClick()}>
			<StyledTitle gutterBottom variant='div' component='div'>
				{children.title.length > 120
					? `${children.name.substring(0, 100)}...`
					: children.title}
			</StyledTitle>
			{children?.subtitle && (
				<Box>
					<hr
						className='cardTitleDivider'
						style={{
							borderWidth: '0',
							borderStyle: 'solid',
							borderBottomWidth: 'thin',
						}}
					/>
					<Typography variant='caption' color='textSecondary'>
						{children.subtitle || null}
					</Typography>
				</Box>
			)}
		</StyledCardContent>
		<CardActions>
			<Grid container direction='row'>
				<Grid item direction='row' spacing={1} sm container>
					{children.double && (
						<Grid item>
							<IconButtonComponent
								label='double'
								onClick={() => children.onDouble()}
							>
								<ContentCopyIcon color='disabled' />
							</IconButtonComponent>
						</Grid>
					)}

					{children.edit && (
						<Grid item>
							<IconButtonComponent
								label='edit'
								onClick={() => children.onEdit()}
							>
								<EditIcon color='disabled' />
							</IconButtonComponent>
						</Grid>
					)}

					{children.delete && (
						<Grid item>
							<IconButtonComponent
								label='delete'
								onClick={() => children.onDelete()}
							>
								<DeleteForever color='disabled' />
							</IconButtonComponent>
						</Grid>
					)}
				</Grid>

				{children.convert_to_simulation && (
					<Grid item spacing={1}>
						<IconButtonComponent
							label='delete'
							onClick={() => children.onConvertToSimulation()}
						>
							<Tooltip title='Pasar a simulación'>
								<MoveDownIcon
									title='Convertir en simulacion'
									label='convert to simulation'
								/>
							</Tooltip>
						</IconButtonComponent>
					</Grid>
				)}
			</Grid>
		</CardActions>
	</CardComponent>
);
