import { makeStyles } from '@mui/styles';

/* xxs = 1px
xs = 2px
s = 5px
m = 10px
l = 20px
xl = 50px 
xxl=100px*/

const marginStyles = makeStyles({
	lxxs: {
		marginLeft: '1px',
	},
	lxs: {
		marginLeft: '2px',
	},
	ls: {
		marginLeft: '5px',
	},
	lm: {
		marginLeft: '10px',
	},
	ll: {
		marginLeft: '20px',
	},
	lxl: {
		marginLeft: '50px',
	},
	lxxl: {
		marginLeft: '100px',
	},

	rxxs: {
		marginRight: '1px',
	},
	rxs: {
		marginRight: '2px',
	},
	rs: {
		marginRight: '5px',
	},
	rm: {
		marginRight: '10px',
	},
	rl: {
		marginRight: '20px',
	},
	rxl: {
		marginRight: '50px',
	},
	rxxl: {
		marginRight: '100px',
	},
	tm: {
		marginTop: '10px',
	},
	tl: {
		marginTop: '20px',
	},
	txl: {
		marginTop: '50px',
	},
	auto: {
		margin: 'auto',
	},
});
export default marginStyles;
