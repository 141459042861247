import React from 'react';
import { useSelector } from 'react-redux';

import { RolePermissionPreviousStudy } from '../Allowed/Allowed';

const IsPreviousStudyEditable = ({ children }) => {
	const { previousStudy } = useSelector((state) => state);
	return (
		previousStudy?.status === 'Stopped' && (
			<RolePermissionPreviousStudy allowed={['update']}>
				{children}
			</RolePermissionPreviousStudy>
		)
	);
};

export default IsPreviousStudyEditable;
