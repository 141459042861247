import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import If from 'components/Compounds/If/If';
import Then from 'components/Compounds/If/Then';
import moment from 'moment';
import styled from 'styled-components';

// import GroupsIcon from '@mui/icons-material/Groups';
import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EuroOutlined from '@mui/icons-material/EuroOutlined';
import FeedIcon from '@mui/icons-material/Feed';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';

/* import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'; */
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Divider, Grid } from '@mui/material';

import { Typography, IconButton, Card } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTheme } from '@material-ui/core/styles';
import {
	Input,
	CalendarToday,
	GetApp, // InfoOutlined,
	// HighlightOffOutlined,
	// Cached,
	AccessTime,
} from '@material-ui/icons';
import { getProjectById } from 'Services/Api/Api';

// import { RolePermissionSimulation } from '../../components/Allowed/Allowed';
import {
	getPreviousStudy,
	getPreviousStudyUrlDownload,
} from '../../Services/Api/PreviousStudy';
import cost from '../../assets/imgs/cost.png';
// import { simulationSocialSecurityUrl } from '../../utilities/routes';
import persons from '../../assets/imgs/persons.png';
import { BreadCrumb, AppDataCard } from '../../components';
import { RolePermissionPreviousStudy } from '../../components/Allowed/Allowed';
import { StyledButton } from '../../components/StyledComponents/Button';
import {
	ViewContainer,
	RowFlex,
	ColumnFlex,
} from '../../components/StyledContainers';
import { ModifyPreviousStudyModal } from '../../containers/PreviousStudy/Solution/Modify/Modal';
import { msToTime, download, formatNumber } from '../../utilities/utils';
import './PreviousStudy.css';

const SimulationInfoRow = styled.div`
	flex-direction: column;
	display: flex;
	justify-content: space-around;
	align-items: left;
`;
// const RowContainer = styled.div`
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: left;
// 	margin-bottom: 10px;
// `;
const LineInfo = styled.div`
	margin-top: 5px;
	display: flex;
	flex-direction: row;
`;
const IconContainer = styled.div`
	display: flex;
	margin-right: 8px;
`;
const InlineIconButton = styled(IconButton)`
	padding: 0px !important;
	margin-left: 5px !important;
`;

const calculateTotalCost = (results) => {
	if (!results || Object.keys(results).length === 0) return '--';
	let cost = results.hiring_costs;
	Object.values(results.categories).forEach((c) => {
		cost += c.wage_costs;
		cost += c.ss_costs;
	});
	if (results?.absenteeism_cost) cost += results.absenteeism_cost;
	if (results?.material_costs) cost += results.material_costs;
	return formatNumber(cost);
};

const calculateTotalEmployees = (results) => {
	let count = 0;
	Object.values(results.categories).forEach((c) => {
		count += c.count_sub;
		count += c.count_new;
	});
	return count;
};
const get_project_name = async (project_id) => {
	const project = await getProjectById(project_id);
	return project.name;
};

/* eslint padded-blocks: ["error", "never"] */

const PreviousStudySolution = ({ match, theme }) => {
	// const dispatch = useDispatch();
	const history = useHistory();
	const {
		params: { projectId, previousStudyId },
	} = match;
	let projectName;
	// let retry = true;
	// const expandedEvent = null;
	const curTime = null;
	const [openModal, setOpenModal] = useState(false);
	const [currentSimulation, setPreviousStudy] = useState({});
	const [gettingPreviousStudy, setGettingPreviousStudy] = useState(false);

	const getPreviousStudyInfo = async () => {
		setGettingPreviousStudy(true);
		try {
			const { data } = await getPreviousStudy(previousStudyId);
			projectName = get_project_name(projectId);
			setPreviousStudy(data);
			setGettingPreviousStudy(false);
		} catch (e) {
			setSnackBarMessage(e.message);
			setGettingPreviousStudy(false);
		}
	};

	const getPreviousStudyDownloadUrl = async () => {
		const { name } = currentSimulation;
		const solution_filename = `Solucion_${name}.xlsx`;
		try {
			const { data } = await getPreviousStudyUrlDownload(previousStudyId);
			fetch(data).then((response) =>
				response.blob().then((blob) => download(blob, solution_filename))
			);
		} catch (e) {
			setSnackBarMessage(e.message);
		}
	};

	useEffect(() => {
		getPreviousStudyInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const renderSubevents = (subEvents) => (
	// 	<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
	// 		{subEvents.map((e) => (
	// 			<div key={e.created} style={{ display: 'flex', marginBottom: 6 }}>
	// 				<div style={{ flex: 1, maxWidth: 100 }} />
	// 				<Typography
	// 					align='left'
	// 					style={{ flex: 1, maxWidth: 150 }}
	// 					color='textSecondary'
	// 				>
	// 					{moment(e.created).format('HH:mm:ss')}
	// 				</Typography>
	// 				<Typography
	// 					align='left'
	// 					style={{ flex: 1, maxWidth: 250 }}
	// 					color='textSecondary'
	// 				>
	// 					{e.title}
	// 				</Typography>
	// 				<Typography align='left' style={{ flex: 1 }} color='textSecondary'>
	// 					{e.description}
	// 				</Typography>
	// 			</div>
	// 		))}
	// 	</AccordionDetails>
	// );

	// const renderEvent = (e, subEvents) => {
	// 	const expand = subEvents.length > 0;
	// 	let eventIcon = <InfoOutlined color='primary' />;
	// 	if (e.type_info === 'Error')
	// 		eventIcon = <HighlightOffOutlined style={{ fill: '#DD2E44' }} />;
	// 	else if (e.group == null) eventIcon = <InfoOutlined color='secondary' />;
	// 	return (
	// 		<Accordion
	// 			key={e.created}
	// 			expanded={expand && expandedEvent === e.created}
	// 			onChange={(_, expanded) =>
	// 				setState({ expandedEvent: expanded ? e.created : null })
	// 			}
	// 		>
	// 			<AccordionSummary
	// 				expandIcon={expand ? <ExpandMoreIcon /> : null}
	// 				{...(!expand ? { style: { cursor: 'default' } } : {})}
	// 			>
	// 				<div style={{ flex: 1, maxWidth: 100 }}>{eventIcon}</div>
	// 				<Typography
	// 					align='left'
	// 					style={{ flex: 1, maxWidth: 150 }}
	// 					color='textPrimary'
	// 				>
	// 					{moment(e.created).format('HH:mm:ss')}
	// 				</Typography>
	// 				<Typography
	// 					align='left'
	// 					style={{ flex: 1, maxWidth: 250 }}
	// 					color='textPrimary'
	// 				>
	// 					{e.title}
	// 				</Typography>
	// 				<Typography align='left' style={{ flex: 1 }} color='textPrimary'>
	// 					{e.description}
	// 				</Typography>
	// 			</AccordionSummary>
	// 			{renderSubevents(subEvents)}
	// 		</Accordion>
	// 	);
	// };

	const renderGreyTitle = (string, divider = false) => (
		<Typography
			variant='h6'
			align='left'
			style={{
				fontWeight: 800,
				color: theme.palette.common.grey,
			}}
		>
			{string}
			{divider && <Divider sx={{ borderBottomWidth: 'medium' }} />}
		</Typography>
	);

	// RUNNING;
	// const renderRefreshButton = () => {
	// 	// const { getSimulation } = props;

	// 	if (!currentSimulation || currentSimulation?.status !== 'Running')
	// 		return null;
	// 	return (
	// 		<InlineIconButton
	// 			style={{ animation: 'spin 3s linear infinite' }}
	// 			color='primary'
	// 			onClick={() => getPreviousStudyInfo(projectId, previousStudyId)}
	// 		>
	// 			<Cached />
	// 		</InlineIconButton>
	// 	);
	// };

	// const renderEvents = () => {
	// 	// const { simulations } = props;

	// 	const events = [];

	// 	if (currentSimulation) {
	// 		const debugEvents = currentSimulation?.events?.filter(
	// 			(e) => e.type_info === 'Debug'
	// 		);
	// 		currentSimulation?.events
	// 			?.filter((e) => e.type_info !== 'Debug')
	// 			?.forEach((event) => {
	// 				const subEvents = debugEvents.filter((e) => e.group === event.group);
	// 				events.push(renderEvent(event, subEvents));
	// 			});
	// 	}

	// 	return (
	// 		<div style={{ marginTop: '30px' }}>
	// 			<RowContainer>
	// 				<Typography variant='h6' color='textPrimary'>
	// 					Progreso
	// 				</Typography>
	// 				{renderRefreshButton()}
	// 			</RowContainer>
	// 			{events}
	// 		</div>
	// 	);
	// };

	// SOLVED
	const renderSolutionButton = () => (
		<StyledButton
			variant='outlined'
			sx={{
				textTransform: 'inherit',
				fontWeight: '800',
				color: 'primary.main',
			}}
			onClick={getPreviousStudyDownloadUrl}
			startIcon={<FileDownloadOutlinedIcon fontSize='small' />}
		>
			Descargar solución
		</StyledButton>
	);

	const renderDuration = (created, finished) => {
		if (!finished) return null;
		const diff = moment(finished).diff(created);
		return (
			<LineInfo>
				<IconContainer>
					<AccessTime color='primary' fontSize='small' />
				</IconContainer>
				<Typography color='textPrimary'>{msToTime(diff)}</Typography>
			</LineInfo>
		);
	};

	const renderInfoCard = (input_filename, data_url, created, finished) => (
		<AppDataCard style={{ width: '45%' }}>
			<SimulationInfoRow>
				<LineInfo>
					<IconContainer>
						<CalendarToday color='primary' fontSize='small' />
					</IconContainer>
					<Typography color='textPrimary'>
						{moment(created).format('DD/MM/YYYY, HH:mm:ss')}
					</Typography>
				</LineInfo>
				<Grid container item xs={12}>
					<Grid container item xs={12} sm={12} md={9} lg={8}>
						<LineInfo>
							<IconContainer>
								<Input color='primary' fontSize='small' />
							</IconContainer>
							<Typography color='textPrimary'>
								Formulario
								{data_url ? (
									<InlineIconButton
										onClick={() => {
											fetch(data_url).then((response) =>
												response
													.blob()
													.then((blob) => download(blob, input_filename))
											);
										}}
									>
										<GetApp fontSize='small' />
									</InlineIconButton>
								) : (
									<InlineIconButton
										onClick={() => {
											history.push(`/previous/${projectId}/${previousStudyId}`);
										}}
									>
										<FeedIcon sx={{ mt: -1 }} color='primary' />
									</InlineIconButton>
								)}
							</Typography>
							{finished && (
								<Box sx={{ display: 'flex' }}>
									<RolePermissionPreviousStudy allowed={['update']}>
										<Box>
											<Typography
												color='textPrimary'
												style={{ marginLeft: 20 }}
											>
												<If conditional={openModal}>
													<Then>
														<ModifyPreviousStudyModal
															openModal={openModal}
															setOpenModal={(open) => setOpenModal(open)}
															previousStudyId={previousStudyId}
															setRefreshPage={() =>
																window.location.reload(false)
															}
														/>
													</Then>
												</If>
												Modificar
												<InlineIconButton onClick={() => setOpenModal(true)}>
													<AutoFixHighIcon sx={{ mt: -1 }} color='primary' />
												</InlineIconButton>
											</Typography>
										</Box>
									</RolePermissionPreviousStudy>
									{/* <RolePermissionPreviousStudy allowed={['update']}>
										<Box ml={2}>
											<InlineIconButton
												onClick={() => {
													history.push(
														simulationSocialSecurityUrl(
															projectId,
															previousStudyId
														)
													);
												}}
											>
												<GroupsIcon
													style={{ fontSize: 38 }}
													sx={{ mt: -1 }}
													color='primary'
												/>
											</InlineIconButton>
										</Box>
									</RolePermissionPreviousStudy> */}
								</Box>
							)}
						</LineInfo>
					</Grid>
					<Grid container item xs={12} md={3} lg={4} sm={12}>
						{renderSolutionButton()}
					</Grid>
				</Grid>
				{renderDuration(created, finished)}
			</SimulationInfoRow>
		</AppDataCard>
	);

	const dataTypography = (string, icon) => (
		<RowFlex style={{ marginTop: 10 }}>
			<Typography
				variant='h4'
				style={{ fontWeight: 600, color: theme.palette.common.black }}
			>
				{string}
			</Typography>
			{icon}
		</RowFlex>
	);

	const renderCostsCard = (results) => {
		const old_ss_costs = Object.values(results.categories)
			.map((c) => c.ss_costs)
			.reduce((a, b) => a + b, 0);
		const ss_costs =
			'social_security' in results ? results.social_security : old_ss_costs;
		const wage_costs = Object.values(results.categories)
			.map((c) => c.wage_costs)
			.reduce((a, b) => a + b, 0);

		const euroIcon = [
			<EuroOutlined
				key='euro-icon'
				style={{
					marginBottom: '-5px',
					marginLeft: '5px',
				}}
			/>,
		];

		return (
			<Card style={{ marginTop: 20 }}>
				<RowFlex style={{ margin: 20, justifyContent: 'space-around' }}>
					<ColumnFlex>
						{renderGreyTitle(['Materiales', euroIcon])}
						{dataTypography(formatNumber(results?.material_cost || 0))}
					</ColumnFlex>
					<ColumnFlex>
						{renderGreyTitle(['Absentismo', euroIcon])}
						{dataTypography(formatNumber(results?.absenteeism_cost || 0))}
					</ColumnFlex>
					<ColumnFlex>
						{renderGreyTitle(['Seguridad social', euroIcon])}
						{dataTypography(formatNumber(ss_costs))}
					</ColumnFlex>
					<ColumnFlex>
						{renderGreyTitle(['Salarial', euroIcon])}
						{dataTypography(formatNumber(wage_costs))}
					</ColumnFlex>
					<ColumnFlex>
						{renderGreyTitle(['Beneficio', euroIcon])}
						{dataTypography(formatNumber(results?.benefit || 0))}
					</ColumnFlex>
				</RowFlex>
			</Card>
		);
	};

	const renderTimerCard = (created) => {
		if (currentSimulation.status !== 'Running') return null;
		let diff;
		if (created && curTime) diff = moment(curTime).diff(created);
		return (
			<AppDataCard title='Tiempo transcurrido' style={{ width: '50%' }}>
				{dataTypography(
					msToTime(diff),
					<AccessTimeOutlined
						color='primary'
						style={{ marginTop: -15, fontSize: 55 }}
					/>
				)}
			</AppDataCard>
		);
	};

	const renderSimulationInfo = () => {
		// const { getSimulation, theme } = props;
		if (!currentSimulation) return null;
		const { data_url, name, finished, started, results } = currentSimulation;

		const cardTitle = (title, color = theme.palette.primary.main) => (
			<Typography
				align='left'
				variant='body1'
				style={{
					fontWeight: 'bold',
					marginBottom: 20,
					color,
				}}
			>
				{title}
			</Typography>
		);

		// request simulation data again
		// if (!data_url && retry) {
		// 	setTimeout(getSimulation(projectId, previousStudyId), 200);
		// 	retry = false;
		// }

		const input_filename = name;

		let parsedResults = {};
		try {
			if (typeof results === 'string') {
				const replaceSingleQuotes = results?.replace(/'/g, '"');
				parsedResults = JSON.parse(replaceSingleQuotes);
			} else {
				parsedResults = { ...results };
			}
		} catch (e) {
			alert(e);
		} // eslint-disable-line

		return (
			<div style={{ marginTop: 30 }}>
				<Typography
					align='left'
					variant='h6'
					style={{ fontWeight: 'bold', marginBottom: 20, color: 'black' }}
				>
					{name}
				</Typography>
				<ColumnFlex>
					<RowFlex>
						{renderInfoCard(input_filename, data_url, started, finished)}
						{(parsedResults.categories && [
							<AppDataCard
								key='employees'
								title={cardTitle('Empleados/as')}
								style={{
									width: '20%',
									borderBottom: `5px solid ${theme.palette.primary.main}`,
								}}
								borderLeft='none'
							>
								{dataTypography(
									calculateTotalEmployees(parsedResults),
									<img
										src={persons}
										style={{
											marginTop: -30,
											fontSize: 55,
										}}
										alt=''
									/>
								)}
							</AppDataCard>,
							<AppDataCard
								key='cost'
								title={cardTitle(
									'Coste Total',
									theme.palette.common.transition5
								)}
								style={{
									width: '30%',
									borderBottom: `5px solid ${theme.palette.common.transition5}`,
								}}
								borderLeft='none'
							>
								{dataTypography(
									parsedResults?.total_cost
										? formatNumber(parsedResults.total_cost)
										: calculateTotalCost(parsedResults),
									<img
										src={cost}
										alt=''
										style={{ marginTop: -30, fontSize: 55 }}
									/>
								)}
							</AppDataCard>,
						]) ||
							renderTimerCard(started)}
					</RowFlex>
					{parsedResults.categories && renderCostsCard(parsedResults)}
				</ColumnFlex>
			</div>
		);
	};

	if (gettingPreviousStudy) {
		return (
			<ViewContainer>
				<CircularProgress />
			</ViewContainer>
		);
	}

	return (
		<ViewContainer>
			<BreadCrumb
				routes={[
					{ name: 'Inicio', href: '/' },
					{ name: 'Licitaciones', href: '/projects' },
					{ name: projectName, href: `/projects/${projectId}` },
					{
						name: currentSimulation?.name || '',
					},
				]}
			/>
			{renderSimulationInfo()}
			{/* {renderEvents()} */}
		</ViewContainer>
	);
};
export default withRouter(withTheme(PreviousStudySolution));
