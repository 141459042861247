import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { get_permission } from '../../utilities/utils';

const matchPermissions = (module, allowed) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { auth } = useSelector((state) => state);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [isAllowed, setIsAllowed] = useState(false);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		setIsAllowed(get_permission(auth?.permissions, module, allowed));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth]);
	return isAllowed;
};

export const Allowed = ({ module, allowed, children }) =>
	matchPermissions(module, allowed) ? children : null;

export const RolePermissionAgreement = ({ allowed, children }) =>
	matchPermissions('agreement', allowed) ? children : null;

export const RolePermissionSimulation = ({ allowed, children }) =>
	matchPermissions('simulation', allowed) ? children : null;

export const RolePermissionInventory = ({ allowed, children }) =>
	matchPermissions('inventory', allowed) ? children : null;

export const RolePermissionPreviousStudy = ({ allowed, children }) =>
	matchPermissions('previous_study', allowed) ? children : null;
