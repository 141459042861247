import React from 'react';

import styledComponents from 'styled-components';

import InfoIcon from '@mui/icons-material/Info';
import { Grid, Typography, Tooltip, InputAdornment } from '@mui/material';

import { AddCircleOutline } from '../../../components/Button/AddCircleOutline';
import { MonthDateRange } from '../../../components/Date/MonthDateRange';
import { YearDateRange } from '../../../components/Date/YearDateRange';
import { FormCheckbox } from '../../../components/Form/Checkbox';
import { FormDropdown } from '../../../components/Form/Dropdown';
import { FormInputNumber } from '../../../components/Form/FormInputNumber';
import { FormInputText } from '../../../components/Form/InputText';
import { FormYearPicker } from '../../../components/Form/YearPicker';
import IsLoading from '../../../components/IsLoading/IsLoading';
import { StyledButton } from '../../../components/StyledComponents/Button';
import { isYearGraderOrEqual1000 } from '../../../utilities/utils';
import { AgreementModal } from './AgreementModal';
import CategoriesTable from './CategoriesTable';
import useAgreementForm from './useAgreementForm';

const Label = styledComponents.div`margin-top: 8px;`;

const areValidDatesValue = (startDate, endDate) => {
	const startYear = new Date(startDate)?.getFullYear();
	const endYear = new Date(endDate)?.getFullYear();
	if (
		!isYearGraderOrEqual1000(startDate) ||
		!isYearGraderOrEqual1000(endDate)
	) {
		return 'El año debe tener 4 dígitos';
	}
	if (startYear > endYear) {
		return 'El año de inicio debe ser menor al de fin';
	}
	return startDate === null || endDate === null ? 'Fecha inválida' : true;
};

const isValidDateValue = (strDate) => {
	if (!isYearGraderOrEqual1000(strDate)) {
		return 'El año debe tener 4 dígitos';
	}
	return strDate === null ? 'Fecha inválida' : true;
};

const AgreementForm = ({ agreementId }) => {
	const {
		control,
		gridInfo,
		agreementTypes,
		periodIntervals,
		openModal,
		categories,
		datesInterval,
		loading,
		setCategories,
		addCategory,
		deleteCategory,
		selectCategoryToEdit,
		categoryToEdit,
		editCategory,
		selectedFixedSeniorityBonus,
		watch,
		handleSubmit,
		onSubmit,
		setETValues,
		setOpenModal,
	} = useAgreementForm({
		agreementId,
	});

	return (
		<Grid sx={{ flexGrow: 1, mt: 3 }} container spacing={2}>
			<Grid item xs={12}>
				<IsLoading conditional={loading}>
					<Grid item xs={12}>
						<Grid item xs={12} container spacing={2}>
							<Grid item xs={7}>
								<FormInputText
									name='name'
									rules={{ required: 'Valor requerido' }}
									control={control}
									label='Nombre'
								/>
							</Grid>
							<Grid item xs={5}>
								<YearDateRange
									label='Vigencia:'
									names={['yearFrom', 'yearTo']}
									control={control}
									rules={[
										{ required: 'Valor requerido' },
										{ required: 'Valor requerido' },
									]}
									inputProps={[
										{ 'data-testid': 'agreementDateForm' },
										{ 'data-testid': 'agreementDateTo' },
									]}
									rangeRule={{
										validate: {
											yearRangeValidation: () =>
												areValidDatesValue(watch('yearFrom'), watch('yearTo')),
										},
									}}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} mt={4}>
						<Typography variant='h6' align='left'>
							Jornadas y descansos
						</Typography>
					</Grid>
					<Grid item xs={12} container spacing={2} mt={1}>
						<Grid item xs={10}>
							<Grid container spacing={2}>
								{gridInfo.map((gridItem, index) => (
									<Grid
										item
										key={gridItem.id}
										xs={6}
										ml={index % 2 === 0 ? 0 : 0}
									>
										<Grid container spacing={2}>
											<Grid
												item
												xs={index % 2 === 0 ? 7 : 8}
												sx={{ mb: 1, textAlign: 'left' }}
											>
												<Label>{gridItem.label}</Label>
											</Grid>
											<Grid item xs={4} sx={{ mb: 1 }}>
												<FormInputNumber
													name={gridItem.name}
													control={control}
													data-testid={gridItem.name}
													max={gridItem?.max}
													min={gridItem?.min}
													required={gridItem.required}
												/>
											</Grid>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
						<Grid container item xs={2} sx={{ float: 'left' }}>
							<Grid item xs={10}>
								<StyledButton
									fullWidth
									sx={{ color: 'text.primary' }}
									onClick={setETValues}
									variant='outlined'
									data-testid='ETValues'
								>
									Valores ET
								</StyledButton>
							</Grid>
							<Grid item xs={1}>
								<Tooltip
									placement='right'
									title='Las jornadas y descansos se rellenarán siguiendo las normas del Estatuto de los Trabajadores.'
								>
									<InfoIcon color='primary' />
								</Tooltip>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} container mt={1} spacing={2}>
						<Grid item xs={5}>
							<Grid container spacing={2}>
								<Grid item xs={7} sx={{ mb: 1, textAlign: 'left' }}>
									<Label>Días de vacaciones</Label>
								</Grid>
								<Grid item xs={4} sx={{ mb: 1 }}>
									<FormInputNumber
										name='holidays'
										control={control}
										label=''
										data-testid='holidays'
										required
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={2}>
							<Grid container spacing={2} sx={{ alignItems: 'center' }}>
								<Grid item xs={3} sx={{ mb: 1, textAlign: 'left' }}>
									Tipo
								</Grid>
								<Grid item xs={9} sx={{ mb: 1 }}>
									<FormDropdown
										name='type'
										control={control}
										options={agreementTypes}
										inputProps={{
											'data-testid': 'type',
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={5}>
							<MonthDateRange
								names={['monthFrom', 'monthTo']}
								control={control}
								label={<Label>Periodo</Label>}
								labels={['', '']}
								periodIntervals={periodIntervals}
								rules={[
									{ required: 'Valor requerido' },
									{ required: 'Valor requerido' },
								]}
								rangeRule={{
									validate: {
										monthRangeValidation: () =>
											watch('monthFrom') < watch('monthTo') ||
											'El mes de inicio debe ser menor al de fin',
									},
								}}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} mt={2} container spacing={2}>
						<Grid item container xs={5}>
							<Grid container spacing={2}>
								<Grid item xs={7}>
									<Typography variant='h6' align='left'>
										Tablas salariales
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<FormYearPicker
										name='salaryTablesYear'
										control={control}
										rules={{
											required: 'Valor requerido',
											validate: (str) => isValidDateValue(str),
										}}
										inputProps={{
											placeholder: 'aaaa',
											'data-testid': 'salaryTablesYear',
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={7}>
							<Grid item xs={12}>
								<Typography variant='h6' align='left' style={{ marginLeft: 5 }}>
									Incremento salarial por año
								</Typography>
							</Grid>
							<Grid container item xs={12} spacing={2} mt={2}>
								<Grid container item xs={4}>
									{datesInterval.map((date, index) => (
										<Grid
											container
											spacing={2}
											key={date.id}
											mb={1}
											sx={{ alignItems: 'center' }}
										>
											<Grid item xs={3}>
												{date.value}
											</Grid>
											<Grid item xs={7}>
												<FormInputNumber
													name={`annualSalaryIncrease${date.value}`}
													control={control}
													sx={{ width: '100px' }}
													min={0}
													max={100}
													disabled={index !== 0 && watch('applyAllYears')}
													endAdornment={
														<InputAdornment position='end'>%</InputAdornment>
													}
												/>
											</Grid>
										</Grid>
									))}
								</Grid>
								<Grid container item xs={8} spacing={2}>
									<Grid container item spacing={2} sx={{ textAlign: 'start' }}>
										<Grid item xs={2}>
											<FormCheckbox name='applyAllYears' control={control} />
										</Grid>
										<Grid item xs={8} sx={{ float: 'left' }} mt={1}>
											Aplicar a todos los años
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item xs={12} sx={{ alignItems: 'center' }}>
						<Grid item sx={{ marginTop: '4px' }}>
							Añadir categoría
						</Grid>
						<Grid item>
							<AddCircleOutline
								ariaLabel='moreCategories'
								onClick={() => setOpenModal(true)}
							/>
						</Grid>
						<AgreementModal
							openModal={openModal}
							setOpenModal={setOpenModal}
							setCategories={setCategories}
							addCategory={addCategory}
							categoryToEdit={categoryToEdit}
							editCategory={editCategory}
						/>
					</Grid>
					<Grid item xs={12}>
						<CategoriesTable
							rows={categories}
							deleteCategory={deleteCategory}
							selectCategoryToEdit={selectCategoryToEdit}
						/>
					</Grid>
					<Grid item container xs={12} mt={4}>
						<Grid item xs={12}>
							<Typography variant='h6' align='left'>
								Antigüedad
							</Typography>
						</Grid>
						<Grid container mt={2}>
							<Grid item container spacing={1} xs={4}>
								<Grid item xs={4}>
									<FormInputNumber
										name='antiquityPercentage'
										control={control}
										min={0}
										max={100}
										disabled={selectedFixedSeniorityBonus}
										endAdornment={
											<InputAdornment position='end'>%</InputAdornment>
										}
									/>
								</Grid>
								<Grid item>
									<Label>cada</Label>
								</Grid>
								<Grid item xs={4}>
									<FormInputNumber
										name='antiquityYears'
										control={control}
										sx={{ width: '100px' }}
										min={0}
										disabled={selectedFixedSeniorityBonus}
									/>
								</Grid>
								<Grid item>
									<Label>años</Label>
								</Grid>
							</Grid>
							<Grid item container spacing={0} xs={6}>
								<Grid xs={4} item>
									<Label>Porcentaje máximo</Label>
								</Grid>
								<Grid item xs={3}>
									<FormInputNumber
										name='maxAntiquityPercentage'
										control={control}
										max={100}
										min={0}
										disabled={selectedFixedSeniorityBonus}
										endAdornment={
											<InputAdornment position='end'>%</InputAdornment>
										}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{ mt: 2, textAlign: 'left' }}>
						{selectedFixedSeniorityBonus &&
							`Hay al menos 1 categoría con plus/cantidad fija. Elimínalo para poder usar esta opción.`}
					</Grid>
					<Grid item xs={12} sx={{ mt: 4 }}>
						<Grid
							item
							xs={12}
							sx={{ display: 'flex', justifyContent: 'right' }}
						>
							<StyledButton
								onClick={handleSubmit(onSubmit)}
								variant='contained'
								data-testid='agreementSaveButton'
							>
								Guardar
							</StyledButton>
						</Grid>
					</Grid>
				</IsLoading>
			</Grid>
		</Grid>
	);
};
export default AgreementForm;
