import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';

import { StyledBoxContainer } from '../../../../components/StyledComponents/BoxContainer';
import HeaderTableCell from '../../../../components/StyledComponents/HeaderTableCell';
import { StyledIcon } from '../../../../components/StyledComponents/Icon';
import { formatNumber } from '../../../../utilities/utils';

const isPlusByDaySelected = (category) => {
	let response = false;
	const daysPluses = [
		'plusByDayHolidays',
		'plusByDaySaturday',
		'plusByDaySunday',
		'plusByDayMonday',
		'plusByDayTuesday',
		'plusByDayWednesday',
		'plusByDayThursday',
		'plusByDayFriday',
	];

	daysPluses.forEach((day) => {
		if (category[day] !== '') {
			response = true;
		}
	});

	return response;
};

const CategoriesTable = ({
	rows = [],
	deleteCategory,
	selectCategoryToEdit,
}) => (
	<StyledBoxContainer>
		<Table aria-label='Categorías'>
			<TableHead>
				<TableRow>
					<HeaderTableCell>Categoría</HeaderTableCell>
					<HeaderTableCell>Salario total anual</HeaderTableCell>
					<HeaderTableCell>Salario base anual</HeaderTableCell>
					<HeaderTableCell>Nocturnidad</HeaderTableCell>
					<HeaderTableCell>Por dias (festivos,etc.)</HeaderTableCell>
					<HeaderTableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row) => (
					<TableRow key={row?.id}>
						<TableCell>{row?.categoryName || '-'}</TableCell>
						<TableCell>
							{row?.netSalary ? formatNumber(parseFloat(row?.netSalary)) : '-'}
						</TableCell>
						<TableCell>
							{row?.annualBaseSalary
								? formatNumber(parseFloat(row?.annualBaseSalary))
								: '-'}
						</TableCell>
						<TableCell>
							{row?.plusNocturnalValue ? <CheckIcon color='primary' /> : '-'}
						</TableCell>
						<TableCell>
							{isPlusByDaySelected(row) ? <CheckIcon color='primary' /> : '-'}
						</TableCell>
						<TableCell>
							<Box sx={{ display: 'flex' }}>
								<StyledIcon
									sx={{ padding: '5px' }}
									variant='contained'
									color='primary'
									label='delete'
									data-testid='deleteButton'
									onClick={() => deleteCategory(row.id)}
								>
									<DeleteIcon />
								</StyledIcon>
								<StyledIcon
									sx={{ marginLeft: 1 }}
									variant='contained'
									color='primary'
									label='edit'
									onClick={() => selectCategoryToEdit(row.id)}
								>
									<EditIcon />
								</StyledIcon>
							</Box>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</StyledBoxContainer>
);

export default CategoriesTable;
