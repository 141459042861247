import React, { Component } from 'react';

import DateFnsUtils from '@date-io/date-fns';

import { withTheme } from '@material-ui/core/styles';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';

class AppDateInput extends Component {
	state = {};

	componentDidUpdate(prevProps, prevState) {
		const { selectedDate } = this.state;
		const { selectedDate: prevSelectedDate } = prevState;
		const { onChange, type, name } = this.props;

		if (prevSelectedDate !== selectedDate) {
			onChange({
				target: {
					value: selectedDate,
					type,
					name,
				},
			});
		}
	}

	handleDateChange = (selectedDate) => {
		this.setState({ selectedDate });
	};

	render() {
		const { selectedDate } = this.state;
		const { type, name, onChange, ...newProps } = this.props;

		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					disableToolbar
					variant='inline'
					format='yyyy-MM-dd'
					id='date-picker-inline'
					label='Date picker inline'
					value={selectedDate}
					onChange={this.handleDateChange}
					{...newProps}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
				/>
			</MuiPickersUtilsProvider>
		);
	}
}

export default withTheme(AppDateInput);
