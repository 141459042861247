import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
	Button,
	Dialog,
	DialogTitle as MuiDialogTitle,
	DialogActions,
	DialogContent,
	Grid,
	Tooltip,
	Typography,
	Box,
} from '@mui/material';

import DialogTitle from '../../../components/Dialog/DialogTitle';
import { FormAutoComplete } from '../../../components/Form/AutoComplete';
import IsSimulationEditable from '../../../components/Simulations/IsSimulationEditable';
import { StyledButton } from '../../../components/StyledComponents/Button';

const PositionsDialog = ({
	dialogOpen,
	setDialogOpen,
	row,
	positionList,
	updatePositionsList,
}) => {
	const defaultValues = {
		position1: row?.positions[0] || null,
		position2: row?.positions[1] || null,
		position3: row?.positions[2] || null,
		position4: row?.positions[3] || null,
		position5: row?.positions[4] || null,
	};

	const { handleSubmit, control, setValue } = useForm({ defaultValues });

	useEffect(() => {
		[0, 1, 2, 3, 4].forEach((value) => {
			if (row?.positions[value]) {
				const a = positionList.filter(
					(pos) => pos.id === row?.positions[value].id
				);
				setValue(`position${value + 1}`, a[0]);
			} else {
				setValue(`position${value + 1}`, null);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [row]);

	const handleDialogClose = () => {
		setDialogOpen(false);
	};

	const handleDialogSubmit = (data) => {
		const parsedData = Object.values(data);
		const filtered = parsedData.filter((position) => position !== null);
		updatePositionsList(row.id, filtered);
		setDialogOpen(false);
	};

	return (
		<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
			<MuiDialogTitle>
				<DialogTitle title='Añadir puestos secundarios' />
				<Typography mt={4}>
					Categoria: {`${row?.category_name} `.toUpperCase()}
				</Typography>
			</MuiDialogTitle>
			<DialogContent>
				<Grid container spacing={1} mt={0.1}>
					<Grid item xs={11}>
						<FormAutoComplete
							name='position1'
							control={control}
							label='Puesto 1'
							options={positionList}
							optionLabel='name'
						/>
					</Grid>
					<Grid item xs={1}>
						<Tooltip placement='right' title='Puesto principal de la categoria'>
							<Box
								sx={{
									height: '30px',
									width: '30px',
									backgroundColor: 'primary.main',
									borderRadius: '50%',
									border: '1px solid',
									textAlign: 'center',
									alignItems: 'center',
									color: 'white',
									fontSize: '20px',
								}}
							>
								1º
							</Box>
						</Tooltip>
					</Grid>
					{[2, 3, 4, 5].map((value) => (
						<Grid item xs={11} key={`gridItem${value}`} sx={{ mt: 1 }}>
							<FormAutoComplete
								name={`position${value}`}
								control={control}
								label={`Puesto ${value}`}
								options={positionList}
								optionLabel='name'
							/>
						</Grid>
					))}
				</Grid>

				<DialogActions>
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						spacing={1}
						mt={2}
					>
						<Grid item>
							<Button onClick={handleDialogClose}>Cancelar</Button>
						</Grid>
						<IsSimulationEditable>
							<Grid item>
								<StyledButton
									onClick={handleSubmit(handleDialogSubmit)}
									variant='contained'
								>
									Guardar
								</StyledButton>
							</Grid>
						</IsSimulationEditable>
					</Grid>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
export default PositionsDialog;
