import { useEffect, useState } from 'react';

import AxiosWrapper from '../utilities/connection/AxiosWrapper';

const useGetResponseFromApi = ({ url, payload = {}, refresh = true }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [error, setError] = useState('');
	useEffect(() => {
		setLoading(true);
		AxiosWrapper.get(url, JSON.stringify(payload))
			.then((respuesta) => {
				setData(respuesta.data);
				setLoading(false);
			})
			.catch((e) => {
				setError(e);
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh]);
	return { loading, data, error };
};

export default useGetResponseFromApi;
