import React, { Component } from 'react';

import styled from 'styled-components';

import { Fab } from '@material-ui/core';

const StyledFab = styled(Fab)`
	position: absolute !important;
	right: 10%;
	bottom: 40px;
`;

class AppFab extends Component {
	render() {
		const { icon, text, ...restProps } = this.props;

		return (
			<StyledFab
				color='primary'
				variant={text ? 'extended' : 'round'}
				{...restProps}
			>
				{icon ? icon : null}
				{text ? text : null}
			</StyledFab>
		);
	}
}

export default AppFab;
