export const clearSeconds = (date) => {
	const newDate = new Date(date);
	newDate.setMilliseconds(0);
	newDate.setSeconds(0);
	return newDate;
};

export const MinutesAndHoursDifference = (firstTime, secondTime) => {
	let hours = '24';
	let minutes = '00';
	if (firstTime.getTime() === secondTime.getTime()) {
		return { hours, minutes };
	}

	const differenceMilliseconds = (secondTime - firstTime) / 60 / 1000;
	hours = Math.floor(differenceMilliseconds / 60);
	minutes = Math.trunc(differenceMilliseconds - hours * 60);
	if (minutes < 10) {
		minutes = `0${minutes}`;
	}
	if (hours === 0) {
		hours = '0';
	}
	return { hours, minutes };
};
