import React from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import { Box, Dialog, Grid, InputAdornment } from '@mui/material';

import If from '../../../components/Compounds/If/If';
import Then from '../../../components/Compounds/If/Then';
import DialogTitle from '../../../components/Dialog/DialogTitle';
import { FormDropdown } from '../../../components/Form/Dropdown';
import { FormInputText } from '../../../components/Form/InputText';
import IsLoading from '../../../components/IsLoading/IsLoading';
import { StyledButton } from '../../../components/StyledComponents/Button';
import useInventoryModal from './useInventoryModal';

const InventoryModal = ({
	openModal,
	setOpenModal,
	edit,
	setEdit,
	inventoryId,
	setReloadInventories,
}) => {
	const {
		handleSubmit,
		control,
		onSubmit,
		inventoryTypes,
		resetForm,
		loadingInventory,
		watch,
		getAmountText,
	} = useInventoryModal({
		setOpenModal,
		edit,
		setEdit,
		inventoryId,
		setReloadInventories,
	});

	return (
		<Dialog fullWidth open={openModal} onClose={() => resetForm()}>
			<Box
				sx={{
					p: 3,
				}}
			>
				<DialogTitle title='Añadir nuevo concepto' />
				<IsLoading conditional={loadingInventory}>
					<Grid mt={2} container spacing={2}>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<Grid container spacing={2} sx={{ alignItems: 'center' }}>
								<Grid item xs={4} sx={{ mb: 1 }}>
									Nombre del concepto
								</Grid>
								<Grid item xs={8} sx={{ mb: 1 }}>
									<FormInputText
										name='name'
										control={control}
										label=''
										data-testid='inventory_name'
										rules={{ required: 'Valor requerido' }}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<Grid container spacing={2} sx={{ alignItems: 'center' }}>
								<Grid item xs={4} sx={{ mb: 1 }}>
									Tipo de concepto
								</Grid>
								<Grid item xs={8} sx={{ mb: 1 }}>
									<FormDropdown
										name='cost_type'
										control={control}
										options={inventoryTypes}
										inputProps={{
											'data-testid': 'inventory_type',
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<If conditional={watch('cost_type') !== 'SALARY_PERCENT'}>
								<Then>
									<Grid container spacing={2} sx={{ alignItems: 'center' }}>
										<Grid item xs={4} sx={{ mb: 1 }}>
											{getAmountText(watch('cost_type'))}
										</Grid>
										<Grid item xs={8} sx={{ mb: 1 }}>
											<FormInputText
												name='amount'
												control={control}
												label=''
												type='number'
												rules={{
													required: 'Valor requerido',
												}}
												inputProps={{
													'data-testid': 'inventory_amount',
													min: 0,
												}}
												endAdornment={
													<InputAdornment position='end'>
														<EuroIcon />
													</InputAdornment>
												}
											/>
										</Grid>
									</Grid>
								</Then>
							</If>
						</Grid>
					</Grid>

					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<StyledButton onClick={handleSubmit(onSubmit)} variant='contained'>
							Guardar
						</StyledButton>
					</Grid>
				</IsLoading>
			</Box>
		</Dialog>
	);
};
export default InventoryModal;
