import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
	deleteShiftById,
	getFixedWorkShift,
	getFixedWorkShiftList,
	updateFixedWorkShift,
	uploadFixedWorkShift,
} from '../../../../Services/Api/Simulation';
import { getNewArrayByIds } from '../../../../utilities/utils';

export default function useFixedShift() {
	const { centers, positions, simulationId } = useSelector(
		(state) => state.createSimulation
	);

	const [initialValues, setInitialValues] = useState({});
	const [openModal, setOpenModal] = useState(false);
	const [edit, setEdit] = useState(false);
	const [refreshWorkShifts, setRefreshWorkShifts] = useState(true);

	const deleteShift = async (shiftId) => {
		const response = await deleteShiftById({ simulationId, shiftId });
		if (response.status === 204) setRefreshWorkShifts((prev) => !prev);
	};

	const editShift = async (shift) => {
		if (shift?.id) {
			setEdit(true);
			const workShiftData = await getFixedWorkShift({
				simulationId,
				fixedWorkShiftId: shift?.id,
			});
			workShiftData.data.center = centers?.data?.filter(
				(cen) => cen.id === workShiftData.data.center_id
			);
			workShiftData.data.position = positions?.data?.filter(
				(pos) => pos.id === workShiftData.data.position_id
			);
			setInitialValues(workShiftData.data);

			setOpenModal(true);
		}
	};

	const { data: workShiftsData, loading: loadingWorkShifts } =
		getFixedWorkShiftList({ simulationId, refresh: refreshWorkShifts });
	const [workShifts, setWorkShifts] = useState([]);

	useEffect(() => {
		if (workShiftsData.length > 0) {
			const withCenter = getNewArrayByIds(
				centers.data,
				'center_id',
				workShiftsData,
				'id',
				'center'
			);
			const withPosition = getNewArrayByIds(
				positions.data,
				'position_id',
				withCenter,
				'id',
				'position'
			);
			setWorkShifts(withPosition);
		} else {
			setWorkShifts([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workShiftsData]);

	const handleUpdateWorkShifts = async (values) => {
		const payload = values;
		if (edit) {
			const response = await updateFixedWorkShift({
				simulationId,
				fixedWorkShiftId: payload?.id,
				payload,
			});
			if (response?.status === 200) setRefreshWorkShifts((prev) => !prev);
			setEdit(false);
		} else {
			const response = await uploadFixedWorkShift({ simulationId, payload });
			if (response?.status === 200) setRefreshWorkShifts((prev) => !prev);
		}
		setInitialValues({});
	};

	return {
		simulationId,
		initialValues,
		setInitialValues,
		openModal,
		setOpenModal,
		edit,
		setEdit,
		refreshWorkShifts,
		setRefreshWorkShifts,
		loadingWorkShifts,
		workShifts,
		setWorkShifts,
		handleUpdateWorkShifts,
		editShift,
		deleteShift,
	};
}
