import React from 'react';

import { Autocomplete, TextField } from '@mui/material';

const AppAutoComplete = ({
	value,
	setValue,
	label,
	options,
	optionLabel = null,
	error,
	errorText,
	autoCompleteProps = {},
	inputProps = {},
}) => (
	<Autocomplete
		size='small'
		fullWidth
		value={value}
		onChange={(event, newValue) => {
			setValue(newValue);
		}}
		{...autoCompleteProps}
		name={label}
		id='controllable-states-demo'
		options={options}
		isOptionEqualToValue={(option, value) =>
			option?.id ? option.id === value.id : option.uid === value.uid
		}
		getOptionLabel={(option) => (optionLabel ? option[optionLabel] : option)}
		/* eslint-disable react/jsx-props-no-spreading */
		renderInput={(params) => (
			<TextField
				{...params}
				label={label}
				helperText={error && errorText}
				error={error}
				{...inputProps}
			/>
		)}
	/>
);
export default AppAutoComplete;
