import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ViewContainer } from '../../components/StyledContainers';

const TypographyContainer = styled('div')(({ theme }) => ({
	color: theme.palette.common.transition1,
	marginTop: 10,
	fontSize: 24,
}));

const TypographyContainer2 = styled('div')(({ theme }) => ({
	color: theme.palette.common.transition7,
	marginTop: 10,
	fontSize: 24,
}));

const ServerNotFound = ({ getProps }) => {
	useEffect(() => {
		const { setToken } = getProps;
		setToken('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ViewContainer>
			<Grid container mt={16}>
				<Grid item xs={0} md={3} />
				<Grid item xs={12} md={8} sx={{ textAlign: 'left' }}>
					<TypographyContainer>¡Ups!</TypographyContainer>
					<TypographyContainer>
						Has perdido la conexión con el servidor
					</TypographyContainer>
					<TypographyContainer2>
						Vuelve a intentarlo probando lo siguiente
					</TypographyContainer2>
					<TypographyContainer2>
						- Ir a <Link to='/'>Inicio</Link>
					</TypographyContainer2>
					<TypographyContainer2>
						- Comprueba tu conexión a internet
					</TypographyContainer2>
					<TypographyContainer>
						Si el problema persiste contáctanos: soporte@op2aim.io
					</TypographyContainer>
				</Grid>
			</Grid>
		</ViewContainer>
	);
};

export default ServerNotFound;
