import React from 'react';

import { AddCircleOutlineTwoTone } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export const AddCircleOutline = ({
	onClick,
	color = 'primary',
	ariaLabel = 'add',
}) => (
	<IconButton
		variant='contained'
		color={color}
		aria-label={ariaLabel}
		onClick={onClick}
	>
		<AddCircleOutlineTwoTone fontSize='large' color={color} />
	</IconButton>
);
