import { useSelector } from 'react-redux';

import { formatNumber } from '../../../../utilities/utils';

export default function useMaterialCostTable() {
	const getTypeByText = (value) => {
		switch (value) {
			case 'SALARY_PERCENT':
				return 'Por porcentaje de coste salarial';
			case 'AMOUNT_BY_WORKER':
				return 'Por trabajador';
			case 'MONTHLY_AMOUNT':
				return 'Por mes';
			case 'MONTHLY_UNIT_COST':
				return 'Cantidad unitaria por mes';
			default:
				return 'Por porcentaje de coste salarial';
		}
	};

	const isInteger = (amount) => amount % 1 === 0;
	const { centers } = useSelector((state) => state.createSimulation);

	const parseAmount = (amount) =>
		formatNumber(amount, isInteger(amount) ? 0 : 2);

	const centerNameForCenterId = (center_id) => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		let center_name = 'Todos los centros';
		if (center_id) {
			const center = centers.data.find(({ id }) => id === center_id);
			center_name = center.name;
		}

		return center_name;
	};

	const getAmountAndIconByType = (type, amount) => {
		switch (type) {
			case 'SALARY_PERCENT':
				return `${parseAmount(amount)}%`;
			case 'AMOUNT_BY_WORKER':
				return `${parseAmount(amount)}€`;
			case 'MONTHLY_AMOUNT':
				return `${parseAmount(amount)}€`;
			case 'MONTHLY_UNIT_COST':
				return `${parseAmount(amount)}€`;
			default:
				return `${parseAmount(amount)}%`;
		}
	};

	return {
		getTypeByText,
		getAmountAndIconByType,
		centerNameForCenterId,
	};
}
