import AxiosWrapper from '../../utilities/connection/AxiosWrapper';
import {
	duplicateAgreementUrl,
	getAgreementUrl,
	getProjectsUrl,
	getUrlToDownloadCentersUrl,
	getUserAgreementsUrl,
	uploadCentersUrl,
	checkConnectionUrl,
} from '../../utilities/routes';
import ApiException from './ApiException';

export default function GenericApiException(response) {
	const response_formatted = {
		message: response?.data?.message || 'Internal Server Error',
		status_code: response?.status || 500,
	};
	const new_message = `Error: ${response_formatted.message} StatusCode: ${response_formatted.status_code}`;
	this.message = new_message;
	this.name = 'GenericApiException';
	this.severity = 'error';
	this.response = response_formatted;
}

export async function post(url, payload = {}) {
	try {
		const { data } = await AxiosWrapper.post(url, JSON.stringify(payload));
		return data;
	} catch (e) {
		throw new GenericApiException(e?.response);
	}
}

export async function get(url, params) {
	try {
		// eslint-disable-next-line object-shorthand
		const { data } = await AxiosWrapper.get(url, { params: params });
		return data;
	} catch (e) {
		throw new GenericApiException(e?.response);
	}
}

export async function put(url, payload = {}) {
	try {
		const { data } = await AxiosWrapper.put(url, JSON.stringify(payload));
		return data;
	} catch (e) {
		throw new GenericApiException(e?.response);
	}
}

export async function getProjects(
	search_string = '',
	limit = 10,
	start_id_project = null,
	workspace_id = ''
) {
	const url = getProjectsUrl();
	const params = {
		workspace_id,
		search_string,
		start_id_project,
		limit,
	};
	try {
		const { data } = await AxiosWrapper.get(url, { params });
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getAgreements(search_string = '', workspace_id = '') {
	const url = `${getUserAgreementsUrl}`;
	const params = { params: { search_string, workspace_id } };
	try {
		const { data } = await AxiosWrapper.get(url, params);
		return data;
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function removeAgreement(agreementId, workspace_id = '') {
	const url = getAgreementUrl(agreementId);
	const params = { params: { workspace_id } };
	try {
		const { data } = await AxiosWrapper.delete(url, params);
		return data;
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function duplicateAgreement(
	origin_agreement,
	new_agreement,
	workspace_id
) {
	const url = duplicateAgreementUrl();
	const params = { origin_agreement, new_agreement, workspace_id };
	try {
		return await AxiosWrapper.post(url, JSON.stringify(params));
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function getUrlToDownloadCenters(simulationId, payload) {
	const url = getUrlToDownloadCentersUrl(simulationId);
	try {
		return await AxiosWrapper.get(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function getUploadCentersUrl(simulationId, payload) {
	const url = uploadCentersUrl(simulationId);
	try {
		return await AxiosWrapper.post(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function checkConnectionEndpoint() {
	try {
		return await AxiosWrapper.get(checkConnectionUrl);
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function getProjectById(projectId) {
	const url = `${getProjectsUrl()}/${projectId}`;
	const response = await get(url);
	return response;
}
