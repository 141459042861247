import ApiException from '../../../../../Services/Api/ApiException';
import useGetResponseFromApi from '../../../../../hooks/useGetResponseFromApi';
import AxiosWrapper from '../../../../../utilities/connection/AxiosWrapper';
import {
	createTaskByHoursUrl,
	deleteTasksByHourUrl,
	getListTaskByHoursUrl,
	getTaskByHourUrl,
} from '../../../../../utilities/routes';

export const getListTaskByHours = ({ simulationId, refresh }) =>
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useGetResponseFromApi({
		url: getListTaskByHoursUrl(simulationId),
		refresh,
	});

export async function getTaskByHour({ simulationId, tasksByHourId }) {
	const url = getTaskByHourUrl(simulationId, tasksByHourId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function deleteTasksByHour({ simulationId, variableWorkId }) {
	const url = deleteTasksByHourUrl(simulationId, variableWorkId);
	try {
		await AxiosWrapper.delete(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function createTaskByHours({ simulationId, payload }) {
	const url = createTaskByHoursUrl(simulationId);
	try {
		await AxiosWrapper.post(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function updateTaskByHour({
	simulationId,
	tasksByHourId,
	payload,
}) {
	const url = getTaskByHourUrl(simulationId, tasksByHourId);
	try {
		return await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
