import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTheme } from '@material-ui/core/styles';

import { StyledButton } from '../StyledComponents/Button';

class AppModal extends Component {
	render() {
		const {
			open,
			handleClose,
			handleSubmit,
			content,
			modalText,
			leftButton,
			rightButton,
			modalTitle,
			disabled = false,
		} = this.props;

		return (
			<Dialog
				open={Boolean(open)}
				onClose={handleClose}
				aria-labelledby='form-dialog-title'
			>
				<DialogTitle id='form-dialog-title'>
					<span style={{ fontWeight: 'bold' }}>{modalTitle}</span>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>{modalText}</DialogContentText>
					{content}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='secondary'>
						{leftButton ? leftButton : 'Cancel'}
					</Button>
					<StyledButton
						variant={disabled ? 'disabled' : 'contained'}
						onClick={handleSubmit}
						color='primary'
					>
						{rightButton ? rightButton : 'Add'}
					</StyledButton>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withTheme(AppModal);
