import React from 'react';

import { StepButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StepButtonComponent = styled(StepButton)((props) => ({
	color: props.theme.palette.common.white,
	borderRadius: '60px',
}));

export const StyledStepButton = ({
	onClick,
	color = 'white',
	children,
	...rest
}) => (
	<StepButtonComponent color={color} onClick={onClick} {...rest}>
		{children}
	</StepButtonComponent>
);
