import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const BoxComponent = styled(Box)((props) => ({
	boxShadow: `0px 10px 40px ${props.theme.palette.common.transition6}0D,inset 0px -4px 0px ${props.theme.palette.common.transition6} `,
	background: `${props.theme.palette.common.white}`,
}));

export const StyledBoxContainer = ({ children, ...rest }) => (
	<BoxComponent sx={{ borderRadius: '6px' }} {...rest}>
		{children}
	</BoxComponent>
);
