import { formatNumber } from '../../../utilities/utils';

export default function useInventoryTable() {
	const getTypeByText = (value) => {
		switch (value) {
			case 'SALARY_PERCENT':
				return 'Por porcentaje de coste salarial';
			case 'AMOUNT_BY_WORKER':
				return 'Cantidad fija por trabajador';
			case 'MONTHLY_AMOUNT':
				return 'Cantidad fija mensual';
			case 'MONTHLY_UNIT_COST':
				return 'Cantidad unitaria';
			default:
				return 'Por porcentaje de coste salarial';
		}
	};

	const isInteger = (amount) => amount % 1 === 0;

	const parseAmount = (amount) =>
		formatNumber(amount, isInteger(amount) ? 0 : 2);

	return {
		getTypeByText,
		parseAmount,
	};
}
