const initial = {
	basicData: {},
	centers: { data: [], loading: false, error: '' },
	simulation: {},
	positions: {},
	agreements: {},
	categoryPositions: {},
	simulationId: '',
	refreshSimulation: false,
	refreshCenters: false,
	refreshPositions: false,
	refreshCategoryPositions: false,
	updatingBasicData: false,
	snackbar: {
		openSnackbar: false,
		duration: 6000,
		severity: 'success',
		message: '',
	},
};
export default function createSimulation(
	state = initial,
	{
		type,
		basicData,
		centers,
		simulation,
		simulationId,
		positions,
		agreements,
		categoryPositions,
		snackbar,
		updatingBasicDataValue,
	} = {}
) {
	switch (type) {
		case 'RESET_STATE':
			return { ...initial };
		case 'SET_BASIC_DATA':
			return { ...state, basicData };
		case 'SET_SIMULATION_INFO':
			return { ...state, simulation };
		case 'SET_CENTERS':
			return { ...state, centers };
		case 'REFRESH_SIMULATION':
			return { ...state, refreshSimulation: !state.refreshSimulation };
		case 'REFRESH_CENTERS':
			return { ...state, refreshCenters: !state.refreshCenters };
		case 'UPDATING_BASIC_DATA':
			return { ...state, updatingBasicData: updatingBasicDataValue };
		case 'REFRESH_POSITIONS':
			return { ...state, refreshPositions: !state.refreshPositions };
		case 'REFRESH_CATEGORY_POSITIONS':
			return {
				...state,
				refreshCategoryPositions: !state.refreshCategoryPositions,
			};
		case 'SET_SIMULATION_ID':
			return { ...state, simulationId };
		case 'SET_POSITIONS':
			return { ...state, positions };
		case 'SET_AGREEMENTS':
			return { ...state, agreements };
		case 'SET_CATEGORY_POSITIONS':
			return { ...state, categoryPositions };
		case 'SET_SNACKBAR':
			return { ...state, snackbar };
		case 'CLOSE_SNACKBAR':
			return {
				...state,
				snackbar: {
					openSnackbar: false,
					duration: 6000,
					severity: 'success',
					message: '',
				},
			};
		default:
			return state;
	}
}
