import React from 'react';
import Select from 'react-select';

import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		height: 250,
		minWidth: 290,
	},
	input: {
		display: 'flex',
		padding: 0,
		height: 'auto',
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	chip: {
		margin: theme.spacing(0.5, 0.25),
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === 'light'
				? theme.palette.grey[300]
				: theme.palette.grey[700],
			0.08
		),
	},
	noOptionsMessage: {
		padding: theme.spacing(1, 2),
	},
	singleValue: {
		fontSize: 16,
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		bottom: 6,
		fontSize: 16,
	},
	paper: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
	},
	divider: {
		height: theme.spacing(2),
	},
}));

NoOptionsMessage.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	/**
	 * Props to be passed on to the wrapper.
	 */
	innerProps: PropTypes.object.isRequired,
	selectProps: PropTypes.object.isRequired,
};

inputComponent.propTypes = {
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({
			current: PropTypes.any.isRequired,
		}),
	]),
};

Control.propTypes = {
	/**
	 * Children to render.
	 */
	children: PropTypes.node,
	/**
	 * The mouse down event and the innerRef to pass down to the controller element.
	 */
	innerProps: PropTypes.shape({
		onMouseDown: PropTypes.func.isRequired,
	}).isRequired,
	innerRef: PropTypes.oneOfType([
		PropTypes.oneOf([null]),
		PropTypes.func,
		PropTypes.shape({
			current: PropTypes.any.isRequired,
		}),
	]).isRequired,
	selectProps: PropTypes.object.isRequired,
};

Option.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	/**
	 * props passed to the wrapping element for the group.
	 */
	innerProps: PropTypes.shape({
		id: PropTypes.string.isRequired,
		key: PropTypes.string.isRequired,
		onClick: PropTypes.func.isRequired,
		onMouseMove: PropTypes.func.isRequired,
		onMouseOver: PropTypes.func.isRequired,
		tabIndex: PropTypes.number.isRequired,
	}).isRequired,
	/**
	 * Inner ref to DOM Node
	 */
	innerRef: PropTypes.oneOfType([
		PropTypes.oneOf([null]),
		PropTypes.func,
		PropTypes.shape({
			current: PropTypes.any.isRequired,
		}),
	]).isRequired,
	/**
	 * Whether the option is focused.
	 */
	isFocused: PropTypes.bool.isRequired,
	/**
	 * Whether the option is selected.
	 */
	isSelected: PropTypes.bool.isRequired,
};

Placeholder.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	/**
	 * props passed to the wrapping element for the group.
	 */
	innerProps: PropTypes.object,
	selectProps: PropTypes.object.isRequired,
};

SingleValue.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	/**
	 * Props passed to the wrapping element for the group.
	 */
	innerProps: PropTypes.any.isRequired,
	selectProps: PropTypes.object.isRequired,
};

ValueContainer.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	selectProps: PropTypes.object.isRequired,
};

Menu.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.element.isRequired,
	/**
	 * Props to be passed to the menu wrapper.
	 */
	innerProps: PropTypes.object.isRequired,
	selectProps: PropTypes.object.isRequired,
};

function NoOptionsMessage(props) {
	const { selectProps, innerProps, children } = props;

	return (
		<Typography
			color='textSecondary'
			className={selectProps.classes.noOptionsMessage}
			{...innerProps}
		>
			{children}
		</Typography>
	);
}

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

function Control(props) {
	const {
		children,
		innerProps,
		innerRef,
		selectProps: { classes, TextFieldProps },
	} = props;

	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: classes.input,
					ref: innerRef,
					children,
					...innerProps,
				},
			}}
			{...TextFieldProps}
		/>
	);
}

function Option(props) {
	const { isFocused, innerProps, children, isSelected, innerRef } = props;

	return (
		<MenuItem
			ref={innerRef}
			selected={isFocused}
			component='div'
			style={{
				fontWeight: isSelected ? 500 : 400,
			}}
			{...innerProps}
		>
			{children}
		</MenuItem>
	);
}

function Placeholder(props) {
	const { selectProps, innerProps = {}, children } = props;
	return (
		<Typography
			color='textSecondary'
			className={selectProps.classes.placeholder}
			{...innerProps}
		>
			{children}
		</Typography>
	);
}

function SingleValue(props) {
	const { selectProps, innerProps, children } = props;
	return (
		<Typography className={selectProps.classes.singleValue} {...innerProps}>
			{children}
		</Typography>
	);
}

function ValueContainer(props) {
	const { selectProps, children } = props;

	return <div className={selectProps.classes.valueContainer}>{children}</div>;
}

function Menu(props) {
	const { selectProps, innerProps, children } = props;
	return (
		<Paper square className={selectProps.classes.paper} {...innerProps}>
			{children}
		</Paper>
	);
}

const components = {
	Control,
	Menu,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer,
};

export default function IntegrationReactSelect(props) {
	const classes = useStyles();
	const theme = useTheme();

	const {
		suggestions,
		placeholder,
		selectedValue,
		handleChangeSelected,
		name,
	} = props;

	const onChange = (element) => {
		handleChangeSelected({
			target: {
				value: element.value,
				element: element,
				type: 'select',
				name,
			},
		});
	};

	const selectStyles = {
		input: (base) => ({
			...base,
			color: theme.palette.text.primary,
			'& input': {
				font: 'inherit',
			},
		}),
	};

	return (
		<div className={classes.root}>
			<NoSsr>
				<Select
					classes={classes}
					styles={selectStyles}
					inputId='react-select-single'
					TextFieldProps={{
						label: 'Country',
						InputLabelProps: {
							htmlFor: 'react-select-single',
							shrink: true,
						},
					}}
					placeholder={placeholder}
					options={suggestions}
					components={components}
					value={selectedValue}
					onChange={onChange}
				/>
				<div className={classes.divider} />
			</NoSsr>
		</div>
	);
}
