import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSnackbar } from '../../../../store/actions/createSimulation';
import {
	deleteTasksByHour,
	getListTaskByHours,
	getTaskByHour,
} from './ApiServices';

export default function useTaskByHour() {
	const dispatch = useDispatch();
	const { simulationId } = useSelector((state) => state.createSimulation);
	const [refresh, setRefresh] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [edit, setEdit] = useState(false);

	const { data: taskByHourData, loading: loadingTaskByHour } =
		getListTaskByHours({ simulationId, refresh });

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const deleteTask = async (variableWorkId) => {
		try {
			await deleteTasksByHour({
				simulationId,
				variableWorkId,
			});
			setRefresh((prev) => !prev);
		} catch (e) {
			setSnackBarMessage(e?.message);
		}
	};

	const editTask = async (taskId) => {
		if (taskId) {
			try {
				const taskByHourData = await getTaskByHour({
					simulationId,
					tasksByHourId: taskId,
				});

				setInitialValues(taskByHourData.data);

				setEdit(true);
				setOpenModal(true);
			} catch (e) {
				setSnackBarMessage(e?.message);
			}
		}
	};

	return {
		taskByHourData,
		loadingTaskByHour,
		openModal,
		refresh,
		setOpenModal,
		initialValues,
		setInitialValues,
		edit,
		setEdit,
		setRefresh,
		deleteTask,
		editTask,
	};
}
