import React from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import {
	Box,
	CircularProgress,
	Dialog,
	Grid,
	InputAdornment,
} from '@mui/material';

import Else from '../../../../components/Compounds/If/Else';
import If from '../../../../components/Compounds/If/If';
import Then from '../../../../components/Compounds/If/Then';
import DialogTitle from '../../../../components/Dialog/DialogTitle';
import DialogLabelTypography from '../../../../components/DialogLabelTypography';
import { FormAutoComplete } from '../../../../components/Form/AutoComplete';
import { FormDatePicker } from '../../../../components/Form/DatePicker';
import { FormDropdown } from '../../../../components/Form/Dropdown';
import { FormNotification } from '../../../../components/Form/FormNotification';
import { FormInputText } from '../../../../components/Form/InputText';
import IsPreviousStudyEditable from '../../../../components/PreviousStudy/IsPreviousStudyEditable';
import { StyledButton } from '../../../../components/StyledComponents/Button';
import TooltipIcon from '../../../../components/TooltipIcon/TooltipIcon';
import useSurrogateModal from './useSurrogateModal';

const SurrogateModal = ({
	openModal,
	setOpenModal,
	setReloadSurrogates,
	surrogateId,
	setSurrogateId,
}) => {
	const {
		uploadingSurrogate,
		loadingSurrogate,
		control,
		agreements,
		categories,
		journeyOptions,
		loadingContractTypes,
		contractTypes,
		handleSubmit,
		onSubmit,
		onSubmitAndContinue,
		watch,
		closeDialog,
		isPossibleRetirement,
	} = useSurrogateModal({
		openModal,
		setOpenModal,
		setReloadSurrogates,
		surrogateId,
		setSurrogateId,
	});

	return (
		<Dialog
			open={openModal}
			onClose={() => {
				closeDialog();
			}}
		>
			<Box p={3}>
				<DialogTitle title='Añadir / editar subrogado' />
				{uploadingSurrogate || loadingSurrogate ? (
					<Box sx={{ textAlign: 'center' }}>
						<CircularProgress />
					</Box>
				) : (
					<Grid mt={2} container spacing={2}>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormAutoComplete
										name='agreement'
										control={control}
										label='Seleccione un convenio'
										options={agreements?.data}
										optionLabel='name'
									/>
								</Grid>
								<Grid item xs={6}>
									<FormAutoComplete
										name='categoryPosition'
										control={control}
										label='Seleccione una categoría'
										options={categories}
										optionLabel='professional_category'
										rules={{ required: 'Categoría requerida' }}
										autoCompleteProps={{
											'data-testid': 'categoryPosition',
										}}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<DialogLabelTypography label='Jornada:' />
						</Grid>

						<Grid item xs={12} sx={{ mb: 1 }}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormDropdown
										name='journey'
										control={control}
										options={journeyOptions}
										selectProps={{ 'data-testid': 'journeySelect' }}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormInputText
										name='journeyValue'
										control={control}
										label=''
										data-testid='journeyValue'
										endAdornment={
											<InputAdornment position='end'>
												<If conditional={watch('journey') === 'percent'}>
													<Then>%</Then>
													<Else>h/s</Else>
												</If>{' '}
											</InputAdornment>
										}
										inputProps={{ min: 0 }}
										type='number'
										rules={{
											required: 'Valor requerido',
											validate: (amount) => amount >= 0 || 'Valor incorrecto',
										}}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} sx={{ mb: 1 }}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<FormDatePicker
										name='seniorityDate'
										control={control}
										label='Fecha antigüedad'
										componentProps={{
											'data-testid': 'dateValue',
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<If conditional={loadingContractTypes}>
										<Then>
											<CircularProgress />
										</Then>
										<Else>
											<FormAutoComplete
												name='contractType'
												control={control}
												label='Tipo de contrato'
												options={contractTypes}
												optionLabel='label'
												autoCompleteProps={{
													'data-testid': 'contractType',
												}}
											/>
										</Else>
									</If>
								</Grid>
								{isPossibleRetirement ? (
									<Grid item xs={6}>
										<FormNotification notification='Posible jubilación' />
									</Grid>
								) : null}
							</Grid>
						</Grid>

						<Grid item xs={12} sx={{ mb: 1 }}>
							<Grid container spacing={2} sx={{ alignItems: 'center' }}>
								<Grid item xs={6}>
									<FormInputText
										name='salaryYear'
										control={control}
										label='Salario anual pactado'
										type='number'
										endAdornment={
											<InputAdornment position='end'>
												<EuroIcon sx={{ mr: 0.5, ml: 0.0, width: '14px' }} />
											</InputAdornment>
										}
										inputProps={{
											min: 0,
										}}
										rules={{
											validate: (amount) => amount >= 0 || 'Valor incorrecto',
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TooltipIcon
										tooltipTitle='Rellenar solo cuando al subrogado no se le aplican las tablas salariales del convenio(por ejemplo, tiene un plus adicional pactado)'
										tooltipPlace='right'
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<Grid container spacing={2} sx={{ alignItems: 'center' }}>
								<Grid item xs={6}>
									<FormInputText
										name='annualBonuses'
										control={control}
										label='Pluses y/o complementos anuales'
										type='number'
										data-testid='annualBonuses'
										endAdornment={
											<InputAdornment position='end'>
												<EuroIcon sx={{ mr: 0.5, ml: 0.0, width: '14px' }} />
											</InputAdornment>
										}
										inputProps={{
											min: 0,
										}}
										rules={{
											validate: (amount) => amount >= 0 || 'Valor incorrecto',
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TooltipIcon
										tooltipTitle='Añade el importe anual de todos los pluses o complementos que están fuera de convenio.'
										tooltipPlace='right'
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid
							container
							direction='column'
							justifyContent='center'
							alignItems='center'
							spacing={1}
							mt={2}
						>
							<Grid item>
								<IsPreviousStudyEditable>
									<StyledButton
										onClick={handleSubmit(onSubmit)}
										variant='contained'
										sx={{ textTransform: 'inherit' }}
									>
										Añadir y cerrar
									</StyledButton>
								</IsPreviousStudyEditable>
							</Grid>
							<Grid item>
								<IsPreviousStudyEditable>
									<StyledButton
										onClick={handleSubmit(onSubmitAndContinue)}
										variant='text'
										sx={{
											color: 'primary.main',
											textTransform: 'inherit',
										}}
									>
										<u>Continuar añadiendo subrogados</u>
									</StyledButton>
								</IsPreviousStudyEditable>
							</Grid>
							<Grid item>
								<StyledButton
									variant='text'
									sx={{ color: 'text.secondary', textTransform: 'inherit' }}
									onClick={closeDialog}
								>
									Cancelar
								</StyledButton>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Box>
		</Dialog>
	);
};
export default SurrogateModal;
