import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Stepper, Step, Grid, StepLabel, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import {
	getPreviousStudy,
	launchAsyncPreviousStudy,
} from '../../Services/Api/PreviousStudy';
import launchSimulationIcon from '../../assets/imgs/launchSimulation.svg';
import { WhiteTitleContainer } from '../../components';
import If from '../../components/Compounds/If/If';
import Then from '../../components/Compounds/If/Then';
import IsLoading from '../../components/IsLoading/IsLoading';
import { StyledButton } from '../../components/StyledComponents/Button';
import { StyledSemicircleOnTop } from '../../components/StyledComponents/SemicircleOnTop';
import { StyledStepButton } from '../../components/StyledComponents/StepButton';
import {
	TitleContainer,
	ViewContainer,
} from '../../components/StyledContainers';
import BasicDataTab from '../../containers/PreviousStudy/BasicData';
import MaterialCost from '../../containers/PreviousStudy/MaterialCost';
import SurrogatesTab from '../../containers/PreviousStudy/Surrogate/SurrogatesTab';
import {
	setPreviousStudyGettingState,
	setPreviousStudyStore,
} from '../../store/actions/previousStudy';
import { setSnackbar } from '../../store/actions/snackbar';
import {
	previousStudyLaunchedUrl,
	getPreviousStudyUrl,
} from '../../utilities/routes';
import useSubscribe from '../../utilities/useSubscribe';

const PreviousStudy = ({ match }) => {
	const { projectId, previousStudyId } = match.params;
	const projects = useSelector((state) => state.projects);
	const dispatch = useDispatch();
	const history = useHistory();

	const [project, setProject] = useState({});
	const [previousStudy, setPreviousStudy] = useState({});
	const [launchingPreviousStudy, setLaunchingPreviousStudy] = useState(false);
	const [activeStep, setActiveStep] = useState(0);

	const { handleCreateSubscription } = useSubscribe();

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const handleInitPreviousStudy = async () => {
		setLaunchingPreviousStudy(true);
		try {
			await launchAsyncPreviousStudy({ previousStudyId });
			handleCreateSubscription({
				endpoint_url: getPreviousStudyUrl(previousStudyId),
				on_request: (response) => {
					if (response.status === 'Solved') {
						history.push(previousStudyLaunchedUrl(projectId, previousStudyId));
						return true;
					}
					if (response.sub_status === 'Stopped') {
						setLaunchingPreviousStudy(false);
						setSnackBarMessage('Error al procesar el estudio previo');
						return true;
					}
				},
				on_error: () => {},
				on_timeout: () => {
					setLaunchingPreviousStudy(false);
					setSnackBarMessage('El servidor esta tardando bastante en responder');
					return true;
				},
				max_timeout: 300,
			});
		} catch (e) {
			setLaunchingPreviousStudy(false);
			setSnackBarMessage(e.message);
		}
	};
	const getPreviousStudyInfo = async () => {
		dispatch(setPreviousStudyGettingState(true));
		try {
			const { data } = await getPreviousStudy(previousStudyId);
			setPreviousStudy(data);
			if (data?.name !== '') {
				dispatch(setPreviousStudyStore(data));
			}
			dispatch(setPreviousStudyGettingState(false));
		} catch (e) {
			dispatch(setPreviousStudyGettingState(false));
			setSnackBarMessage(e.message);
		}
	};

	useEffect(() => {
		setProject(projects.list.filter((item) => item.uid === projectId)[0]);
		getPreviousStudyInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	const header = () => (
		<WhiteTitleContainer
			routes={[
				{ name: 'Inicio', href: '/' },
				{ name: 'Licitaciones', href: '/projects' },
				{ name: project?.name, href: `/projects/${projectId}` },
				{
					name:
						previousStudy?.name?.length > 80
							? `${previousStudy?.name?.substring(0, 80)}...`
							: previousStudy?.name,
				},
			]}
			title=''
		/>
	);

	// const disableTabsAndButton = createSimulation?.updatingBasicData;
	const steps = ['Datos Básicos', 'Subrogados', 'Otros Costes'];

	const stepComponent = [
		<BasicDataTab previousStudyId={previousStudyId} key='basic-data-tab' />,
		<SurrogatesTab key='surrogates-tab' />,
		<MaterialCost key='material-cost' />,
	];

	const stepsDraw = () => (
		<Stepper nonLinear activeStep={activeStep} alternativeLabel>
			{steps.map((label, index) => (
				<Step key={label}>
					<StyledStepButton color='inherit' onClick={handleStep(index)}>
						<StepLabel>
							<Box>{label}</Box>
						</StepLabel>
					</StyledStepButton>
				</Step>
			))}
		</Stepper>
	);

	return (
		<>
			{project?.name && header()}
			<ViewContainer>
				<Box sx={{ width: '100%' }}>{stepsDraw()}</Box>
				<TitleContainer>
					<Grid
						xs={12}
						container
						item
						direction='row'
						justifyContent='space-between'
					>
						<Grid item md={9.5} lg={10}>
							<Typography
								variant='h5'
								color='textPrimary'
								style={{ fontWeight: 'bold' }}
							>
								{previousStudy?.name}
							</Typography>
						</Grid>
						<Grid item md={2.5} lg={2} mt={0}>
							<Box sx={{ width: '100%', margin: 'auto' }}>
								<span>
									<StyledButton
										disabled={previousStudy?.status === 'Solved'}
										sx={{
											textTransform: 'inherit',
											maxHeight: '50px',
											height: '42px',
											width: '200px',
											color: 'secondary.main',
										}}
										variant='outlined'
										onClick={() => handleInitPreviousStudy()}
										startIcon={<img alt='start' src={launchSimulationIcon} />}
									>
										<Typography sx={{ fontWeight: '800' }}>
											Estudio previo
										</Typography>
									</StyledButton>
								</span>
							</Box>
						</Grid>
					</Grid>
				</TitleContainer>

				<Box
					sx={{
						flexGrow: 1,
						bgcolor: 'background.paper',
						display: 'block',
						width: '100%',
						m: 'auto',
						mt: '50px',
						p: 2,
						boxShadow: `0px 10px 40px rgba(68, 82, 231, 0.11)`,
						borderRadius: '6px',
					}}
				>
					<Grid container justifyContent='center'>
						<Grid item>
							<StyledSemicircleOnTop
								sx={{ boxShadow: '0px -10px 10px 0px #4452e71c' }}
							>
								<Box
									sx={{
										height: '36px',
										width: '36px',
										borderRadius: '50%',
										backgroundColor: 'primary.main',
										paddingTop: '10px',
										fontSize: '16px',
										fontWeight: '700',
									}}
								>
									{activeStep + 1}
								</Box>
							</StyledSemicircleOnTop>
						</Grid>
					</Grid>

					<Grid container>
						<Grid item xs={12} sx={{ ml: 3, mr: 3, mt: -6 }}>
							<If conditional={launchingPreviousStudy}>
								<Then>
									<Typography
										variant='h6'
										style={{
											fontWeight: 'bold',
											marginBottom: 20,
											color: 'black',
										}}
									>
										Calculando costes del estudio previo
									</Typography>
								</Then>
							</If>
							<IsLoading conditional={launchingPreviousStudy}>
								{stepComponent[activeStep]}
							</IsLoading>
						</Grid>
					</Grid>
				</Box>
			</ViewContainer>
		</>
	);
};
export default PreviousStudy;
