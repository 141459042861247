import React from 'react';
import { useSelector } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';

import IsPreviousStudyEditable from '../../../../components/PreviousStudy/IsPreviousStudyEditable';
import { StyledBoxContainer } from '../../../../components/StyledComponents/BoxContainer';
import HeaderTableCell from '../../../../components/StyledComponents/HeaderTableCell';
import { StyledIcon } from '../../../../components/StyledComponents/Icon';
import { formatNumber } from '../../../../utilities/utils';

const isInteger = (working_day) => working_day % 1 === 0;
const formattedNumber = (working_day) =>
	formatNumber(working_day, isInteger(working_day) ? 0 : 2);

const SurrogatesTable = ({
	surrogates = [],
	deleteSurrogate,
	editSurrogate,
}) => {
	const { service_date_init, service_duration } = useSelector(
		(state) => state.previousStudy
	);

	const surrogateDetailContent = (string) => (
		<Typography
			variant='subtitle2'
			align='left'
			style={{
				fontWeight: 800,
				color: 'black',
				float: 'left',
			}}
		>
			{string}
		</Typography>
	);

	const isPossibleRetirement = (beginning_year) => {
		if (beginning_year) {
			const list_chain_date = beginning_year.split('-');
			const year = new Date(
				list_chain_date[2],
				list_chain_date[1],
				list_chain_date[0]
			).getFullYear();
			const previousStudyDate = new Date(service_date_init);
			previousStudyDate.setMonth(
				previousStudyDate.getMonth() + service_duration
			);
			const previousStudyYear = previousStudyDate.getFullYear();

			return beginning_year && previousStudyYear - year > 35;
		}

		return false;
	};

	const getTooltipTitle = (salary_year, annual_bonuses) => {
		let title = '';
		const a = `Subrogado con salario distinto a convenio: ${formatNumber(
			salary_year,
			2
		)}€`;
		const b = `Complemento anual: ${formatNumber(annual_bonuses, 2)}€`;
		if (salary_year) {
			title = `${a}`;
		}
		if (annual_bonuses) {
			title = `${title} ${b}`;
		}
		return title;
	};

	const surrogatesRows = () =>
		surrogates.map((row) => (
			<TableRow key={row.id}>
				<TableCell>{surrogateDetailContent(row.name)}</TableCell>
				<TableCell>
					{surrogateDetailContent(
						row?.agreement?.name.length > 20
							? `${row.agreement.name.substring(0, 20)}...`
							: row?.agreement?.name
					)}
				</TableCell>
				<TableCell>{surrogateDetailContent(row?.category?.name)}</TableCell>
				<TableCell>
					{surrogateDetailContent(
						row?.value_metric_working_day === 'FIXED'
							? `${formattedNumber(row?.working_day)} h`
							: `${formattedNumber(row?.working_day)} %`
					)}
				</TableCell>
				<TableCell>
					<Grid container spacing={2} sx={{ alignItems: 'center' }}>
						<Grid item>{surrogateDetailContent(row?.beginning_year)}</Grid>
						<Grid item>
							{isPossibleRetirement(row?.beginning_year) ? (
								<Tooltip title='Posible jubilación'>
									<WarningAmberIcon
										color='primary'
										sx={{ fontSize: 25, ml: 1 }}
									/>
								</Tooltip>
							) : null}
						</Grid>
					</Grid>
				</TableCell>
				<TableCell>{surrogateDetailContent(row?.contract_code)}</TableCell>
				<TableCell sx={{ width: '5%', padding: '0px' }}>
					{row?.salary_year || row?.annual_bonuses ? (
						<Tooltip
							title={getTooltipTitle(row?.salary_year, row?.annual_bonuses)}
						>
							<EuroIcon />
						</Tooltip>
					) : null}
				</TableCell>
				<TableCell sx={{ width: '5%', padding: '0px' }}>
					<Box sx={{ display: 'flex' }}>
						<IsPreviousStudyEditable>
							<StyledIcon
								sx={{ padding: '5px' }}
								variant='contained'
								label='delete'
								data-testid='deleteButton'
								onClick={() => deleteSurrogate(row.id)}
							>
								<DeleteIcon />
							</StyledIcon>
						</IsPreviousStudyEditable>
						<StyledIcon
							sx={{ marginLeft: 1 }}
							variant='contained'
							color='primary'
							label='edit'
							onClick={() => editSurrogate(row.id)}
						>
							<EditIcon />
						</StyledIcon>
					</Box>
				</TableCell>
			</TableRow>
		));

	return surrogates.length > 0 ? (
		<StyledBoxContainer>
			<Table>
				<TableHead>
					<TableRow>
						<HeaderTableCell />
						<HeaderTableCell>Convenio</HeaderTableCell>
						<HeaderTableCell>Categoría</HeaderTableCell>
						<HeaderTableCell>Jornada</HeaderTableCell>
						<HeaderTableCell>Antigüedad</HeaderTableCell>
						<HeaderTableCell>Tipo de Contrato</HeaderTableCell>
						<HeaderTableCell />
						<HeaderTableCell />
					</TableRow>
				</TableHead>
				<TableBody>{surrogatesRows()}</TableBody>
			</Table>
		</StyledBoxContainer>
	) : (
		<Box sx={{ width: '100%', mt: 2 }}>
			No existen datos de Subrogados para mostrar
		</Box>
	);
};
export default SurrogatesTable;
