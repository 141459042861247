import { Box } from '@mui/system';

const ErrorComponent = ({ message, error, sx, className }) => {
	return (
		<Box sx={sx} className={className}>
			<Box> {message}</Box>
			<Box> {error}</Box>
		</Box>
	);
};

export default ErrorComponent;
