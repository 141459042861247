import ApiException from '../../../../Services/Api/ApiException';
import useGetResponseFromApi from '../../../../hooks/useGetResponseFromApi';
import AxiosWrapper from '../../../../utilities/connection/AxiosWrapper';
import {
	getLocalitiesUrl,
	getUserAgreementsUrl,
} from '../../../../utilities/routes';

export async function getTaskByHour({ simulationId, tasksByHourId }) {
	const url = getTaskByHourUrl(simulationId, tasksByHourId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getLocalities() {
	const url = `${getLocalitiesUrl}`;
	try {
		const { data } = await AxiosWrapper.get(url);
		return data;
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export function getAgreements() {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useGetResponseFromApi({
		url: getUserAgreementsUrl,
	});
}
