import React from 'react';

import { Box } from '@mui/material';

import IsLoading from '../../../components/IsLoading/IsLoading';
import IsPreviousStudyEditable from '../../../components/PreviousStudy/IsPreviousStudyEditable';
import { StyledLoadingButton } from '../../../components/StyledComponents/LoadingButton';
import BasicDataForm from './BasicDataForm';
import useBasicData from './useBasicData';

const BasicDataTab = ({ success, previousStudyId = '' }) => {
	const {
		updatingBasicData,
		gettingPreviousStudyData,
		handleSubmit,
		handleSubmitForm,
		control,
	} = useBasicData({ previousStudyId, success });

	return (
		<Box sx={{ width: '100%' }}>
			<Box
				sx={{
					width: '100%',
					fontSize: '18px',
					fontWeight: '800',
					textAlign: 'left',
					mb: 3,
				}}
				color='primary.main'
			>
				Datos Básicos
			</Box>
			<Box>
				<IsLoading conditional={gettingPreviousStudyData}>
					<BasicDataForm control={control} />
				</IsLoading>
			</Box>

			<Box sx={{ maxHeight: '30px', float: 'right', mt: 2, mb: 3 }}>
				<IsPreviousStudyEditable>
					<StyledLoadingButton
						loading={updatingBasicData}
						variant='contained'
						color='primary'
						onClick={handleSubmit(handleSubmitForm)}
					>
						Guardar
					</StyledLoadingButton>
				</IsPreviousStudyEditable>
			</Box>
		</Box>
	);
};

export default BasicDataTab;
