import React from 'react';

import { Box, Dialog, Grid, InputAdornment, Typography } from '@mui/material';

import DialogTitle from '../../../../components/Dialog/DialogTitle';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import IsLoading from '../../../../components/IsLoading/IsLoading';
import { StyledLoadingButton } from '../../../../components/StyledComponents/LoadingButton';
import TooltipIcon from '../../../../components/TooltipIcon/TooltipIcon';
import IncreaseForm from './IncreaseForm';
import { ListMaterialCost } from './ListMaterialCost';
import useModifySimulationModal from './useModifySimulationModal';

export const ModifySimulationModal = ({
	openModal,
	setOpenModal,
	simulationId,
	setRefreshPage,
	setUploadingParameters,
}) => {
	const {
		resetForm,
		control,
		handleSubmit,
		onSubmit,
		loadingSolution,
		uploadingSolution,
		onRestoreDefaults,
		loadingDefaults,
		centers,
		increaseFields,
	} = useModifySimulationModal({
		openModal,
		setOpenModal,
		simulationId,
		setRefreshPage,
		setUploadingParameters,
	});

	const inputWithLabel = ({ label, inputName }) => (
		<Grid container item xs={7} spacing={2}>
			<Grid item xs={4} mt={1}>
				<Typography
					variant='subtitle1'
					component='div'
					sx={{ fontWeight: 'bold' }}
				>
					{label}
				</Typography>
			</Grid>
			<Grid item container xs={4}>
				<Grid item container>
					<Grid item>
						<FormInputNumber
							name={inputName}
							control={control}
							min={0}
							required
							aria-label={inputName}
							endAdornment={<InputAdornment position='end'>%</InputAdornment>}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);

	const dialogTitleWithInfo = () => (
		<Grid container sx={{ placeContent: 'center' }}>
			<Grid item>Modificar parámetros</Grid>
			<Grid item mt={0.5} ml={1}>
				<TooltipIcon
					tooltipTitle='Aquí puedes cambiar algunos parámetros no relacionados con la optimización de personal. Para realizar otro tipo de cambios, duplica la simulación y realízalos en el formulario para lanzar una nueva.'
					tooltipPlace='right'
				/>
			</Grid>
		</Grid>
	);

	return (
		<Dialog
			fullWidth
			maxWidth='md'
			open={openModal}
			onClose={() => resetForm()}
		>
			<Box p={3}>
				<DialogTitle title={dialogTitleWithInfo()} />
				<Box sx={{ justifyContent: 'center', mt: '32px' }}>
					<IsLoading conditional={loadingSolution}>
						<Grid item container spacing={2}>
							<Grid item xs={12}>
								{inputWithLabel({
									label: 'Absentismo:',
									inputName: 'absenteeism_rate',
								})}
							</Grid>
							<Grid item xs={12}>
								<ListMaterialCost control={control} centers={centers} />
							</Grid>
							<Grid item xs={12} mt={2}>
								{inputWithLabel({ label: 'Beneficio:', inputName: 'benefit' })}
							</Grid>
							{increaseFields?.length ? (
								<>
									<Grid item xs={12}>
										<Typography
											component='div'
											sx={{ fontWeight: 'bold', paddingTop: 2 }}
											variant='subtitle1'
										>
											Incremento salarial por año
										</Typography>
									</Grid>
									{increaseFields.map((item, index) => (
										<IncreaseForm
											control={control}
											index={index}
											item={item}
											key={item?.id}
										/>
									))}
								</>
							) : null}

							<Grid
								item
								xs={8}
								ml='40%'
								sx={{ display: 'flex', justifyContent: 'space-around' }}
							>
								<StyledLoadingButton
									loading={uploadingSolution}
									onClick={handleSubmit(onSubmit)}
									variant='contained'
									disabled={uploadingSolution || loadingDefaults}
									data-testid='solutionSaveButton'
								>
									Guardar
								</StyledLoadingButton>
								<StyledLoadingButton
									loading={loadingDefaults}
									onClick={onRestoreDefaults}
									disabled={uploadingSolution || loadingDefaults}
									data-testid='solutionSaveButton'
									variant='text'
									textColor='primary'
									style={{
										textDecoration: 'underline',
									}}
								>
									Restablecer valores del formulario
								</StyledLoadingButton>
							</Grid>
						</Grid>
					</IsLoading>
				</Box>
			</Box>
		</Dialog>
	);
};
