import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { getAgreements } from '../../../Services/Api/Api';
import { updateBasicDataSimulation } from '../../../Services/Api/BasicData';
import {
	getSimulations,
	getSimulationsCenters,
	updateSimulationCenters,
} from '../../../Services/Api/Simulation';
import useIsMounted from '../../../hooks/useIsMounted';
import {
	refreshCategoryPositions,
	refreshCenters,
	refreshPositions,
	setAgreements,
	setSnackbar,
	updatingBasicData,
} from '../../../store/actions/createSimulation';
import {
	parsingCentersToShowInTable,
	parsingCentersToUpload,
} from '../../../utilities/parseData';
import {
	getFilteredArrayByObjectName,
	isValidDateFormat,
	isYearGraderThan1000,
} from '../../../utilities/utils';
import { getLocalities } from './ApiServices';

export default function useBasicData({ simulationId, success }) {
	const { auth } = useSelector((state) => state);
	const { isMounted } = useIsMounted();
	const dispatch = useDispatch();

	const [updatingBasicSimulation, setUpdatingBasicSimulation] = useState(false);
	const [edit, setEdit] = useState(false);
	const [info, setInfo] = useState([]);

	const [agreements, setAgreementsList] = useState([]);
	const [loadingAgreements, setLoadingAgreements] = useState(true);

	const [cities, setCities] = useState([]);
	const [loadingLocalities, setLoadingLocalities] = useState(true);

	const [centers, setCenters] = useState([]);
	const [loadingCenters, setLoadingCenters] = useState(true);
	const [reloadCenters, setReloadCenters] = useState(true);

	const [simulation, setSimulations] = useState([]);
	const [loadingSimulation, setLoadingSimulations] = useState(true);
	const [reloadSimulation, setReloadSimulation] = useState(true);

	const [openCenterFormModal, setOpenCenterFormModal] = useState(false);
	const [updatingSimulation, setUpdatingSimulation] = useState(false);
	const [uploadingFile, setUploadingFile] = useState(false);

	const [basicDataValid, setBasicDataValid] = useState(false);

	const [initialInfo, setInitialInfo] = useState({
		name: '',
		localidad: null,
		convenio: null,
		center_group: '',
		id: uuidv4(),
		vacation_coverage: 100,
	});

	const defaultValues = {
		service_date_init: simulation?.service_date_init || '',
		service_duration: simulation?.service_duration || '',
		social_security: simulation?.social_security || '34',
		absenteeism_rate: simulation?.absenteeism_rate || '0',
	};

	const { handleSubmit, control, getValues, setValue, setError, watch } =
		useForm({
			defaultValues,
		});

	const localities = getFilteredArrayByObjectName(cities);

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const updateSimulationBasicData = (formValues) => {
		dispatch({ type: 'SET_BASIC_DATA', basicData: formValues });
	};

	const parseSimulation = (simulationInfo) => {
		setValue('service_duration', simulationInfo?.service_duration || '');
		setValue(
			'social_security',
			Number.isNaN(parseFloat(simulationInfo?.social_security))
				? '34'
				: parseFloat(simulationInfo?.social_security)
		);
		setValue(
			'absenteeism_rate',
			simulationInfo?.absenteeism_rate
				? parseFloat(simulationInfo.absenteeism_rate)
				: '0'
		);
		setValue('service_date_init', simulationInfo?.service_date_init || '');

		const formValues = {
			startDate: simulationInfo?.service_date_init || '',
			serviceDuration: simulationInfo?.service_duration || '',
			socialsecurity: simulationInfo?.social_security || '34',
			absenteeismRate: simulationInfo?.absenteeism_rate || '',
		};
		updateSimulationBasicData(formValues);
	};

	const getSimulationList = async () => {
		setLoadingSimulations(true);
		try {
			const response = await getSimulations({ simulationId });
			if (isMounted) {
				setSimulations(response);
				parseSimulation(response);
				setLoadingSimulations(false);
			}
		} catch (e) {
			setLoadingSimulations(false);
			setSnackBarMessage(e.message || 'Error desconocido en el servidor');
		}
	};

	const getSimulationCentersList = async () => {
		setLoadingCenters(true);
		try {
			const response = await getSimulationsCenters({ simulationId });
			if (isMounted) {
				setCenters(response);
				setLoadingCenters(false);
			}
		} catch (e) {
			setLoadingCenters(false);
			setSnackBarMessage(e.message || 'Error desconocido en el servidor');
		}
	};

	const getLocalitiesList = async () => {
		setLoadingLocalities(true);
		try {
			const response = await getLocalities('', auth?.workSpace?.id);
			if (isMounted) {
				setCities(response);
				setLoadingLocalities(false);
			}
		} catch (e) {
			setLoadingLocalities(false);
			setSnackBarMessage(e.message || 'Error desconocido en el servidor');
		}
	};

	const getAgreementsList = async () => {
		setLoadingAgreements(true);
		try {
			const response = await getAgreements('', auth?.workSpace?.id);
			if (isMounted) {
				setAgreementsList(response);
				dispatch(
					setAgreements({
						loading: false,
						data: response,
						error: '',
					})
				);
				setLoadingAgreements(false);
			}
		} catch (e) {
			setLoadingAgreements(false);
			setSnackBarMessage(e.message || 'Error desconocido en el servidor');
		}
	};

	const updateCentersList = (formValues) => {
		dispatch({ type: 'SET_CENTROS_LIST', centrosList: formValues });
	};

	const infoTemp = useRef(info);

	useEffect(() => {
		infoTemp.current = info;
	}, [info]);

	const validSubmit = () =>
		getValues('service_date_init') !== '' &&
		getValues('service_duration') !== '';

	useEffect(() => {
		getAgreementsList();
		getLocalitiesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getSimulationCentersList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadCenters]);

	useEffect(() => {
		getSimulationList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadSimulation]);

	useEffect(
		() => async () => {
			if (infoTemp?.current?.length !== 0 && validSubmit()) {
				const payload = { centers: parsingCentersToUpload(infoTemp.current) };
				await updateSimulationCenters({ payload, simulationId });
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	useEffect(() => {
		if (
			!getValues('service_date_init') ||
			getValues('social_security').toString() === 'NaN' ||
			!getValues('service_duration')
		) {
			setBasicDataValid(false);
		} else {
			setBasicDataValid(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// eslint-disable-next-line react-hooks/exhaustive-deps
		watch('service_date_init'),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		watch('social_security'),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		watch('service_duration'),
	]);

	useEffect(() => {
		if (!loadingCenters && !loadingLocalities && !loadingAgreements) {
			const parsedCenters = parsingCentersToShowInTable({
				centers,
				localities,
				convenios: agreements,
			});
			setInfo(parsedCenters);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingCenters, loadingLocalities, loadingAgreements]);

	const updateBasicSimulation = async () => {
		setUpdatingBasicSimulation(true);
		try {
			const payload = {
				name: simulation?.name ? simulation?.name : '7test',
				service_date_init: moment(getValues('service_date_init')).format(
					'YYYY-MM-DD'
				),
				service_duration: parseInt(getValues('service_duration'), 10),
				social_security: parseFloat(getValues('social_security')),
				absenteeism_rate: parseFloat(getValues('absenteeism_rate'), 10),
			};
			await updateBasicDataSimulation({
				payload,
				simulationId,
			});
			setUpdatingBasicSimulation(false);
			setReloadSimulation(!reloadSimulation);
		} catch {
			setUpdatingBasicSimulation(false);
		}
	};

	const updateInfo = (values) => {
		const rows = info.slice();
		if (edit) {
			rows[rows.findIndex((obj) => obj.id === values.id)] = values;
			setEdit(false);
		} else {
			rows.push(values);
		}
		setInfo(rows);
		updateCentersList(rows);
	};

	const updateSimulation = async () => {
		setUpdatingSimulation(true);
		try {
			const payload = { centers: parsingCentersToUpload(info) };
			const response = await updateSimulationCenters({ payload, simulationId });
			if (response) {
				dispatch(refreshCenters());
				dispatch(refreshPositions());
				dispatch(refreshCategoryPositions());
				setUpdatingSimulation(false);
			}
		} catch (e) {
			setUpdatingSimulation(false);
			const message =
				e.response.data.message === 'Center is used'
					? 'No puedes borrar un centro con turnos y/o tareas. Elimina lo turnos/tareas primero. También puedes reutilizar el centro editando sus datos y los turnos/tareas se mantendrán asignados.'
					: e.response.data.message;
			setSnackBarMessage(message);
			dispatch(refreshCenters());
		}
	};

	const validForm = (data) => {
		let response = true;
		if (
			!isValidDateFormat(data?.service_date_init) ||
			!isYearGraderThan1000(data?.service_date_init)
		) {
			setError('service_date_init', {
				type: 'custom',
				message: 'Fecha incorrecta',
			});
			response = false;
		}
		return response;
	};

	const handleSubmitForm = async (data) => {
		if (validForm(data)) {
			dispatch(updatingBasicData(true));
			const formValues = {
				startDate: data.startDate,
				serviceDuration: data.service_duration,
				socialsecurity: data.social_security,
				absenteeismRate: data.absenteeism_rate,
			};
			updateSimulationBasicData(formValues);
			await updateBasicSimulation();
			await updateSimulation();
			setReloadCenters((prev) => !prev);
			success(true);
		}
	};

	const updateFromTableDelete = (value) => {
		setInfo(value);
		if (value.length === 0) success(false);
		updateCentersList(value);
	};

	const closeCenterFormModal = () => {
		setOpenCenterFormModal(false);
		setEdit(false);
	};

	return {
		edit,
		setEdit,
		info,
		initialInfo,
		setInitialInfo,
		updatingSimulation,
		updatingBasicSimulation,
		loadingSimulation,
		loadingLocalities,
		loadingCenters,
		localities,
		loadingAgreements,
		agreements,
		updateInfo,
		handleSubmit,
		handleSubmitForm,
		updateFromTableDelete,
		basicDataValid,
		openCenterFormModal,
		closeCenterFormModal,
		setOpenCenterFormModal,
		uploadingFile,
		setUploadingFile,
		setReloadCenters,
		control,
	};
}
