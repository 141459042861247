import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DropzoneArea } from 'material-ui-dropzone';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudDownloadOutlinedIcon from '@mui/icons-material/DownloadForOffline';
import { Typography, Box } from '@mui/material';

import DialogComponentWithoutEditableOption from '../../../components/Dialog/DialogWithoutEditableOption';
import IsPreviousStudyEditable from '../../../components/PreviousStudy/IsPreviousStudyEditable';
import { StyledButton } from '../../../components/StyledComponents/Button';
import { setSnackbar } from '../../../store/actions/snackbar';
import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import UploadFile from '../../../utilities/connection/UploadFile';
import {
	downloadSurrogatesPreviousStudyUrl,
	uploadSurrogatesPreviousStudyUrl,
} from '../../../utilities/routes';
import { download } from '../../../utilities/utils';

const ErrorSpan = ({ error }) =>
	error ? (
		<Box>
			<Typography variant='subtitle1' color='error'>
				{error}
			</Typography>
		</Box>
	) : null;

const UploadDownLoadButtons = ({
	previousStudyId,
	uploadSurrogatesStatus,
	disabledButtons,
	setOpenCreateModal,
}) => {
	const [error, setError] = useState('');
	const [files, setFiles] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [uploadingFile, setUploadingFile] = useState(false);
	const [downloadingFile, setDownloadingFile] = useState(false);

	const dispatch = useDispatch();
	const dispatchErrorMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};
	const downloadSurrogates = () => {
		setDownloadingFile(true);
		const payload = {
			filename: 'subrogados',
			mime_type:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		};
		AxiosWrapper.get(
			downloadSurrogatesPreviousStudyUrl(previousStudyId),
			JSON.stringify(payload)
		)
			.then(({ data }) => {
				fetch(data.download_url)
					.then((response) => response.blob())
					.then((blob) => {
						download(blob, 'plantilla_subrogados.xlsx');
						setDownloadingFile(false);
					})
					.catch((e) => {
						setDownloadingFile(false);
						dispatchErrorMessage(e?.response?.data?.message || e?.message);
					});
			})
			.catch((e) => {
				setDownloadingFile(false);
				dispatchErrorMessage(e?.response?.data?.message || e?.message);
			});
	};

	const uploadSurrogates = () => {
		setUploadingFile(true);
		const payload = { filename: files[0].name, mime_type: files[0].type };
		const url = uploadSurrogatesPreviousStudyUrl(previousStudyId);
		AxiosWrapper.post(url, JSON.stringify(payload))
			.then((response) => {
				UploadFile({
					method: 'PUT',
					url: response.data.upload_url,
					content: files[0],
				})
					.then(() => {
						setUploadingFile(false);
						setFiles([]);
						setOpenModal(false);
						uploadSurrogatesStatus();
					})
					.catch((e) => {
						dispatchErrorMessage(
							`Error subiendo archivo, ${e?.message ? e?.message : ' '}`
						);
					});
			})
			.catch((e) => {
				dispatchErrorMessage(
					`Error obteniendo la url de subida, ${e?.message ? e?.message : ' '}`
				);
			});
	};

	const handleUploadSurrogates = () => {
		if (files.length > 0) {
			setError('');
			uploadSurrogates();
		} else {
			setError('Seleccione un fichero');
		}
	};

	const handleChangeFiles = (files) => {
		setFiles(files);
	};

	return (
		<Box>
			<Box
				sx={{
					display: 'block',
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<IsPreviousStudyEditable>
					<StyledButton
						onClick={() => setOpenCreateModal(true)}
						variant='text'
						aria-label='Añadir Subrogado'
						sx={{
							mr: 6,
							color: 'primary.main',
							textTransform: 'inherit',
							fontSize: '18px',
							fontWeight: '800',
						}}
						startIcon={<AddCircleOutlineIcon color='primary' />}
					>
						Añadir Subrogado
					</StyledButton>
				</IsPreviousStudyEditable>
				<StyledButton
					sx={{
						mr: 6,
						color: 'primary.main',
						textTransform: 'inherit',
						fontSize: '18px',
						fontWeight: '800',
					}}
					onClick={downloadSurrogates}
					variant='text'
					aria-label='Descargar Plantilla'
					startIcon={<CloudDownloadOutlinedIcon />}
					disabled={disabledButtons || downloadingFile}
				>
					Descargar Plantilla
				</StyledButton>
				<IsPreviousStudyEditable>
					<StyledButton
						sx={{
							color: 'primary.main',
							textTransform: 'inherit',
							fontSize: '18px',
							fontWeight: '800',
						}}
						onClick={() => setOpenModal(true)}
						variant='text'
						aria-label='Subir Plantilla de Subrogados'
						startIcon={
							<CloudDownloadOutlinedIcon sx={{ transform: 'scaleY(-1)' }} />
						}
						disabled={disabledButtons}
					>
						Subir Plantilla de Subrogados
					</StyledButton>
				</IsPreviousStudyEditable>
			</Box>
			<DialogComponentWithoutEditableOption
				open={openModal}
				onClose={() => setOpenModal(false)}
				title='Cargar subrogados'
				disableAccept={uploadingFile}
				disableCancel={uploadingFile}
				content={
					<Box>
						<DropzoneArea
							onChange={handleChangeFiles}
							dropzoneText='Arrastra el excel de subrogados'
							filesLimit={1}
							showFileNames={false}
							useChipsForPreview
							showPreviewsInDropzone={false}
							showPreviews
							dropzoneParagraphClass='DropZoneAreaText'
						/>
						<ErrorSpan error={error} />
					</Box>
				}
				onAccept={handleUploadSurrogates}
				acceptText='Aceptar'
				cancelText='Cancelar'
				onCancel={() => setOpenModal(false)}
				cancelButtonFirst={false}
			/>
		</Box>
	);
};
export default UploadDownLoadButtons;
