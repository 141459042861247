import React from 'react';

import { Box } from '@mui/material';

const LabelInfoBox = ({ label, infoToShow }) => (
	<Box height={40} display='flex'>
		<Box textAlign='left'>{label}</Box>
		<Box ml={1}>
			{infoToShow
				? ` ${
						!parseFloat(infoToShow).isNaN
							? infoToShow.toString().replace('.', ',')
							: infoToShow
				  }`
				: ' -'}
		</Box>
	</Box>
);
export default LabelInfoBox;
