import useGetResponseFromApi from '../../hooks/useGetResponseFromApi';
import AxiosWrapper from '../../utilities/connection/AxiosWrapper';
import {
	createSurrogateUrl,
	getSurrogatesUrl,
	getSurrogateUrl,
} from '../../utilities/routes';
import ApiException from './ApiException';

export async function deleteSurrogate({ simulationId, surrogateId }) {
	const url = getSurrogateUrl(simulationId, surrogateId);
	try {
		await AxiosWrapper.delete(url);
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export const getSurrogates = ({ simulationId, refresh }) =>
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useGetResponseFromApi({
		url: getSurrogatesUrl(simulationId),
		refresh,
	});

export async function getSurrogate({ simulationId, surrogateId }) {
	const url = getSurrogateUrl(simulationId, surrogateId);
	try {
		return (await AxiosWrapper.get(url)).data;
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function updateSurrogate({ simulationId, surrogateId, payload }) {
	const url = getSurrogateUrl(simulationId, surrogateId);
	try {
		await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function createSurrogate({ simulationId, payload }) {
	const url = createSurrogateUrl(simulationId);
	const response = await AxiosWrapper.post(url, JSON.stringify(payload));
	return response;
}
