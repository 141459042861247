import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import CheckConnection from 'components/CheckConnection/CheckConnection';

import { getTokenFromLocalStorage } from '../utils';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
	const token = getTokenFromLocalStorage();

	return (
		// restricted = false meaning public route
		// restricted = true meaning restricted route
		<Route
			{...rest}
			render={(props) =>
				token && restricted ? (
					<Redirect to='/projects' />
				) : (
					<Component {...{ ...props, ...rest }} />
				)
			}
		/>
	);
};

const PrivateRoute = ({ component: Component, ...rest }) => {
	const token = getTokenFromLocalStorage();
	return (
		// Show the component only when the user is logged in
		// Otherwise, redirect the user to /signin page
		<CheckConnection>
			<Route
				{...rest}
				render={(props) =>
					token ? (
						<Component {...{ ...props, ...rest }} />
					) : (
						<Redirect to='/login' />
					)
				}
			/>
		</CheckConnection>
	);
};

export { PrivateRoute, PublicRoute };
