import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Tooltip } from '@mui/material';

import group from '../../../../assets/imgs/group.png';
import DialogComponent from '../../../../components/Dialog/Dialog';
import { FormAutoComplete } from '../../../../components/Form/AutoComplete';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import { FormInputText } from '../../../../components/Form/InputText';
import useCenterForm from './useCenterForm';

const AddCenterForm = ({
	openCenterFormModal,
	closeCenterFormModal,
	updateInfo,
	initialInfo,
	setInitialInfo,
	localities,
	agreements,
}) => {
	const { onSubmit, submitAndClose, handleSubmit, control, closeModal } =
		useCenterForm({
			updateInfo,
			initialInfo,
			setInitialInfo,
			localities,
			agreements,
			closeCenterFormModal,
			openCenterFormModal,
		});

	const form = () => (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<FormInputText
						name='name'
						rules={{ required: 'Centros es requerido' }}
						control={control}
						label='Centro'
						data-testid='center_test_id'
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormAutoComplete
						name='locality'
						control={control}
						label='Localidad'
						options={localities}
						optionLabel='name'
						rules={{ required: 'Localidad es requerido' }}
						inputProps={{
							'data-testid': 'locality-test-id',
							variant: 'outlined',
						}}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<FormAutoComplete
						name='agreement'
						control={control}
						label='Convenio'
						options={agreements}
						optionLabel='name'
						rules={{ required: 'Convenio es requerido' }}
						inputProps={{
							'data-testid': 'agreement-test-id',
							variant: 'outlined',
						}}
					/>
				</Grid>
				<Grid container item xs={12} md={6} sx={{ alignItems: 'center' }}>
					<Grid item xs={10} md={10}>
						<FormInputNumber
							name='center_group'
							control={control}
							data-testid='center_group'
							label={<Box sx={{ zIndex: '1000', mb: 1 }}>Agrupación</Box>}
						/>
					</Grid>
					<Grid item xs={2} md={2}>
						<Tooltip
							placement='right'
							title='Campo opcional. Úsalo cuando necesites que los trabajadores de un centro no puedan trabajar en otro. Por ejemplo, todos los centros de Madrid tendrán la agrupación 1 y los de Segovia la agrupación 2.'
						>
							<InfoIcon
								sx={{ ml: 1, mt: 1, color: 'primary.main', fontSize: '1.5rem' }}
							/>
						</Tooltip>
					</Grid>
				</Grid>
				<Grid container item xs={12} md={6} sx={{ alignItems: 'center' }}>
					<Grid item xs={10} md={10}>
						<FormInputNumber
							name='vacation_coverage'
							control={control}
							data-testid='vacation_coverage'
							label='% cobertura vacaciones'
							max={100}
							min={0}
						/>
					</Grid>
					<Grid item xs={2} md={2}>
						<Tooltip
							placement='right'
							title='Campo opcional. Establece en qué porcentaje se cubrirán las vacaciones de los subrogados y nuevo personal que trabaje durante toda la simulación. Valores entre 0 y 100%.'
						>
							<InfoIcon
								sx={{ ml: 1, mt: 1, color: 'primary.main', fontSize: '1.5rem' }}
							/>
						</Tooltip>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);

	return (
		<DialogComponent
			open={openCenterFormModal}
			onClose={closeModal}
			title='Añadir Centro'
			content={form()}
			acceptText='Añadir y cerrar'
			acceptAndContinueText={<u>Guardar y continuar añadiendo</u>}
			cancelText='Cancelar'
			onAccept={() => {
				handleSubmit(submitAndClose)();
			}}
			onAcceptAndContinue={() => {
				handleSubmit(onSubmit)();
			}}
			onCancel={closeModal}
			withAvatar
			avatar={<img alt='group' src={group} style={{ width: 50 }} />}
		/>
	);
};
export default AddCenterForm;
