import React from 'react';

import esLocale from 'date-fns/locale/es';

import { LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Grid, TextField } from '@mui/material';

const TimePickerFromTo = ({
	hourFirst,
	setHourFirst,
	hourSecond,
	setHourSecond,
	nameInit = 'init',
	nameEnd = 'end',
	dataTestId1 = '',
	dataTestId2 = '',
}) => (
	<Grid container item mr={1} sx={{ alignItems: 'center' }}>
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
			<Grid item md={5.5} xs={12} mr={1}>
				<TimePicker
					value={hourFirst}
					onChange={(newValue) => {
						setHourFirst(newValue);
					}}
					ampm={false}
					name={nameInit}
					/* eslint-disable react/jsx-props-no-spreading */
					renderInput={(params) => (
						<TextField size='small' data-testid={dataTestId1} {...params} />
					)}
				/>
			</Grid>
			-
			<Grid item md={5.5} xs={12} ml={1}>
				<TimePicker
					value={hourSecond}
					onChange={(newValue) => {
						setHourSecond(newValue);
					}}
					ampm={false}
					name={nameEnd}
					renderInput={(params) => (
						<TextField size='small' data-testid={dataTestId2} {...params} />
					)}
				/>
			</Grid>
		</LocalizationProvider>
	</Grid>
);
export default TimePickerFromTo;
