import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

const TooltipIcon = ({ tooltipPlace = 'right', tooltipTitle = '' }) => (
	<Tooltip placement={tooltipPlace} title={tooltipTitle}>
		<InfoIcon color='primary' />
	</Tooltip>
);

export default TooltipIcon;
