import AxiosWrapper from '../../utilities/connection/AxiosWrapper';
import {
	createSurrogatePreviousStudyUrl,
	getContractTypesUrl,
	getMaterialCostsPreviousStudyIdUrl,
	getPreviousStudiesUrl,
	getPreviousStudySurrogatesUrl,
	getPreviousStudyUrl,
	getPreviousStudyUrlDownloadUrl,
	getSurrogatePreviousStudyUrl,
	getUserAgreementsUrl,
	launchPreviousStudyUrl,
	launchPreviousStudyAsyncUrl,
} from '../../utilities/routes';
import { post } from './Api';
import ApiException from './ApiException';

export async function createPreviousStudy(simulationName, projectId) {
	const url = `/previous_study`;
	const payload = { name: simulationName, project_id: projectId };
	try {
		return await AxiosWrapper.post(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function getPreviousStudies(project_id) {
	const url = `${getPreviousStudiesUrl()}`;
	const params = { project_id };
	try {
		return await AxiosWrapper.get(url, { params });
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function updatePreviousStudy({ previousStudyId, payload }) {
	const url = `previous_study/${previousStudyId}`;
	try {
		const { data } = await AxiosWrapper.put(url, JSON.stringify(payload));
		return data;
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function removePreviousStudy(previousStudyId) {
	const url = `previous_study/${previousStudyId}`;
	try {
		await AxiosWrapper.delete(url);
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function getPreviousStudy(previousStudyId) {
	const url = getPreviousStudyUrl(previousStudyId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function launchPreviousStudy({ previousStudyId }) {
	const url = launchPreviousStudyUrl(previousStudyId);
	try {
		return await AxiosWrapper.post(url);
	} catch (e) {
		throw new ApiException(e?.response.data.message);
	}
}

export function InternalServerErrorException() {
	this.message = 'Ha ocurrido un error desconocido en el servidor';
	this.name = 'InternalServerErrorException';
	this.severity = 'error';
}

export function PreviousStudyNotFoundException() {
	this.message = 'El estudio previo no existe';
	this.name = 'PreviousStudyNotFoundException';
	this.severity = 'error';
}

export function PreviousStudyNotValidToProcessException(message) {
	let new_message = '';
	switch (message) {
		case 'Previous Study not have initial date':
			new_message = 'No existe fecha inicial en el Estudio Previo';
			break;
		case 'Previous Study is solved':
			new_message = 'Ya se ha procesado el Estudio Previo';
			break;
		case 'Previous Study not have month duration':
			new_message = 'No existe duración del servicio en el Estudio Previo';
			break;
		case 'Previous Study not have social security':
			new_message = 'No existe seguridad social en el Estudio Previo';
			break;
		case 'Previous Study not have absenteeism rate':
			new_message = 'No existe porcentaje de absentismo en el Estudio Previo';
			break;
		case 'There are subrogates without category':
			new_message = 'Existe subrogado sin categoría';
			break;
		default:
			new_message = 'Error en el servidor';
			break;
	}
	this.message = new_message;
	this.name = 'PreviousStudyNotValidToProcessException';
	this.severity = 'error';
}

export async function launchAsyncPreviousStudy({ previousStudyId }) {
	const url = launchPreviousStudyAsyncUrl(previousStudyId);
	try {
		return await post(url);
	} catch (e) {
		switch (e.response.status_code) {
			case 404:
				throw new PreviousStudyNotFoundException();
			case 400:
				throw new PreviousStudyNotValidToProcessException(e.response.message);
			default:
				throw new InternalServerErrorException();
		}
	}
}

export async function getPreviousStudyUrlDownload(previousStudyId) {
	const url = getPreviousStudyUrlDownloadUrl(previousStudyId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function updateBasicData({ previousStudyId, payload }) {
	const url = getPreviousStudyUrl(previousStudyId);
	try {
		await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export const getSurrogates = async ({ previousStudyId }) => {
	const url = getPreviousStudySurrogatesUrl(previousStudyId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
};

export async function getSurrogate({ previousStudyId, surrogateId }) {
	const url = getSurrogatePreviousStudyUrl(previousStudyId, surrogateId);
	try {
		return (await AxiosWrapper.get(url)).data;
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function createSurrogate({ previousStudyId, payload }) {
	const url = createSurrogatePreviousStudyUrl(previousStudyId);
	try {
		return await AxiosWrapper.post(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function updateSurrogate({
	previousStudyId,
	surrogateId,
	payload,
}) {
	const url = getSurrogatePreviousStudyUrl(previousStudyId, surrogateId);
	try {
		await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function deleteSurrogate({ previousStudyId, surrogateId }) {
	const url = getSurrogatePreviousStudyUrl(previousStudyId, surrogateId);
	try {
		await AxiosWrapper.delete(url);
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function getAgreements(workspace_id = '') {
	const url = getUserAgreementsUrl;
	const params = { params: { workspace_id } };
	try {
		return await AxiosWrapper.get(url, params);
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function getContractTypes() {
	const url = getContractTypesUrl();
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function getMaterialCosts({ previousStudyId }) {
	const url = getMaterialCostsPreviousStudyIdUrl(previousStudyId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}
