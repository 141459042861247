import React from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import { Box, CircularProgress, Grid, InputAdornment } from '@mui/material';

import group from '../../../../assets/imgs/group4.png';
import Else from '../../../../components/Compounds/If/Else';
import If from '../../../../components/Compounds/If/If';
import Then from '../../../../components/Compounds/If/Then';
import DialogComponent from '../../../../components/Dialog/Dialog';
import DialogLabelTypography from '../../../../components/DialogLabelTypography';
import { FormAutoComplete } from '../../../../components/Form/AutoComplete';
import { FormDatePicker } from '../../../../components/Form/DatePicker';
import { FormDropdown } from '../../../../components/Form/Dropdown';
import { FormNotification } from '../../../../components/Form/FormNotification';
import { FormInputText } from '../../../../components/Form/InputText';
import TooltipIcon from '../../../../components/TooltipIcon/TooltipIcon';
import useSurrogateModal from './useSurrogateModal';

const SurrogateModal = ({
	openModal,
	setOpenModal,
	setReloadSurrogates,
	surrogateId,
	setSurrogateId,
}) => {
	const {
		uploadingSurrogate,
		loadingSurrogate,
		control,
		centersData,
		agreements,
		categories,
		journeyOptions,
		loadingContractTypes,
		contractTypes,
		handleSubmit,
		onSubmit,
		onSubmitAndContinue,
		watch,
		closeDialog,
		isPossibleRetirement,
	} = useSurrogateModal({
		openModal,
		setOpenModal,
		setReloadSurrogates,
		surrogateId,
		setSurrogateId,
	});

	const form = () =>
		uploadingSurrogate || loadingSurrogate ? (
			<Box sx={{ textAlign: 'center' }}>
				<CircularProgress />
			</Box>
		) : (
			<Grid container spacing={2}>
				<Grid item xs={12} sx={{ mb: 1 }}>
					<Grid container spacing={2} sx={{ alignItems: 'center' }}>
						<Grid item xs={6}>
							<FormAutoComplete
								name='center'
								control={control}
								label='Seleccione un centro'
								options={centersData}
								optionLabel='name'
								autoCompleteProps={{
									'data-testid': 'centerSelector',
								}}
							/>
						</Grid>
						<Grid item xs={6} sx={{ verticalAlign: 'middle' }}>
							<TooltipIcon
								tooltipTitle='Opcional. Si no seleccionas 1 centro/agrupación el sistema lo asignará automáticamnete'
								tooltipPlace='right'
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} sx={{ mb: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<FormAutoComplete
								name='agreement'
								control={control}
								label='Seleccione un convenio'
								options={agreements?.data}
								optionLabel='name'
							/>
						</Grid>
						<Grid item xs={6}>
							<FormAutoComplete
								name='categoryPosition'
								control={control}
								label='Seleccione una categoría'
								options={categories}
								optionLabel='professional_category'
								rules={{ required: 'Categoría requerida' }}
								autoCompleteProps={{
									'data-testid': 'categoryPosition',
								}}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<DialogLabelTypography label='Jornada:' />
				</Grid>

				<Grid item xs={12} sx={{ mb: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<FormDropdown
								name='journey'
								control={control}
								options={journeyOptions}
								selectProps={{ 'data-testid': 'journeySelect' }}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormInputText
								name='journeyValue'
								control={control}
								label=''
								data-testid='journeyValue'
								endAdornment={
									<InputAdornment position='end'>
										<If conditional={watch('journey') === 'percent'}>
											<Then>%</Then>
											<Else>h/s</Else>
										</If>{' '}
									</InputAdornment>
								}
								inputProps={{ min: 0 }}
								type='number'
								rules={{
									required: 'Valor requerido',
									validate: (amount) => amount >= 0 || 'Valor incorrecto',
								}}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} sx={{ mb: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<FormDatePicker
								name='seniorityDate'
								control={control}
								label='Fecha antigüedad'
								componentProps={{
									'data-testid': 'dateValue',
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<If conditional={loadingContractTypes}>
								<Then>
									<CircularProgress />
								</Then>
								<Else>
									<FormAutoComplete
										name='contractType'
										control={control}
										label='Tipo de contrato'
										options={contractTypes}
										optionLabel='label'
										autoCompleteProps={{
											'data-testid': 'contractType',
										}}
									/>
								</Else>
							</If>
						</Grid>
						{isPossibleRetirement ? (
							<Grid item xs={6}>
								<FormNotification notification='Posible jubilación' />
							</Grid>
						) : null}
					</Grid>
				</Grid>

				<Grid item xs={12} sx={{ mb: 1 }}>
					<Grid container spacing={2} sx={{ alignItems: 'center' }}>
						<Grid item xs={6}>
							<FormInputText
								name='salaryYear'
								control={control}
								label='Salario anual pactado'
								type='number'
								endAdornment={
									<InputAdornment position='end'>
										<EuroIcon sx={{ mr: 0.5, ml: 0.0, width: '14px' }} />
									</InputAdornment>
								}
								inputProps={{
									min: 0,
								}}
								rules={{
									validate: (amount) => amount >= 0 || 'Valor incorrecto',
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TooltipIcon
								tooltipTitle='Rellenar solo cuando al subrogado no se le aplican las tablas salariales del convenio(por ejemplo, tiene un plus adicional pactado)'
								tooltipPlace='right'
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} sx={{ mb: 1 }}>
					<Grid container spacing={2} sx={{ alignItems: 'center' }}>
						<Grid item xs={6}>
							<FormInputText
								name='annualBonuses'
								control={control}
								label='Pluses y/o complementos anuales'
								type='number'
								data-testid='annualBonuses'
								endAdornment={
									<InputAdornment position='end'>
										<EuroIcon sx={{ mr: 0.5, ml: 0.0, width: '14px' }} />
									</InputAdornment>
								}
								inputProps={{
									min: 0,
								}}
								rules={{
									validate: (amount) => amount >= 0 || 'Valor incorrecto',
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TooltipIcon
								tooltipTitle='Añade el importe anual de todos los pluses o complementos que están fuera de convenio.'
								tooltipPlace='right'
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);

	return (
		<DialogComponent
			open={openModal}
			onClose={closeDialog}
			title='Añadir / editar subrogado'
			content={form()}
			acceptText='Añadir y cerrar'
			acceptAndContinueText={<u>Continuar añadiendo subrogados</u>}
			cancelText='Cancelar'
			onAccept={handleSubmit(onSubmit)}
			onAcceptAndContinue={handleSubmit(onSubmitAndContinue)}
			onCancel={closeDialog}
			fullWidth
			disableAccept={uploadingSurrogate}
			disableAcceptAndContinue={uploadingSurrogate}
			withAvatar
			avatar={<img alt='group' src={group} style={{ width: 50 }} />}
		/>
	);
};
export default SurrogateModal;
