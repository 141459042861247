import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import EuroIcon from '@mui/icons-material/Euro';

import useIsMounted from '../../../../hooks/useIsMounted';
import { setSnackbar } from '../../../../store/actions/createSimulation';
import { getInventories } from '../../../Inventory/ApiServices';
import {
	createMaterialCost,
	getMaterialCostById,
	updateMaterialCosts,
} from '../ApiServices';

export default function useMaterialCostModal({
	openModal,
	setOpenModal,
	edit,
	setEdit,
	materialCostId,
	materialCostsData,
	setReloadMaterialCosts,
}) {
	const dispatch = useDispatch();
	const { basicData } = useSelector((state) => state.createSimulation);
	const { previousStudy, auth } = useSelector((state) => state);
	const previousStudyId = previousStudy.uid;
	const { isMounted } = useIsMounted();

	const [materialCosts, setMaterialCosts] = useState([]);
	const [loadingMaterialCost, setLoadingMaterialCost] = useState(false);
	const [selectedMaterialCosts, setSelectedMaterialCosts] = useState('');
	const [amountIcon, setAmountIcon] = useState('');
	const [inventoriesData, setInventories] = useState([]);
	const [loadingInventories, setLoadingInventories] = useState(false);

	const defaultValues = {
		id: uuidv4(),
		name: '',
		cost_type: materialCosts?.length > 0 ? materialCosts[0] : null,
		amount: '',
		months_to_apply: basicData?.serviceDuration || '',
		number_of_units: '',
	};
	const { handleSubmit, control, reset, setValue, watch } = useForm({
		defaultValues,
	});

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const getInventoriesList = async () => {
		setLoadingInventories(true);
		if (auth?.workSpace?.id) {
			try {
				const inventoryList = await getInventories(auth?.workSpace?.id);
				if (isMounted) {
					setInventories(inventoryList);
					setLoadingInventories(false);
				}
			} catch (e) {
				setLoadingInventories(false);
				setSnackBarMessage(e?.response?.data?.message || e?.message || '');
			}
		}
	};

	useEffect(() => {
		getInventoriesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth?.workSpace?.id]);

	useEffect(() => {
		if (inventoriesData?.length > 0 && openModal) {
			setValue('cost_type', inventoriesData[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openModal]);

	useEffect(() => {
		if (!loadingInventories) {
			setMaterialCosts(inventoriesData);
		}
	}, [loadingInventories, inventoriesData]);

	const resetForm = () => {
		setOpenModal(false);
		setEdit(false);
		reset(defaultValues);
	};

	const resetFormWidthOutClose = () => {
		setEdit(false);
		reset(defaultValues);
	};

	const updateMaterialCostIconAndText = (costType) => {
		switch (costType) {
			case 'SALARY_PERCENT':
				setAmountIcon('%');
				setSelectedMaterialCosts('Porcentaje del coste salarial');
				break;
			case 'AMOUNT_BY_WORKER':
				setAmountIcon(<EuroIcon />);
				setSelectedMaterialCosts('Por trabajador');
				break;
			case 'MONTHLY_AMOUNT':
				setAmountIcon(<EuroIcon />);
				setSelectedMaterialCosts('Por mes');
				break;
			case 'MONTHLY_UNIT_COST':
				setAmountIcon(<EuroIcon />);
				setSelectedMaterialCosts('Cada unidad');
				break;
			default:
				setAmountIcon('%');
				setSelectedMaterialCosts('Porcentaje del coste salarial');
				break;
		}
	};

	useEffect(() => {
		if (watch('cost_type')) {
			const materialList = edit ? materialCostsData : materialCosts;
			const material = materialList?.find(
				(material) => material.id === watch('cost_type').id
			);
			if (material) updateMaterialCostIconAndText(material?.cost_type);

			if (material?.cost_type !== 'SALARY_PERCENT') {
				setValue('amount', material?.amount);
			} else if (!edit) {
				setValue('amount', '');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('cost_type')]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		if (edit) {
			try {
				setLoadingMaterialCost(true);
				const materialCostData = await getMaterialCostById({
					previousStudyId,
					materialCostId,
				});
				setValue('id', materialCostData?.id);
				setValue('name', materialCostData?.name);
				setValue('cost_type', materialCostData);
				setValue('amount', materialCostData?.amount);
				setValue('months_to_apply', materialCostData?.months_to_apply);
				setValue('number_of_units', materialCostData?.number_of_units);
				updateMaterialCostIconAndText(materialCostData.cost_type);
				setLoadingMaterialCost(false);
			} catch (e) {
				setLoadingMaterialCost(false);
				setSnackBarMessage(e?.message);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit, materialCostId]);

	const parseToSubmit = (values) => {
		values.amount = parseFloat(values.amount);
		const materialCostSelected = materialCosts.find(
			(material) => material.id === values.cost_type.id
		);

		values.cost_type = materialCostSelected?.cost_type;
		values.name = materialCostSelected?.name;
		values.months_to_apply = parseInt(values?.months_to_apply, 10);
		values.number_of_units = parseInt(values.number_of_units, 10);
		return values;
	};

	const createOrUpdate = async (values) => {
		const payload = parseToSubmit(values);
		try {
			if (edit) {
				await updateMaterialCosts({
					previousStudyId,
					materialCostId,
					payload,
				});
				setEdit(false);
			} else {
				await createMaterialCost({ previousStudyId, payload });
			}
		} catch (e) {
			setSnackBarMessage(e?.message);
		}
	};

	const onSubmit = async (values) => {
		await createOrUpdate(values);
		setReloadMaterialCosts((prev) => !prev);
		resetForm();
	};

	const onSubmitAndContinue = async (values) => {
		await createOrUpdate(values);
		setReloadMaterialCosts((prev) => !prev);
		resetFormWidthOutClose();
	};

	const isValidAmountValue = (amount) =>
		selectedMaterialCosts === 'Porcentaje del coste salarial'
			? (amount >= 0 && amount <= 100) || 'Valor incorrecto'
			: amount >= 0 || 'Valor incorrecto';

	const isValidPersentAmountValue = (amount) =>
		selectedMaterialCosts === 'Porcentaje del coste salarial'
			? (amount >= 0 && amount <= 100) || 'Valor incorrecto'
			: true;

	return {
		handleSubmit,
		control,
		onSubmit,
		onSubmitAndContinue,
		materialCosts,
		loadingMaterialCost,
		resetForm,
		watch,
		selectedMaterialCosts,
		amountIcon,
		isValidAmountValue,
		isValidPersentAmountValue,
	};
}
