import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ErrorManager from 'containers/Simulation/Surrogate/OcrErrorsSubrogated/ErrorManager';
import translator from 'utilities/translator/translator';

import {
	Alert,
	Box,
	CircularProgress,
	Grid,
	Snackbar,
	TextField,
} from '@mui/material';

import {
	deleteSurrogate,
	getSurrogates,
} from '../../../Services/Api/Surrogate';
import IsSimulationEditable from '../../../components/Simulations/IsSimulationEditable';
import SurrogatesTable from '../../../components/Simulations/Surrogates/SurrogatesTable';
import { setSnackbar } from '../../../store/actions/createSimulation';
import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import { uploadSurrogatesStatusUrl } from '../../../utilities/routes';
import { formatNumber, sleep } from '../../../utilities/utils';
import SurrogateModal from './Modal';
import UploadDownLoadButtons from './UploadDownLoadButtons';

const SurrogatesTab = () => {
	const { simulationId } = useSelector((state) => state.createSimulation);
	const dispatch = useDispatch();

	const [surrogateId, setSurrogateId] = useState('');
	const [reloadSurrogates, setReloadSurrogates] = useState(false);
	const [reloadingSurrogates, setReloadingSurrogates] = useState(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [workingDaySum, setWorkingDaySum] = useState(0);
	const [checkErrors, setCheckErrors] = useState(false);

	const uploadSurrogatesStatus = async () => {
		let response = '';
		let count = 0;
		setReloadingSurrogates(true);
		do {
			count += 1;
			try {
				response = await AxiosWrapper.get(
					uploadSurrogatesStatusUrl(simulationId),
					{}
				);
			} catch (e) {
				dispatch(
					setSnackbar({
						openSnackbar: true,
						duration: 6000,
						severity: 'error',

						message: e?.response?.data?.message || e?.message,
					})
				);
			}
			await sleep(500);
			if (response?.data?.status === 'ERROR') {
				count = 10;
				setToastMessage(translator(response.data.info_message));
				setOpenSnackbar(true);
				setReloadingSurrogates(false);
				return;
			}
			if (response?.data?.status === 'PENDING' && count >= 10) {
				setToastMessage('El servidor está tardando mucho en responder');
				setOpenSnackbar(true);
				setReloadingSurrogates(false);
				return;
			}
			if (response?.data?.status !== 'PENDING' || count >= 10) {
				setReloadSurrogates(!reloadSurrogates);
				setReloadingSurrogates(false);
			}
		} while (response?.data?.status === 'PENDING' && count <= 10);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};

	const { loading: loadingSurrogates, data: surrogates } = getSurrogates({
		simulationId,
		refresh: reloadSurrogates,
	});

	const deleteSurrogateById = async (surrogateId) => {
		try {
			await deleteSurrogate({
				simulationId,
				surrogateId,
			});
			setReloadSurrogates((prev) => !prev);
		} catch (e) {
			dispatch(
				setSnackbar({
					openSnackbar: true,
					duration: 6000,
					severity: 'error',
					message: e?.message,
				})
			);
		}
	};

	const editSurrogate = async (surrogateId) => {
		if (surrogateId) {
			setSurrogateId(surrogateId);
			setOpenCreateModal(true);
		}
	};

	const getSurrogatesWorkingDaysSum = () => {
		let sum = 0;
		surrogates?.forEach((surrogate) => {
			if (surrogate?.working_day_fixed) sum += surrogate.working_day_fixed;
		});
		return formatNumber(sum);
	};

	useEffect(() => {
		setWorkingDaySum(getSurrogatesWorkingDaysSum);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surrogates]);

	return checkErrors ? (
		<ErrorManager
			simulationId={simulationId}
			setCheckErrors={setCheckErrors}
			setReloadSurrogates={() => setReloadSurrogates((prev) => !prev)}
		/>
	) : (
		<Box sx={{ width: '100%' }}>
			<SurrogateModal
				openModal={openCreateModal}
				setOpenModal={(val) => setOpenCreateModal(val)}
				setReloadSurrogates={() => setReloadSurrogates((prev) => !prev)}
				surrogateId={surrogateId}
				setSurrogateId={(val) => setSurrogateId(val)}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<Box
					sx={{
						fontSize: '18px',
						fontWeight: '800',
						textAlign: 'left',
						mb: 3,
					}}
					color='primary.main'
				>
					Subrogados
				</Box>
				<Box>
					<Grid container item mt='2px' sx={{ alignItems: 'center' }}>
						<TextField
							color='primary'
							value={`Total de horas semanales       ${workingDaySum}h`}
							InputProps={{
								readOnly: true,
								inputProps: {
									style: {
										textAlign: 'center',
										padding: '2px',
										height: '30px',
									},
								},
							}}
							focused
							sx={{ width: '300px', marginLeft: '10px' }}
						/>
					</Grid>
				</Box>
			</Box>
			<Box mt={2}>
				<IsSimulationEditable>
					<UploadDownLoadButtons
						simulationId={simulationId}
						uploadSurrogatesStatus={uploadSurrogatesStatus}
						disabledButtons={reloadingSurrogates || loadingSurrogates}
						setToastMessage={setToastMessage}
						setOpenCreateModal={setOpenCreateModal}
						setReloadSurrogates={() => setReloadSurrogates((prev) => !prev)}
						setCheckErrors={setCheckErrors}
					/>
				</IsSimulationEditable>
			</Box>
			<Box mt={4}>
				{reloadingSurrogates || loadingSurrogates ? (
					<CircularProgress />
				) : (
					<SurrogatesTable
						surrogates={surrogates}
						deleteSurrogate={deleteSurrogateById}
						editSurrogate={editSurrogate}
					/>
				)}
			</Box>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity='error'
					sx={{ width: '100%' }}
				>
					{toastMessage}
				</Alert>
			</Snackbar>
		</Box>
	);
};
export default SurrogatesTab;
