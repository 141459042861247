export function resetState() {
	return {
		type: 'RESET_STATE',
	};
}

export function setCenters(centers) {
	return {
		type: 'SET_CENTERS',
		centers,
	};
}

export function updatingBasicData(updatingBasicDataValue) {
	return {
		type: 'UPDATING_BASIC_DATA',
		updatingBasicDataValue,
	};
}

export function refreshSimulation() {
	return {
		type: 'REFRESH_SIMULATION',
	};
}

export function refreshCenters() {
	return {
		type: 'REFRESH_CENTERS',
	};
}

export function refreshPositions() {
	return {
		type: 'REFRESH_POSITIONS',
	};
}

export function setSimulationId(simulationId) {
	return {
		type: 'SET_SIMULATION_ID',
		simulationId,
	};
}

export function setPositions(positions) {
	return {
		type: 'SET_POSITIONS',
		positions,
	};
}

export function setCategoryPositions(categoryPositions) {
	return {
		type: 'SET_CATEGORY_POSITIONS',
		categoryPositions,
	};
}

export function refreshCategoryPositions() {
	return {
		type: 'REFRESH_CATEGORY_POSITIONS',
	};
}

export function setAgreements(agreements) {
	return {
		type: 'SET_AGREEMENTS',
		agreements,
	};
}

export function setSnackbar(snackbar) {
	return {
		type: 'SET_SNACKBAR',
		snackbar,
	};
}

export function closeSnackBar() {
	return {
		type: 'CLOSE_SNACKBAR',
	};
}

export function setSimulationInfo(simulation) {
	return {
		type: 'SET_SIMULATION_INFO',
		simulation,
	};
}
