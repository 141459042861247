import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider } from '@mui/material';

import { showSnackbar } from '../../store/actions/snackbar';
import { parseSimulationCounter } from '../../utilities/utils';

const SimulationCounter = ({ isLoggedIn, openDrawer }) => {
	const { auth } = useSelector((state) => state);

	const [simulationCounter, setSimulationCounter] = useState('Simulaciones: ∞');
	const dispatch = useDispatch();

	const getSimulationCounter = async () => {
		try {
			setSimulationCounter(parseSimulationCounter(auth?.workSpace));
		} catch (e) {
			dispatch(
				showSnackbar({
					severity: 'error',
					message: e?.message || 'Error desconocido en el servidor',
				})
			);
		}
	};

	useEffect(() => {
		if (isLoggedIn()) {
			getSimulationCounter();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn(), auth?.workSpace]);

	return (
		<Box
			sx={{
				position: 'absolute',
				bottom: '40px',
				width: '100%',
				color: 'primary.main',
			}}
			display={openDrawer ? 'inline' : 'none'}
		>
			<Divider
				sx={{
					borderColor: 'primary.main',
					backgroundColor: 'primary.main',
					mb: 2,
					borderBottomWidth: 'initial',
					width: '100%',
				}}
			/>
			{simulationCounter}
		</Box>
	);
};
export default SimulationCounter;
