import React from 'react';

import { StyledLoadingButton } from 'components/StyledComponents/LoadingButton';

import { Box } from '@mui/material';

const ActionButtons = ({ saveSubrogate, uploadingSubroutesSimulation }) => (
	<Box sx={{ width: '100%', textAlign: 'center' }}>
		<StyledLoadingButton
			color='primary'
			onClick={saveSubrogate}
			sx={{ m: 3 }}
			variant='contained'
		>
			Guardar
		</StyledLoadingButton>
		<StyledLoadingButton
			color='primary'
			onClick={uploadingSubroutesSimulation}
			sx={{ m: 3 }}
			variant='contained'
		>
			Finalizar
		</StyledLoadingButton>
	</Box>
);

export default ActionButtons;
