import React from 'react';
import { useFieldArray } from 'react-hook-form';

import { Grid, InputAdornment, Typography } from '@mui/material';

import { FormInputNumber } from '../../../../components/Form/FormInputNumber';

const IncreaseForm = ({ item, control, index }) => {
	const { fields: increaseAgreement } = useFieldArray({
		control,
		name: `increase.${index}.values`,
	});

	return (
		<>
			<Grid item xs={12}>
				<Typography variant='body1'>{item?.agreement_name}</Typography>
			</Grid>
			<Grid item xs={12} spacing={2}>
				<Grid container>
					{increaseAgreement?.map((date, i) => (
						<Grid item xs={3} key={date.id} mb={2}>
							<Grid container>
								<Grid
									item
									xs={3}
									sx={{ alignItems: 'center', display: 'flex' }}
								>
									{date.year}
								</Grid>
								<Grid item xs={7}>
									<FormInputNumber
										control={control}
										max={100}
										min={0}
										name={`increase.${index}.values.${i}.value`}
										sx={{ width: '100px' }}
										endAdornment={
											<InputAdornment position='end'>%</InputAdornment>
										}
									/>
								</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
		</>
	);
};

export default IncreaseForm;
