import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import DialogVariantComponent from 'components/Dialog/DialogVariantComponent';
import { DropzoneArea } from 'material-ui-dropzone';
import { setSnackbar } from 'store/actions/createSimulation';
import UploadFile from 'utilities/connection/UploadFile';
import { getStatusOcrSimulationUrl } from 'utilities/routes';
import useSubscribe from 'utilities/useSubscribe';

import { Box, Typography, CircularProgress } from '@mui/material';

import {
	getSimulationOcrGetUploadUrl,
	startOcrExecution,
} from 'Services/Api/SimulationOcr';

const UploadDialogPDF = ({
	simulationId,
	openModal,
	setOpenModal,
	setCheckErrors,
}) => {
	const [uploadingFile, setUploadingFile] = useState(false);
	const [loadingOcr, setLoadingOcr] = useState(false);
	const [error, setError] = useState('');
	const [files, setFiles] = useState([]);

	const { handleCreateSubscription } = useSubscribe();

	const dispatch = useDispatch();

	const uploadFilePdf = (files) => {
		setUploadingFile(true);
		setFiles(files);
		setUploadingFile(false);
	};

	const setSnackBarMessage = ({ message, severity = 'error' }) => {
		dispatch(
			setSnackbar({
				duration: 6000,
				message,
				openSnackbar: true,
				severity,
			})
		);
	};

	const start_ocr_process = () => {
		startOcrExecution({ simulationId }).then(() => {
			handleCreateSubscription({
				endpoint_url: getStatusOcrSimulationUrl(simulationId),
				on_request: (response) => {
					if (response.status === 'IDLE') {
						setCheckErrors(true);

						return true;
					}
					if (response.status === 'ERROR') {
						setLoadingOcr(false);
						setFiles([]);
						setSnackBarMessage({
							message: `Error  porcesando pdf, ${
								response?.msg ? response?.msg : ' '
							}`,
						});
						return true;
					}
				},
				on_error: () => {},
				on_timeout: () => {
					setLoadingOcr(false);
					setSnackBarMessage({
						message: 'El servidor esta tardando bastante en responder',
					});
					return true;
				},
				max_timeout: 1200,
			});
		});
	};

	const undloadPdf = async (simulationId) => {
		setLoadingOcr(true);

		try {
			const response = await getSimulationOcrGetUploadUrl({ simulationId });
			UploadFile({
				method: 'PUT',
				url: response,
				content: files[0],
			})
				.then(() => {
					start_ocr_process();
				})
				.catch((e) => {
					setLoadingOcr(false);
					setSnackBarMessage({
						message: `Error subiendo archivo, ${e?.message ? e?.message : ' '}`,
					});
				});
		} catch (e) {
			setLoadingOcr(false);
			setSnackBarMessage({ message: 'Error desconocido en el servidor' });
		}
	};

	const handleUploadSurrogatesPDF = () => {
		if (files.length > 0) {
			setError('');
			undloadPdf(simulationId);
		} else {
			setError('Seleccione un fichero');
		}
	};
	return (
		<DialogVariantComponent
			onClose={() => setOpenModal(false)}
			open={openModal}
			title='Cargar subrogados'
			content={
				<Box>
					{loadingOcr ? (
						<Box sx={{ textAlign: 'center' }}>
							<CircularProgress />
						</Box>
					) : (
						<DropzoneArea
							acceptedFiles={['application/pdf']}
							dropzoneClass='test-drop'
							dropzoneParagraphClass='DropZoneAreaText'
							dropzoneProps={{
								disabled: uploadingFile,
							}}
							dropzoneText='Arrastra el pdf de subrogados'
							filesLimit={1}
							onChange={uploadFilePdf}
							showFileNames={false}
							showPreviews
							showPreviewsInDropzone={false}
							useChipsForPreview
						/>
					)}

					<Box>
						<Typography variant='subtitle1' color='error'>
							{error}
						</Typography>
					</Box>
				</Box>
			}
			acceptText='Aceptar'
			cancelText='Cancelar'
			disableAccept={uploadingFile || loadingOcr}
			disableCancel={uploadingFile || loadingOcr}
			onAccept={handleUploadSurrogatesPDF}
			onCancel={() => setOpenModal(false)}
		/>
	);
};

export default UploadDialogPDF;
