import React, { Component } from 'react';

import styled from 'styled-components';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
	ListItem,
	ListItemAvatar,
	IconButton,
	ListItemSecondaryAction,
	ListItemText,
	Avatar,
	Menu,
	MenuItem,
	Card,
	Tooltip,
} from '@mui/material';

import { withTheme } from '@material-ui/core/styles';
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	MoreVert,
} from '@material-ui/icons';

import If from '../Compounds/If/If';
import Then from '../Compounds/If/Then';

const StyledCard = styled(Card)`
	margin-top: 15px;
`;

class AppCard extends Component {
	constructor(props) {
		// Required step: always call the parent class' constructor
		super(props);

		this.state = {
			openMenu: null,
			option: null,
		};
	}

	renderIcon = (element) => {
		let { leftIcon } = this.props;
		leftIcon = element.icon ? element.icon : leftIcon;
		let iconList = leftIcon ? (
			<ListItemAvatar>
				<Avatar>{leftIcon}</Avatar>
			</ListItemAvatar>
		) : null;
		if (element.expired) {
			iconList = (
				<ListItemAvatar>
					<Tooltip title='Convenio fuera de vigencia. Asegúrate de que no haya salido uno nuevo antes de usarlo en una simulación'>
						<WarningAmberIcon color='primary' sx={{ fontSize: 25, ml: 1 }} />
					</Tooltip>
				</ListItemAvatar>
			);
		}

		return iconList;
	};

	renderActions = (idx) => {
		const {
			element,
			typeActions,
			customAction,
			onDelete,
			onEdit,
			onDuplicate,
		} = this.props;
		const { openMenu, option } = this.state;

		if ((!typeActions || typeActions === 'delete') && onDelete) {
			return (
				<IconButton
					edge='end'
					aria-label='delete'
					onClick={() => onDelete(idx)}
				>
					<DeleteIcon />
				</IconButton>
			);
		}

		if (typeActions === 'editDelete' && onDelete && onEdit) {
			return (
				<>
					<IconButton
						edge='end'
						aria-label='more'
						onClick={(event) =>
							this.setState({ openMenu: event.currentTarget, option: idx })
						}
					>
						<MoreVert />
					</IconButton>
					<Menu
						id='simple-menu'
						anchorEl={openMenu}
						open={Boolean(openMenu)}
						onClose={() => this.setState({ openMenu: null })}
						PaperProps={{
							elevation: 1,
							sx: {
								border: '0px solid',
								boxShadow: '1px 2px 4px -3px rgb(0 0 0 / 8%) !important',
							},
						}}
					>
						<If conditional={element?.update_permissions}>
							<Then>
								<MenuItem
									onClick={() => {
										onDelete(option);
										this.setState({ openMenu: null });
									}}
								>
									<DeleteIcon />
									Borrar
								</MenuItem>
							</Then>
						</If>
						<If conditional={element.update_permissions}>
							<Then>
								<MenuItem
									onClick={() => {
										onEdit(option);
										this.setState({ openMenu: null });
									}}
								>
									<EditIcon />
									Editar
								</MenuItem>
							</Then>
						</If>
						<If conditional={typeof onDuplicate !== 'undefined'}>
							<Then>
								<MenuItem
									onClick={() => {
										onDuplicate(option);
									}}
								>
									<ContentCopyIcon />
									Duplicar
								</MenuItem>
							</Then>
						</If>
					</Menu>
				</>
			);
		}

		if (typeActions === 'none') {
			return null;
		}

		return customAction || null;
	};

	/* eslint padded-blocks: ["error",  { "blocks": "never" }] */
	render() {
		const { element, idx, onClick } = this.props;

		return (
			<StyledCard key={idx}>
				<ListItem
					button={onClick ? true : false}
					onClick={onClick ? () => onClick(idx) : null}
				>
					{this.renderIcon(element)}
					<ListItemText
						primary={element.name}
						secondary={element.secondary ? element.secondary : null}
					/>
					<ListItemSecondaryAction>
						{this.renderActions(idx)}
					</ListItemSecondaryAction>
				</ListItem>
			</StyledCard>
		);
	}
}

export default withTheme(AppCard);
