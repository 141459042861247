import Api from '../../utilities/api';

// GET
export function getProjectsList() {
	return (dispatch, getState) => {
		dispatch(resetProjectsList()); // Clean fav state old data
		dispatch(setFetchingState(true)); // Api call will now begin, fetching data
		return Api.get(
			'/project', // Endpoint
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then((response) => {
			dispatch(setProjectsList(response)); // Data is received from the api call. Save it in the state
			dispatch(setFetchingState(false)); // The Api call finished
		});
	};
}

export function getProject(id) {
	return (dispatch, getState) => {
		dispatch(resetCurrentProject());
		dispatch(setFetchingState(true));
		return Api.get(
			`project/${id}`,
			getState().auth.token,
			dispatch,
			'user'
		).then((response) => {
			dispatch(setCurrentProject(response));
			dispatch(setFetchingState(false));
		});
	};
}

// PUT
export function updateProjects(projectDict, projectId) {
	return (dispatch, getState) =>
		Api.put(
			`project/${projectId}`, // Endpoint
			projectDict, // "Body" of the request (PUT)
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then(() => dispatch(getProjectsList())); // Once the fav has been updated, retrieve the updated list of projects
}

export function setProjectsList(projects) {
	return {
		type: 'SET_PROJECTS_LIST',
		projects,
	};
}

export function setCurrentProject(currentProject) {
	return {
		type: 'SET_CURRENT_PROJECT',
		currentProject,
	};
}

export function resetCurrentProject() {
	return { type: 'RESET_CURRENT_PROJECT' };
}

export function resetProjectsList() {
	return { type: 'RESET_PROJECTS_LIST' };
}

export function setFetchingState(fetching) {
	return {
		type: 'SET_FETCHING_STATE_PROJECTS',
		fetching,
	};
}
