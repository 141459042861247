import AxiosWrapper from '../../utilities/connection/AxiosWrapper';
import { getSimulationUrl } from '../../utilities/routes';
import ApiException from './ApiException';

export async function updateBasicDataSimulation({ simulationId, payload }) {
	const url = getSimulationUrl(simulationId);
	try {
		await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}
