import { useEffect, useState, useCallback } from 'react';

import { getUseSubscribe } from './getUseSubscribe';

export default function useSubscribe() {
	const [active, setActive] = useState(false);

	const [data, setData] = useState({});

	const handleCreateSubscription = useCallback((payload) => {
		setData(payload);
		setActive(true);
	}, []);

	useEffect(() => {
		if (active) {
			const {
				endpoint_url,
				on_request,
				on_error,
				on_timeout,
				seconds_to_wait = 1,
				max_timeout = 60,
			} = data;

			let count = 0;
			const interval_id = setInterval(async () => {
				let exit = false;
				count += 1;
				try {
					const response = await getUseSubscribe(endpoint_url);
					exit = on_request(response);
				} catch (e) {
					exit = on_error(e);
				}
				if (count > max_timeout) {
					exit = on_timeout();
				}
				if (exit) {
					setActive(false);
				}
			}, seconds_to_wait * 1000);

			return () => clearInterval(interval_id);
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active]);

	return {
		handleCreateSubscription,
	};
}
