import Api from '../../utilities/api';
import { setError } from './error';

// POST
export function requestUploadUrl(payload) {
	if (
		!payload.project_uid ||
		!payload.simulation_uid ||
		!payload.filename ||
		!payload.mime_type
	) {
		console.warn(
			'Payload must be and object with project_uid, simulation_uid, filename and mime_type'
		);
		return Promise.reject(payload);
	}

	return (dispatch, getState) =>
		Api.post(
			`simulation/${payload.simulation_uid}/upload-url`, // Endpoint
			payload, // "Body" of the request (POST)
			getState().auth.token, // Current user token
			dispatch, // store.dispatch.
			'user' // Keyword to set the api host config (flowtag-user)
		).then((response) =>
			dispatch(setContentUploadUrl(response.upload_url, true, 0))
		);
}

export function setContentUploadUrl(contentUploadUrl, uploading, progress) {
	return {
		type: 'SET_CONTENT_UPLOAD_URL',
		contentUploadUrl,
		uploading,
		progress,
	};
}

export function uploadContent(contentUploadUrl, content) {
	return (dispatch) =>
		makeRequest('PUT', contentUploadUrl, content, dispatch).then(
			() => dispatch(setContentUploadUrl(null)),
			(response) => dispatch(setError(response))
		);
}

function updateProgress(evt, dispatch) {
	if (evt.lengthComputable) {
		const percentComplete = evt.loaded / evt.total;
		const roundedPercentage = Math.round(percentComplete * 10000) / 100;
		dispatch(setProgress(roundedPercentage));
	} else {
		// Unable to compute progress information since the total size is unknown
	}
}

function makeRequest(method, url, content, dispatch) {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open(method, url, true);
		xhr.onload = () => {
			dispatch(setUploading(false));
			if (xhr.status >= 200 && xhr.status < 300) {
				resolve(xhr.response);
			} else {
				reject(
					new Error({
						status: xhr.status,
						statusText: xhr.statusText,
					})
				);
			}
		};
		xhr.onerror = () => {
			dispatch(setUploading(false));
			reject(
				new Error({
					status: xhr.status,
					statusText: xhr.statusText,
				})
			);
		};
		xhr.upload.addEventListener(
			'progress',
			(e) => updateProgress(e, dispatch),
			false
		);
		xhr.setRequestHeader('X-File-Type', content.type);
		xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
		xhr.send(content);
	});
}

export function setProgress(progress) {
	return {
		type: 'SET_PROGRESS',
		progress,
	};
}

export function setUploadUrl(uploadUrl) {
	return {
		type: 'SET_UPLOAD_URL',
		uploadUrl,
	};
}

export function setUploading(uploading) {
	return {
		type: 'SET_UPLOADING',
		uploading,
	};
}
