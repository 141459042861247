import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setSnackbar } from 'store/actions/createSimulation';

import { validateSimulation } from 'Services/Api/Simulation';

const getValidateSimulation = async (simulation_id) => {
	const validateData = await validateSimulation(simulation_id);
	return validateData;
};

export default function useValidateSimulationModal({
	setOpenModal,
	simulationId,
	openModal,
}) {
	const [validateSimulationData, setValidateSimulationData] = useState([]);
	const [loadingValidate, setLoadingValidate] = useState(true);
	const dispatch = useDispatch();

	const closeDialog = () => {
		setOpenModal(false);
		setLoadingValidate(true);
	};

	useEffect(() => {
		if (simulationId && openModal) {
			getValidateSimulation(simulationId)
				.then((response) => {
					setValidateSimulationData(response);
					setLoadingValidate(false);
				})
				.catch(() => {
					closeDialog();
					dispatch(
						setSnackbar({
							openSnackbar: true,
							duration: 6000,
							severity: 'error',
							message: 'Error validando los datos de la simulación.',
						})
					);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openModal, simulationId]);

	return {
		validateSimulationData,
		loadingValidate,
		closeDialog,
	};
}
