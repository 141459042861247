import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
	getUploadCentersUrl,
	getUrlToDownloadCenters,
} from '../../../../Services/Api/Api';
import { setSnackbar } from '../../../../store/actions/snackbar';
import AxiosWrapper from '../../../../utilities/connection/AxiosWrapper';
import UploadFile from '../../../../utilities/connection/UploadFile';
import { download, sleep } from '../../../../utilities/utils';

export default function useCreateUploadDownload({
	simulationId,
	setUploadingFile,
	setReloadCenters,
}) {
	const dispatch = useDispatch();

	const [centersFile, setCentersFile] = useState([]);
	const [downloadingFile, setDownloadingFile] = useState(false);
	const [openUploadCentersModal, setOpenUploadCentersModal] = useState(false);
	const [centerUploadError, setCenterUploadError] = useState('');

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const uploadCentersStatus = async () => {
		setUploadingFile(true);
		let response = '';
		let count = 0;
		do {
			count += 1;
			try {
				response = await AxiosWrapper.get(
					`/simulation/${simulationId}/center/excel/status`,
					{}
				);
			} catch (e) {
				setSnackBarMessage(e?.response?.data?.message || e?.message);
			}
			await sleep(500);
			if (response?.data?.status === 'ERROR') {
				count = 10;
				setUploadingFile(false);
				setSnackBarMessage(response?.data?.info_message);
				return;
			}
			if (response?.data?.status === 'PENDING' && count >= 10) {
				setUploadingFile(false);
				setSnackBarMessage('El servidor está tardando mucho en responder');
				return;
			}
			if (response?.data?.status !== 'PENDING' || count >= 10) {
				await sleep(500);
				setReloadCenters((prev) => !prev);
				setUploadingFile(false);
				return;
			}
		} while (response?.data?.status === 'PENDING' && count <= 10);
	};

	const uploadCenters = async (data) => {
		try {
			await UploadFile({
				method: 'PUT',
				url: data.upload_url,
				content: centersFile[0],
			});
			setCentersFile([]);
			setOpenUploadCentersModal(false);
			uploadCentersStatus();
		} catch (e) {
			setSnackBarMessage(
				`Error subiendo archivo, ${e?.message ? e?.message : ' '}`
			);
			setUploadingFile(false);
		}
	};

	const handleUploadCenters = async () => {
		if (centersFile.length > 0) {
			setCenterUploadError('');
			setUploadingFile(true);
			const payload = {
				filename: centersFile[0].name,
				mime_type: centersFile[0].type,
			};
			try {
				const { data } = await getUploadCentersUrl(simulationId, payload);
				uploadCenters(data);
			} catch (e) {
				setSnackBarMessage(
					`Error obteniendo la url de subida, ${e?.message ? e?.message : ' '}`
				);
				setUploadingFile(false);
			}
		} else {
			setCenterUploadError('Seleccione un fichero');
		}
	};

	const downloadCenters = async () => {
		setDownloadingFile(true);
		const payload = {
			filename: 'centros',
			mime_type:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		};
		try {
			const { data } = await getUrlToDownloadCenters(simulationId, payload);
			fetch(data.download_url)
				.then(async (response) => {
					const blob = await response.blob();
					download(blob, 'plantilla_centros.xlsx');
					setDownloadingFile(false);
				})
				.catch((e) => {
					setDownloadingFile(false);
					setSnackBarMessage(e?.response?.data?.message || e?.message);
				});
		} catch (e) {
			setDownloadingFile(false);
			setSnackBarMessage(e?.response?.data?.message || e?.message);
		}
	};
	return {
		downloadingFile,
		setDownloadingFile,
		setCentersFile,
		handleUploadCenters,
		downloadCenters,
		openUploadCentersModal,
		setOpenUploadCentersModal,
		centerUploadError,
		setCenterUploadError,
	};
}
