import { useEffect } from 'react';

import { checkConnectionEndpoint } from 'Services/Api/Api';

const CheckConnection = ({ children }) => {
	useEffect(() => {
		const seconds_to_wait = 300;
		const interval_id = setInterval(async () => {
			await checkConnectionEndpoint();
		}, seconds_to_wait * 1000);

		return () => clearInterval(interval_id);
	});

	return children;
};

export default CheckConnection;
