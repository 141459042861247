export const mondayToFridayDays = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
];

export const selectItemsTaskFrequencyOption1 = [
	{ label: 'Semana', value: 'week' },
	{ label: 'Día', value: 'day' },
	{ label: 'Mes', value: 'month' },
	{ label: 'Año', value: 'year' },
];

export const selectItemsTaskFrequencyOption2 = [
	{ label: 'Día', value: 'day' },
	{ label: 'Semana', value: 'week' },
	{ label: 'Mes', value: 'month' },
	{ label: 'Año', value: 'year' },
];

export const selectItemsTaskFrequencyOption3 = [
	{ label: 'Días', value: 'day' },
	{ label: 'Semanas', value: 'week' },
	{ label: 'Meses', value: 'month' },
];
