import { applyMiddleware, compose, createStore } from 'redux';
import { persistCombineReducers, persistStore } from 'redux-persist';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage';
import reduxReset from 'redux-reset';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

const uuidv4 = require('uuid/v4');
// combineReducers

const persistConfig = {
	key: 'root',
	storage,
};

const persistedReducers = persistCombineReducers(persistConfig, rootReducer);

const initialState = {
	projects: {
		fetching: true,
	},
	simulations: {
		fetching: true,
	},
	events: {
		fetching: true,
	},
	content: {
		uploading: false,
	},
	createSimulation: {
		basicData: {},
		centers: { data: [], loading: false, error: '' },
		simulation: {},
		positions: {},
		agreements: {},
		categoryPositions: {},
		simulationId: '',
		refreshSimulation: false,
		refreshCenters: false,
		refreshPositions: false,
		updatingBasicData: false,
		refreshCategoryPositions: false,
		snackbar: {
			openSnackbar: false,
			duration: 6000,
			severity: 'success',
			message: '',
		},
	},
	inventoryStore: {
		snackbar: {
			openSnackbar: false,
			duration: 6000,
			severity: 'success',
			message: '',
		},
	},
	snackbar: {
		openSnackbar: false,
		duration: 6000,
		severity: 'success',
		message: '',
	},
	auth: { device: uuidv4(), is_loading: false },
};

const windowCompose =
	typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = windowCompose || compose;

const middlewares = [thunk];

// Use redux-logger only on development mode
if (process.env.NODE_ENV === 'development') {
	// eslint-disable-next-line
	// const { logger } = require('redux-logger');
	// middlewares.push(logger);
}

/*
  applyMiddleware receives as argument functions that conform to the Redux middleware API.

  Each middleware receives Store's dispatch and getState functions as named arguments, and returns a function.
  That function will be given the next middleware's dispatch method, and is expected to return a function of action
  calling next(action) with a potentially different argument, or at a different time, or maybe not calling it at all.
  The last middleware in the chain will receive the real store's dispatch method as the next parameter, thus ending the chain.

  So, the middleware signature is ({ getState, dispatch }) => next => action
*/

export const store = createStore(
	persistedReducers,
	initialState,
	composeEnhancers(
		applyMiddleware(...middlewares),
		reduxReset() // Will use 'RESET' as default action.type to trigger reset
	)
);
// redux state is saved to persisted storage whenever it changes
// when the state object gets persisted, it first gets serialized with JSON.stringify()
export const persistor = persistStore(store);

export { initialState };
