import ApiException from '../../../Services/Api/ApiException';
import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import {
	getRolesUrl,
	getUsersByWorkSpacesUrl,
	updateUserRoleUrl,
} from '../../../utilities/routes';

export async function getUsersByWorkSpaces(workSpacesId) {
	const url = getUsersByWorkSpacesUrl(workSpacesId);
	try {
		const { data } = await AxiosWrapper.get(url);
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getRoles() {
	const url = getRolesUrl();
	try {
		const { data } = await AxiosWrapper.get(url);
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function updateUserRole(payload, workspace_id, user_id) {
	const url = updateUserRoleUrl(workspace_id, user_id);
	try {
		const { data } = await AxiosWrapper.put(url, JSON.stringify(payload));
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
