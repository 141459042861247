import React from 'react';

import { Box, Grid, Slide, Tooltip } from '@mui/material';

import group from '../../../../../assets/imgs/group2.png';
import { AppInputMUI } from '../../../../../components';
import AppAutoComplete from '../../../../../components/AppAutoComplete/AppAutoComplete';
import If from '../../../../../components/Compounds/If/If';
import Then from '../../../../../components/Compounds/If/Then';
import DialogComponent from '../../../../../components/Dialog/Dialog';
import DialogLabelTypography from '../../../../../components/DialogLabelTypography';
import FixedShiftsBottom from '../../../../../components/Simulations/Workload/FixedShiftsBottom';
import ShiftSchedule from '../../../../../components/Simulations/Workload/ShiftSchedule';
import ToggleWeekOptions from '../../../../../components/Simulations/Workload/ToggleWeekOptions';
import DateRange from '../../DateRange';
import useShiftsModal from './useShiftModal';

const ShiftsModal = ({
	openModal,
	setOpenModal,
	handleUpdateWorkShifts,
	initialValues,
	edit,
	setEdit,
	simulationId,
}) => {
	const {
		centers,
		positions,
		loadingPositions,
		errorCenters,
		days,
		setDays,
		checked,
		setChecked,
		errorHours,
		errorHoursText,
		errorPositions,
		errorServiceDuration,
		conditionalErrorMessage,
		firstSplitTurn,
		setFirstSplitTurn,
		firstTurn,
		setFirstTurn,
		holidaysChecked,
		setHolidaysChecked,
		applyOnHolidays,
		setApplyOnHolidays,
		noShiftsChecked,
		setNoShiftsChecked,
		numberOfWorkers,
		setNumberOfWorkers,
		secondSplitTurn,
		setSecondSplitTurn,
		secondTurn,
		setSecondTurn,
		selectedCenter,
		setSelectedCenter,
		selectedPosition,
		setSelectedPosition,
		shiftsValue,
		setShiftsValue,
		weekDaysChecked,
		setWeekDaysChecked,
		containerRef,
		handleSave,
		handleSaveAndContinue,
		closeModalActions,
		maxDate,
		minDate,
	} = useShiftsModal({
		openModal,
		setOpenModal,
		handleUpdateWorkShifts,
		initialValues,
		edit,
		setEdit,
		simulationId,
	});

	const infoToShowTooltip = () => (
		<Tooltip
			placement='top'
			title='Nº de empleados destinados al puesto seleccionado'
		>
			<span>Nº </span>
		</Tooltip>
	);

	const form = () => (
		<Box width='100%' sx={{ display: 'flex' }}>
			<Box width='100%'>
				<Grid container spacing={2}>
					<Grid container item md={5} xs={12}>
						<AppAutoComplete
							value={selectedCenter}
							setValue={setSelectedCenter}
							label='Nombre Centro'
							options={centers.data}
							optionLabel='name'
							errorText='Seleccione un centro'
							error={errorCenters}
						/>
					</Grid>
					<Grid item md={5} xs={12}>
						<AppAutoComplete
							value={selectedPosition}
							setValue={setSelectedPosition}
							label='Puesto'
							options={positions}
							optionLabel='name'
							errorText='Seleccione un puesto'
							error={errorPositions}
							autoCompleteProps={{ disabled: loadingPositions }}
						/>
					</Grid>
					<Grid item md={2} xs={12}>
						<AppInputMUI
							fullWidth
							helperText={errorServiceDuration && 'Campo requerido'}
							type='number'
							label={infoToShowTooltip()}
							error={errorServiceDuration}
							name='serviceDuration'
							value={numberOfWorkers}
							onChange={(event) => setNumberOfWorkers(event.target.value)}
							size='small'
						/>
					</Grid>
				</Grid>
				<ShiftSchedule
					hourFirst={firstTurn}
					setHourFirst={setFirstTurn}
					hourSecond={secondTurn}
					setHourSecond={setSecondTurn}
					firstSplitTurn={firstSplitTurn}
					setFirstSplitTurn={setFirstSplitTurn}
					secondSplitTurn={secondSplitTurn}
					setSecondSplitTurn={setSecondSplitTurn}
					checked={checked}
					setChecked={setChecked}
				/>
				<Box sx={{ width: '100%' }}>
					<If conditional={errorHours}>
						<Then>
							<DialogLabelTypography label={errorHoursText} color='error' />
						</Then>
					</If>
				</Box>
				<Box mt={3} mb={2}>
					<ToggleWeekOptions days={days} setDays={setDays} />
					<FixedShiftsBottom
						holidaysChecked={holidaysChecked}
						setHolidaysChecked={setHolidaysChecked}
						weekDaysChecked={weekDaysChecked}
						setWeekDaysChecked={setWeekDaysChecked}
						noShiftsChecked={noShiftsChecked}
						setNoShiftsChecked={setNoShiftsChecked}
						applyOnHolidays={applyOnHolidays}
						setApplyOnHolidays={setApplyOnHolidays}
					/>
				</Box>
				<DialogLabelTypography label={conditionalErrorMessage} color='error' />
				<Box
					sx={{
						display: noShiftsChecked ? 'flex' : 'none',
						overflow: 'hidden',
						background: '#F7F7F8',
					}}
					ref={containerRef}
					mt={2}
				>
					<Grid container justifyContent='center' mt={2} mb={2}>
						<Grid container item xs={9}>
							<Grid
								item
								xs={12}
								sx={{
									fontSize: '18px',
									fontWeight: '800',
									color: 'primary.main',
								}}
							>
								Sin turno
							</Grid>
							<Grid container item>
								<Slide in={noShiftsChecked} mountOnEnter unmountOnExit>
									<Box mt={2}>
										<DateRange
											setDateRangeList={(e) => setShiftsValue(e)}
											listRange={shiftsValue}
											minDate={minDate}
											maxDate={maxDate}
										/>
									</Box>
								</Slide>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);

	return (
		<DialogComponent
			open={openModal}
			onClose={closeModalActions}
			title='Crear turno'
			content={form()}
			acceptText='Añadir y cerrar'
			acceptAndContinueText={<u>Continuar añadiendo turnos</u>}
			cancelText='Cancelar'
			onAccept={handleSave}
			onAcceptAndContinue={handleSaveAndContinue}
			onCancel={closeModalActions}
			fullWidth
			withAvatar
			avatar={<img alt='group' src={group} style={{ width: 50 }} />}
		/>
	);
};

export default ShiftsModal;
