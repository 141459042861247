import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { StyledBoxContainer } from 'components/StyledComponents/BoxContainer';
import { setSnackbar } from 'store/actions/createSimulation';

import { Box, Table, CircularProgress } from '@mui/material';

import {
	getSubrogateWithErrorWorkingDay,
	saveSubrogateWithErrorWorkingDay,
} from 'Services/Api/SimulationOcr';

import ActionButtons from './ActionButtons';
import SurrogateTableBody from './SurrogateTable/SurrogateTableBody';
import SurrogateTableHead from './SurrogateTable/SurrogateTableHead';
import { checkSubrogate, parseSubrogateToSave } from './utils';

const colorOnClick = '#D6EAF8';

const WorkingDayForm = ({ setIsSaveSurrogates, simulationId }) => {
	const dispatch = useDispatch();

	const [loadingSubrogate, setLoadingSubrogate] = useState(false);
	const [subrogates, setSubrogates] = useState([]);
	const [valueMetricWorkingDay, setValueMetricWorkingDay] = useState('h/s');

	const changeType = useCallback((typeWorkingDay) => {
		setValueMetricWorkingDay(typeWorkingDay === 'FIXED' ? 'h/s' : '%');
		setSubrogates((prev) =>
			prev.map((values) => ({
				...values,
				value_metric_working_day: typeWorkingDay,
			}))
		);
	}, []);

	const uploadingSubroutesSimulation = useCallback(() => {
		setIsSaveSurrogates(true);
	}, [setIsSaveSurrogates]);

	const saveSubrogate = useCallback(() => {
		if (checkSubrogate(subrogates)) {
			dispatch(
				setSnackbar({
					openSnackbar: true,
					duration: 6000,
					severity: 'error',
					message: 'La jornada del subrogado no puede ser 0 ni estar vacia',
				})
			);
		} else {
			setLoadingSubrogate(true);
			const subrogate_data = { candidates: parseSubrogateToSave(subrogates) };
			saveSubrogateWithErrorWorkingDay({
				simulationId,
				payload: subrogate_data,
			}).then(setLoadingSubrogate(false));
		}
	}, [dispatch, simulationId, subrogates]);

	useEffect(() => {
		if (simulationId) {
			setLoadingSubrogate(true);

			getSubrogateWithErrorWorkingDay({ simulationId })
				.then((response) => {
					const subrogates_result = response.subrogates_with_errors;
					if (subrogates_result?.length > 0) {
						setSubrogates(subrogates_result);
					} else if (subrogates_result?.length === 0) {
						setIsSaveSurrogates(true);
					}
				})
				.finally(() => {
					setLoadingSubrogate(false);
				});
		} else {
			setSubrogates([]);
		}
	}, [setIsSaveSurrogates, simulationId]);

	return loadingSubrogate ? (
		<Box sx={{ textAlign: 'center' }} my={8}>
			<CircularProgress />
		</Box>
	) : subrogates?.length ? (
		<>
			<Box
				sx={{
					fontSize: '18px',
					fontWeight: '800',
					my: 3,
					textAlign: 'left',
				}}
				color='primary.main'
			>
				Jornadas
			</Box>
			<Box
				sx={{
					fontSize: '18px',
					fontWeight: '800',
					mb: 3,
					textAlign: 'left',
				}}
				color='common.red'
			>
				Revisa las Jornadas de los siguientes subrogados:
			</Box>
			<StyledBoxContainer>
				<Table>
					<SurrogateTableHead
						changeType={changeType}
						colorOnClick={colorOnClick}
						valueMetricWorkingDay={valueMetricWorkingDay}
					/>
					<SurrogateTableBody
						setSubrogates={setSubrogates}
						subrogates={subrogates}
						valueMetricWorkingDay={valueMetricWorkingDay}
					/>
				</Table>
			</StyledBoxContainer>
			<ActionButtons
				saveSubrogate={saveSubrogate}
				uploadingSubroutesSimulation={uploadingSubroutesSimulation}
			/>
		</>
	) : null;
};

export default WorkingDayForm;
