import React from 'react';

import uuid from 'uuid';

import { Box, Grid } from '@mui/material';

import IsLoading from '../../../components/IsLoading/IsLoading';
import { formatNumber } from '../../../utilities/utils';
import EuroPerTime from '../EuroPerTime';
import FloatWithEuroIcon from '../FloatWithEuroIcon';
import NumberWithEuroIcon from '../NumberWithEuroIcon';
import useCategoryInfo from './useCategoryInfo';

const CategoryInfo = ({ agreementId, categoryId, resizeTabs }) => {
	const { loadingCategory, category } = useCategoryInfo({
		agreementId,
		categoryId,
		resizeTabs,
	});

	return (
		<IsLoading conditional={loadingCategory}>
			<Grid container spacing={2}>
				<Grid item md={4} xs={12}>
					<FloatWithEuroIcon
						amount={category.salary_year}
						label='Salario total anual:'
					/>
					<FloatWithEuroIcon
						amount={category.base_salary_year}
						label='Salario base anual:'
					/>
				</Grid>

				<Grid item md={8} xs={12}>
					<Box mt={2} sx={{ display: 'flex' }}>
						<Box mr={1}>Pluses fijos:</Box>
						{category?.fixed_pluses?.length > 0 ? (
							<Box>
								{category.fixed_pluses[0].price_day_worked && (
									<NumberWithEuroIcon
										amount={`${formatNumber(
											category.fixed_pluses[0].price_day_worked
										)}`}
										time='día trabajado'
									/>
								)}
								{category.fixed_pluses[0].price_year_worked && (
									<NumberWithEuroIcon
										amount={`${formatNumber(
											category.fixed_pluses[0].price_year_worked
										)}`}
										time='año trabajado'
									/>
								)}
								{category.fixed_pluses[0].price_month_worked && (
									<NumberWithEuroIcon
										amount={`${formatNumber(
											category.fixed_pluses[0].price_month_worked
										)}`}
										time='mes trabajado'
									/>
								)}
							</Box>
						) : (
							'-'
						)}
					</Box>
					<EuroPerTime
						amount={
							category?.pluses_by_schedule?.length > 0
								? `${category.pluses_by_schedule[0].time_init} - ${
										category.pluses_by_schedule[0].time_end
								  } = ${formatNumber(
										category.pluses_by_schedule[0].price_hour
								  )}`
								: ' '
						}
						label='Pluses por horario:'
					/>
					<Box mt={2} sx={{ display: 'flex' }}>
						<Box>Pluses por días de la semana:</Box>
						<Box ml={1}>
							{category?.pluses_by_weekday?.length > 0 &&
								category?.pluses_by_weekday.map((plus) => (
									<NumberWithEuroIcon
										key={uuid()}
										amount={`${plus.day} = ${
											plus?.price_hour
												? formatNumber(plus.price_hour)
												: formatNumber(plus.price_day)
										}`}
										time={`${plus.price_hour ? 'hora' : 'día'}`}
									/>
								))}
						</Box>
					</Box>
					{category?.free_days && (
						<Box mt={2} sx={{ display: 'flex' }}>
							<Box>Festivos:</Box>
							<Box ml={1}>{category.free_days}</Box>
							<Box ml={1}>días libres</Box>
						</Box>
					)}
				</Grid>
			</Grid>
		</IsLoading>
	);
};

export default CategoryInfo;
