/*
 * Actions are payloads of information that send data from your application to your store.
 * They are the only source of information for the store.
 */

// Action creator setError
export function setError(errorData) {
	// return the action, type SET_ERROR, with the error data as payload
	return { type: 'SET_ERROR', error: errorData };
}

// Action creator setError
export function resetError() {
	// return the action, type RESET_ERROR, with no payload. The reducer will take care of the rest
	return { type: 'RESET_ERROR' };
}
