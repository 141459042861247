import React from 'react';

import { Grid } from '@mui/material';

import AppAutoComplete from '../../../components/AppAutoComplete/AppAutoComplete';
import IsLoading from '../../../components/IsLoading/IsLoading';
import { parseSimulationCounter } from '../../../utilities/utils';
import useWorkSpaces from './useWorkSpaces';

const WorkSpaces = () => {
	const {
		loadingWorkSpace,
		workSpaces,
		selectedWorkSpace,
		auth,
		setSelectedWorkSpace,
	} = useWorkSpaces();

	return (
		<IsLoading conditional={loadingWorkSpace}>
			<Grid container spacing={2} sx={{ alignItems: 'center', mt: '25px' }}>
				<Grid
					item
					sm={4}
					md={2}
					sx={{ color: 'black', fontWeight: '700', display: 'flex' }}
				>
					Espacio de trabajo
				</Grid>
				<Grid item sm={8} md={4}>
					<AppAutoComplete
						value={selectedWorkSpace}
						setValue={setSelectedWorkSpace}
						label='Nombre espacio trabajo'
						options={workSpaces}
						optionLabel='name'
					/>
				</Grid>
				<Grid
					container
					item
					sm={11}
					ml={{ sm: 0, md: 8 }}
					md={5}
					sx={{ textAlign: 'left' }}
				>
					<Grid item sx={{ fontWeight: '700', color: 'black' }}>
						{parseSimulationCounter(
							auth?.workSpace,
							'Simulaciones gastadas/contratadas'
						)}
					</Grid>
				</Grid>
			</Grid>
		</IsLoading>
	);
};
export default WorkSpaces;
