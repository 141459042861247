export const translatorData = {
	'Not all surrogates have associated tasks': {
		SPANISH:
			'Hay categorías de subrogados que no pueden realizar ninguna tarea. Asocia el puesto de algún turno o tarea a sus categorías o añade los turnos/tareas que falten para evitar que esos subrogados se queden fuera.',
	},
	'There are subcontractors who have more hours than the hours allowed in their collective agreement.':
		{
			SPANISH:
				'Error en los datos de entrada, revisa jornadas del excel subido.',
		},
	'Error creating a simulation': {
		SPANISH:
			'Ha habido un error al crear la simulación, por favor, intentelo mas tarde.',
	},
	'Surrogate is assigned more hours than allowed': {
		SPANISH: 'No puedes poner más horas que las que marca el convenio.',
	},
};
