import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { StyledLoadingButton } from 'components/StyledComponents/LoadingButton';
import { setSnackbar } from 'store/actions/createSimulation';

import { Box, CircularProgress } from '@mui/material';

import {
	getSubrogateWithErrorCategories,
	saveSubrogateWithErrorCategories,
} from 'Services/Api/SimulationOcr';

import AgreementForm from './AgreementForm';
import CategoriesForm from './CategoriesForm';

const Categories = ({ simulationId, setLoading, onSaveCategories }) => {
	const { control, setValue, watch, handleSubmit, getValues } = useForm();
	const dispatch = useDispatch();
	const [agreementSelected, setAgreementSelected] = useState(null);
	const [categoriesSelected, setCategoriesSelected] = useState([]);
	const [loadingCategoriesSelected, setLoadingCategoriesSelected] =
		useState(false);

	const getCategories = useCallback(async () => {
		setLoadingCategoriesSelected(true);
		setCategoriesSelected([]);
		try {
			await getSubrogateWithErrorCategories({ simulationId })
				.then((resp) => {
					setCategoriesSelected(resp?.categories_with_errors);
					if (!resp?.categories_with_errors?.length) {
						onSaveCategories();
					}
				})
				.finally(() => {
					setLoadingCategoriesSelected(false);
				});
		} catch (e) {
			setLoadingCategoriesSelected(false);

			dispatch(
				setSnackbar({
					duration: 6000,
					message: e.message || 'Error desconocido en el servidor',
					openSnackbar: true,
					severity: 'error',
				})
			);
		}
	}, [dispatch, onSaveCategories, simulationId]);

	const onSubmit = useCallback(
		async (data) => {
			setLoadingCategoriesSelected(true);
			try {
				await saveSubrogateWithErrorCategories({
					simulationId,
					payload: {
						categories: data?.categories.map((values) => ({
							agreement_id: getValues('agreement')?.id,
							category_id: values?.category?.id,
							label: values?.label,
						})),
					},
				})
					.then(() => {
						dispatch(
							setSnackbar({
								duration: 6000,
								message: 'Guardado con éxito',
								openSnackbar: true,
								severity: 'success',
							})
						);

						getCategories();
					})
					.catch((e) => {
						dispatch(
							setSnackbar({
								duration: 6000,
								message: e.message || 'Error desconocido en el servidor',
								openSnackbar: true,
								severity: 'error',
							})
						);
					});
			} catch (e) {
				dispatch(
					setSnackbar({
						duration: 6000,
						message: e.message || 'Error desconocido en el servidor',
						openSnackbar: true,
						severity: 'error',
					})
				);
			} finally {
				setLoadingCategoriesSelected(false);
			}
		},
		[dispatch, getCategories, getValues, simulationId]
	);

	useEffect(() => {
		getCategories();
	}, [getCategories]);

	useEffect(() => {
		if (categoriesSelected?.length) {
			setAgreementSelected(categoriesSelected[0]?.agreement_id);
		}
	}, [categoriesSelected]);

	useEffect(() => {
		setLoading(loadingCategoriesSelected);
	}, [loadingCategoriesSelected, setLoading]);

	return loadingCategoriesSelected ? (
		<Box sx={{ textAlign: 'center' }} my={20}>
			<CircularProgress />
		</Box>
	) : categoriesSelected.length ? (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<AgreementForm
				agreementSelected={agreementSelected}
				control={control}
				setValue={setValue}
				simulationId={simulationId}
			/>
			<CategoriesForm
				categoriesSelected={categoriesSelected}
				control={control}
				watch={watch}
			/>

			<Box sx={{ width: '100%', textAlign: 'center' }}>
				<StyledLoadingButton
					color='primary'
					disabled={loadingCategoriesSelected}
					onClick={handleSubmit(onSubmit)}
					sx={{ m: 3 }}
					variant='contained'
				>
					Guardar y revisar jornadas
				</StyledLoadingButton>
			</Box>
		</form>
	) : null;
};

export default Categories;
