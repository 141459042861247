import React, { useState, useEffect, useCallback } from 'react';

import { Box, CircularProgress } from '@mui/material';

import CategoriesForm from './CategoriesForm/index';
import subrogateError from './ScreenErrors';
import WorkingDayForm from './WorkingDayForm';

const WorkingDayErrorTable = ({ changeScreen, simulationId }) => {
	const [loadingChildren, setLoadingChildren] = useState(false);
	const [isSaveSurrogates, setIsSaveSurrogates] = useState(false);
	const [isSaveCategories, setIsSaveCategories] = useState(false);

	const onSaveCategories = useCallback(() => {
		setIsSaveSurrogates(false);
		setIsSaveCategories(true);
	}, []);

	useEffect(() => {
		if (isSaveSurrogates && isSaveCategories) {
			changeScreen(subrogateError.SAVESUBROGATE);
		}
	}, [isSaveSurrogates, isSaveCategories, changeScreen]);

	return (
		<Box sx={{ width: '100%' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					textAlign: 'left',
					width: '100%',
				}}
			>
				<CategoriesForm
					onSaveCategories={onSaveCategories}
					setLoading={setLoadingChildren}
					simulationId={simulationId}
				/>
				{loadingChildren ? (
					<Box sx={{ textAlign: 'center' }} my={8}>
						<CircularProgress />
					</Box>
				) : (
					<WorkingDayForm
						setIsSaveSurrogates={setIsSaveSurrogates}
						simulationId={simulationId}
					/>
				)}
			</Box>
		</Box>
	);
};

export default WorkingDayErrorTable;
