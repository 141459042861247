import React from 'react';

import { Typography } from '@mui/material';

const SurrogateDetailContent = ({ text }) => (
	<Typography
		align='left'
		variant='subtitle2'
		style={{
			color: 'black',
			float: 'left',
			fontWeight: 800,
		}}
	>
		{text}
	</Typography>
);

export default SurrogateDetailContent;
