import { useRef, useState } from 'react';

import { getCategories } from '../ApiServices';

export default function useAgreementCategories({ agreementId }) {
	const [value, setValue] = useState('0');
	const [tabsHeight, setTabsHeight] = useState(350);
	const ref = useRef(null);

	const { loading: loadingCategories, data: categories } = getCategories({
		agreementId,
	});

	const resizeTabsComponent = () => {
		if (ref.current) {
			const defaultSize = 350;
			const currentSize = ref.current.clientHeight;
			setTabsHeight(Math.max(currentSize, defaultSize));
		}
	};

	return {
		value,
		setValue,
		loadingCategories,
		categories,
		tabsHeight,
		ref,
		setTabsHeight,
		resizeTabsComponent,
	};
}
