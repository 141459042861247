import React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ButtonComponent = styled(Button)((props) => ({
	color: 'primary.main',
	textTransform: 'inherit',
	fontSize: '18px',
	fontWeight: '800',
	borderRadius: '60px',
	'&:hover': {
		background: props.theme.palette.primary.main,
		color: props.theme.palette.common.white,
		'& span svg': {
			color: props.theme.palette.common.white,
		},
		'& span img': {
			filter: 'brightness(0) invert(1)',
		},
	},
}));

export const StyledLinkButton = ({
	onClick,
	color = 'primary',
	label,
	...rest
}) => (
	<ButtonComponent variant='text' color={color} onClick={onClick} {...rest}>
		{label}
	</ButtonComponent>
);
