import React from 'react';
import { useHistory } from 'react-router-dom';

import AssignmentIndOutlined from '@mui/icons-material/AssignmentIndOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderOutlined from '@mui/icons-material/FolderOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';

import { getTokenFromLocalStorage } from '../../utilities/utils';
import SimulationCounter from '../SimulationCounter/SimulationCounter';

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const MenuBar = ({ openDrawer, handleDrawerOpenOrClose, isLoggedIn }) => {
	const history = useHistory();
	const theme = useTheme();

	const isLogged = () => {
		const token = getTokenFromLocalStorage();
		return token ? true : false;
	};

	return (
		<Drawer
			sx={{ display: isLogged() ? 'inline' : 'none' }}
			variant='permanent'
			open={openDrawer}
		>
			<DrawerHeader>
				<IconButton onClick={(prev) => handleDrawerOpenOrClose(!prev)}>
					{theme.direction === 'rtl' ? (
						<ChevronRightIcon />
					) : (
						<ChevronLeftIcon />
					)}
				</IconButton>
			</DrawerHeader>
			<Divider />
			<List>
				{[
					{
						text: 'Licitaciones',
						icon: <FolderOutlined fontSize='small' />,
						link: '/projects',
					},
					{
						text: 'Convenios',
						icon: <AssignmentIndOutlined fontSize='small' />,
						link: '/agreements',
					},
					{
						text: 'Costes materiales',
						icon: <InventoryOutlinedIcon fontSize='small' />,
						link: '/inventory',
					},
				].map((link) => (
					<ListItem key={link.text} disablePadding sx={{ display: 'block' }}>
						<Tooltip
							title={link.text}
							placement='right'
							arrow
							disableHoverListener={openDrawer}
						>
							<ListItemButton
								onClick={() => history.push(link.link)}
								sx={{
									minHeight: 48,
									justifyContent: openDrawer ? 'initial' : 'center',

									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: openDrawer ? 3 : 'auto',
										justifyContent: 'center',
										color: 'primary.main',
									}}
								>
									{link.icon}
								</ListItemIcon>
								<ListItemText
									primary={link.text}
									sx={{ opacity: openDrawer ? 1 : 0, color: 'primary.main' }}
								/>
							</ListItemButton>
						</Tooltip>
					</ListItem>
				))}
			</List>
			<SimulationCounter isLoggedIn={isLoggedIn} openDrawer={openDrawer} />
		</Drawer>
	);
};
export default MenuBar;
