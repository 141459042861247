import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

import Else from '../../../../components/Compounds/If/Else';
import If from '../../../../components/Compounds/If/If';
import Then from '../../../../components/Compounds/If/Then';
import IsSimulationEditable from '../../../../components/Simulations/IsSimulationEditable';
import { StyledLinkButton } from '../../../../components/StyledComponents/LinkButton';
import TaskByHourModal from './Modal';
import TaskByHourTable from './Table';
import useTaskByHour from './useTaskByHour';

const TasksByHour = () => {
	const {
		taskByHourData,
		loadingTaskByHour,
		openModal,
		setOpenModal,
		initialValues,
		setInitialValues,
		edit,
		setEdit,
		setRefresh,
		deleteTask,
		editTask,
	} = useTaskByHour();

	return (
		<Box>
			<Grid container item xs={12} sx={{ alignItems: 'center' }}>
				<Grid item xs={1} md={0.5}>
					<InfoIcon color='primary' sx={{ fontSize: 30 }} />
				</Grid>
				<Grid item xs={11} md={11.5}>
					<Typography
						id='modal-modal-title'
						color='black'
						component='div'
						ml={1}
						sx={{ fontSize: '12px', fontWeight: '500', textAlign: 'left' }}
					>
						Usa este apartado cuando no sepas los turnos y el número de
						trabajadores por turno de antemano para que los genere el sistema
						automáticamente a partir de las horas que hay que cubrir.
					</Typography>
				</Grid>
			</Grid>
			<IsSimulationEditable>
				<StyledLinkButton
					startIcon={<AddCircleOutline color='primary' />}
					sx={{ mb: 3, mt: 4 }}
					onClick={() => {
						setInitialValues({});
						setOpenModal(true);
					}}
					label='Añadir tarea'
				/>
			</IsSimulationEditable>
			<If conditional={loadingTaskByHour}>
				<Then>
					<CircularProgress />
				</Then>
				<Else>
					<If conditional={taskByHourData?.length > 0}>
						<Then>
							<Box sx={{ mt: 2 }}>
								<TaskByHourTable
									rows={taskByHourData}
									deleteTask={deleteTask}
									editTask={editTask}
								/>
							</Box>
						</Then>
						<Else>
							<Box
								sx={{
									color: 'black',
									fontSize: '11px',
									fontWeight: '700',
									mt: 2,
									mb: 3,
								}}
							>
								No existen datos para mostrar
							</Box>
						</Else>
					</If>
				</Else>
			</If>
			<TaskByHourModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				setRefresh={setRefresh}
				initialValues={initialValues}
				setInitialValues={() => setInitialValues({})}
				edit={edit}
				setEdit={setEdit}
			/>
		</Box>
	);
};

export default TasksByHour;
