import React from 'react';

import { Box, Card, CardContent, Typography } from '@mui/material';

import { ViewContainer } from '../../../components/StyledContainers';
import UserInfo from '../../../containers/User/UserInfo';
import WorkSpaces from '../../../containers/User/WorkSpaces';

const AdministrationPanel = () => (
	<ViewContainer>
		<Box sx={{ textAlign: 'left', mb: '10px' }}>
			<Typography variant='h6' sx={{ fontWeight: '700' }} color='black'>
				Administración
			</Typography>
		</Box>
		<Card>
			<CardContent sx={{ marginLeft: 2, marginRight: 2 }}>
				<Box
					sx={{
						textAlign: 'left',
						marginLeft: '5px',
						mt: '10px',
					}}
				>
					<Typography
						sx={{
							fontWeight: '700',
							textTransform: 'uppercase',
							fontSize: '16px',
							color: 'primary.main',
						}}
						variant='body2'
						color='primary'
					>
						Información de tu cuenta
					</Typography>
				</Box>

				<WorkSpaces />
				<UserInfo />
			</CardContent>
		</Card>
	</ViewContainer>
);

export default AdministrationPanel;
