import React from 'react';
import { Controller } from 'react-hook-form';

import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputLabel,
} from '@mui/material';

export const FormCheckbox = ({
	name,
	control,
	label,
	rules = {},
	checkProps = {},
	inputProps = {},
}) => (
	<Controller
		rules={rules}
		control={control}
		name={name}
		render={({ field: { onChange, value }, fieldState: { error } }) => (
			<FormControl size='small' fullWidth>
				{label && <InputLabel>{label}</InputLabel>}
				<Checkbox
					{...checkProps}
					checked={value}
					onChange={onChange}
					inputProps={{ ...inputProps }}
				/>
				<FormHelperText error>{error ? error.message : null}</FormHelperText>
			</FormControl>
		)}
	/>
);
