import React from 'react';
import { useSelector } from 'react-redux';

import { RolePermissionSimulation } from '../Allowed/Allowed';

const IsSimulationEditable = ({ children }) => {
	const { createSimulation } = useSelector((state) => state);
	return (
		createSimulation?.simulation?.status === 'Stopped' && (
			<RolePermissionSimulation allowed={['update']}>
				{children}
			</RolePermissionSimulation>
		)
	);
};

export default IsSimulationEditable;
