import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSnackbar } from 'store/actions/createSimulation';

import { Box, CircularProgress } from '@mui/material';

import { getSimulationsCenters } from 'Services/Api/Simulation';

import { FormAutoComplete } from '../../../../../components/Form/AutoComplete';

const AgreementForm = ({
	agreementSelected,
	control,
	setValue,
	simulationId,
}) => {
	const dispatch = useDispatch();

	const [agreementOptions, setAgreementOptions] = useState([]);
	const [loadingAgreementOptions, setLoadingAgreementOptions] = useState(false);

	const getSimulationCentersList = useCallback(
		async (simulationIdSelected) => {
			setLoadingAgreementOptions(true);
			try {
				const response = await getSimulationsCenters({
					simulationId: simulationIdSelected,
				});

				setAgreementOptions(response?.map((val) => val?.agreement) || []);
			} catch (e) {
				dispatch(
					setSnackbar({
						duration: 6000,
						message: e.message || 'Error desconocido en el servidor',
						openSnackbar: true,
						severity: 'error',
					})
				);
			} finally {
				setLoadingAgreementOptions(false);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		if (simulationId) {
			getSimulationCentersList(simulationId);
		}
	}, [getSimulationCentersList, simulationId]);

	useEffect(() => {
		if (agreementOptions?.length) {
			setValue(
				'agreement',
				agreementOptions.find((val) => val.id === agreementSelected) || null
			);
		}
	}, [agreementOptions, agreementSelected, setValue]);

	return !simulationId || loadingAgreementOptions ? (
		<Box sx={{ textAlign: 'center' }} my={8}>
			<CircularProgress />
		</Box>
	) : (
		<>
			<Box
				sx={{
					fontSize: '18px',
					fontWeight: '800',
					mb: 3,
					textAlign: 'left',
				}}
				color='primary.main'
			>
				Convenios
			</Box>
			<Box
				alignItems='center'
				display='inline-flex'
				my={1}
				sx={{
					textAlign: 'left',
				}}
			>
				<Box>¿Es este el convenio? Si no, selecciona el correcto:</Box>

				<Box mx={2} width={250}>
					<FormAutoComplete
						control={control}
						label='Seleccione un convenio'
						name='agreement'
						optionLabel='name'
						options={agreementOptions}
						rules={{ required: 'El convenio es requerido' }}
						inputProps={{
							'data-testid': 'agreement-test-id',
							variant: 'outlined',
						}}
					/>
				</Box>
			</Box>
		</>
	);
};

export default AgreementForm;
