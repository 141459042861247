import { useEffect } from 'react';

import { getCategory } from '../ApiServices';

export default function useCategoryInfo({
	agreementId,
	categoryId,
	resizeTabs,
}) {
	const { loading: loadingCategory, data: category } = getCategory({
		agreementId,
		categoryId,
	});

	useEffect(() => {
		if (!loadingCategory) {
			resizeTabs();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingCategory]);

	return {
		loadingCategory,
		category,
	};
}
