import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';

import { RolePermissionInventory } from '../../../components/Allowed/Allowed';
import { StyledBoxContainer } from '../../../components/StyledComponents/BoxContainer';
import HeaderTableCell from '../../../components/StyledComponents/HeaderTableCell';
import { StyledIcon } from '../../../components/StyledComponents/Icon';
import useInventoryTable from './useInventoryTable';

const InventoryTable = ({
	rows = [],
	deleteInventory,
	setInventoryId,
	setEdit,
	setOpenModal,
}) => {
	const { getTypeByText, parseAmount } = useInventoryTable();
	return (
		<StyledBoxContainer>
			<Table aria-label='Turnos fijos'>
				<TableHead>
					<TableRow>
						<HeaderTableCell>Nombre</HeaderTableCell>
						<HeaderTableCell>Tipo</HeaderTableCell>
						<HeaderTableCell>Importe</HeaderTableCell>
						<RolePermissionInventory allowed={['delete', 'update']}>
							<HeaderTableCell sx={{ width: 1 / 6 }} />
						</RolePermissionInventory>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row?.id}>
							<TableCell>{row?.name || '-'}</TableCell>
							<TableCell>{`${
								row?.cost_type ? getTypeByText(row?.cost_type) : '-'
							}`}</TableCell>
							<TableCell>{`${
								row?.amount && row.cost_type !== 'SALARY_PERCENT'
									? parseAmount(row?.amount)
									: '-'
							}`}</TableCell>
							<RolePermissionInventory allowed={['delete', 'update']}>
								<TableCell sx={{ maxWidth: 10 }}>
									<Box sx={{ width: 1 / 5, display: 'flex' }}>
										<RolePermissionInventory allowed={['delete']}>
											<StyledIcon
												variant='contained'
												color='primary'
												aria-label='delete'
												onClick={() => deleteInventory(row?.id)}
											>
												<DeleteIcon />
											</StyledIcon>
										</RolePermissionInventory>

										<RolePermissionInventory allowed={['update']}>
											<StyledIcon
												sx={{ marginLeft: 1 }}
												variant='contained'
												color='primary'
												aria-label='edit'
												onClick={() => {
													setInventoryId(row?.id);
													setEdit(true);
													setOpenModal(true);
												}}
											>
												<EditIcon />
											</StyledIcon>
										</RolePermissionInventory>
									</Box>
								</TableCell>
							</RolePermissionInventory>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</StyledBoxContainer>
	);
};

export default InventoryTable;
