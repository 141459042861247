import React from 'react';

import { ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const ToggleButtonComponent = styled(ToggleButton)((props) => ({
	borderRadius: props?.borderradius || '60px',
	background: props?.backgroundcolor || props.theme.palette.grey.main,
	color: props?.color || props.theme.palette.common.black,
	'&.Mui-selected, &.Mui-selected:hover': {
		color: 'white',
		backgroundColor: props.theme.palette.primary.main,
	},
	'&:hover': {
		background: props.theme.palette.primary.main,
		color: props.theme.palette.common.white,
		'& span svg': {
			color: props.theme.palette.common.white,
		},
		'& span img': {
			filter: 'brightness(0) invert(1)',
		},
	},
}));

export const StyledToggleButton = ({ children, ...rest }) => (
	<ToggleButtonComponent color='primary' {...rest}>
		{children}
	</ToggleButtonComponent>
);
