import React from 'react';
import { Controller } from 'react-hook-form';

import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';

const generateSingleOptions = (options, optionsValue, optionsLabel) =>
	options.map((option) => (
		<MenuItem key={option.id} value={option[optionsValue]}>
			{option[optionsLabel]}
		</MenuItem>
	));

export const FormDropdown = ({
	name,
	control,
	label,
	options,
	optionsLabel = 'label',
	optionsValue = 'value',
	rules = {},
	selectProps = {},
	inputProps = {},
}) => (
	<Controller
		rules={rules}
		render={({ field: { onChange, value }, fieldState: { error } }) => (
			/* eslint-disable react/jsx-props-no-spreading */
			<FormControl size='small' fullWidth>
				{label && <InputLabel>{label}</InputLabel>}
				<Select
					{...selectProps}
					onChange={onChange}
					value={value}
					size='small'
					error={!!error}
					inputProps={{ ...inputProps }}
				>
					{generateSingleOptions(options, optionsValue, optionsLabel)}
				</Select>
				<FormHelperText error>{error ? error.message : null}</FormHelperText>
			</FormControl>
		)}
		control={control}
		name={name}
	/>
);
