/* eslint-disable */
import moment from 'moment';

export function msToTime(s) {
	if (!s) return '';
	const ms = s % 1000;
	s = (s - ms) / 1000;
	let secs = s % 60;
	s = (s - secs) / 60;
	let mins = s % 60;
	let hrs = (s - mins) / 60;

	hrs = hrs.toString().padStart(2, '0');
	mins = mins.toString().padStart(2, '0');
	secs = secs.toString().padStart(2, '0');

	return `${hrs}:${mins}:${secs}`;
}

export function download(blob, filename) {
	const a = document.createElement('a');
	a.href = URL.createObjectURL(blob);
	a.setAttribute('download', filename);
	a.click();
	return false;
}

export const formatNumber = (number, fixed = 2) => {
	if (!number) number = 0;
	return number
		.toFixed(fixed)
		.toString()
		.replace('.', ',')
		.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getNewArrayByIds = (
	originalArray,
	arrayId,
	listWithIds,
	id,
	returnObject
) =>
	listWithIds.map((idListItem) => {
		const coincidence = originalArray?.filter(
			(arrayItem) => idListItem[arrayId] === arrayItem[id]
		);
		return { [returnObject]: coincidence[0], ...idListItem };
	});

export const getStringFromTime = (time) => {
	let shiftType = '';
	if (moment(time).hours() < 14) {
		shiftType = 'morning';
	} else if (moment(time).hours() < 18) {
		shiftType = 'afternoon';
	} else {
		shiftType = 'night';
	}
	return shiftType;
};

export const getHourFromStringTimeHour = (stringTimeInHour) => {
	const dates = stringTimeInHour.split(':');
	return moment().hour(dates[0]).minutes(dates[1]);
};

export const groupBy = (items, key) =>
	items.reduce(
		(result, item) => ({
			...result,
			[item[key]]: [...(result[item[key]] || []), item],
		}),
		{}
	);

export const isValidDateFormat = (str) => {
	const dateFormatted = moment(str).format('DD/MM/YYYY');
	var regex = /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g;
	return regex.test(dateFormatted);
};

export const isYearGraderThan1000 = (str) => {
	const year = moment(str).year();
	return year > 1000;
};

export const isYearGraderOrEqual1000 = (str) => {
	const year = moment(str).year();
	return year >= 1000;
};

export const getFilteredArrayByObjectName = (objectArray) => {
	const tempLocalities = {};

	const response = objectArray.filter((item) => {
		const exists = !tempLocalities[item.name];
		tempLocalities[item.name] = true;
		return exists;
	});
	return response;
};

export const getTokenFromLocalStorage = () => {
	const storage = JSON.parse(localStorage.getItem('persist:root'));

	const auth = storage?.auth ? JSON.parse(storage.auth) : '';

	const token = auth?.token ? auth.token : '';

	return token;
};

export const clearLocalStorage = () => {
	localStorage.clear();
};

export const get_permission = (permissions, module, permit) => {
	let allowed = false;
	permissions?.forEach((permission) => {
		if (permission.module === module) {
			permit.forEach((allow) => {
				if (permission[allow]) {
					allowed = true;
				}
			});
		}
	});
	return allowed;
};

export const parseSimulationCounter = (counter, label = 'Simulaciones') => {
	const simulationPlus = counter?.simulations_added_from_last_month || 0;
	const total = counter?.max_simulations
		? counter.max_simulations + simulationPlus
		: '∞';
	const usedSimulations =
		counter?.simulation_executed !== null ? counter?.simulation_executed : '∞';
	const hasValue =
		counter?.max_simulations && counter?.simulation_executed !== null
			? `${usedSimulations}/${total}`
			: '∞';
	const toShow =
		counter?.max_simulations === 0 && counter?.simulation_executed === 0
			? '0/0'
			: hasValue;
	return `${label}: ${toShow}`;
};
