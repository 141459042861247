import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { DropzoneArea } from 'material-ui-dropzone';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, Typography } from '@mui/material';

import DialogVariantComponent from '../../../components/Dialog/DialogVariantComponent';
import { StyledButton } from '../../../components/StyledComponents/Button';
import { getAgreementUpdateUrlById } from '../../../containers/Agreements/ApiServices';
import { setSnackbar } from '../../../store/actions/snackbar';
import UploadFile from '../../../utilities/connection/UploadFile';

const UploadAgreementButton = ({ agreementId }) => {
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);

	const [files, setFiles] = useState([]);
	const [error, setError] = useState('');
	const [uploadingFile, setUploadingFile] = useState(false);

	const setSnackBarMessage = ({ message, severity = 'error' }) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity,
				message,
			})
		);
	};

	const getAgreementUpdateUrl = async (agreementId) => {
		setUploadingFile(true);

		const payload = { filename: `${files[0].name}` };
		try {
			const { data } = await getAgreementUpdateUrlById({
				agreementId,
				payload,
			});
			UploadFile({
				method: 'PUT',
				url: data,
				content: files[0],
			})
				.then(() => {
					setUploadingFile(false);
					setFiles([]);
					setOpenModal(false);
				})
				.catch((e) => {
					setUploadingFile(false);
					setSnackBarMessage({
						message: `Error subiendo archivo, ${e?.message ? e?.message : ' '}`,
					});
				});
		} catch (e) {
			setUploadingFile(false);
			setSnackBarMessage({ message: 'Error desconocido en el servidor' });
		}
	};

	const handleUploadAgreement = () => {
		if (files.length > 0) {
			setError('');
			getAgreementUpdateUrl(agreementId);
		} else {
			setError('Seleccione un fichero');
		}
	};

	const uploadDialog = () => (
		<DialogVariantComponent
			open={openModal}
			onClose={() => setOpenModal(false)}
			title='Cargar convenio'
			content={
				<Box>
					<DropzoneArea
						onChange={setFiles}
						dropzoneText='Arrastra el convenio'
						filesLimit={1}
						showFileNames={false}
						useChipsForPreview
						showPreviewsInDropzone={false}
						showPreviews
						acceptedFiles={['application/pdf']}
						dropzoneProps={{
							disabled: uploadingFile,
						}}
						dropzoneParagraphClass='DropZoneAreaText'
					/>
					<Box>
						<Typography variant='subtitle1' color='error'>
							{error}
						</Typography>
					</Box>
				</Box>
			}
			onAccept={handleUploadAgreement}
			acceptText='Aceptar'
			disableCancel={uploadingFile}
			disableAccept={uploadingFile}
			cancelText='Cancelar'
			onCancel={() => setOpenModal(false)}
			isInSimulation={false}
		/>
	);

	return (
		<Box>
			{uploadDialog()}

			<StyledButton
				size='medium'
				variant='outlined'
				sx={{
					color: 'primary.main',
					borderColor: 'primary.main',
					textTransform: 'inherit',
				}}
				onClick={() => setOpenModal(true)}
			>
				Convenio en pdf
				<AttachFileIcon />
			</StyledButton>
		</Box>
	);
};

export default UploadAgreementButton;
