export function resetState() {
	return {
		type: 'RESET_STATE',
	};
}

export function setSnackbar(snackbar) {
	return {
		type: 'SET_SNACKBAR',
		snackbar,
	};
}

export function closeSnackBar() {
	return {
		type: 'CLOSE_SNACKBAR',
	};
}
