import Api from '../../utilities/api';
import { setError } from './error';

// GET
export function getAgreementsList() {
	return (dispatch, getState) => {
		dispatch(resetAgreementsList()); // Clean fav state old data
		dispatch(setFetchingState(true)); // Api call will now begin, fetching data
		return Api.get(
			'agreement', // Endpoint
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (op2aim-user)
		).then((response) => {
			dispatch(setAgreementsList(response)); // Data is received from the api call. Save it in the state
			dispatch(setFetchingState(false)); // The Api call finished
		});
	};
}

export function requestAgreementUploadUrl(payload) {
	if (!payload.filename || !payload.mime_type) {
		console.warn(
			'Payload must be and object with project_uid, simulation_uid, filename and mime_type'
		);
		return Promise.reject(payload);
	}

	return (dispatch, getState) =>
		Api.post(
			'agreement/upload-url', // Endpoint
			payload, // "Body" of the request (POST)
			getState().auth.token, // Current user token
			dispatch, // store.dispatch.
			'user' // Keyword to set the api host config (flowtag-user)
		).then((response) =>
			dispatch(setAgreementUploadUrl(response.upload_url, true, 0))
		);
}

export function setAgreementsList(agreements) {
	return {
		type: 'SET_AGREEMENTS_LIST',
		agreements,
	};
}

export function resetAgreementsList() {
	return { type: 'RESET_AGREEMENTS_LIST' };
}

export function setFetchingState(fetching) {
	return {
		type: 'SET_FETCHING_STATE_AGREEMENTS',
		fetching,
	};
}

export function setAgreementUploadUrl(agreementUploadUrl, uploading, progress) {
	return {
		type: 'SET_AGREEMENT_UPLOAD_URL',
		agreementUploadUrl,
		uploading,
		progress,
	};
}

function makeRequest(method, url, content, dispatch) {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open(method, url, true);
		xhr.onload = () => {
			dispatch(setUploading(false));
			if (xhr.status >= 200 && xhr.status < 300) {
				resolve(xhr.response);
			} else {
				reject(
					new Error({
						status: xhr.status,
						statusText: xhr.statusText,
					})
				);
			}
		};
		xhr.onerror = () => {
			dispatch(setUploading(false));
			reject(
				new Error({
					status: xhr.status,
					statusText: xhr.statusText,
				})
			);
		};
		xhr.upload.addEventListener(
			'progress',
			(e) => updateProgress(e, dispatch),
			false
		);
		xhr.setRequestHeader('X-File-Type', content.type);
		xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
		xhr.send(content);
	});
}

export function setUploading(uploading) {
	return {
		type: 'SET_UPLOADING',
		uploading,
	};
}

function updateProgress(evt, dispatch) {
	if (evt.lengthComputable) {
		const percentComplete = evt.loaded / evt.total;
		const roundedPercentage = Math.round(percentComplete * 10000) / 100;
		dispatch(setProgress(roundedPercentage));
	} else {
		// Unable to compute progress information since the total size is unknown
	}
}

export function setProgress(progress) {
	return {
		type: 'SET_PROGRESS',
		progress,
	};
}

export function updateAgreement(agreementDict, agreementId) {
	return (dispatch, getState) =>
		Api.put(
			`agreement/${agreementId}`, // Endpoint
			agreementDict, // "Body" of the request (PUT)
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then(() => dispatch(getAgreementsList())); // Once the fav has been updated, retrieve the updated list of projects
}
