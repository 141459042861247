// import useGetResponseFromApi from '../../../hooks/useGetResponseFromApi';
import ApiException from '../../../Services/Api/ApiException';
import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import {
	createInventoryUrl,
	deleteInventoryUrl,
	getInventoriesUrl,
	getInventoryUrl,
	updateInventoryUrl,
} from '../../../utilities/routes';

export async function getInventories(workspace_id) {
	const url = getInventoriesUrl();
	const params = { workspace_id };
	try {
		const { data } = await AxiosWrapper.get(url, { params });
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getInventory({ inventoryId }) {
	const url = getInventoryUrl(inventoryId);
	try {
		const inventoryData = await AxiosWrapper.get(url);
		return inventoryData.data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function createInventory({ simulationId, payload }) {
	const url = createInventoryUrl(simulationId);
	try {
		await AxiosWrapper.post(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function updateInventory({ inventoryId, payload }) {
	const url = updateInventoryUrl(inventoryId);
	try {
		return await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function deleteInventoryById({ inventoryId }) {
	const url = deleteInventoryUrl(inventoryId);
	try {
		await AxiosWrapper.delete(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
