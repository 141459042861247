import launchResponses from '../objects/launchResponse';

/* eslint padded-blocks: ["error", { "classes": "never" }] */
export class LaunchResponse {
	static getResponseByCode(code) {
		const launchResponseFiltered = launchResponses.find(
			(launchResponse) => launchResponse.code === code
		);
		return launchResponseFiltered;
	}

	static getResponseLabelByMessage(message) {
		const launchResponseFiltered = launchResponses.find(
			(launchResponse) => launchResponse.message === message
		);
		return launchResponseFiltered.label;
	}

	static getResponseLabelByCode(error_code) {
		const launchResponseFiltered = launchResponses.find(
			(launchResponse) => launchResponse.error_code === error_code
		);
		return launchResponseFiltered.label;
	}
}
