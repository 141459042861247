import React from 'react';

import { Box } from '@mui/material';

import marginStyles from '../../assets/css/margin';
import { WhiteTitleContainer } from '../../components';
import Else from '../../components/Compounds/If/Else';
import If from '../../components/Compounds/If/If';
import Then from '../../components/Compounds/If/Then';
import ErrorComponent from '../../components/Error/ErrorComponent';
import IsLoading from '../../components/IsLoading/IsLoading';
import { ViewContainer } from '../../components/StyledContainers';
import AgreementCategories from '../../containers/Agreements/AgreementCategories';
import AgreementInfo from '../../containers/Agreements/AgreementInfo';
import { getAgreements } from '../../containers/Agreements/ApiServices';
import DownloadAgreementButton from './UploadDownloadButton/DownloadAgreementButton';

const CollectiveAgreementDataView = ({ match }) => {
	const margin = marginStyles();
	const { agreementId } = match.params;

	const {
		loading: loadingAgreement,
		data: agreementData,
		error: errorAgreement,
	} = getAgreements({ agreementId });

	return (
		<Box>
			<WhiteTitleContainer
				routes={[
					{ name: 'Inicio', href: '/' },
					{ name: 'Convenios', href: '/agreements' },
					{ name: agreementData?.name ? agreementData.name : '' },
				]}
				title={
					!loadingAgreement && !errorAgreement && agreementData?.name ? (
						<Box mt={0.5}>{agreementData.name}</Box>
					) : (
						''
					)
				}
				rightSide={<DownloadAgreementButton agreementId={agreementId} />}
			/>
			<If conditional={errorAgreement}>
				<Then>
					<ErrorComponent
						className={` ${margin.auto}`}
						message='¡¡Ops!! Ha ocurrido un error al obtener los datos del Convenio'
						error={errorAgreement.message}
					/>
				</Then>
				<Else>
					<ViewContainer>
						<Box className={`${margin.auto}`}>
							<IsLoading conditional={loadingAgreement}>
								<AgreementInfo info={agreementData} />
								<AgreementCategories
									agreementId={agreementId}
									agreementData={agreementData}
								/>
							</IsLoading>
						</Box>
					</ViewContainer>
				</Else>
			</If>
		</Box>
	);
};

export default CollectiveAgreementDataView;
