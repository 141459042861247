import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '../../hooks/useIsMounted';
import { setSnackbar } from '../../store/actions/snackbar';
import { deleteInventoryById, getInventories } from './ApiServices';

export default function useInventory() {
	const dispatch = useDispatch();
	const { inventoryStore, auth } = useSelector((state) => state);
	const { isMounted } = useIsMounted();
	const [reloadInventories, setReloadInventories] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [inventories, setInventories] = useState([]);
	const [loadingInventories, setLoadingInventories] = useState(false);
	const [edit, setEdit] = useState(false);
	const [inventoryId, setInventoryId] = useState('');

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const closeSnackBarAction = () => {
		dispatch(closeSnackBar());
	};

	const getInventoriesList = async () => {
		setLoadingInventories(true);
		if (auth?.workSpace?.id) {
			try {
				const inventoryList = await getInventories(auth?.workSpace?.id);
				if (isMounted) {
					setInventories(inventoryList);
					setLoadingInventories(false);
				}
			} catch (e) {
				setLoadingInventories(false);
				setSnackBarMessage(e?.response?.data?.message || e?.message || '');
			}
		}
	};

	useEffect(() => {
		getInventoriesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth?.workSpace?.id, reloadInventories]);

	const deleteInventory = async (inventoryId) => {
		try {
			await deleteInventoryById({ inventoryId });
			setReloadInventories((prev) => !prev);
		} catch (e) {
			setSnackBarMessage(e?.message);
		}
	};

	return {
		openModal,
		setOpenModal,
		edit,
		setEdit,
		inventoryId,
		setInventoryId,
		deleteInventory,
		inventories,
		setReloadInventories,
		loadingInventories,
		inventoryStore,
		closeSnackBarAction,
	};
}
