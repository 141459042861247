import React from 'react';
import { useFieldArray } from 'react-hook-form';

import { Grid, Typography } from '@mui/material';

import { MaterialCost } from './MaterialCost';

export const ListMaterialCost = ({ control, centers }) => {
	const { fields } = useFieldArray({
		control,
		name: 'material_costs',
	});

	const getMaterialCostData = () => {
		const data = [];
		const material_costs = fields;
		const materials_center_id_null = [];

		if (material_costs.length > 0) {
			// eslint-disable-next-line array-callback-return
			material_costs.map((material, index) => {
				if (material.center_id === null) {
					material.index = index;
					materials_center_id_null.push(material);
				}
			});
			data.push({
				center_name: 'Todos los centros',
				data_materials: materials_center_id_null,
			});
		}
		// eslint-disable-next-line array-callback-return
		centers.map((center) => {
			if (material_costs.find(({ center_id }) => center_id === center.id)) {
				const materials = [];
				// eslint-disable-next-line array-callback-return
				material_costs.map((material, index) => {
					if (material.center_id === center.id) {
						material.index = index;
						materials.push(material);
					}
				});

				data.push({ center_name: center.name, data_materials: materials });
			}
		});
		return data;
	};
	const materialCostData = getMaterialCostData();

	return (
		<Grid item container xs={12} spacing={2}>
			<Grid item container xs={12} mt={2}>
				<Typography
					variant='subtitle1'
					component='div'
					sx={{ fontWeight: 'bold' }}
				>
					Costes materiales:
				</Typography>
			</Grid>
			<Grid item container spacing={2}>
				{materialCostData.map((data) => (
					<Grid key={data.center_name} container item spacing={1}>
						<Grid item xs={4}>
							{data.center_name}
						</Grid>
						{data.data_materials.map((material) => (
							<Grid
								key={material.id}
								item
								container
								rowSpacing={2}
								spacing={2}
								columns={1}
							>
								<MaterialCost
									control={control}
									material={material}
									centers={centers}
								/>
							</Grid>
						))}
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};
