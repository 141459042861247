import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Snackbar } from '@mui/material';

import { closeSnackbar } from '../../store/actions/snackbar';

const SnackBar = () => {
	const { snackbar } = useSelector((state) => state);
	const dispatch = useDispatch();

	const getOpenSnackBarValue = () => snackbar?.message !== 'Unauthorized';

	return (
		<Snackbar
			open={snackbar?.openSnackbar && getOpenSnackBarValue()}
			autoHideDuration={snackbar?.duration}
			onClose={() => dispatch(closeSnackbar())}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert severity={snackbar?.severity} sx={{ width: '100%' }}>
				{snackbar?.message}
			</Alert>
		</Snackbar>
	);
};
export default SnackBar;
