import React from 'react';

import { Box, CircularProgress } from '@mui/material';

import Else from '../Compounds/If/Else';
import If from '../Compounds/If/If';
import Then from '../Compounds/If/Then';

const IsLoading = ({ conditional, width, color, children }) => (
	<If conditional={conditional}>
		<Then>
			<Box sx={{ textAlign: 'center' }}>
				<CircularProgress size={width} sx={{ color }} />
			</Box>
		</Then>
		<Else>{children}</Else>
	</If>
);

export default IsLoading;
