import React from 'react';

import { Grid, Typography } from '@mui/material';

import Else from '../../../components/Compounds/If/Else';
import If from '../../../components/Compounds/If/If';
import Then from '../../../components/Compounds/If/Then';
import IsLoading from '../../../components/IsLoading/IsLoading';
import { StyledCard } from '../../../components/StyledComponents/Card';
import { ViewContainer } from '../../../components/StyledContainers';
import { PreviousStudiesLogic } from './PreviousStudiesLogic';

export const PreviousStudies = ({
	projectId,
	setIsPreviousStudy,
	setOpenModal,
	setSimulationName,
	setEditUid,
	setSimulationUid,
	setOpenDeleteModal,
}) => {
	const { parsePreviousStudiesList, previousStudies, loadingPreviousStudies } =
		PreviousStudiesLogic({
			projectId,
			setIsPreviousStudy,
			setOpenModal,
			setSimulationName,
			setEditUid,
			setSimulationUid,
			setOpenDeleteModal,
		});

	return (
		<div>
			<IsLoading conditional={loadingPreviousStudies}>
				<If conditional={!previousStudies || previousStudies.length === 0}>
					<Then>
						<ViewContainer>
							<Typography variant='h5' color='secondary'>
								No hay estudios previos aún
							</Typography>
						</ViewContainer>
					</Then>
					<Else>
						<Grid container spacing={2}>
							{parsePreviousStudiesList().map((previousStudy) => (
								<Grid item xs={3} key={previousStudy.id}>
									<StyledCard>{previousStudy}</StyledCard>
								</Grid>
							))}
						</Grid>
					</Else>
				</If>
			</IsLoading>
		</div>
	);
};
