import { createTheme } from '@material-ui/core/styles';

export default createTheme({
	palette: {
		primary: {
			main: '#6631D5',
			contrastText: '#545454',
		},
		text: {
			primary: '#545454',
		},
		error: {
			main: '#dd2e44',
		},
		secondary: {
			main: '#AFAFAF',
		},
		common: {
			white: '#ffffff',
			black: '#000000',
			red: '#EF233C',
			orange: '#FFC045',
			green: '#37DDB5',
			grey: '#939393',
			transition1: '#6631D5',
			transition2: '#7722CB',
			transition3: '#6631D5',
			transition4: '#5642DF',
			transition5: '#4452E7',
			transition6: '#3462F2',
			transition7: '#2472FC',
		},
		grey: {
			main: '#E6E6E6',
			iconBackground: '#E6E6E6',
			iconColor: '#939393',
		},
	},
	typography: {
		fontFamily: 'Nunito',
	},
	shape: {
		borderRadius: 6,
	},
});
