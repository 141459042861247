const launchResponses = [
	{
		code: '460',
		error_code: 1,
		message: 'There are subrogates without category',
		label: 'Existen surrogados sin centros',
	},
	{
		code: '460',
		error_code: 2,
		message: 'There are fixed work without center or position',
		label: 'Hay turnos fijo sin centro o puesto',
	},
	{
		code: '460',
		error_code: 3,
		message: 'There are variable work without center or position',
		label: 'Hay tareas por horas sin centro o puesto',
	},

	{
		code: '460',
		error_code: 4,
		message: 'There arent any center created',
		label: 'No existe ningún centro creado.',
	},
	{
		code: '460',
		error_code: 5,
		message: 'There arent any task created',
		label: 'No existen tareas creadas.',
	},
	{
		code: '460',
		error_code: 6,
		message: 'There arent any agreement with categories',
		label: 'Los convenios seleccionados no tiene categorias.',
	},
	{
		code: '460',
		error_code: 7,
		message: 'Usage limit exceeded',
		label:
			'Has llegado al límite de simulaciones contratadas. Contacta con info@op2aim.io para ampliar tu plan.',
	},
	{
		code: '460',
		error_code: 8,
		message: 'The simulation does not have a start date.',
		label: 'Faltan datos básicos: fecha de inicio. Rellena y guarda por favor',
	},
	{
		code: '460',
		error_code: 9,
		message: 'The simulation does not have service duration.',
		label:
			'Faltan datos básicos: duracion del servicio. Rellena y guarda por favor',
	},
];

export default launchResponses;
