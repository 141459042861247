import React from 'react';

import {
	Avatar,
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import If from '../Compounds/If/If';
import Then from '../Compounds/If/Then';
import IsSimulationEditable from '../Simulations/IsSimulationEditable';
import { StyledButton } from '../StyledComponents/Button';
import DialogTitle from './DialogTitle';

const StyledAvatar = styled(Avatar)`
	transform: translate(0px, -80%);
	width: 80px;
	height: 80px;
	background-color: #fff;
	justify-content: center;
`;

const acceptButton = (acceptText, onAccept, disableAccept) => (
	<Grid item>
		<StyledButton
			onClick={onAccept}
			disabled={disableAccept}
			data-testid='dialogButtonAccept'
			sx={{ textTransform: 'inherit' }}
		>
			{acceptText}
		</StyledButton>
	</Grid>
);

const acceptAndContinueTButton = (
	acceptAndContinueText,
	onAcceptAndContinue,
	disableAcceptAndContinue
) => (
	<Grid item>
		<If conditional={acceptAndContinueText !== ''}>
			<Then>
				<StyledButton
					onClick={onAcceptAndContinue}
					disabled={disableAcceptAndContinue}
					variant='text'
					data-testid='dialogButtonAcceptAndContinue'
					sx={{
						color: 'primary.main',
						textTransform: 'inherit',
					}}
				>
					{acceptAndContinueText}
				</StyledButton>
			</Then>
		</If>
	</Grid>
);

const cancelButton = (cancelText, onCancel, disableCancel) => (
	<Grid item>
		<StyledButton
			variant='text'
			sx={{ color: 'text.secondary', textTransform: 'inherit' }}
			disabled={disableCancel}
			onClick={onCancel}
		>
			{cancelText}
		</StyledButton>
	</Grid>
);

const DialogComponent = ({
	open,
	onClose,
	title = '',
	content = '',
	acceptText = '',
	acceptAndContinueText = '',
	cancelText = '',
	onAccept,
	onAcceptAndContinue,
	onCancel,
	disableAccept = false,
	disableAcceptAndContinue = false,
	disableCancel = false,
	isInSimulation = true,
	cancelButtonFirst = true,
	inline = false,
	withAvatar = false,
	avatar = {},
	...rest
}) => (
	<Dialog
		open={open}
		onClose={onClose}
		scroll='body'
		PaperProps={{ sx: { overflowY: 'visible !important', marginTop: '50px' } }}
		{...rest}
	>
		<Box
			sx={{
				p: 3,
			}}
		>
			{withAvatar ? (
				<Grid item container justifyContent='center' height='15px'>
					<StyledAvatar
						sx={{
							borderRadius: '50%',
							backgroundColor: 'fff',
							paddingTop: '10px',
							fontSize: '16px',
							fontWeight: '700',
						}}
					>
						{avatar}
					</StyledAvatar>
				</Grid>
			) : null}
			<Box width='100%'>
				<DialogTitle sx={{ textAlign: 'center' }} title={title} />
				<DialogContent sx={{ pl: '0px', pr: '0px' }}>{content}</DialogContent>
				<DialogActions>
					<Grid
						container
						direction={
							acceptAndContinueText === '' || inline ? 'row' : 'column'
						}
						justifyContent='center'
						alignItems='center'
						spacing={1}
					>
						{!cancelButtonFirst &&
							cancelButton(cancelText, onCancel, disableCancel)}

						{isInSimulation ? (
							<IsSimulationEditable>
								{acceptButton(acceptText, onAccept, disableAccept)}
								{acceptAndContinueTButton(
									acceptAndContinueText,
									onAcceptAndContinue,
									disableAcceptAndContinue
								)}
							</IsSimulationEditable>
						) : (
							<>
								{acceptButton(acceptText, onAccept, disableAccept)}
								{acceptAndContinueTButton(
									acceptAndContinueText,
									onAcceptAndContinue,
									disableAcceptAndContinue
								)}
							</>
						)}
						{cancelButtonFirst &&
							cancelButton(cancelText, onCancel, disableCancel)}
					</Grid>
				</DialogActions>
			</Box>
		</Box>
	</Dialog>
);
export default DialogComponent;
