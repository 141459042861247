import Api from '../../utilities/api';

// GET
export function getSimulationsList(project_id, reset = true) {
	return (dispatch, getState) => {
		if (reset) {
			dispatch(resetSimulationsList()); // Clean fav state old data
			dispatch(setFetchingState(true)); // Api call will now begin, fetching data
		}
		return Api.get(
			`project/${project_id}/simulation/`, // Endpoint
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then((response) => {
			dispatch(setSimulationsList(response)); // Data is received from the api call. Save it in the state
			dispatch(setFetchingState(false)); // The Api call finished
		});
	};
}

export function getSimulation(project_id, simulation_id, reset = true) {
	return (dispatch, getState) => {
		if (reset) {
			dispatch(resetCurrentSimulation());
			dispatch(setFetchingState(true));
		}
		return Api.get(
			`simulation/${simulation_id}`,
			getState().auth.token,
			dispatch,
			'user'
		).then((response) => {
			dispatch(setCurrentSimulation(response));
			dispatch(setFetchingState(false));
		});
	};
}

// POST
export function createSimulation(project_id, simulationDict) {
	return (dispatch, getState) =>
		Api.post(
			`project/${project_id}/simulation`, // Endpoint
			simulationDict, // "Body" of the request (POST)
			getState().auth.token, // Current user token
			dispatch, // store.dispatch.
			'user' // Keyword to set the api host config (flowtag-user)
		); // Once the new fav has been saved, retrieve the updated list of simulations
}

// PUT
export function updateSimulation(project_id, simulation_id, simulationDict) {
	return (dispatch, getState) =>
		Api.put(
			`simulation/${simulation_id}`, // Endpoint
			simulationDict, // "Body" of the request (PUT)
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then(() => dispatch(getSimulationsList(project_id, false))); // Once the fav has been updated, retrieve the updated list of simulations
}

// DELETE
export function removeSimulation(project_id, simulation_id) {
	return (dispatch, getState) =>
		Api.delete(
			`simulation/${simulation_id}`, // Endpoint
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then(() => dispatch(getSimulationsList(project_id, false))); // Once the fav has been deleted, retrieve the updated list of simulations
}

export function setSimulationsList(simulations) {
	return {
		type: 'SET_SIMULATIONS_LIST',
		simulations,
	};
}

export function setCurrentSimulation(currentSimulation) {
	return {
		type: 'SET_CURRENT_SIMULATION',
		currentSimulation,
	};
}

export function resetCurrentSimulation() {
	return { type: 'RESET_CURRENT_SIMULATION' };
}

export function resetSimulationsList() {
	return { type: 'RESET_SIMULATIONS_LIST' };
}

export function setFetchingState(fetching) {
	return {
		type: 'SET_FETCHING_STATE_SIMULATIONS',
		fetching,
	};
}

export function setBasicData(fetching) {
	return {
		type: 'SET_FETCHING_STATE_SIMULATIONS',
		fetching,
	};
}

export function setCentrosList(fetching) {
	return {
		type: 'SET_CENTROS_LIST',
		fetching,
	};
}
