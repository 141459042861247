import AxiosWrapper from '../../utilities/connection/AxiosWrapper';
import { getWorkSpacesByIdUrl, getWorkSpacesUrl } from '../../utilities/routes';
import ApiException from './ApiException';

export async function getWorkSpaces(is_admin = true) {
	const url = getWorkSpacesUrl();
	const params = { is_admin };
	try {
		const { data } = await AxiosWrapper.get(url, { params });
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getWorkSpaceById(workSpaceId) {
	const url = getWorkSpacesByIdUrl(workSpaceId);
	const params = { workSpaceId };
	try {
		const { data } = await AxiosWrapper.get(url, JSON.stringify(params));
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
