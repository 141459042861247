import axios from 'axios';
import { logoutPers } from 'store/actions/auth';
import { store, persistor, initialState } from 'store/storage/store';

import { getTokenFromLocalStorage } from '../utils';

const requestAllowedErrors = (error) => {
	switch (error) {
		case 404:
		case 500:
		case 502:
		case 503:
		case 504:
		case 505:
		case 550:
			return false;
		default:
			return true;
	}
};

const apiHost = process.env.REACT_APP_baseUrl_config;

const axiosWrapper = () => {
	const defaultOptions = {
		baseURL: apiHost,

		headers: {
			accept: 'application/json',

			'Content-Type': 'application/json',

			'x-api-key': process.env.REACT_APP_x_api_key_config,
		},
	};

	const instance = axios.create(defaultOptions);

	instance.interceptors.request.use(function (config) {
		const token = getTokenFromLocalStorage();

		config.headers.Authorization = token || '';

		return config;
	});

	instance.interceptors.response.use(
		function (config) {
			return config;
		},

		function (error) {
			if (error.response.status === 401) {
				logoutPers(persistor, store, initialState)(store.dispatch);
			}
			if (error.response.request.responseURL.includes('/validate')) {
				throw error;
			}
			if (error.response.request.responseURL.includes('/from_previous_study'))
				throw error;
			if (requestAllowedErrors(error.response.status)) {
				throw error;
			} else {
				return (window.location.href = '/404');
			}
		}
	);

	return instance;
};

export default axiosWrapper();
