import React from 'react';

import { Box, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';

import If from '../Compounds/If/If';
import Then from '../Compounds/If/Then';
import { StyledButton } from '../StyledComponents/Button';
import DialogTitle from './DialogTitle';

const acceptButton = (acceptText, onAccept, disableAccept) => (
	<Grid item>
		<StyledButton
			onClick={onAccept}
			disabled={disableAccept}
			data-testid='dialogButtonAccept'
			sx={{ textTransform: 'inherit' }}
		>
			{acceptText}
		</StyledButton>
	</Grid>
);

const acceptAndContinueTButton = (
	acceptAndContinueText,
	onAcceptAndContinue,
	disableAcceptAndContinue
) => (
	<Grid item>
		<If conditional={acceptAndContinueText !== ''}>
			<Then>
				<StyledButton
					onClick={onAcceptAndContinue}
					disabled={disableAcceptAndContinue}
					variant='text'
					data-testid='dialogButtonAcceptAndContinue'
					sx={{
						color: 'primary.main',
						textTransform: 'inherit',
					}}
				>
					{acceptAndContinueText}
				</StyledButton>
			</Then>
		</If>
	</Grid>
);

const cancelButton = (cancelText, onCancel, disableCancel) => (
	<Grid item>
		<StyledButton
			variant='text'
			sx={{ color: 'text.secondary', textTransform: 'inherit' }}
			disabled={disableCancel}
			onClick={onCancel}
		>
			{cancelText}
		</StyledButton>
	</Grid>
);

const DialogComponentWithoutEditableOption = ({
	open,
	onClose,
	title = '',
	content = '',
	acceptText = '',
	acceptAndContinueText = '',
	cancelText = '',
	onAccept,
	onAcceptAndContinue,
	onCancel,
	disableAccept = false,
	disableAcceptAndContinue = false,
	disableCancel = false,
	cancelButtonFirst = true,
	inline = false,
	...rest
}) => (
	<Dialog open={open} onClose={onClose} {...rest}>
		<Box
			sx={{
				p: 3,
			}}
		>
			<Box width='100%'>
				<DialogTitle sx={{ textAlign: 'center' }} title={title} />
				<DialogContent sx={{ pl: '0px', pr: '0px' }}>{content}</DialogContent>
				<DialogActions>
					<Grid
						container
						direction={
							acceptAndContinueText === '' || inline ? 'row' : 'column'
						}
						justifyContent='center'
						alignItems='center'
						spacing={1}
					>
						{!cancelButtonFirst &&
							cancelButton(cancelText, onCancel, disableCancel)}

						{acceptButton(acceptText, onAccept, disableAccept)}
						{acceptAndContinueTButton(
							acceptAndContinueText,
							onAcceptAndContinue,
							disableAcceptAndContinue
						)}

						{cancelButtonFirst &&
							cancelButton(cancelText, onCancel, disableCancel)}
					</Grid>
				</DialogActions>
			</Box>
		</Box>
	</Dialog>
);
export default DialogComponentWithoutEditableOption;
