const UploadFile = ({ method, url, content }) => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open(method, url, true);
		xhr.onload = () => {
			if (xhr.status >= 200 && xhr.status < 300) {
				resolve(xhr.response);
			} else {
				reject(
					new Error({
						status: xhr.status,
						statusText: xhr.statusText,
					})
				);
			}
		};
		xhr.onerror = () => {
			reject(
				new Error({
					status: xhr.status,
					statusText: xhr.statusText,
				})
			);
		};
		xhr.setRequestHeader('X-File-Type', content.type);
		xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
		xhr.send(content);
	});
};
export default UploadFile;
