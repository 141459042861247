const initial = {
	snackbar: {
		openSnackbar: false,
		duration: 6000,
		severity: 'success',
		message: '',
	},
};
export default function inventoryStore(
	state = initial,
	{ type, snackbar } = {}
) {
	switch (type) {
		case 'RESET_STATE':
			return { ...initial };
		case 'SET_SNACKBAR':
			return { ...state, snackbar };
		case 'CLOSE_SNACKBAR':
			return {
				...state,
				snackbar: {
					openSnackbar: false,
					duration: 6000,
					severity: 'success',
					message: '',
				},
			};
		default:
			return state;
	}
}
