import React from 'react';

import { Tooltip, Box } from '@mui/material';

import IsLoading from '../../../components/IsLoading/IsLoading';
import IsSimulationEditable from '../../../components/Simulations/IsSimulationEditable';
import { StyledLoadingButton } from '../../../components/StyledComponents/LoadingButton';
import AddCenterForm from './AddCenterForm';
import BasicDataTable from './BasicDataTable';
import BasicSimulationForm from './BasicSimulationForm';
import { CreateUploadDownload } from './CreateUploadDownload';
import useBasicData from './useBasicData';

const BasicDataTab = ({ success, simulationId = '' }) => {
	const {
		edit,
		setEdit,
		info,
		initialInfo,
		setInitialInfo,
		updatingSimulation,
		updatingBasicSimulation,
		loadingSimulation,
		loadingLocalities,
		localities,
		loadingAgreements,
		loadingCenters,
		agreements,
		updateInfo,
		handleSubmit,
		handleSubmitForm,
		updateFromTableDelete,
		basicDataValid,
		openCenterFormModal,
		closeCenterFormModal,
		setOpenCenterFormModal,
		uploadingFile,
		setUploadingFile,
		setReloadCenters,
		control,
	} = useBasicData({ simulationId, success });

	return (
		<Box sx={{ width: '100%' }}>
			<Box
				sx={{
					width: '100%',
					fontSize: '18px',
					fontWeight: '800',
					textAlign: 'left',
					mb: 3,
				}}
				color='primary.main'
			>
				Datos Básicos
			</Box>
			<Box>
				<IsLoading conditional={loadingSimulation}>
					<BasicSimulationForm control={control} />
				</IsLoading>
			</Box>
			<Box sx={{ mt: 5 }}>
				<IsLoading conditional={loadingLocalities || loadingAgreements}>
					<CreateUploadDownload
						simulationId={simulationId}
						setReloadCenters={setReloadCenters}
						setOpenCenterFormModal={setOpenCenterFormModal}
						uploadingFile={uploadingFile}
						setUploadingFile={setUploadingFile}
					/>
				</IsLoading>

				<Box sx={{ width: 1, mt: 5, mb: 1 }}>
					<IsLoading
						conditional={
							loadingCenters ||
							loadingLocalities ||
							loadingAgreements ||
							uploadingFile
						}
					>
						{info.length > 0 ? (
							<BasicDataTable
								key={edit}
								rows={info}
								setInitialInfo={(values) => {
									setInitialInfo(values);
									setEdit(true);
									setOpenCenterFormModal(true);
								}}
								updateInfo={(value) => {
									updateFromTableDelete(value);
								}}
							/>
						) : null}
					</IsLoading>
				</Box>
			</Box>
			<IsSimulationEditable>
				<Box sx={{ maxHeight: '30px', float: 'right', mt: 2, mb: 3 }}>
					<Tooltip
						title={`${
							info.length === 0
								? 'Para poder guardar, añade al menos un centro pulsando en +.'
								: ''
						}${
							!basicDataValid ? 'Complete	correctamente los datos básicos.' : ''
						}`}
						arrow
					>
						<span>
							<StyledLoadingButton
								loading={updatingSimulation || updatingBasicSimulation}
								disabled={!basicDataValid || info.length === 0}
								variant='contained'
								color='primary'
								onClick={handleSubmit(handleSubmitForm)}
							>
								Guardar
							</StyledLoadingButton>
						</span>
					</Tooltip>
				</Box>
			</IsSimulationEditable>
			<AddCenterForm
				openCenterFormModal={openCenterFormModal}
				closeCenterFormModal={closeCenterFormModal}
				updateInfo={updateInfo}
				initialInfo={initialInfo}
				setInitialInfo={setInitialInfo}
				localities={localities}
				agreements={agreements}
			/>
		</Box>
	);
};

export default BasicDataTab;
