import React from 'react';
import { useSelector } from 'react-redux';

import IsLoading from '../IsLoading/IsLoading';

const WorkspaceLoading = ({ children }) => {
	const { auth } = useSelector((state) => state);

	return (
		<IsLoading conditional={!!auth?.is_loading} width='100%'>
			{children}
		</IsLoading>
	);
};
export default WorkspaceLoading;
