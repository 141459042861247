import Api from '../../utilities/api';

// GET
export function getProfile() {
	return (dispatch, getState) => {
		dispatch(resetProfile());
		dispatch(setFetchingState(true)); // Api call will now begin, fetching data
		return Api.get(
			'user/profile', // Endpoint
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then((response) => {
			dispatch(setProfile(response)); // Data is received from the api call. Save it in the state
			dispatch(setFetchingState(false)); // The Api call finished
		});
	};
}

// PUT
export function updateProfile(body) {
	return (dispatch, getState) =>
		Api.put(
			'user/profile', // Endpoint
			body, // "Body" of the request (PUT)
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then(() => dispatch(getProfile())); // Once the fav has been updated, retrieve the updated list of favs
}

export function setProfile(profile) {
	return {
		type: 'SET_PROFILE',
		profile,
	};
}

export function resetProfile() {
	return { type: 'RESET_PROFILE' };
}

export function setFetchingState(fetching) {
	return {
		type: 'SET_FETCHING_STATE_PROFILE',
		fetching,
	};
}
