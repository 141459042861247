const uuidv4 = require('uuid/v4');

const initialState = {
	device: uuidv4(),
	permissions: [
		{
			module: 'agreement',
			read: true,
			delete: false,
			update: false,
			create: false,
		},
		{
			module: 'simulation',
			read: true,
			delete: false,
			update: false,
			create: false,
		},
		{
			module: 'inventory',
			read: true,
			delete: false,
			update: false,
			create: false,
		},
	],
	is_loading: false,
};

export default function auth(state = initialState, action) {
	const { type, token, workSpace, permissions, is_loading } = action;
	switch (type) {
		case 'SET_TOKEN':
			return { ...state, token };
		case 'SET_WORKSPACE':
			return { ...state, workSpace };
		case 'UPDATE_PERMISSIONS':
			return { ...state, permissions };
		case 'SET_LOADING_WORKSPACE':
			return { ...state, is_loading };
		default:
			return state;
	}
}
