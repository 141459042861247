import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMaterialCosts } from '../../../Services/Api/PreviousStudy';
import { setSnackbar } from '../../../store/actions/snackbar';
import { deleteMaterialCostById } from './ApiServices';

export default function useMaterialCost() {
	const dispatch = useDispatch();
	const { previousStudy } = useSelector((state) => state);
	const previousStudyId = previousStudy.uid;
	const [materialCostsData, setMaterialCostsData] = useState([]);
	const [loadingMaterialCosts, setLoadingMaterialCosts] = useState(false);
	const [edit, setEdit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [reloadMaterialCosts, setReloadMaterialCosts] = useState(false);
	const [materialCostId, setMaterialCostId] = useState('');

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const getMaterialCostsList = async () => {
		setLoadingMaterialCosts(true);
		try {
			const { data } = await getMaterialCosts({ previousStudyId });
			setMaterialCostsData(data);
			setLoadingMaterialCosts(false);
		} catch (e) {
			setLoadingMaterialCosts(false);
			setSnackBarMessage(e?.message);
		}
	};

	const deleteMaterialCosts = async (materialCostId) => {
		try {
			await deleteMaterialCostById({ previousStudyId, materialCostId });
			setReloadMaterialCosts((prev) => !prev);
		} catch (e) {
			setSnackBarMessage(e?.message);
		}
	};

	useEffect(() => {
		getMaterialCostsList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadMaterialCosts]);

	return {
		openModal,
		setOpenModal,
		edit,
		setEdit,
		materialCostsData,
		materialCostId,
		setMaterialCostId,
		loadingMaterialCosts,
		deleteMaterialCosts,
		setReloadMaterialCosts,
	};
}
