import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import translator from 'utilities/translator/translator';

import { useTheme } from '@mui/material/styles';

import { convertPreviousStudyToSimulation } from 'Services/Api/Simulation';

import { getPreviousStudies } from '../../../Services/Api/PreviousStudy';
import useIsMounted from '../../../hooks/useIsMounted';
import { setSnackbar } from '../../../store/actions/snackbar';
import { get_permission } from '../../../utilities/utils';

export const PreviousStudiesLogic = ({
	projectId,
	setIsPreviousStudy,
	setOpenModal,
	setSimulationName,
	setEditUid,
	setSimulationUid,
	setOpenDeleteModal,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { auth, projects } = useSelector((state) => state);
	const { isMounted } = useIsMounted();
	const { currentProject } = projects;
	const [previousStudies, setPreviousStudies] = useState([]);
	const [loadingPreviousStudies, setLoadingPreviousStudies] = useState(true);
	const theme = useTheme();

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};
	const convertToSimulation = (previousStudyId) => {
		convertPreviousStudyToSimulation(previousStudyId)
			.then((simulationId) => {
				history.push(`/simulation/${currentProject.uid}/${simulationId}/form`);
			})
			.catch(() => {
				setSnackBarMessage(translator('Error creating a simulation'));
			});
	};

	const getPreviousStudiesList = async (projectId) => {
		setLoadingPreviousStudies(true);
		try {
			const { data } = await getPreviousStudies(projectId);
			if (isMounted) {
				setPreviousStudies(data);
			}
			setLoadingPreviousStudies(false);
		} catch (e) {
			setLoadingPreviousStudies(false);
			setSnackBarMessage(e?.message);
		}
	};

	useEffect(() => {
		getPreviousStudiesList(projectId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const parsePreviousStudiesList = () => {
		const previousStudiesList = previousStudies.map((previousStudy) => ({
			id: previousStudy.id,
			name: previousStudy.name,
			title: previousStudy.name,
			subtitle: `(${moment(previousStudy.started).format(
				'DD/MM/YYYY, HH:mm:ss'
			)})`,
			createdAt: moment(previousStudy.createdAt).format('DD/MM/YYYY'),
			updatedAt: moment(previousStudy.updatedAt).format('DD/MM/YYYY'),
			status: previousStudy.status,
			uid: previousStudy.id,
			delete: get_permission(auth?.permissions, 'previous_study', ['delete']),
			status_color:
				previousStudy.status === 'Running'
					? theme.palette.common.orange
					: previousStudy.status === 'Error'
					? theme.palette.common.red
					: previousStudy.status === 'Solved'
					? theme.palette.common.blue
					: `none`,
			onDelete: () => {
				setIsPreviousStudy(true);
				setSimulationUid(previousStudy.id);
				setOpenDeleteModal(true);
			},
			edit: get_permission(auth?.permissions, 'previous_study', ['update']),
			onEdit: () => {
				setOpenModal(true);
				setIsPreviousStudy(true);
				setSimulationName(previousStudy.name);
				setEditUid(previousStudy.id);
			},
			convert_to_simulation: get_permission(auth?.permissions, 'simulation', [
				'create',
			]),
			onConvertToSimulation: () => convertToSimulation(previousStudy.id),
			onClick: () =>
				previousStudy.status === 'Stopped'
					? history.push(`/previous/${currentProject.uid}/${previousStudy.id}`)
					: history.push(
							`/previous_solution/${currentProject.uid}/${previousStudy.id}`
					  ),
		}));
		return previousStudiesList;
	};

	return { parsePreviousStudiesList, previousStudies, loadingPreviousStudies };
};
