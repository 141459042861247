import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, withRouter } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import { Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import { getProjects } from '../../Services/Api/Api';
import { AppInput, WhiteTitleContainer } from '../../components';
import { RolePermissionSimulation } from '../../components/Allowed/Allowed';
import Else from '../../components/Compounds/If/Else';
import If from '../../components/Compounds/If/If';
import Then from '../../components/Compounds/If/Then';
import DialogComponent from '../../components/Dialog/Dialog';
import DialogVariantComponent from '../../components/Dialog/DialogVariantComponent';
import IsLoading from '../../components/IsLoading/IsLoading';
import SearchBar from '../../components/SearchBar/SearchBar';
import { StyledButton } from '../../components/StyledComponents/Button';
import { StyledCard } from '../../components/StyledComponents/Card';
import { ViewContainer } from '../../components/StyledContainers';
import {
	createProject,
	deleteProjectById,
} from '../../containers/Agreements/ApiServices';
import useIsMounted from '../../hooks/useIsMounted';
import {
	setFetchingState,
	setProjectsList,
} from '../../store/actions/projects';
import { setSnackbar } from '../../store/actions/snackbar';
import { get_permission } from '../../utilities/utils';

const bodyMessage = (bodyText, recommendation) => (
	<Box sx={{ marginTop: '100px' }}>
		<Box sx={{ padding: '100px' }}>
			<Typography variant='h5' color='secondary'>
				{bodyText}
			</Typography>
		</Box>
		<Typography color='secondary'>{recommendation}</Typography>
	</Box>
);

const Projects = (props) => {
	const dispatch = useDispatch();

	const observer = useRef();
	const observerNull = useRef();
	const { auth } = useSelector((state) => state);
	const { isMounted } = useIsMounted();
	const [projects, setProjects] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [newName, setNewName] = useState('');
	const [editUid, setEditUid] = useState(undefined);
	const [projectUid, setProjectUid] = useState('');
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [projectsFirstLoad, setProjectsFirstLoad] = useState(true);
	const [loadingProjects, setLoadingProjects] = useState(false);
	const [thereIsProjects, setThereIsProjects] = useState(true);
	const [queryString, setQueryString] = useState(false);
	const [redirectTo, setRedirectTo] = useState('');
	const [hasMore, setHasMore] = useState(true);

	const getProjectsList = async (search_string = '', startIdProject = null) => {
		setLoadingProjects(true);
		startIdProject === null && setProjectsFirstLoad(true);
		const limit = 32;

		if (auth?.workSpace?.id) {
			try {
				const pro = await getProjects(
					search_string,
					limit,
					startIdProject,
					auth?.workSpace?.id
				);
				if (isMounted) {
					startIdProject !== null
						? setProjects((prev) => prev.concat(pro))
						: setProjects(pro);

					pro.length < limit ? setHasMore(false) : setHasMore(true);

					if (pro.length === 0 && projects.length === 0) {
						setThereIsProjects(false);
					}
				}
				dispatch(setProjectsList(pro));
				dispatch(setFetchingState(false));
				setLoadingProjects(false);
				setProjectsFirstLoad(false);
			} catch (e) {
				setLoadingProjects(false);
				setProjectsFirstLoad(false);
				setSnackbar({
					openSnackbar: true,
					duration: 6000,
					severity: 'error',
					message: e?.response?.data?.message || e?.message || '',
				});
			}
		}
	};

	const removeProject = async (projectId) => {
		setLoadingProjects(true);
		try {
			await deleteProjectById(projectId);
			getProjectsList();
		} catch (e) {
			setLoadingProjects(false);
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message: e?.response?.data?.message || e?.message || '',
			});
		}
	};

	useEffect(() => {
		getProjectsList();
	}, [auth?.workSpace?.id]);

	const deleteProject = async (project_uid) => {
		setProjectsFirstLoad(true);
		await removeProject(project_uid);
	};

	const modalClose = () => {
		setOpenModal(false);
		setNewName('');
	};

	const newProjectButton = () => (
		<StyledButton
			size='medium'
			variant='outlined'
			sx={{
				color: 'primary.main',
				borderColor: 'primary.main',
				textTransform: 'inherit',
			}}
			onClick={() => {
				setOpenModal(true);
				setEditUid(undefined);
			}}
		>
			<AddIcon />
			Nueva licitación
		</StyledButton>
	);

	const renderModal = () => {
		const { updateProject } = props;
		const editing = editUid !== undefined;

		const history = useHistory();

		const saveProject = async (projectName) => {
			const payload = { name: projectName, workspace_id: auth?.workSpace?.id };
			try {
				const project = await createProject({ payload });
				history.push(`/projects/${project}`);
			} catch (e) {
				setSnackBarMessage(e?.response?.data?.message || e?.message || '');
			}
		};

		return (
			<DialogVariantComponent
				open={openModal}
				onClose={modalClose}
				title={editing ? 'Editar nombre' : 'Nueva Licitación'}
				content={
					<AppInput
						label='Nombre'
						type='text'
						name='simulationName'
						margin='dense'
						value={newName}
						autoFocus
						onChange={(event) => setNewName(event.target.value)}
						style={{ width: 350, marginBottom: 30 }}
					/>
				}
				acceptText={editing ? 'Editar' : 'Crear'}
				cancelText='Cancelar'
				onAccept={() => {
					setProjectsFirstLoad(true);
					if (editing)
						updateProject({ name: newName }, editUid)
							.then(() => getProjectsList(queryString))
							.catch(() => setProjectsFirstLoad(false));
					else
						saveProject(newName)
							.then(() => getProjectsList(queryString))
							.catch(() => setProjectsFirstLoad(false));
					modalClose();
				}}
				onCancel={modalClose}
				isInSimulation={false}
			/>
		);
	};

	const closeDeleteModal = () => {
		setOpenDeleteModal(false);
		setProjectUid('');
	};

	const searchBar = () => (
		<SearchBar
			onType={true}
			onSearch={(queryString) => {
				setQueryString(queryString);
				getProjectsList(queryString, null);
			}}
		/>
	);

	const lastProjectRef = useCallback(
		(node) => {
			if (loadingProjects) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					getProjectsList(queryString, projects[projects.length - 1]?.uid);
				}
			});
			if (node) observer.current.observe(node);
		},
		[loadingProjects, hasMore]
	);

	const render = () => {
		const { history } = props;

		if (redirectTo) {
			return <Redirect push to={redirectTo} />;
		}

		const projectsList = projects.map((project) => ({
			title: project.name,
			subtitle: `(${project?.simulation_number || 0}) Simulaciones`,
			delete: get_permission(auth?.permissions, 'simulation', ['delete']),
			onDelete: () => {
				setProjectUid(project.uid);
				setOpenDeleteModal(true);
			},
			edit: get_permission(auth?.permissions, 'simulation', ['update']),
			onEdit: () => {
				setOpenModal(true);
				setNewName(project.name);
				setEditUid(project.uid);
			},
			clicked: true,
			onClick: () => history.push(`/projects/${project.uid}`),
			...project,
		}));

		return (
			<div>
				<WhiteTitleContainer
					routes={[{ name: 'Inicio', href: '/' }, { name: 'Licitaciones' }]}
					title='Licitaciones'
					rightSide={
						<Grid item container spacing={2} justifyContent='flex-end'>
							<Grid item>{searchBar()}</Grid>
							<Grid item>
								<RolePermissionSimulation allowed={['create']}>
									{newProjectButton()}
								</RolePermissionSimulation>
							</Grid>
						</Grid>
					}
					twoField
				/>
				<DialogComponent
					open={openDeleteModal}
					onClose={closeDeleteModal}
					title='Eliminar licitación'
					content='Vas a eliminar una licitación y todas las simulaciones que contiene, ¿estás seguro?'
					acceptText='Sí, eliminar'
					cancelText='Cancelar'
					onAccept={() => {
						deleteProject(projectUid);
						closeDeleteModal();
					}}
					onCancel={closeDeleteModal}
					isInSimulation={false}
				/>
				<ViewContainer>
					<IsLoading conditional={projectsFirstLoad}>
						<If conditional={!thereIsProjects}>
							<Then>
								{bodyMessage(
									'No hay ninguna licitación todavía',
									'Comienza creando una'
								)}
							</Then>
							<Else>
								<If conditional={projects.length > 0}>
									<Then>
										<Grid container spacing={2}>
											{projectsList.map((project, index) => (
												<Grid
													item
													xs={6}
													sm={4}
													md={3}
													ref={
														projectsList.length - 1 === index
															? lastProjectRef
															: observerNull
													}
													key={project.uid}
												>
													<StyledCard>{project}</StyledCard>
												</Grid>
											))}
										</Grid>
									</Then>
									<Else>
										{bodyMessage(
											'Ninguna licitación cumple los criterios de búsqueda',
											'Vuelve a intentarlo'
										)}
									</Else>
								</If>
							</Else>
						</If>
						<Box mt={2}>
							<IsLoading conditional={loadingProjects} />
						</Box>
						{renderModal()}
					</IsLoading>
				</ViewContainer>
			</div>
		);
	};
	return render();
};

export default withRouter(Projects);
