import React from 'react';
import { useDispatch } from 'react-redux';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box } from '@mui/material';

import { StyledButton } from '../../../components/StyledComponents/Button';
import { getAgreementDownloadUrlById } from '../../../containers/Agreements/ApiServices';
import { setSnackbar } from '../../../store/actions/snackbar';

const DownloadAgreementButton = ({ agreementId }) => {
	const dispatch = useDispatch();

	const setSnackBarMessage = ({ message, severity = 'error' }) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity,
				message,
			})
		);
	};

	const getAgreementDownloadUrl = async () => {
		try {
			const { data } = await getAgreementDownloadUrlById({ agreementId });
			window.open(data, '_blank');
		} catch (e) {
			setSnackBarMessage({
				message:
					e?.message === 'Agreement not have pdf file'
						? 'No hay ningún convenio, súbelo desde "editar" el convenio.'
						: 'Error desconocido en el servidor',
			});
		}
	};

	return (
		<Box>
			<StyledButton
				size='medium'
				variant='outlined'
				sx={{
					color: 'primary.main',
					borderColor: 'primary.main',
					textTransform: 'inherit',
					float: 'right',
				}}
				onClick={() => getAgreementDownloadUrl()}
			>
				Convenio en pdf
				<AttachFileIcon />
			</StyledButton>
		</Box>
	);
};

export default DownloadAgreementButton;
