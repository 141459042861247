import React, { Component } from 'react';

import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import LocationCity from '@material-ui/icons/LocationCity';
import SearchIcon from '@material-ui/icons/Search';

import AppInput from '../../components/AppInput/AppInput';
import AppModal from '../../components/AppModal/AppModal';
import AppSimpleList from '../../components/AppSimpleList/AppSimpleList';

const OuterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
`;
const ViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
`;
const RowContainer = styled.div`
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
`;

const ElementContainer = styled.span`
	margin-right: 10px;
`;

class NewProjectAgreements extends Component {
	state = {
		search: '',
		listAgreements: [
			{ text: 'Oficinas y despachos de Madrid' },
			{ text: 'Limpieza de edificios de Madrid' },
		],
		modalStatus: false,
	};

	handleInputChange = (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;

		this.setState({
			[name]: value,
		});
	};

	modalOpen = (type) => {
		this.setState({ [`${type}ModalStatus`]: true });
	};

	modalClose = (type) => {
		this.setState({ [`${type}ModalStatus`]: false });
	};

	render() {
		const { agreementSearch, listAgreements, calendarModalStatus } = this.state;

		return (
			<OuterContainer>
				<ViewContainer>
					<RowContainer>
						<ElementContainer>
							<AppInput
								label=''
								type='text'
								placeholder='Buscar convenio'
								name='agreementSearch'
								value={agreementSearch}
								onChange={(event) => this.handleInputChange(event)}
							/>
							<IconButton
								edge='end'
								aria-label='Buscar'
								onClick={() => console.log('search')}
							>
								<SearchIcon />
							</IconButton>
						</ElementContainer>
					</RowContainer>
					<RowContainer>
						<ElementContainer>
							<AppSimpleList
								items={listAgreements}
								leftIcon={<LocationCity />}
							/>
						</ElementContainer>
					</RowContainer>
					<AppModal
						open={calendarModalStatus}
						handleClose={() => this.modalClose('calendar')}
						modalTitle='Calendarios'
						modalText='Añadir un calendario laboral'
						content={null}
					/>
				</ViewContainer>
			</OuterContainer>
		);
	}
}

export default NewProjectAgreements;
