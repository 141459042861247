import React from 'react';

import { Typography } from '@mui/material';

const DialogLabelTypography = ({
	label,
	variant = 'body2',
	color,
	...rest
}) => (
	<Typography variant={variant} color={color} {...rest}>
		{label}
	</Typography>
);
export default DialogLabelTypography;
