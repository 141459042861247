import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import UploadDialogPDF from 'containers/Simulation/Surrogate/Modal/ModalPdf';
import { DropzoneArea } from 'material-ui-dropzone';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudDownloadOutlinedIcon from '@mui/icons-material/DownloadForOffline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Typography, Box } from '@mui/material';

import DialogComponent from '../../../components/Dialog/Dialog';
import { StyledButton } from '../../../components/StyledComponents/Button';
import { setSnackbar } from '../../../store/actions/snackbar';
import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import UploadFile from '../../../utilities/connection/UploadFile';
import {
	downloadSurrogatesUrl,
	uploadSurrogatesUrl,
} from '../../../utilities/routes';
import { download } from '../../../utilities/utils';

const ErrorSpan = ({ error }) =>
	error ? (
		<Box>
			<Typography variant='subtitle1' color='error'>
				{error}
			</Typography>
		</Box>
	) : null;

const UploadDownLoadButtons = ({
	simulationId,
	uploadSurrogatesStatus,
	disabledButtons,
	setOpenCreateModal,
	setCheckErrors,
}) => {
	const [error, setError] = useState('');
	const [files, setFiles] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [openModalPdf, setOpenModalPdf] = useState(false);
	const [uploadingFile, setUploadingFile] = useState(false);
	const [downloadingFile, setDownloadingFile] = useState(false);

	const dispatch = useDispatch();

	const downloadSurrogates = () => {
		setDownloadingFile(true);
		const payload = {
			filename: 'subrogados',
			mime_type:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		};
		AxiosWrapper.get(
			downloadSurrogatesUrl(simulationId),
			JSON.stringify(payload)
		)
			.then(({ data }) => {
				fetch(data.download_url)
					.then((response) => response.blob())
					.then((blob) => {
						download(blob, 'plantilla_subrogados.xlsx');
						setDownloadingFile(false);
					})
					.catch((e) => {
						setDownloadingFile(false);
						dispatch(
							setSnackbar({
								openSnackbar: true,
								duration: 6000,
								severity: 'error',
								message: e?.response?.data?.message || e?.message,
							})
						);
					});
			})
			.catch((e) => {
				setDownloadingFile(false);
				dispatch(
					setSnackbar({
						openSnackbar: true,
						duration: 6000,
						severity: 'error',
						message: e?.response?.data?.message || e?.message,
					})
				);
			});
	};

	const uploadSurrogates = () => {
		setUploadingFile(true);
		const payload = { filename: files[0].name, mime_type: files[0].type };
		const url = uploadSurrogatesUrl(simulationId);
		AxiosWrapper.post(url, JSON.stringify(payload))
			.then((response) => {
				UploadFile({
					method: 'PUT',
					url: response.data.upload_url,
					content: files[0],
				})
					.then(() => {
						setUploadingFile(false);
						setFiles([]);
						setOpenModal(false);
						uploadSurrogatesStatus();
					})
					.catch((e) => {
						setSnackbar({
							openSnackbar: true,
							duration: 6000,
							severity: 'error',
							message: `Error subiendo archivo, ${
								e?.message ? e?.message : ' '
							}`,
						});
					});
			})
			.catch((e) => {
				setSnackbar({
					openSnackbar: true,
					duration: 6000,
					severity: 'error',
					message: `Error obteniendo la url de subida, ${
						e?.message ? e?.message : ' '
					}`,
				});
			});
	};

	const handleUploadSurrogates = () => {
		if (files.length > 0) {
			setError('');
			uploadSurrogates();
		} else {
			setError('Seleccione un fichero');
		}
	};

	const handleChangeFiles = (files) => {
		setFiles(files);
	};

	return (
		<Box>
			<Box
				sx={{
					display: 'block',
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<StyledButton
					onClick={() => setOpenModalPdf(true)}
					variant='text'
					aria-label='Subir PDF'
					sx={{
						mr: 6,
						color: 'primary.main',
						textTransform: 'inherit',
						fontSize: '18px',
						fontWeight: '800',
					}}
					startIcon={<PictureAsPdfIcon />}
				>
					Subir PDF
				</StyledButton>
				<StyledButton
					onClick={() => setOpenCreateModal(true)}
					variant='text'
					aria-label='Añadir Subrogado'
					sx={{
						mr: 6,
						color: 'primary.main',
						textTransform: 'inherit',
						fontSize: '18px',
						fontWeight: '800',
					}}
					startIcon={<AddCircleOutlineIcon color='primary' />}
				>
					Añadir Subrogado
				</StyledButton>
				<StyledButton
					sx={{
						mr: 6,
						color: 'primary.main',
						textTransform: 'inherit',
						fontSize: '18px',
						fontWeight: '800',
					}}
					onClick={downloadSurrogates}
					variant='text'
					aria-label='Descargar Plantilla'
					startIcon={<CloudDownloadOutlinedIcon />}
					disabled={disabledButtons || downloadingFile}
				>
					Descargar Plantilla
				</StyledButton>
				<StyledButton
					sx={{
						color: 'primary.main',
						textTransform: 'inherit',
						fontSize: '18px',
						fontWeight: '800',
					}}
					onClick={() => setOpenModal(true)}
					variant='text'
					aria-label='Subir Plantilla de Subrogados'
					startIcon={
						<CloudDownloadOutlinedIcon sx={{ transform: 'scaleY(-1)' }} />
					}
					disabled={disabledButtons}
				>
					Subir plantilla de Excel
				</StyledButton>
			</Box>
			<DialogComponent
				open={openModal}
				onClose={() => setOpenModal(false)}
				title='Cargar subrogados'
				disableAccept={uploadingFile}
				disableCancel={uploadingFile}
				content={
					<Box>
						<DropzoneArea
							onChange={handleChangeFiles}
							dropzoneText='Arrastra el excel de subrogados'
							filesLimit={1}
							showFileNames={false}
							useChipsForPreview
							showPreviewsInDropzone={false}
							showPreviews
							dropzoneParagraphClass='DropZoneAreaText'
						/>
						<ErrorSpan error={error} />
					</Box>
				}
				onAccept={handleUploadSurrogates}
				acceptText='Aceptar'
				cancelText='Cancelar'
				onCancel={() => setOpenModal(false)}
				cancelButtonFirst={false}
			/>
			<UploadDialogPDF
				openModal={openModalPdf}
				setOpenModal={setOpenModalPdf}
				simulationId={simulationId}
				setCheckErrors={setCheckErrors}
			/>
		</Box>
	);
};
export default UploadDownLoadButtons;
