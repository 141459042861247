export function setPreviousStudyStore(previousStudyData) {
	return {
		type: 'SET_PREVIOUS_STUDY',
		previousStudyData,
	};
}

export function setPreviousStudyGettingState(gettingPreviousStudyData) {
	return {
		type: 'SET_PREVIOUS_STUDY_GET_STATE',
		gettingPreviousStudyData,
	};
}
