import React from 'react';

import {
	Box,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';

import IsLoading from '../../../components/IsLoading/IsLoading';
import { StyledBoxContainer } from '../../../components/StyledComponents/BoxContainer';
import { StyledButton } from '../../../components/StyledComponents/Button';
import HeaderTableCell from '../../../components/StyledComponents/HeaderTableCell';
import { formatNumber } from '../../../utilities/utils';
import useSocialSecurityCost from './useSocialSecurityCost';

export const SocialSecurityCost = ({ match }) => {
	const {
		employees,
		selected,
		loadingEmployees,

		handleSelectAllClick,
		isSelected,
		handleClick,
		putEmployeesList,
	} = useSocialSecurityCost(match);

	return (
		<IsLoading conditional={loadingEmployees}>
			<Box>
				<Box
					sx={{ width: '100%', fontSize: '18px', fontWeight: '800', mt: 2 }}
					color='common.transition6'
				>
					Selecciona a los nuevos empleados/as a los que se les aplicará un
					contrato de discapacidad, para descontar su coste de la seguridad
					social en la solución
				</Box>
				<Box sx={{ width: '100%', mt: 4 }}>
					<StyledButton
						onClick={() => putEmployeesList()}
						data-testid='socialSecurityDiscount'
						sx={{ textTransform: 'inherit' }}
					>
						Descontar SS
					</StyledButton>
				</Box>
				<Box sx={{ width: '100%', mt: 4 }}>
					<StyledBoxContainer>
						<Table>
							<TableHead>
								<TableRow>
									<HeaderTableCell>
										<Checkbox
											color='primary'
											indeterminate={
												selected.length > 0 &&
												selected.length < employees.length
											}
											checked={
												employees.length > 0 &&
												selected.length === employees.length
											}
											onChange={handleSelectAllClick}
											inputProps={{
												'aria-label': 'select all desserts',
											}}
										/>
									</HeaderTableCell>
									<HeaderTableCell>Empleado</HeaderTableCell>
									<HeaderTableCell>Jornada</HeaderTableCell>
									<HeaderTableCell>Coste salarial</HeaderTableCell>
									<HeaderTableCell>Coste seguridad social</HeaderTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{employees.map((row, index) => {
									const isItemSelected = isSelected(row);
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<TableRow
											key={row.id}
											hover
											onClick={(event) => handleClick(event, row)}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											selected={isItemSelected}
										>
											<TableCell padding='checkbox' sx={{ p: '16px' }}>
												<Checkbox
													sx={{ p: '9px' }}
													color='primary'
													checked={isItemSelected}
													inputProps={{
														'aria-label': labelId,
													}}
												/>
											</TableCell>
											<TableCell>{row.name}</TableCell>
											<TableCell>
												{formatNumber(row?.weekly_hours || 0)} h
											</TableCell>
											<TableCell>{formatNumber(row?.salary)}</TableCell>

											<TableCell>
												{formatNumber(row?.social_security)}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</StyledBoxContainer>
				</Box>
			</Box>
		</IsLoading>
	);
};
