import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import {
	Avatar,
	Card,
	CardContent,
	Divider,
	Typography,
} from '@material-ui/core';

import { AppInput } from '../../components';
import { StyledButton } from '../../components/StyledComponents/Button';
import { ViewContainer } from '../../components/StyledContainers';
import { setSnackbar } from '../../store/actions/snackbar';
import {
	getProfileData,
	updatePasswordData,
	updateProfileData,
} from './ApiServices';

const ProfileContainer = styled.div`
	display: flex;
	margin: auto;
	margin-bottom: 20px;
`;
const AvatarContainer = styled.div`
	display: flex;
	flex-grow: 0.15;
`;
const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 0.85;
`;
const TitleContainter = styled.div`
	text-align: left;
	margin-left: 5px;
	margin-bottom: 10px;
`;
const ChangeTitleContainter = styled.div`
	text-align: left;
	margin-left: 5px;
	margin-top: 10px;
`;
const ChangePassContainter = styled.div`
	display: flex;
	margin: auto;
	margin-top: 25px;
	justify-self: flex-start;
	justify-content: space-evenly;
	align-items: center;
`;

const StyledAvatar = styled(Avatar)`
	margin: auto;
	width: 160px !important;
	height: 160px !important;
	font-size: 80px !important;
`;
const StyledAppInput = styled(AppInput)`
	width: 80%;
	margin-top: 15px !important;
`;
const StyledChangeInput = styled(AppInput)`
	margin-left: 15px;
`;
const SaveButtonContainer = styled.div`
	margin-top: 10px;
`;

const Profile = () => {
	const dispatch = useDispatch();
	const [email, setEmail] = React.useState('');
	const [name, setName] = React.useState('');
	const [first_surname, setFirstSurname] = React.useState('');
	const [second_surname, setSecondSurname] = React.useState('');
	const [oldPassword, setOldPassword] = React.useState('');
	const [newPassword, setNewPassword] = React.useState('');
	const [repeatPassword, setRepeatPassword] = React.useState('');

	const setSnackBarMessage = ({ message, severity = 'error' }) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity,
				message,
			})
		);
	};

	useEffect(async () => {
		try {
			const a = await getProfileData();
			setEmail(a.email);
			setName(a.name);
			setFirstSurname(a.first_surname);
			setSecondSurname(a.second_surname);
		} catch (e) {
			setSnackBarMessage({ message: e?.message });
		}
	}, []);

	const updateProfile = async () => {
		try {
			await updateProfileData({
				payload: { email, name, first_surname, second_surname },
			});
			setSnackBarMessage({
				message: 'Guardado con éxito',
				severity: 'success',
			});
		} catch (e) {
			setSnackBarMessage({ message: e?.message });
		}
	};

	const changePassword = async () => {
		if (newPassword !== '') {
			if (newPassword === repeatPassword) {
				try {
					await updatePasswordData({
						payload: { password: newPassword, old_password: oldPassword },
					});
					setSnackBarMessage({
						message: 'Guardado con éxito',
						severity: 'success',
					});
				} catch (e) {
					setSnackBarMessage({ message: e?.message });
				}
			} else {
				setSnackBarMessage({
					message: 'Las contraseñas no coinciden',
				});
			}
		} else {
			setSnackBarMessage({
				message: 'La nueva contraseña no puede estar vacía',
			});
		}
	};

	return (
		<ViewContainer>
			<TitleContainter>
				<Typography variant='h6' color='textPrimary'>
					Perfil
				</Typography>
			</TitleContainter>
			<Card>
				<CardContent>
					<ProfileContainer>
						<AvatarContainer>
							<StyledAvatar>
								{email ? email.charAt(0).toUpperCase() : 'O'}
							</StyledAvatar>
						</AvatarContainer>
						<InfoContainer>
							<StyledAppInput
								label='Email'
								disabled
								type='text'
								name='email'
								value={email ? email : ''}
								onChange={(event) => setEmail(event.target.value)}
							/>
							<StyledAppInput
								label='Nombre'
								type='text'
								name='name'
								value={name ? name : ''}
								onChange={(event) => setName(event.target.value)}
							/>
							<StyledAppInput
								label='Primer apellido'
								type='text'
								name='first_surname'
								value={first_surname ? first_surname : ''}
								onChange={(event) => setFirstSurname(event.target.value)}
							/>
							<StyledAppInput
								label='Segundo apellido'
								type='text'
								name='second_surname'
								value={second_surname ? second_surname : ''}
								onChange={(event) => setSecondSurname(event.target.value)}
							/>
							<SaveButtonContainer>
								<StyledButton
									color='primary'
									variant='contained'
									onClick={() => updateProfile()}
								>
									Actualizar
								</StyledButton>
							</SaveButtonContainer>
						</InfoContainer>
					</ProfileContainer>
					<Divider />
					<ChangeTitleContainter>
						<Typography variant='body2' color='textPrimary'>
							Cambiar contraseña
						</Typography>
					</ChangeTitleContainter>
					<ChangePassContainter>
						<StyledChangeInput
							type='password'
							name='oldPassword'
							value={oldPassword ? oldPassword : ''}
							onChange={(event) => setOldPassword(event.target.value)}
							label='Contraseña anterior'
							margin='dense'
						/>
						<StyledChangeInput
							type='password'
							name='newPassword'
							value={newPassword ? newPassword : ''}
							onChange={(event) => setNewPassword(event.target.value)}
							label='Nueva contraseña'
							margin='dense'
						/>
						<StyledChangeInput
							type='password'
							name='repeatPassword'
							value={repeatPassword ? repeatPassword : ''}
							onChange={(event) => setRepeatPassword(event.target.value)}
							label='Repetir nueva contraseña'
							margin='dense'
						/>
						<StyledButton
							color='primary'
							variant='contained'
							onClick={() => changePassword()}
						>
							Cambiar
						</StyledButton>
					</ChangePassContainter>
				</CardContent>
			</Card>
		</ViewContainer>
	);
};

export default Profile;
