import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import InfoIcon from '@mui/icons-material/Info';
import { TabContext, TabPanel } from '@mui/lab';
import {
	Alert,
	Box,
	Checkbox,
	FormControlLabel,
	Snackbar,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';

import {
	getCategoryPositions,
	updateCategoryPositions,
} from '../../../Services/Api/Simulation';
import Else from '../../../components/Compounds/If/Else';
import If from '../../../components/Compounds/If/If';
import Then from '../../../components/Compounds/If/Then';
import IsLoading from '../../../components/IsLoading/IsLoading';
import IsSimulationEditable from '../../../components/Simulations/IsSimulationEditable';
import WorkloadTable from '../../../components/Simulations/Workload/WorkloadTable';
import { StyledLoadingButton } from '../../../components/StyledComponents/LoadingButton';
import { refreshPositions } from '../../../store/actions/createSimulation';
import FixedShifts from './FixedShift';
import TasksByHour from './TaskByHour';

const Workload = () => {
	const { simulationId } = useSelector((state) => state.createSimulation);
	const dispatch = useDispatch();
	const { createSimulation } = useSelector((state) => state);
	const [categoryPositions, setCategoryPositions] = useState([]);
	const [messageSeverity, setMessageSeverity] = useState('error');
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [positions, setPositions] = useState(
		createSimulation?.positions?.data || []
	);
	const [positionAny, setPositionAny] = useState(
		createSimulation?.positions?.anyPosition || false
	);
	const [toastMessage, setToastMessage] = useState('');
	const [updatingWorkload, setUpdatingWorkload] = useState(false);
	const [value, setValue] = useState('1');

	const {
		loading: loadingCategoryPositions,
		data: categoryPositionsData,
		error: categoryPositionsError,
	} = getCategoryPositions(simulationId);

	useEffect(() => {
		dispatch(refreshPositions());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!loadingCategoryPositions) {
			if (categoryPositionsError) {
				setToastMessage(categoryPositionsError.message);
				setMessageSeverity('error');
				setOpenSnackbar(true);
			} else {
				setCategoryPositions(categoryPositionsData.categories);
				setPositionAny(
					categoryPositionsData?.disabled
						? categoryPositionsData.disabled
						: false
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingCategoryPositions]);

	const setRowPositionName = (index, value) => {
		const position = positions.filter((pos) => pos.name === value);
		const tempCategoryPositions = [...categoryPositions];
		const positionReplace = { name: value, id: uuidv4() };
		tempCategoryPositions[index].positions[0] =
			position.length > 0 ? position[0] : positionReplace;
		setCategoryPositions(tempCategoryPositions);
		if (position.length === 0) {
			setPositions([...positions, positionReplace]);
		}
	};

	const isValidSubmit = () => {
		let valid = true;
		categoryPositions.forEach((categoryPosition) => {
			if (!categoryPosition.positions[0]?.name) {
				valid = false;
			}
		});
		return valid;
	};

	const updateMessage = (message, severity) => {
		setToastMessage(message);
		setMessageSeverity(severity);
		setOpenSnackbar(true);
	};

	const setAllPositions = (position, listPositions) =>
		listPositions.map((value) => {
			value.positions = [position];
			return value;
		});

	const handleSubmitWorkload = async () => {
		if (isValidSubmit()) {
			setUpdatingWorkload(true);
			let positions = [...categoryPositions];
			if (positionAny) {
				positions = setAllPositions(
					{ name: 'cualquiera', id: uuidv4() },
					positions
				);
			}

			const payload = { disabled: positionAny, categories: positions };
			try {
				const response = await updateCategoryPositions({
					payload,
					simulationId,
				});
				if (response) {
					if (response.status === 200) {
						updateMessage('Guardado con exito', 'success');
						dispatch(refreshPositions());
					} else {
						updateMessage('Error al guardar los datos', 'error');
					}
				}
				setUpdatingWorkload(false);
			} catch (e) {
				updateMessage('Error en el servidor', 'error');
				setUpdatingWorkload(false);
			}
		} else {
			updateMessage('Hay categorías sin puestos', 'error');
		}
	};

	const updatePositionsList = (id, value) => {
		const d = categoryPositions.map((pos) => {
			if (pos.id === id) {
				pos.positions = value;
			}
			return pos;
		});
		setCategoryPositions(d.length > 0 ? d : []);
	};

	return (
		<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
			<Box
				sx={{
					width: '100%',
					fontSize: '18px',
					fontWeight: '800',
					textAlign: 'left',
					mb: 3,
				}}
				color='primary.main'
			>
				Carga de Trabajo
			</Box>
			<TabContext value={value}>
				<Tabs
					centered
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
					}}
				>
					<Tab label='Categorías-Puestos' value='1' />
					<Tab label='Turnos fijos' value='2' />
					<Tab label='Tareas por horas' value='3' />
				</Tabs>
				<Box sx={{ width: 1, ml: 2 }}>
					<TabPanel value='1'>
						<Box sx={{ textAlign: 'left', display: 'flex', mb: 2 }}>
							<InfoIcon color='primary' sx={{ fontSize: 30 }} />
							<Typography
								id='modal-modal-title'
								color='black'
								component='div'
								ml={1}
								sx={{ fontSize: '12px', fontWeight: '500', textAlign: 'left' }}
							>
								Los turnos y tareas se deberán asignar a puestos. Por defecto,
								cada categoría tiene un puesto con el mismo nombre. Puedes
								cambiarlos creando puestos nuevos: escribe el nombre +
								enter/intro. Por ejemplo, crea el puesto “limpieza” y
								selecciónalo en todas las categorías que realicen este puesto,
								ya sea como función principal o en puestos secundarios.
							</Typography>
						</Box>
						<IsLoading conditional={loadingCategoryPositions}>
							<Box sx={{ display: 'block', alignItems: 'center' }}>
								<FormControlLabel
									sx={{ pb: '1.2em' }}
									control={
										<Checkbox
											inputProps={{
												'data-testid': 'any_position_checkbox',
											}}
											sx={{ color: 'primary.main' }}
											checked={positionAny}
											onChange={(e) => {
												setPositionAny(e.target.checked);
											}}
										/>
									}
									label={
										<Typography color='black' sx={{ fontWeight: 800 }}>
											Cualquier categoría puede realizar cualquier puesto
										</Typography>
									}
								/>
							</Box>
							<If conditional={categoryPositions?.length > 0}>
								<Then>
									<Box>
										<Box
											data-testid='WorkloadTable'
											sx={{ display: positionAny ? 'none' : 'block' }}
										>
											<WorkloadTable
												rows={categoryPositions}
												setRowPositionName={(index, value) =>
													setRowPositionName(index, value)
												}
												positions={positions}
												updatePositionsList={(id, value) =>
													updatePositionsList(id, value)
												}
											/>
										</Box>
										<IsSimulationEditable>
											<Box
												sx={{
													maxHeight: '30px',
													float: 'right',
													mt: 2,
													mb: 3,
												}}
											>
												<span>
													<StyledLoadingButton
														loading={updatingWorkload}
														variant='contained'
														color='primary'
														onClick={handleSubmitWorkload}
													>
														Guardar
													</StyledLoadingButton>
												</span>
											</Box>
										</IsSimulationEditable>
									</Box>
								</Then>
								<Else>No existen datos para mostrar</Else>
							</If>
						</IsLoading>
					</TabPanel>
					<TabPanel value='2'>
						<FixedShifts simulationId={simulationId} />
					</TabPanel>
					<TabPanel value='3'>
						<TasksByHour />
					</TabPanel>
				</Box>
			</TabContext>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={messageSeverity}
					sx={{ width: '100%' }}
				>
					{toastMessage}
				</Alert>
			</Snackbar>
		</Box>
	);
};
export default Workload;
