export function setSnackbar(snackbar) {
	return {
		type: 'SET_SNACKBAR',
		snackbar,
	};
}

export function showSnackbar(snackbar) {
	return {
		type: 'SHOW_SNACKBAR',
		snackbar: {
			openSnackbar: true,
			duration: 6000,
			severity: 'success',
			message: '',
			...snackbar,
		},
	};
}

export function closeSnackbar() {
	return {
		type: 'CLOSE_SNACKBAR',
	};
}
