import React, { Component } from 'react';

import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component='div'
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

class AppTabComponent extends Component {
	state = {
		value: 0,
	};

	renderTabs = (content) => {
		const { listTitles, listContents } = this.props;
		const { value } = this.state;
		if (listTitles.length !== listContents.length) {
			return null;
		}

		const listComponents = [];

		if (!content) {
			listTitles.forEach((element, idx) => {
				listComponents.push(<Tab label={element} {...a11yProps(idx)} />);
			});
		} else {
			listContents.forEach((element, idx) => {
				listComponents.push(
					<TabPanel value={value} index={idx}>
						{element}
					</TabPanel>
				);
			});
		}
		return listComponents;
	};

	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const { value } = this.state;

		return (
			<div>
				<Tabs
					value={value}
					onChange={this.handleChange}
					aria-label='simple tabs example'
					color='primary'
				>
					{this.renderTabs(false)}
				</Tabs>
				{this.renderTabs(true)}
			</div>
		);
	}
}

export default withTheme(AppTabComponent);
