import React from 'react';

import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const IconButtonComponent = styled(IconButton)((props) => ({
	color: props.theme.palette.primary.main,
	borderRadius: '60px',
	'&:hover': {
		background: props.theme.palette.primary.main,
		color: props.theme.palette.common.white,
		'& span svg': {
			color: props.theme.palette.common.white,
		},
		'& span img': {
			filter: 'brightness(0) invert(1)',
		},
	},
}));

export const StyledIconButton = ({
	onClick,
	color = 'primary',
	children,
	...rest
}) => (
	<IconButtonComponent
		variant='contained'
		color={color}
		onClick={onClick}
		{...rest}
	>
		{children}
	</IconButtonComponent>
);
