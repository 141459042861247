/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import moment from 'moment';
import styled from 'styled-components';
import { simulationSocialSecurityUrl } from 'utilities/routes';

import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EuroOutlined from '@mui/icons-material/EuroOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, Divider, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	IconButton,
	Card,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTheme } from '@material-ui/core/styles';
import {
	Input,
	CalendarToday,
	GetApp,
	InfoOutlined,
	HighlightOffOutlined,
	Cached,
	AccessTime,
} from '@material-ui/icons';

/* import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'; */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getProjectById } from 'Services/Api/Api';

import { SolutionExcelDownloadUrl } from '../../Services/Api/Simulation';
import cost from '../../assets/imgs/cost.png';
import people from '../../assets/imgs/people.png';
import persons from '../../assets/imgs/persons.png';
import { BreadCrumb, AppDataCard } from '../../components';
import { RolePermissionSimulation } from '../../components/Allowed/Allowed';
import { StyledButton } from '../../components/StyledComponents/Button';
import { ContentTableCell } from '../../components/StyledComponents/ContentTableCell';
import HeaderTableCell from '../../components/StyledComponents/HeaderTableCell';
import {
	ViewContainer,
	RowFlex,
	ColumnFlex,
} from '../../components/StyledContainers';
import { ModifySimulationModal } from '../../containers/SimulationInfo/Modify/Modal';
import { msToTime, download, formatNumber } from '../../utilities/utils';
import './Simulation.css';

const SimulationInfoRow = styled.div`
	flex-direction: column;
	display: flex;
	justify-content: space-around;
	align-items: left;
`;
const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	margin-bottom: 10px;
`;
const LineInfo = styled.div`
	margin-top: 5px;
	display: flex;
	flex-direction: row;
`;
const IconContainer = styled.div`
	display: flex;
	margin-right: 8px;
`;
const InlineIconButton = styled(IconButton)`
	padding: 0px !important;
	margin-left: 5px !important;
`;

const calculateTotalCost = (results) => {
	if (!results || Object.keys(results).length === 0) return '--';
	let cost = results.hiring_costs;
	Object.values(results.categories).forEach((c) => {
		cost += c.wage_costs;
		cost += c.ss_costs;
	});
	if (results?.absenteeism_cost) cost += results.absenteeism_cost;
	if (results?.material_costs) cost += results.material_costs;
	return formatNumber(cost);
};

const calculateTotalEmployees = (results) => {
	let count = 0;
	Object.values(results.categories).forEach((c) => {
		count += c.count_sub;
		count += c.count_new;
	});
	return count;
};

const get_project_name = async (project_id) => {
	const project = await getProjectById(project_id);
	return project.name;
};

// eslint-disable-next-line padded-blocks
class Simulation extends Component {
	constructor(props) {
		super(props);
		const { match } = props;
		const {
			params: { id, simulationId },
		} = match;
		this.state = {
			files: [],
			expandedEvent: null,
			curTime: null,
			openModal: false,
			uploadingParameters: false,
		};
		this.simulationId = simulationId;
		this.projectId = id;
		this.projectName = undefined;

		this.retry = true;
	}

	componentDidMount() {
		this.loadInfo();
	}

	componentDidUpdate(prevProps) {
		const {
			simulations: { currentSimulation },
			getSimulation,
		} = this.props;
		const {
			simulations: { currentSimulation: prevSimulation },
		} = prevProps;

		// TODO: simplify
		if (
			currentSimulation &&
			prevSimulation &&
			currentSimulation.status !== prevSimulation.status &&
			(currentSimulation.status === 'Running' ||
				currentSimulation.status === 'Stopped')
		) {
			if (!this.listener) {
				this.listener = setInterval(
					() => getSimulation(this.projectId, this.simulationId, false),
					2000
				); // eslint-disable-line
				this.interval = setInterval(() => {
					this.setState({
						curTime: new Date(),
					});
				}, 1000);
			}
		} else if (
			currentSimulation &&
			prevSimulation &&
			currentSimulation.status !== prevSimulation.status &&
			prevSimulation.status === 'Running'
		) {
			clearInterval(this.listener);
			clearInterval(this.interval);
			this.listener = undefined;
			this.interval = undefined;
		}
	}

	componentWillUnmount() {
		if (this.listener) {
			clearInterval(this.listener);
			clearInterval(this.interval);
		}
	}

	renderEvent = (e, subEvents) => {
		const { expandedEvent } = this.state;
		const expand = subEvents.length > 0;
		let eventIcon = <InfoOutlined color='primary' />;
		if (e.type_info === 'Error')
			eventIcon = <HighlightOffOutlined style={{ fill: '#DD2E44' }} />;
		else if (e.group == null) eventIcon = <InfoOutlined color='secondary' />;
		return (
			<Accordion
				key={e.created}
				expanded={expand && expandedEvent === e.created}
				onChange={(_, expanded) =>
					this.setState({ expandedEvent: expanded ? e.created : null })
				}
			>
				<AccordionSummary
					expandIcon={expand ? <ExpandMoreIcon /> : null}
					{...(!expand ? { style: { cursor: 'default' } } : {})}
				>
					<div style={{ flex: 1, maxWidth: 100 }}>{eventIcon}</div>
					<Typography
						align='left'
						style={{ flex: 1, maxWidth: 150 }}
						color='textPrimary'
					>
						{moment(e.created).format('HH:mm:ss')}
					</Typography>
					<Typography
						align='left'
						style={{ flex: 1, maxWidth: 250 }}
						color='textPrimary'
					>
						{e.title}
					</Typography>
					<Typography align='left' style={{ flex: 1 }} color='textPrimary'>
						{e.description}
					</Typography>
				</AccordionSummary>
				{this.renderSubevents(subEvents)}
			</Accordion>
		);
	};

	renderGreyTitle = (string, divider = false) => {
		const { theme } = this.props;

		return (
			<Typography
				variant='h6'
				align='left'
				style={{
					fontWeight: 800,
					color: theme.palette.common.grey,
				}}
			>
				{string}
				{divider && <Divider sx={{ borderBottomWidth: 'medium' }} />}
			</Typography>
		);
	};

	renderSubevents = (subEvents) => (
		<AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
			{subEvents.map((e) => (
				<div key={e.created} style={{ display: 'flex', marginBottom: 6 }}>
					<div style={{ flex: 1, maxWidth: 100 }} />
					<Typography
						align='left'
						style={{ flex: 1, maxWidth: 150 }}
						color='textSecondary'
					>
						{moment(e.created).format('HH:mm:ss')}
					</Typography>
					<Typography
						align='left'
						style={{ flex: 1, maxWidth: 250 }}
						color='textSecondary'
					>
						{e.title}
					</Typography>
					<Typography align='left' style={{ flex: 1 }} color='textSecondary'>
						{e.description}
					</Typography>
				</div>
			))}
		</AccordionDetails>
	);

	renderEvents = () => {
		const { simulations } = this.props;

		const { currentSimulation } = simulations;

		const events = [];

		if (currentSimulation) {
			const debugEvents = currentSimulation?.events?.filter(
				(e) => e.type_info === 'Debug'
			);
			currentSimulation.events
				.filter((e) => e.type_info !== 'Debug')
				.forEach((event) => {
					const subEvents = debugEvents.filter((e) => e.group === event.group);
					events.push(this.renderEvent(event, subEvents));
				});
		}

		return (
			<div style={{ marginTop: '30px' }}>
				<RowContainer>
					<Typography variant='h6' color='textPrimary'>
						Progreso
					</Typography>
					{this.renderRefreshButton()}
				</RowContainer>
				{events}
			</div>
		);
	};

	renderInfoCard = (input_filename, data_url, created, finished) => (
		<AppDataCard style={{ width: '45%' }}>
			<SimulationInfoRow>
				<LineInfo>
					<IconContainer>
						<CalendarToday color='primary' fontSize='small' />
					</IconContainer>
					<Typography color='textPrimary'>
						{moment(created).format('DD/MM/YYYY, HH:mm:ss')}
					</Typography>
				</LineInfo>
				<Grid container>
					<Grid container item xs={12} sm={12} md={9} lg={8}>
						<LineInfo>
							<IconContainer>
								<Input color='primary' fontSize='small' />
							</IconContainer>
							<Typography color='textPrimary'>
								Formulario
								{data_url ? (
									<InlineIconButton
										onClick={() => {
											fetch(data_url).then((response) =>
												response
													.blob()
													.then((blob) => download(blob, input_filename))
											);
										}}
									>
										<GetApp fontSize='small' />
									</InlineIconButton>
								) : (
									<InlineIconButton
										onClick={() => {
											// eslint-disable-next-line react/destructuring-assignment
											this.props.history.push(
												`/simulation/${this.projectId}/${this.simulationId}/form`
											);
										}}
									>
										<FeedIcon sx={{ mt: -1 }} color='primary' />
									</InlineIconButton>
								)}
							</Typography>
							{finished && (
								<Box sx={{ display: 'flex' }}>
									<RolePermissionSimulation allowed={['update']}>
										<Box>
											<Typography
												color='textPrimary'
												style={{ marginLeft: 20 }}
											>
												<ModifySimulationModal
													// eslint-disable-next-line react/destructuring-assignment
													openModal={this.state.openModal}
													setOpenModal={(open) =>
														this.setState({ openModal: open })
													}
													simulationId={this.simulationId}
													setRefreshPage={
														() =>
															this.loadInfo() /* window.location.reload(false) */
													}
													setUploadingParameters={(value) =>
														this.setState({ uploadingParameters: value })
													}
												/>
												Modificar
												<InlineIconButton
													onClick={() => {
														this.setState({ openModal: true });
													}}
												>
													<AutoFixHighIcon sx={{ mt: -1 }} color='primary' />
												</InlineIconButton>
											</Typography>
										</Box>
									</RolePermissionSimulation>
									<RolePermissionSimulation allowed={['update']}>
										<Box ml={2}>
											<InlineIconButton
												onClick={() => {
													// eslint-disable-next-line react/destructuring-assignment
													this.props.history.push(
														simulationSocialSecurityUrl(
															this.projectId,
															this.simulationId
														)
													);
												}}
											>
												<GroupsIcon
													style={{ fontSize: 38 }}
													sx={{ mt: -1 }}
													color='primary'
												/>
											</InlineIconButton>
										</Box>
									</RolePermissionSimulation>
								</Box>
							)}
						</LineInfo>
					</Grid>
					<Grid container item xs={12} md={3} lg={4} sm={12}>
						{this.renderSolutionButton(input_filename)}
					</Grid>
				</Grid>
				{this.renderDuration(created, finished)}
			</SimulationInfoRow>
		</AppDataCard>
	);

	dataTypography = (string, icon) => {
		const { theme } = this.props;
		return (
			<RowFlex style={{ marginTop: 10 }}>
				<Typography
					variant='h4'
					style={{ fontWeight: 600, color: theme.palette.common.black }}
				>
					{string}
				</Typography>
				{icon}
			</RowFlex>
		);
	};

	loadInfo() {
		const { getSimulation } = this.props;

		getSimulation(this.projectId, this.simulationId);
		get_project_name(this.projectId).then((response) => {
			this.projectName = response;
		});
	}

	renderCostsCard = (results) => {
		const old_ss_costs = Object.values(results.categories)
			.map((c) => c.ss_costs)
			.reduce((a, b) => a + b, 0);
		const ss_costs =
			'social_security' in results ? results.social_security : old_ss_costs;
		const wage_costs = Object.values(results.categories)
			.map((c) => c.wage_costs)
			.reduce((a, b) => a + b, 0);

		const euroIcon = [
			// eslint-disable-next-line react/jsx-key
			<EuroOutlined
				style={{
					marginBottom: '-5px',
					marginLeft: '5px',
				}}
				key='euro'
			/>,
		];

		return (
			<Card style={{ marginTop: 20 }}>
				<RowFlex style={{ margin: 20, justifyContent: 'space-around' }}>
					<ColumnFlex>
						{this.renderGreyTitle(['Materiales', euroIcon])}
						{this.dataTypography(formatNumber(results?.material_cost || 0))}
					</ColumnFlex>
					<ColumnFlex>
						{this.renderGreyTitle(['Absentismo', euroIcon])}
						{this.dataTypography(formatNumber(results?.absenteeism_cost || 0))}
					</ColumnFlex>
					<ColumnFlex>
						{this.renderGreyTitle(['Seguridad social', euroIcon])}
						{this.dataTypography(formatNumber(ss_costs))}
					</ColumnFlex>
					<ColumnFlex>
						{this.renderGreyTitle(['Salarial', euroIcon])}
						{this.dataTypography(formatNumber(wage_costs))}
					</ColumnFlex>
					<ColumnFlex>
						{this.renderGreyTitle(['Beneficio', euroIcon])}
						{this.dataTypography(formatNumber(results?.benefit || 0))}
					</ColumnFlex>
				</RowFlex>
			</Card>
		);
	};

	renderEmployeesCard = (results) => {
		const sub_count = Object.values(results.categories)
			.map((c) => c.count_sub)
			.reduce((a, b) => a + b, 0);
		const non_sub_count = Object.values(results.categories)
			.map((c) => c.count_new)
			.reduce((a, b) => a + b, 0);
		const sub_fte = Object.values(results.categories)
			.map((c) => c.fte_sub || 0)
			.reduce((a, b) => a + b, 0);
		const non_sub_fte = Object.values(results.categories)
			.map((c) => c.fte_new || 0)
			.reduce((a, b) => a + b, 0);

		return (
			<Box style={{ marginTop: 60 }}>
				<Grid container spacing={4}>
					<Grid item md={2} xs={6}>
						<img
							src={people}
							style={{
								marginTop: '-15px',
								fontSize: 35,
							}}
						/>
					</Grid>
					<Grid item md={3} xs={6}>
						{this.renderGreyTitle('A contratar', true)}
						{this.dataTypography(non_sub_count)}
					</Grid>
					<Grid item md={2} xs={6}>
						{this.renderGreyTitle('ETC', true)}
						{this.dataTypography(formatNumber(non_sub_fte, 1))}
					</Grid>
					<Grid item md={3} xs={6}>
						{this.renderGreyTitle('A subrogar', true)}
						{this.dataTypography(sub_count)}
					</Grid>
					<Grid item md={2} xs={6}>
						{this.renderGreyTitle('ETC', true)}
						{this.dataTypography(formatNumber(sub_fte, 1))}
					</Grid>
				</Grid>
			</Box>
		);
	};

	renderEmployeesDetailedCard = (results) => {
		const category_data = {};
		Object.entries(results.categories).forEach(([cat, data]) => {
			category_data[cat] = {
				employees: data.count_new + data.count_sub,
				hours: data.hours,
				wageCost: data.wage_costs,
				FTE: data.fte_new + data.fte_sub,
			};
		});

		return (
			<TableContainer
				component={Paper}
				style={{ paddingLeft: 30, paddingRight: 30, marginTop: 50 }}
			>
				<Table>
					<TableHead>
						<TableRow>
							<HeaderTableCell
								sx={{
									padding: '0px',
									display: { xs: 'none', md: 'table-cell' },
									width: { md: '1%', lg: '2%' },
								}}
							/>
							<HeaderTableCell>Categorías</HeaderTableCell>
							<HeaderTableCell>Empleados/as </HeaderTableCell>
							<HeaderTableCell>ETC</HeaderTableCell>
							<HeaderTableCell>Horas</HeaderTableCell>
							<HeaderTableCell> Coste salarial (€)</HeaderTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.entries(category_data).map(([cat, data]) => (
							<TableRow key={cat}>
								<ContentTableCell
									sx={{
										padding: '0px',
										display: { xs: 'none', md: 'table-cell' },
										width: { md: '1%', lg: '2%' },
									}}
								/>
								<ContentTableCell>{cat}</ContentTableCell>
								<ContentTableCell>{data.employees}</ContentTableCell>
								<ContentTableCell>{formatNumber(data.FTE, 1)}</ContentTableCell>
								<ContentTableCell>{formatNumber(data.hours)}</ContentTableCell>
								<ContentTableCell>
									{formatNumber(data.wageCost)}
								</ContentTableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	renderTimerCard = (created) => {
		const {
			simulations: { currentSimulation },
		} = this.props;

		if (currentSimulation.status !== 'Running') return null;
		const { curTime } = this.state;
		let diff;
		if (created && curTime) diff = moment(curTime).diff(created);
		return (
			<AppDataCard title='Tiempo transcurrido' style={{ width: '50%' }}>
				{this.dataTypography(
					msToTime(diff),
					<AccessTimeOutlined
						color='primary'
						style={{ marginTop: -15, fontSize: 55 }}
					/>
				)}
			</AppDataCard>
		);
	};

	renderSimulationInfo() {
		const {
			simulations: { currentSimulation },
			getSimulation,
			theme,
		} = this.props;
		if (!currentSimulation) return null;
		const { data_url, name, finished, started, results } = currentSimulation;

		const cardTitle = (title, color = theme.palette.primary.main) => (
			<Typography
				align='left'
				variant='body1'
				style={{
					fontWeight: 'bold',
					marginBottom: 20,
					color,
				}}
			>
				{title}
			</Typography>
		);

		// request simulation data again
		if (!data_url && this.retry) {
			getSimulation(this.projectId, this.simulationId);
			this.retry = false;
		}

		const input_filename = name;

		let parsedResults = {};
		try {
			if (typeof results === 'string') {
				const replaceSingleQuotes = results?.replace(/'/g, '"');
				parsedResults = JSON.parse(replaceSingleQuotes);
			} else {
				parsedResults = { ...results };
			}
		} catch (e) {
			console.log(e);
		} // eslint-disable-line

		return (
			<div style={{ marginTop: 30 }}>
				<Typography
					align='left'
					variant='h6'
					style={{ fontWeight: 'bold', marginBottom: 20, color: 'black' }}
				>
					{name}
				</Typography>
				<ColumnFlex>
					<RowFlex>
						{this.renderInfoCard(input_filename, data_url, started, finished)}
						{(parsedResults.categories && [
							<AppDataCard
								key='employees'
								title={cardTitle('Empleados/as')}
								style={{
									width: '20%',
									borderBottom: `5px solid ${theme.palette.primary.main}`,
								}}
								borderLeft='none'
							>
								{this.dataTypography(
									calculateTotalEmployees(parsedResults),
									// eslint-disable-next-line jsx-a11y/alt-text
									<img
										src={persons}
										style={{
											marginTop: -30,
											fontSize: 55,
										}}
									/>
								)}
							</AppDataCard>,
							<AppDataCard
								key='cost'
								title={cardTitle(
									'Coste Total',
									theme.palette.common.transition5
								)}
								style={{
									width: '30%',
									borderBottom: `5px solid ${theme.palette.common.transition5}`,
								}}
								borderLeft='none'
							>
								{this.dataTypography(
									parsedResults?.total_cost
										? formatNumber(parsedResults.total_cost)
										: calculateTotalCost(parsedResults),
									// eslint-disable-next-line jsx-a11y/alt-text
									<img src={cost} style={{ marginTop: -30, fontSize: 55 }} />
								)}
							</AppDataCard>,
						]) ||
							this.renderTimerCard(started)}
					</RowFlex>
					{parsedResults.categories && this.renderCostsCard(parsedResults)}
					{parsedResults.categories && this.renderEmployeesCard(parsedResults)}
					{parsedResults.categories &&
						this.renderEmployeesDetailedCard(parsedResults)}
				</ColumnFlex>
			</div>
		);
	}

	// RUNNING
	renderRefreshButton() {
		const { simulations, getSimulation } = this.props;

		const { currentSimulation } = simulations;
		if (!currentSimulation || currentSimulation.status !== 'Running')
			return null;
		return (
			<InlineIconButton
				style={{ animation: 'spin 3s linear infinite' }}
				color='primary'
				onClick={() => getSimulation(this.projectId, this.simulationId)}
			>
				<Cached />
			</InlineIconButton>
		);
	}

	// SOLVED
	renderSolutionButton(input_filename) {
		if (!input_filename) {
			return null;
		}

		const {
			simulations: { currentSimulation },
		} = this.props;

		const { result_url, uid } = currentSimulation;
		if (!result_url) return null;

		const solution_filename = `Solucion_${input_filename}.xlsx`;

		return (
			<StyledButton
				variant='outlined'
				sx={{
					textTransform: 'inherit',
					fontWeight: '800',
					color: 'primary.main',
				}}
				onClick={() => {
					SolutionExcelDownloadUrl(uid).then((data) => {
						fetch(data).then((response) =>
							response.blob().then((blob) => download(blob, solution_filename))
						);
					});
				}}
				startIcon={<FileDownloadOutlinedIcon fontSize='small' />}
			>
				Descargar solución
			</StyledButton>
		);
	}

	renderDuration = (created, finished) => {
		if (!finished) return null;
		const diff = moment(finished).diff(created);
		return (
			<LineInfo>
				<IconContainer>
					<AccessTime color='primary' fontSize='small' />
				</IconContainer>
				<Typography color='textPrimary'>{msToTime(diff)}</Typography>
			</LineInfo>
		);
	};

	render() {
		const { redirectTo, uploadingParameters } = this.state;
		const { simulations } = this.props;

		if (redirectTo) {
			delete this.state.redirectTo; // eslint-disable-line
			return <Redirect push to={redirectTo} />;
		}

		if (simulations.fetching) {
			return (
				<ViewContainer>
					<CircularProgress />
				</ViewContainer>
			);
		}
		if (uploadingParameters) {
			return (
				<ViewContainer>
					<Typography
						style={{
							fontWeight: '700',
							fontSize: '20px',
						}}
						color='primary'
					>
						Actualizando simulación
					</Typography>
					<CircularProgress />
				</ViewContainer>
			);
		}

		return (
			<ViewContainer>
				<BreadCrumb
					routes={[
						{ name: 'Inicio', href: '/' },
						{ name: 'Licitaciones', href: '/projects' },
						{ name: this.projectName, href: `/projects/${this.projectId}` },
						{
							name: simulations.currentSimulation
								? simulations.currentSimulation.name
								: '',
						},
					]}
				/>
				{this.renderSimulationInfo()}
				{this.renderEvents()}
			</ViewContainer>
		);
		// eslint-disable-next-line padded-blocks
	}
}

export default withRouter(withTheme(Simulation));
