import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import translator from 'utilities/translator/translator';

import {
	// Alert,
	Box,
	CircularProgress,
	Grid, // Snackbar,
	TextField,
} from '@mui/material';

import {
	deleteSurrogate,
	getSurrogates,
} from '../../../Services/Api/PreviousStudy';
import { setSnackbar } from '../../../store/actions/snackbar';
import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import { uploadSurrogatesStatusPreviousStudyUrl } from '../../../utilities/routes';
import { formatNumber, sleep } from '../../../utilities/utils';
import SurrogateModal from './Modal';
import SurrogatesTable from './Table/SurrogatesTable';
import UploadDownLoadButtons from './UploadDownLoadButtons';

const SurrogatesTab = () => {
	const { uid: previousStudyId } = useSelector((state) => state.previousStudy);
	const dispatch = useDispatch();

	const [surrogateId, setSurrogateId] = useState('');
	const [reloadSurrogates, setReloadSurrogates] = useState(false);
	const [reloadingSurrogates, setReloadingSurrogates] = useState(false);
	// const [openSnackbar, setOpenSnackbar] = useState(false);
	// const [toastMessage, setToastMessage] = useState('');
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [workingDaySum, setWorkingDaySum] = useState(0);
	const [surrogates, setSurrogates] = useState([]);
	const [loadingSurrogates, setLoadingSurrogates] = useState(false);

	const dispatchErrorMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const uploadSurrogatesStatus = async () => {
		let response = '';
		let count = 0;
		setReloadingSurrogates(true);
		do {
			count += 1;
			try {
				response = await AxiosWrapper.get(
					uploadSurrogatesStatusPreviousStudyUrl(previousStudyId),
					{}
				);
			} catch (e) {
				dispatchErrorMessage(e?.response?.data?.message || e?.message);
			}
			await sleep(500);
			if (response?.data?.status === 'ERROR') {
				count = 10;
				dispatchErrorMessage(translator(response.data.info_message));
				setReloadingSurrogates(false);
				return;
			}
			if (response?.data?.status === 'PENDING' && count >= 10) {
				dispatchErrorMessage('El servidor está tardando mucho en responder');
				setReloadingSurrogates(false);
				return;
			}
			if (response?.data?.status !== 'PENDING' || count >= 10) {
				setReloadSurrogates(!reloadSurrogates);
				setReloadingSurrogates(false);
			}
		} while (response?.data?.status === 'PENDING' && count <= 10);
	};

	// const handleClose = (event, reason) => {
	// 	if (reason === 'clickaway') {
	// 		return;
	// 	}
	// 	setOpenSnackbar(false);
	// };

	const getSurrogatesWorkingDaysSum = (surrogatesList) => {
		let sum = 0;
		surrogatesList?.forEach((surrogate) => {
			if (surrogate?.working_day_fixed) sum += surrogate.working_day_fixed;
		});
		setWorkingDaySum(formatNumber(sum));
	};

	const getSurrogatesList = async () => {
		setLoadingSurrogates(true);
		try {
			const { data } = await getSurrogates({ previousStudyId });
			setSurrogates(data);
			getSurrogatesWorkingDaysSum(data);
			setLoadingSurrogates(false);
		} catch (e) {
			setLoadingSurrogates(false);
		}
	};

	const deleteSurrogateById = async (surrogateId) => {
		try {
			await deleteSurrogate({
				previousStudyId,
				surrogateId,
			});
			setReloadSurrogates((prev) => !prev);
		} catch (e) {
			dispatchErrorMessage(e?.message);
		}
	};

	const editSurrogate = async (surrogateId) => {
		if (surrogateId) {
			setSurrogateId(surrogateId);
			setOpenCreateModal(true);
		}
	};

	useEffect(() => {
		getSurrogatesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadSurrogates]);

	return (
		<Box sx={{ width: '100%' }}>
			<SurrogateModal
				openModal={openCreateModal}
				setOpenModal={(val) => setOpenCreateModal(val)}
				setReloadSurrogates={() => setReloadSurrogates((prev) => !prev)}
				surrogateId={surrogateId}
				setSurrogateId={(val) => setSurrogateId(val)}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<Box
					sx={{
						fontSize: '18px',
						fontWeight: '800',
						textAlign: 'left',
						mb: 3,
					}}
					color='primary.main'
				>
					Subrogados
				</Box>
				<Box>
					<Grid container item mt='2px' sx={{ alignItems: 'center' }}>
						<TextField
							color='primary'
							value={`Total de horas semanales       ${workingDaySum}h`}
							InputProps={{
								readOnly: true,
								inputProps: {
									style: {
										textAlign: 'center',
										padding: '2px',
										height: '30px',
									},
								},
							}}
							focused
							sx={{ width: '300px', marginLeft: '10px' }}
						/>
					</Grid>
				</Box>
			</Box>
			<Box mt={2}>
				<UploadDownLoadButtons
					previousStudyId={previousStudyId}
					uploadSurrogatesStatus={uploadSurrogatesStatus}
					disabledButtons={reloadingSurrogates || loadingSurrogates}
					setOpenCreateModal={setOpenCreateModal}
				/>
			</Box>
			<Box mt={4}>
				{reloadingSurrogates || loadingSurrogates ? (
					<CircularProgress />
				) : (
					<SurrogatesTable
						surrogates={surrogates}
						deleteSurrogate={deleteSurrogateById}
						editSurrogate={editSurrogate}
					/>
				)}
			</Box>
			{/* <Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleClose}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity='error'
					sx={{ width: '100%' }}
				>
					{toastMessage}
				</Alert>
			</Snackbar> */}
		</Box>
	);
};
export default SurrogatesTab;
