import AxiosWrapper from '../../utilities/connection/AxiosWrapper';
import {
	getTokenUrl,
	getUserPermissionsUrl,
	getUserSimulationCounterUrl,
} from '../../utilities/routes';
import ApiException from './ApiException';

export async function getUserSimulationCounter() {
	const url = getUserSimulationCounterUrl();
	try {
		const simulationCounter = await AxiosWrapper.get(url);
		return simulationCounter.data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getUserPermissions(id) {
	const url = getUserPermissionsUrl(id);
	try {
		const { data } = await AxiosWrapper.get(url);
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getToken(payload) {
	const url = getTokenUrl();
	try {
		const { data } = await AxiosWrapper.post(url, JSON.stringify(payload));
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
