import React from 'react';

import DialogTitle from 'components/Dialog/DialogTitle';

import { Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import DialogValidateContent from './Validate';
import useValidateSimulationModal from './useDialogValidate';

const DialogValidate = ({
	openModal,
	setOpenModal,
	simulationId,
	handleInitSimulation,
}) => {
	const { loadingValidate, validateSimulationData, closeDialog } =
		useValidateSimulationModal({ setOpenModal, simulationId, openModal });
	return (
		<Dialog
			open={openModal}
			onClose={closeDialog}
			scroll='body'
			PaperProps={{
				sx: { overflowY: 'visible !important', marginTop: '50px' },
			}}
		>
			<Box
				sx={{
					p: 3,
				}}
			>
				<Box width='100%'>
					<DialogTitle title='Validando simulación' />

					{loadingValidate || !validateSimulationData ? (
						<DialogContent sx={{ pl: '0px', pr: '0px' }}>
							<Box sx={{ textAlign: 'center' }}>
								<CircularProgress />
							</Box>
						</DialogContent>
					) : (
						<DialogValidateContent
							simulationErrors={validateSimulationData}
							closeDialog={closeDialog}
							handleInitSimulation={handleInitSimulation}
						/>
					)}
				</Box>
			</Box>
		</Dialog>
	);
};

export default DialogValidate;
