import React from 'react';

import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

const ButtonComponent = styled(LoadingButton)((props) => ({
	color: props.textcolor ? props.textcolor : '#fff',
	borderRadius: '60px',
}));

export const StyledLoadingButton = ({
	onClick,
	textColor = '#fff',
	color = 'primary',
	children,
	variant = 'contained',
	...rest
}) => (
	<ButtonComponent
		variant={variant}
		textcolor={textColor}
		color={color}
		onClick={onClick}
		{...rest}
	>
		{children}
	</ButtonComponent>
);
