const initial = {
	name: '',
	refreshPreviousStudy: false,
	service_date_init: '',
	service_duration: '',
	social_security: '33.5',
	absenteeism_rate: '0',
	gettingPreviousStudyData: false,
};

export default function previousStudy(
	state = initial,
	{ type, name, previousStudyData, gettingPreviousStudyData } = {}
) {
	switch (type) {
		case 'SET_PREVIOUS_STUDY_NAME':
			return { ...state, name };
		case 'SET_PREVIOUS_STUDY':
			return { ...state, ...previousStudyData };
		case 'SET_PREVIOUS_STUDY_GET_STATE':
			return { ...state, gettingPreviousStudyData };
		case 'REFRESH_PREVIOUS_STUDY':
			return { ...state, refreshPreviousStudy: !state.refreshPreviousStudy };
		default:
			return state;
	}
}
