import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import {
	Table,
	TableBody,
	TableHead,
	TableRow,
	Box,
	Tooltip,
} from '@mui/material';

import { StyledBoxContainer } from '../../StyledComponents/BoxContainer';
import { ContentTableCell } from '../../StyledComponents/ContentTableCell';
import HeaderTableCell from '../../StyledComponents/HeaderTableCell';
import { StyledIcon } from '../../StyledComponents/Icon';
import IsSimulationEditable from '../IsSimulationEditable';

const getWeekDays = (
	weekdays_working,
	period_without_work,
	work_in_holidays,
	weekday_work_in_holidays
) => {
	const weekDays = [];
	const dayFormat = {
		monday: 'L',
		tuesday: 'M',
		wednesday: 'X',
		thursday: 'J',
		friday: 'V',
		saturday: 'S',
		sunday: 'D',
	};

	weekdays_working.forEach((day) => {
		if (dayFormat[day]) {
			weekDays.push(dayFormat[day]);
		}
	});
	if (work_in_holidays) {
		weekDays.push(' F');
	}
	if (period_without_work) {
		weekDays.push(' Existen periodos sin turno');
	}
	if (weekday_work_in_holidays) {
		weekDays.push(' (AF)');
	}
	return weekDays?.join(' ').trim();
};

const FixedShiftsTable = ({ rows = [], deleteShift, editShift }) => (
	<StyledBoxContainer>
		<Table aria-label='Turnos fijos'>
			<TableHead>
				<TableRow>
					<HeaderTableCell>Centro</HeaderTableCell>
					<HeaderTableCell>Turnos/horarios</HeaderTableCell>
					<HeaderTableCell>Puestos</HeaderTableCell>
					<HeaderTableCell sx={{ width: 1 / 6 }} />
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row) => (
					<TableRow key={row?.id}>
						<ContentTableCell>{row?.center?.name || '-'}</ContentTableCell>
						<ContentTableCell>
							{`${
								row?.shift_type === 'night'
									? 'Noche'
									: row?.shift_type === 'afternoon'
									? 'Tarde'
									: row?.shift_type === 'split'
									? 'Partido'
									: 'Mañana'
							} (${row?.shifts[0]?.date_init}-${row?.shifts[0]?.date_end} ${
								row?.shift_type === 'split'
									? `/ ${row.shifts[1].date_init}-${row.shifts[1].date_end}`
									: ''
							})` || '-'}
							<Tooltip
								placement='top'
								title={getWeekDays(
									row?.weekdays_working,
									row?.exists_period_without_work,
									row?.work_in_holidays,
									row?.weekday_work_in_holidays
								)}
							>
								<InfoIcon color='primary' />
							</Tooltip>
						</ContentTableCell>
						<ContentTableCell>
							{`${row?.number_of_workers || ''} ${row?.position?.name || '-'}`}
						</ContentTableCell>

						<ContentTableCell sx={{ maxWidth: 10 }}>
							<Box sx={{ width: 1 / 5, display: 'flex' }}>
								<IsSimulationEditable>
									<StyledIcon
										variant='contained'
										color='primary'
										aria-label='delete'
										onClick={() => deleteShift(row?.id)}
									>
										<DeleteIcon />
									</StyledIcon>
								</IsSimulationEditable>
								<StyledIcon
									sx={{ marginLeft: 1 }}
									variant='contained'
									color='primary'
									aria-label='edit'
									onClick={() => editShift(row)}
								>
									<EditIcon />
								</StyledIcon>
							</Box>
						</ContentTableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</StyledBoxContainer>
);

export default FixedShiftsTable;
