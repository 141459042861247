import React from 'react';

import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const IconComponent = styled(IconButton)((props) => ({
	cursor: 'pointer',
	background: props.theme.palette.grey.iconBackground,
	color: props.theme.palette.grey.iconColor,
	'&:hover': {
		background: props.theme.palette.primary.main,
		color: props.theme.palette.common.white,
	},
}));

export const StyledIcon = ({
	onClick,
	label = 'iconButton',
	children,
	...rest
}) => (
	<IconComponent
		variant='outlined'
		aria-label={label}
		size='small'
		onClick={() => onClick()}
		sx={{
			borderRadius: '50%',
			zIndex: '10',
		}}
		{...rest}
	>
		{children}
	</IconComponent>
);
