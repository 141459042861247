import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

import Else from '../../../../components/Compounds/If/Else';
import If from '../../../../components/Compounds/If/If';
import Then from '../../../../components/Compounds/If/Then';
import IsSimulationEditable from '../../../../components/Simulations/IsSimulationEditable';
import FixedShiftsTable from '../../../../components/Simulations/Workload/FixedShiftsTable';
import { StyledLinkButton } from '../../../../components/StyledComponents/LinkButton';
import ShiftsModal from './Modal';
import useFixedShift from './useFixedShift';

const FixedShift = () => {
	const {
		simulationId,
		initialValues,
		setInitialValues,
		openModal,
		setOpenModal,
		loadingWorkShifts,
		workShifts,
		handleUpdateWorkShifts,
		editShift,
		deleteShift,
		edit,
		setEdit,
	} = useFixedShift();
	return (
		<Box>
			<ShiftsModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				handleUpdateWorkShifts={handleUpdateWorkShifts}
				initialValues={initialValues}
				setEdit={setEdit}
				edit={edit}
				simulationId={simulationId}
			/>

			<Grid container item xs={12} sx={{ alignItems: 'center' }}>
				<Grid item xs={1} md={0.5}>
					<InfoIcon color='primary' sx={{ fontSize: 30 }} />
				</Grid>
				<Grid item xs={11} md={11.5}>
					<Typography
						id='modal-modal-title'
						color='black'
						component='div'
						ml={1}
						sx={{ fontSize: '12px', fontWeight: '500', textAlign: 'left' }}
					>
						Usa este apartado sólo si conoces de antemano los turnos/horarios y
						el número de trabajadores por turno. Si no hay obligación de cubrir
						determinados turnos predefinidos, utiliza la siguiente pestaña de
						&quot;Tareas por horas&ldquo; para que los genere el sistema
						automáticamente.
					</Typography>
				</Grid>
			</Grid>

			<IsSimulationEditable>
				<StyledLinkButton
					startIcon={<AddCircleOutline color='primary' />}
					onClick={() => {
						setInitialValues({});
						setOpenModal(true);
					}}
					sx={{ mb: 3, mt: 4 }}
					label='Añadir turno'
				/>
			</IsSimulationEditable>
			<If conditional={loadingWorkShifts}>
				<Then>
					<CircularProgress />
				</Then>
				<Else>
					<If conditional={workShifts?.length > 0}>
						<Then>
							<Box sx={{ mt: 2 }}>
								<FixedShiftsTable
									rows={workShifts}
									deleteShift={deleteShift}
									editShift={editShift}
								/>
							</Box>
						</Then>
						<Else>
							<Box sx={{ mt: 2, mb: 3 }}>No existen datos para mostrar</Box>
						</Else>
					</If>
				</Else>
			</If>
		</Box>
	);
};

export default FixedShift;
