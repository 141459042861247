import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '../../../hooks/useIsMounted';
import { logoutPers } from '../../../store/actions/auth';
import { setSnackbar } from '../../../store/actions/snackbar';
import { initialState, persistor, store } from '../../../store/storage/store';
import { getUsersByWorkSpaces } from '../ApiServices';

export default function useUserInfo() {
	const dispatch = useDispatch();
	const { auth } = useSelector((state) => state);
	const { isMounted } = useIsMounted();
	const [loadingUsersByWorkSpace, setLoadingUsersByWorkSpace] = useState(false);
	const [users, setUsers] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [reloadUsers, setReloadUsers] = useState(false);

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const parseUsersData = (users) =>
		users.map((row) => ({
			edit: () => {
				setOpenModal(true);
				setSelectedUser(row);
			},
			...row,
		}));

	const getUsersByWorkSpacesList = async () => {
		setLoadingUsersByWorkSpace(true);
		if (auth.workSpace) {
			const { id } = auth.workSpace;
			try {
				const data = await getUsersByWorkSpaces(id);
				if (isMounted) {
					const parsed = parseUsersData(data);
					setUsers(parsed);
					setLoadingUsersByWorkSpace(false);
				}
			} catch (e) {
				setLoadingUsersByWorkSpace(false);
				setSnackBarMessage(e?.response?.data?.message || e?.message || '');
			}
		} else {
			dispatch(logoutPers(persistor, store, initialState));
		}
	};

	const onClose = () => {
		setSelectedUser(null);
		setOpenModal(false);
	};

	useEffect(() => {
		getUsersByWorkSpacesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadUsers]);

	return {
		users,
		loadingUsersByWorkSpace,
		selectedUser,
		openModal,
		onClose,
		setReloadUsers,
	};
}
