import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import moment from 'moment';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import CircleIcon from '@mui/icons-material/Circle';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CircularProgress, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';

import GenericApiException from '../../Services/Api/ApiException';
import {
	createPreviousStudy,
	removePreviousStudy,
	updatePreviousStudy,
} from '../../Services/Api/PreviousStudy';
import {
	duplicateSimulation,
	removeSimulation,
	updateSimulation,
} from '../../Services/Api/Simulation';
import { AppInput, WhiteTitleContainer } from '../../components';
// import DialogVariantComponent from '../../components/Dialog/DialogVariantComponent';
import { RolePermissionSimulation } from '../../components/Allowed/Allowed';
import DialogComponent from '../../components/Dialog/Dialog';
import IsLoading from '../../components/IsLoading/IsLoading';
import Uploader from '../../components/Projects/Uploader';
import { StyledButton } from '../../components/StyledComponents/Button';
import { StyledCard } from '../../components/StyledComponents/Card';
import { ViewContainer } from '../../components/StyledContainers';
import { PreviousStudies } from '../../containers/Project/PreviousStudies/PreviousStudies';
import ActionCreators from '../../store/actions';
import { setSnackbar } from '../../store/actions/snackbar';
import AxiosWrapper from '../../utilities/connection/AxiosWrapper';
import { get_permission, sleep } from '../../utilities/utils';
import './Project.css';

const MessageContainer = styled.div`
	padding: 100px;
`;

const Project = (props) => {
	const dispatch = useDispatch();
	const { getProject, getSimulationsList } = props;

	const { auth } = useSelector((state) => state);

	const {
		projects,
		match,
		requestUploadUrl,
		uploadContent,
		createSimulation,
		history,
		simulations,
	} = props;
	const { currentProject } = projects;
	const {
		params: { id },
	} = match;
	const projectId = id;
	const [files, setFiles] = useState([]);
	const [editUid, setEditUid] = useState(undefined);
	const [uploading, setUploading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [simulationUid, setSimulationUid] = useState('');
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [simulationName, setSimulationName] = useState('');
	const [isPreviousStudy, setIsPreviousStudy] = useState(false);
	const [simulationNameError, setSimulationNameError] = useState(false);
	const [fileError, setFileError] = useState(false);
	const [creatingSimulation, setCreatingSimulation] = useState(false);
	const theme = useTheme();

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	useEffect(() => {
		getSimulationsList(projectId);
		getProject(projectId);
	}, []);

	const handleChangeFiles = (files) => {
		setFiles(files);
		setFileError('');
	};

	const startSimulation = () => {
		if (!simulationName) {
			setSimulationNameError('Nombre requerido');
			return;
		}

		setUploading(true);

		if (files.length === 0) {
			createNewSimulation();
		} else {
			createSimulation(currentProject.uid, { name: simulationName }).then(
				(simulation) => {
					requestUploadUrl({
						filename: files[0].name,
						project_uid: currentProject.uid,
						simulation_uid: simulation,
						mime_type: files[0].type,
					}).then((response) =>
						uploadContent(response.contentUploadUrl, files[0]).then(() => {
							setUploading(false);
							history.push(`${currentProject.uid}/simulations/${simulation}`);
						})
					);
				}
			);
		}
	};

	const createNewSimulation = () => {
		setCreatingSimulation(true);
		const url = `/simulation`;
		const payload = { name: simulationName, project_id: projectId };
		AxiosWrapper.post(url, JSON.stringify(payload))
			.then((respuesta) => {
				history.push(`/simulation/${projectId}/${respuesta.data}/form`);
				setCreatingSimulation(false);
				modalClose();
			})
			.catch(() => {
				setCreatingSimulation(false);
			});
	};

	const modalClose = () => {
		setOpenModal(false);
		setFileError('');
		setSimulationName('');
		setSimulationNameError('');
		setFiles([]);
		setUploading(false);
		setIsPreviousStudy(false);
	};

	const goToPreviousStudy = async () => {
		if (!simulationName) {
			setSimulationNameError('Nombre requerido');
			return;
		}
		setUploading(true);
		try {
			const { data } = await createPreviousStudy(simulationName, projectId);
			history.push(`/previous/${projectId}/${data}`);
		} catch (e) {
			setSnackBarMessage(e.message);
		}
	};

	const closeDeleteModal = () => {
		setOpenDeleteModal(false);
		setSimulationUid('');
		setIsPreviousStudy(false);
	};

	const handleInputChange = (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		setSimulationName(value);
		setSimulationNameError('');
	};

	const LegendComponent = (color, text) => (
		<Grid item xs={2.5} container alignItems='center' spacing={1}>
			<Grid item>
				<CircleIcon sx={{ color: color }} />
			</Grid>
			<Grid item>
				<Typography variant='caption'>{text}</Typography>
			</Grid>
		</Grid>
	);

	const renderSimulations = () => {
		if (simulations.fetching) {
			return (
				<ViewContainer>
					<CircularProgress />
				</ViewContainer>
			);
		}

		if (!simulations.list || simulations.list.length === 0) {
			return (
				<ViewContainer>
					<MessageContainer>
						<Typography variant='h5' color='secondary'>
							No hay simulaciones
						</Typography>
					</MessageContainer>
					<Typography color='secondary'>Crea una nueva</Typography>
				</ViewContainer>
			);
		}

		const duplicateSimulationData = (simulationUid) => {
			const new_simulation_id = uuidv4();
			duplicateSimulation(simulationUid, new_simulation_id)
				.then(() => {
					history.push(
						`/simulation/${currentProject.uid}/${new_simulation_id}/form`
					);
				})
				.catch((e) => {
					var error_message = 'Error desconocido';
					if (e instanceof GenericApiException) {
						error_message = e.message;
					}
					setSnackBarMessage(error_message);
				});
		};

		const parseSimulationList = () => {
			const simulationList = simulations.list.map((simulation) => ({
				id: simulation.id,
				name: simulation.name,
				title: simulation.name,
				subtitle: `(${moment(simulation.started).format(
					'DD/MM/YYYY, HH:mm:ss'
				)})`,
				createdAt: moment(simulation.createdAt).format('DD/MM/YYYY'),
				updatedAt: moment(simulation.updatedAt).format('DD/MM/YYYY'),
				status: simulation.status,
				uid: simulation.uid,
				status_color:
					simulation.status === 'Running'
						? theme.palette.common.orange
						: simulation.status === 'Error'
						? theme.palette.common.red
						: simulation.status === 'Solved'
						? theme.palette.common.green
						: `none`,
				delete: get_permission(auth?.permissions, 'simulation', ['delete']),
				onDelete: () => {
					setSimulationUid(simulation.uid);
					setOpenDeleteModal(true);
					setIsPreviousStudy(false);
				},
				edit: get_permission(auth?.permissions, 'simulation', ['update']),
				onEdit: () => {
					setOpenModal(true);
					setSimulationName(simulation.name);
					setEditUid(simulation.uid);
					setIsPreviousStudy(false);
				},
				double: get_permission(auth?.permissions, 'simulation', ['create']),
				onDouble: () => duplicateSimulationData(simulation.uid),
				onClick: () =>
					simulation.status === 'Stopped'
						? history.push(
								`/simulation/${currentProject.uid}/${simulation.uid}/form`
						  )
						: history.push(
								`${currentProject.uid}/simulations/${simulation.uid}`
						  ),
			}));
			return simulationList;
		};

		return (
			<Box>
				<Grid container spacing={2}>
					{parseSimulationList().map((simulation) => (
						<Grid item xs={3} key={simulation.uid}>
							<StyledCard>{simulation}</StyledCard>
						</Grid>
					))}
				</Grid>
				<Grid container justifyContent='center' spacing={2} mt={4} mb={2}>
					{LegendComponent('common.green', 'Simulación completada')}
					{LegendComponent('common.orange', 'Simulación en progreso')}
					{LegendComponent('common.red', 'Simulación con errores')}
				</Grid>
			</Box>
		);
	};

	const TitleLabel = (label) => (
		<Box
			sx={{
				width: '100%',
				fontSize: '18px',
				fontWeight: '800',
				textAlign: 'left',
				m: 2,
			}}
			color='primary.main'
		>
			{label}
		</Box>
	);

	const acceptButtonText = () =>
		files.length > 0 ? 'Crear y lanzar' : 'Crear';

	const renderModal = () => {
		const editing = editUid !== undefined;

		return (
			<DialogComponent
				open={openModal}
				onClose={() => modalClose()}
				title={editing ? 'Editar nombre' : 'Nueva Simulación'}
				content={
					editing ? (
						<AppInput
							label='Nombre'
							type='text'
							name='simulationName'
							margin='dense'
							value={simulationName}
							autoFocus
							onChange={(event) => {
								setSimulationName(event.target.value);
							}}
							style={{ width: 350, marginBottom: 30 }}
						/>
					) : (
						<Uploader
							error={props.error}
							progress={props.progress}
							simulationName={simulationName}
							handleInputChange={handleInputChange}
							simulationNameError={simulationNameError}
							handleChangeFiles={handleChangeFiles}
							fileError={fileError}
						/>
					)
				}
				onAccept={async () => {
					if (editing) {
						if (isPreviousStudy) {
							await updatePreviousStudy({
								previousStudyId: editUid,
								payload: { name: simulationName },
							});
							getSimulationsList(projectId);
							setIsPreviousStudy(false);
						} else {
							await updateSimulation({
								simulationId: editUid,
								payload: { name: simulationName },
							});
							getSimulationsList(projectId);
						}
						modalClose();
					} else {
						startSimulation();
					}
				}}
				onCancel={modalClose}
				acceptText={editing ? 'Editar' : acceptButtonText()}
				cancelText='Cancelar'
				onAcceptAndContinue={goToPreviousStudy}
				acceptAndContinueText={editing ? '' : 'Estudio previo'}
				isInSimulation={false}
				inline
				cancelButtonFirst={false}
			/>
		);
	};

	return (
		<div>
			<WhiteTitleContainer
				routes={[
					{ name: 'Inicio', href: '/' },
					{ name: 'Licitaciones', href: '/projects' },
					{ name: currentProject ? currentProject.name : '' },
				]}
				title={
					currentProject
						? `${currentProject?.name || ''} ${
								currentProject?.name && simulations?.list
									? `(${simulations?.list?.length})`
									: ''
						  }`
						: ''
				}
				rightSide={
					<RolePermissionSimulation allowed={['create']}>
						<StyledButton
							size='medium'
							variant='outlined'
							sx={{
								color: 'primary.main',
								borderColor: 'primary.main',
								textTransform: 'inherit',
							}}
							onClick={() => {
								setOpenModal(true);
								setEditUid(undefined);
							}}
						>
							<AddIcon />
							Nueva simulación
						</StyledButton>
					</RolePermissionSimulation>
				}
			/>
			<ViewContainer>
				<IsLoading conditional={projects?.fetching || simulations?.fetching}>
					{TitleLabel('Estudios previos')}
					<PreviousStudies
						projectId={projectId}
						setIsPreviousStudy={setIsPreviousStudy}
						setOpenModal={setOpenModal}
						setSimulationName={setSimulationName}
						setEditUid={setEditUid}
						setOpenDeleteModal={setOpenDeleteModal}
						setSimulationUid={setSimulationUid}
						match={match}
						requestUploadUrl={requestUploadUrl}
						uploadContent={uploadContent}
						createSimulation={createSimulation}
					/>
					<Box sx={{ mt: 6 }}>
						{TitleLabel('Simulaciones')}
						{renderSimulations()}
					</Box>
					{renderModal()}
					<DialogComponent
						open={openDeleteModal}
						onClose={closeDeleteModal}
						title={
							isPreviousStudy
								? 'Eliminar estudio previo'
								: 'Eliminar simulación'
						}
						content={`Vas a eliminar ${
							isPreviousStudy ? 'un estudio previo' : 'una simulación'
						}, ¿estás seguro?`}
						acceptText='Sí, eliminar'
						cancelText='Cancelar'
						onAccept={async () => {
							if (isPreviousStudy) {
								await removePreviousStudy(simulationUid);
								setIsPreviousStudy(false);
								getSimulationsList(projectId);
							} else {
								await removeSimulation(simulationUid);
								getSimulationsList(projectId);
							}
							closeDeleteModal();
						}}
						onCancel={closeDeleteModal}
						isInSimulation={false}
					/>
				</IsLoading>
			</ViewContainer>
		</div>
	);
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => {
	ActionCreators.logout = (store, state) =>
		ActionCreators.logoutPers(persistor, store, state);
	return { dispatch, ...bindActionCreators(ActionCreators, dispatch) };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withTheme(Project)));
