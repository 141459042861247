import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Table,
	TableBody,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';

import { StyledBoxContainer } from '../../../../components/StyledComponents/BoxContainer';
import { ContentTableCell } from '../../../../components/StyledComponents/ContentTableCell';
import HeaderTableCell from '../../../../components/StyledComponents/HeaderTableCell';
import { StyledIcon } from '../../../../components/StyledComponents/Icon';

const GreyStyledInfo = (info) => (
	<Typography color='textSecondary'>{`${info} (Desactivado)`}</Typography>
);

const UserInfoTable = ({ rows = [] }) => (
	<StyledBoxContainer>
		<Table aria-label='user_by_workSpace'>
			<TableHead>
				<TableRow>
					<HeaderTableCell>Email usuario</HeaderTableCell>
					<HeaderTableCell>Perfil / rol</HeaderTableCell>
					<HeaderTableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row) => (
					<TableRow key={row?.id}>
						<ContentTableCell>
							{!row?.active ? GreyStyledInfo(row.email) : row?.email || '-'}
						</ContentTableCell>
						<ContentTableCell>{row?.role?.name || '-'}</ContentTableCell>
						<ContentTableCell>
							<StyledIcon
								sx={{ marginLeft: 1 }}
								variant='contained'
								color='primary'
								label='edit'
								onClick={() => row.edit(row.id)}
							>
								<EditIcon />
							</StyledIcon>
						</ContentTableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</StyledBoxContainer>
);

export default UserInfoTable;
