// import ApiException from '../../../Services/Api/ApiException';
import { put } from 'Services/Api/Api';

import ApiException from '../../../../Services/Api/ApiException';
import AxiosWrapper from '../../../../utilities/connection/AxiosWrapper';
// import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import {
	getPreviousStudyParametersByIdUrl,
	getSolutionDefaultParametersByIdUrl,
	getPreviousStudyParametersByIdUrlAsync,
} from '../../../../utilities/routes';

export async function getSolutionParametersById({ previousStudyId }) {
	const url = getPreviousStudyParametersByIdUrl(previousStudyId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getSolutionDefaultParametersById({ simulationId }) {
	const url = getSolutionDefaultParametersByIdUrl(simulationId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function updateSolutionParameters({ previousStudyId, payload }) {
	const url = getPreviousStudyParametersByIdUrl(previousStudyId);
	try {
		await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export function InternalServerErrorException() {
	this.message = 'Ha ocurrido un error desconocido en el servidor';
	this.name = 'InternalServerErrorException';
	this.severity = 'error';
}

export function DataInputErrorException() {
	this.message = 'Error al procesar los datos introducidos';
	this.name = 'DataInputErrorException';
	this.severity = 'error';
}

export async function updateSolutionParametersAsync({
	previousStudyId,
	payload,
}) {
	const url = getPreviousStudyParametersByIdUrlAsync(previousStudyId);
	try {
		await put(url, payload);
	} catch (e) {
		switch (e.response.status_code) {
			case 400:
				throw new DataInputErrorException();
			default:
				throw new InternalServerErrorException();
		}
	}
}
