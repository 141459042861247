import styled from 'styled-components';

/* eslint-disable */

export const ViewContainer = styled.div`
	width: 100%;
	max-width: 1920px;
	margin: auto;
	max-height: 80%;
	padding: 15px;
`;

export const AppContainer = styled.div`
	text-align: center;
	height: 100%;
	overflow: auto;
	background: linear-gradient(
		180deg,
		#fbf9fd 0%,
		#fbf9fe 20%,
		#fbfafe 42.19%,
		#f3f5fe 51.56%,
		#eaf1fe 100%
	);
`;

export const WhiteContainer = styled.div`
	background-color: white;
`;

export const TitleContainer = styled.div`
	text-align: left;
	margin-top: 18px;
	margin-bottom: -10px;
`;

export const RowFlex = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const ColumnFlex = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;
