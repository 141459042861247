import ApiException from '../../../Services/Api/ApiException';
import useGetResponseFromApi from '../../../hooks/useGetResponseFromApi';
import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import {
	createAgreementUrl,
	getAgreementByIdUrl,
	getAgreementDownloadUrlByIdUrl,
	getAgreementUpdateUrlByIdUrl,
	getAgreementUrl,
	getCategoriesUrl,
	getCategoryUrl,
	getProjectsUrl,
} from '../../../utilities/routes';

export const getAgreements = ({ agreementId }) =>
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useGetResponseFromApi({
		url: getAgreementUrl(agreementId),
	});

export const getCategories = ({ agreementId }) =>
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useGetResponseFromApi({
		url: getCategoriesUrl(agreementId),
	});

export const getCategory = ({ agreementId, categoryId }) =>
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useGetResponseFromApi({
		url: getCategoryUrl(agreementId, categoryId),
	});

export async function createAgreement({ payload }) {
	const url = createAgreementUrl();
	try {
		await AxiosWrapper.post(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getAgreementById({ agreementId }) {
	const url = getAgreementByIdUrl(agreementId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getAgreementUpdateUrlById({ agreementId, payload }) {
	const url = getAgreementUpdateUrlByIdUrl(agreementId);
	try {
		return await AxiosWrapper.post(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getAgreementDownloadUrlById({ agreementId }) {
	const url = getAgreementDownloadUrlByIdUrl(agreementId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function createProject({ payload }) {
	const url = getProjectsUrl();
	try {
		const { data } = await AxiosWrapper.post(url, JSON.stringify(payload));
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function deleteProjectById(projectId) {
	const url = `${getProjectsUrl()}/${projectId}`;
	try {
		const { data } = await AxiosWrapper.delete(url);
		return data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
