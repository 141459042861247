import React, { Component } from 'react';

import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import LocationCity from '@material-ui/icons/LocationCity';

import AppDateInput from '../../components/AppDateInput/AppDateInput';
import AppInput from '../../components/AppInput/AppInput';
import AppModal from '../../components/AppModal/AppModal';
import AppSimpleList from '../../components/AppSimpleList/AppSimpleList';
import NewProjectCalendar from './NewProjectCalendar';
import NewProjectLocation from './NewProjectLocation';

const OuterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
`;
const ViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
`;
const RowContainer = styled.div`
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
`;

const ElementContainer = styled.span`
	margin-right: 10px;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
`;

class NewProjectGeneral extends Component {
	state = {
		projectName: '',
		projectDuration: '',
		projectLocation: '',
		listLocations: [
			{ text: 'CEIP Guadalajara' },
			{ text: 'CEIP Fernandez' },
			{ text: 'Colegio del Carmen' },
			{ text: 'Colegio Fuente' },
			{ text: 'Colegio antemano' },
		],
		listCalendars: [
			{ text: 'Madrid' },
			{ text: 'Guadalajara' },
			{ text: 'Toledo' },
			{ text: 'Madagascar' },
		],
		modalStatus: false,
		formData: {},
	};

	handleInputChange = (event) => {
		const { emitData } = this.props;
		const { formData } = this.state;
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;

		this.setState(
			{
				[name]: value,
			},
			() => emitData({ ...formData, [name]: value })
		);
	};

	modalOpen = (type) => {
		this.setState({ [`${type}ModalStatus`]: true });
	};

	modalClose = (type) => {
		this.setState({ [`${type}ModalStatus`]: false });
	};

	render() {
		const {
			projectName,
			projectDuration,
			startDate,
			listLocations,
			calendarModalStatus,
			locationModalStatus,
			listCalendars,
		} = this.state;

		return (
			<OuterContainer>
				<ViewContainer>
					<RowContainer>
						<ElementContainer>
							<AppInput
								label='Nombre de la licitación'
								type='text'
								name='projectName'
								value={projectName}
								onChange={(event) => this.handleInputChange(event)}
							/>
						</ElementContainer>
						<TextContainer>¿Reutilizar datos de otra licitación?</TextContainer>
					</RowContainer>
					<RowContainer>
						<ElementContainer>
							<AppDateInput
								label='Fecha de inicio'
								type='date'
								name='startDate'
								value={startDate}
								onChange={(event) => this.handleInputChange(event)}
							/>
						</ElementContainer>
						<ElementContainer>
							<AppInput
								label='Duración del servicio'
								type='text'
								name='projectDuration'
								value={projectDuration}
								onChange={(event) => this.handleInputChange(event)}
							/>
						</ElementContainer>
					</RowContainer>
					<RowContainer>
						<ElementContainer>
							Centros o ubicaciones
							<IconButton
								edge='end'
								aria-label='Añadir centro/ubicación'
								onClick={() => this.modalOpen('location')}
							>
								<AddIcon />
							</IconButton>
							<AppSimpleList
								items={listLocations}
								leftIcon={<LocationCity />}
								onDelete={(idx) => {
									listLocations.splice(idx, 1);
									this.setState({ listLocations });
								}}
							/>
						</ElementContainer>
						<ElementContainer>
							Calendario laboral/festivos
							<IconButton
								edge='end'
								aria-label='Añadir calendario laboral'
								onClick={() => this.modalOpen('calendar')}
							>
								<AddIcon />
							</IconButton>
							<AppSimpleList
								items={listCalendars}
								onDelete={(idx) => {
									listCalendars.splice(idx, 1);
									this.setState({ listLocations });
								}}
							/>
						</ElementContainer>
					</RowContainer>
					<AppModal
						open={calendarModalStatus}
						handleClose={() => this.modalClose('calendar')}
						modalTitle='Calendarios'
						modalText='Añadir un calendario laboral'
						content={<NewProjectCalendar />}
					/>
					<AppModal
						open={locationModalStatus}
						handleClose={() => this.modalClose('location')}
						modalTitle='Centros'
						modalText='Añadir centro/ubicación'
						content={<NewProjectLocation listLocations={listLocations} />}
					/>
				</ViewContainer>
			</OuterContainer>
		);
	}
}

export default NewProjectGeneral;
