import React from 'react';

import { v4 as uuidv4 } from 'uuid';

import {
	Box,
	FormControl,
	Grid,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';

import { AppInputMUI } from '../../../../../components';
import {
	selectItemsTaskFrequencyOption1,
	selectItemsTaskFrequencyOption2,
	selectItemsTaskFrequencyOption3,
} from '../../../../../utilities/const';

const SelectOptions = ({ value, setValue, items, ...restProps }) => (
	/* eslint-disable react/jsx-props-no-spreading */
	<Box {...restProps}>
		<FormControl fullWidth>
			<Select
				labelId='demo-simple-select-label'
				id='demo-simple-select'
				value={value}
				label=''
				size='small'
				inputProps={{
					id: 'select-id',
					'data-testid': 'FrequencySelect',
				}}
				onChange={(val) => setValue(val.target.value)}
			>
				{items.map((item) => (
					<MenuItem name key={uuidv4()} value={`${item.value}`}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	</Box>
);

const optionSelected = (value) => {
	switch (value) {
		case 'day':
			return 'Días';
		case 'week':
			return 'Semanas';
		case 'month':
			return 'Meses';
		default:
			return 'Días';
	}
};

const TypographyLabel = ({ label, ...rest }) => (
	<Typography
		sx={{ fontWeight: '800', fontSize: '12px' }}
		id='modal-modal-title'
		variant='subtitle1'
		component='div'
		{...rest}
	>
		{label}
	</Typography>
);

const TaskFrequency = ({
	taskInterval,
	value,
	setValue,
	numberInterval,
	setNumberInterval,
}) => {
	switch (taskInterval) {
		case 'fixed_period':
			return (
				<Box sx={{ width: '20%' }}>
					<TypographyLabel label='Frecuencia' />
					<SelectOptions
						value={value}
						setValue={setValue}
						items={selectItemsTaskFrequencyOption1}
					/>
				</Box>
			);
		case 'repeated_x_times_over_period':
			return (
				<Box sx={{ width: '100%' }}>
					<TypographyLabel label='Nº veces' />
					<Grid container spacing={4}>
						<Grid container item md={3} xs={12}>
							<AppInputMUI
								required
								type='number'
								name='numberInterval'
								value={numberInterval}
								onChange={(event) => setNumberInterval(event.target.value)}
								size='small'
							/>
						</Grid>
						<Grid container item md={8} xs={12}>
							<Box sx={{ display: 'flex', width: '100%' }}>
								<SelectOptions
									sx={{ display: 'block', width: '100%' }}
									value={value}
									setValue={setValue}
									items={selectItemsTaskFrequencyOption2}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>
			);
		case 'repeated_over_x_period':
			return (
				<Grid container spacing={4}>
					<Grid container item md={3} xs={12}>
						<TypographyLabel label='Cada' />
						<Box sx={{ display: 'flex' }}>
							<AppInputMUI
								required
								type='number'
								name='numberInterval'
								value={numberInterval}
								onChange={(event) => setNumberInterval(event.target.value)}
								size='small'
							/>
						</Box>
					</Grid>
					<Grid container item md={8} xs={12}>
						<TypographyLabel
							width='100%'
							color='primary'
							label={`${numberInterval || ''} ${optionSelected(value)}`}
						/>
						<Box sx={{ display: 'flex', width: '100%' }}>
							<SelectOptions
								value={value}
								setValue={setValue}
								sx={{ display: 'block', width: '100%' }}
								items={selectItemsTaskFrequencyOption3}
							/>
						</Box>
					</Grid>
				</Grid>
			);
		default:
			return <TypographyLabel label='Frecuencia' />;
	}
};
export default TaskFrequency;
