export default function agreements(state = null, agreement) {
	const {
		type,
		fetching,
		agreements,
		agreementUploadUrl,
		uploading,
		progress,
	} = agreement;
	switch (type) {
		case 'SET_AGREEMENTS_LIST':
			return { ...state, list: agreements };
		case 'RESET_AGREEMENTS_LIST':
			return { fetching: true, list: [] };
		case 'SET_FETCHING_STATE_PROJECTS':
			return { ...state, fetching };
		case 'SET_FETCHING_STATE_AGREEMENTS':
			return { ...state, fetching };
		case 'SET_AGREEMENT_UPLOAD_URL':
			return { ...state, agreementUploadUrl, uploading, progress };
		default:
			return state;
	}
}
