import React from 'react';

import { Box, Divider, Typography } from '@mui/material';

const DialogTitle = ({ title }) => (
	<Box>
		<Typography
			align='center'
			sx={{
				color: 'black',
				fontSize: '27px',
				fontWeight: 800,
			}}
			id='modal-modal-title'
			component='div'
		>
			{title}
		</Typography>
		<Divider
			sx={{
				borderColor: 'primary.main',
				backgroundColor: 'primary.main',
				mb: 2,
				width: '100%',
			}}
		/>
	</Box>
);
export default DialogTitle;
