import React from 'react';

import { Typography } from '@mui/material';

const Title = (text) => (
	<Typography
		variant='h5'
		width='100%'
		mt={4}
		gutterBottom
		component='div'
		align='left'
		color='text.primary'
	>
		{text}
	</Typography>
);
export default Title;
