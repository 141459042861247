import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

const HeaderTableCell = styled(TableCell)((props) => ({
	backgroundColor: props.theme.palette.common.white,
	color: props.theme.palette.common.transition6,
	fontWeight: 700,
	fontSize: '16px',
}));
export default HeaderTableCell;
