import React, { useState } from 'react';

import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import AddIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Grid, TextField, Typography } from '@mui/material';

import Else from '../../../components/Compounds/If/Else';
import If from '../../../components/Compounds/If/If';
import Then from '../../../components/Compounds/If/Then';
import ShiftsTable from '../../../components/Simulations/Workload/ShiftsTable';
import { StyledIconButton } from '../../../components/StyledComponents/IconButton';
import { isYearGraderOrEqual1000 } from '../../../utilities/utils';

const DateRange = ({
	setDateRangeList,
	listRange = [],
	maxDate = null,
	minDate = null,
}) => {
	const [dateList, setDateList] = useState(listRange);
	const [dateRange, setDateRange] = useState([null, null]);
	const [dateError, setDateError] = useState('');

	const areDatesValid = () => {
		if (
			isYearGraderOrEqual1000(dateRange[0]) &&
			isYearGraderOrEqual1000(dateRange[1])
		) {
			return true;
		}
		return false;
	};

	const updateDateList = () => {
		if (dateRange[0] && dateRange[1]) {
			if (areDatesValid()) {
				const tempDateList = [...dateList];
				tempDateList.push({
					id: uuidv4(),
					date_init: moment(dateRange[0]).format('YYYY-MM-DD'),
					date_end: moment(dateRange[1]).format('YYYY-MM-DD'),
				});
				setDateRange([null, null]);
				setDateList(tempDateList);
				setDateRangeList(tempDateList);
				setDateError('');
			} else {
				setDateError('El año debe tener 4 dígitos');
			}
		} else {
			setDateError('Seleccione fechas válidas');
		}
	};

	const disabledDates = (date) => {
		const dates = [];
		dateList.forEach((sh) => {
			let start = new Date(sh.date_init);
			const end = new Date(sh.date_end);
			while (start <= end) {
				dates.push(moment(start));
				const newDate = start.setDate(start.getDate() + 1);
				start = new Date(newDate);
			}
		});
		const dateComparison = dates.filter(
			(d) =>
				moment(d).format('DD-MM-YYYY') === moment(date).format('DD-MM-YYYY')
		);
		return dateComparison.length > 0;
	};

	const deleteShiftsRow = (id) => {
		const temp = [...dateList];
		temp.splice(id, 1);
		setDateList(temp);
		setDateRangeList(temp);
	};

	return (
		<Grid container item xs={12}>
			<Grid container item xs={12} spacing={1}>
				<Grid item xs={10.5}>
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
						<DateRangePicker
							allowSameDateSelection
							defaultCalendarMonth={new Date(minDate)}
							calendars={1}
							startText='Desde'
							endText='Hasta'
							inputFormat='dd/MM/yyyy'
							value={dateRange}
							maxDate={maxDate}
							minDate={minDate}
							shouldDisableDate={disabledDates}
							onChange={(newValue) => {
								setDateRange(newValue);
							}}
							renderInput={(startProps, endProps) => (
								<Box sx={{ display: 'flex' }}>
									{/* eslint-disable react/jsx-props-no-spreading */}
									<TextField size='small' {...startProps} />
									<Box sx={{ mx: 2, m: 0.5 }}> -</Box>
									<TextField size='small' {...endProps} />
								</Box>
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={1}>
					<StyledIconButton
						onClick={updateDateList}
						variant='contained'
						color='primary'
						aria-label='add date'
					>
						<AddIcon />
					</StyledIconButton>
				</Grid>
			</Grid>
			<If conditional={dateError}>
				<Then>
					<Typography align='left' color='error'>
						{dateError}
					</Typography>
				</Then>
			</If>
			<Box sx={{ width: '100%', mt: 2 }}>
				<If conditional={dateList && dateList?.length > 0}>
					<Then>
						<ShiftsTable rows={dateList} deleteShiftsRow={deleteShiftsRow} />
					</Then>
					<Else>No existen datos para mostrar</Else>
				</If>
			</Box>
		</Grid>
	);
};
export default DateRange;
