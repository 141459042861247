import { setToken } from '../store/actions/auth';
import { setError, resetError } from '../store/actions/error';

// Based on: https://github.com/jlebensold/peckish/blob/master/app/lib/api.js
class Api {
	token = null;

	apiHost = null;

	apiKey = null;

	static setApiHostConfig(host) {
		const ENV = process.env;

		switch (host) {
			case 'user':
				this.apiHost = ENV.REACT_APP_baseUrl_user;
				this.apiKey = ENV.REACT_APP_x_api_key_user;
				break;
			case 'config':
				this.apiHost = ENV.REACT_APP_baseUrl_config;
				this.apiKey = ENV.REACT_APP_x_api_key_config;
				break;
			default:
				console.error('MISSING HOST (KEYWORD) PARAMETER IN ACTION CREATOR');
				break;
		}
	}

	static get(route, token = null, dispatch = () => null, host = null) {
		Api.setApiHostConfig(host);
		return this.xhr(route, null, 'GET', token, dispatch);
	}

	static post(route, params, token = null, dispatch = () => null, host = null) {
		Api.setApiHostConfig(host);
		return this.xhr(route, params, 'POST', token, dispatch);
	}

	static put(route, params, token = null, dispatch = () => null, host = null) {
		Api.setApiHostConfig(host);
		return this.xhr(route, params, 'PUT', token, dispatch);
	}

	static delete(route, token = null, dispatch = () => null, host = null) {
		Api.setApiHostConfig(host);
		return this.xhr(route, null, 'DELETE', token, dispatch);
	}

	static xhr(route, params, verb, token, dispatch) {
		const url = `${this.apiHost}${route}`;
		const options = Object.assign(
			{ method: verb },
			params ? { body: JSON.stringify(params) } : null
		);
		if (token) {
			this.token = token;
		}
		options.headers = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'X-Api-Key': this.apiKey,
			Authorization: this.token,
		};

		dispatch(resetError());

		return fetch(url, options).then((response) => {
			const successCodes = [200, 201, 204];

			// If the response doesn't have a success status code, dispatch a setError
			// action with the contents of the error response and reject the promise
			if (response.status === 401) {
				dispatch(setToken(null));
				return Promise.reject(response);
			}

			if (!successCodes.includes(response.status)) {
				response.json().then((json) => {
					dispatch(setError(json));
				});
				return Promise.reject(response);
			}
			// Else, return the successful promise
			if (response.status === 204) {
				return response.text();
			}
			return response.json();
		});
	}
}

export default Api;
