import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getPositionsByCenter } from '../../../Services/Api/Simulation';
import { showSnackbar } from '../../../store/actions/snackbar';

const useGetPositionsByCenter = ({ center, refresh = true, simulationId }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	const getPositionsByCenterId = async () => {
		setLoading(true);
		try {
			const response = await getPositionsByCenter({
				simulationId,
				center,
			});
			setData(response);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			dispatch(
				showSnackbar({
					severity: 'error',
					message: e?.message || 'Error desconocido en el servidor',
				})
			);
		}
	};

	useEffect(() => {
		if (center) {
			getPositionsByCenterId();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refresh, center]);
	return { loading, data };
};

export default useGetPositionsByCenter;
