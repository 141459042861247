import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExitToApp from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import Person from '@mui/icons-material/Person';
import { Avatar, CssBaseline } from '@mui/material';
import { Box } from '@mui/material';
import { Divider } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Menu } from '@mui/material';
import { Toolbar } from '@mui/material';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import AppBar from '@material-ui/core/AppBar';

import bannerBackground from '../../assets/imgs/banner-background.svg';
import logo from '../../assets/imgs/logo.png';
import userAvatar from '../../assets/imgs/user-avatar.png';
import { getTokenFromLocalStorage } from '../../utilities/utils';
import { WorkSpace } from './WorkSpace';

const LogoContainer = styled(Box)`
	flex-grow: 0.8;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const LinksContainer = styled(Box)`
	flex-grow: 0.2;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

const StyledMenu = styled(Menu)`
	margin-top: 35px;
	padding: 20px;
`;

const StyledExit = styled(ExitToApp)`
	margin-right: 6px;
`;

const StyledPerson = styled(Person)`
	margin-right: 6px;
`;

const AppBarContainer = styled(Box)`
	flex-grow: 1;
`;

const StyledToolbar = styled(Toolbar)`
	width: 100%;
	margin: auto;
	min-width: 850px;
`;

const StyledAvatar = styled(Avatar)`
	color: white !important;
	cursor: pointer;
	background-color: inherit !important;
	width: 40px !important;
`;

const drawerWidth = 240;

const MenuAppBar = styled(AppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	backgroundImage: `linear-gradient(
		to right,
		rgba(135, 16, 193, 1) 15%,
		rgba(102, 49, 213, 1) 30%,
		rgba(86, 66, 223, 1) 54%,
		rgba(68, 82, 231, 1) 71%,
		rgba(52, 98, 242, 1) 87%,
		rgba(36, 114, 252, 1) 100%
	),
	url(${bannerBackground})`,
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

class NavBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			anchorEl: null,
		};
	}

	handleToggle = () => {
		const { open } = this.state;
		this.setState({ open: !open });
	};

	handleMenu = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	renderUserMenu = () => {
		const { appLogout } = this.props;
		const { anchorEl } = this.state;

		const open = Boolean(anchorEl);
		return (
			<StyledMenu
				id='menu-appbar'
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={open}
				onClose={this.handleClose}
			>
				<MenuItem
					onClick={() => {
						this.setState({ redirectTo: '/profile' });
						this.handleClose();
					}}
				>
					<StyledPerson fontSize='small' />
					<Typography>Mi perfil</Typography>
				</MenuItem>
				<Divider sx={{ mt: '0px!important', mb: '0px!important' }} />
				<MenuItem
					onClick={() => {
						this.setState({ redirectTo: '/admin' });
						this.handleClose();
					}}
				>
					<AdminPanelSettingsIcon fontSize='small' sx={{ mr: '6px' }} />
					<Typography>Administración</Typography>
				</MenuItem>
				<Divider sx={{ mt: '0px!important', mb: '0px!important' }} />
				<MenuItem
					onClick={() => {
						appLogout();
						this.handleClose();
					}}
				>
					<StyledExit fontSize='small' />
					<Typography>Salir</Typography>
				</MenuItem>
			</StyledMenu>
		);
	};

	isLogged = () => {
		const token = getTokenFromLocalStorage();
		return token ? true : false;
	};

	render() {
		const { redirectTo } = this.state;
		const { location } = this.props;

		if (redirectTo) {
			this.handleClose();
			delete this.state.redirectTo; // eslint-disable-line
			return <Redirect push to={redirectTo} />;
		}

		return (
			<AppBarContainer
				sx={{
					display:
						this.isLogged() || location.pathname === '/notfound'
							? 'inline'
							: 'none',
				}}
			>
				<CssBaseline />
				<MenuAppBar position='fixed' open={this.props.openDrawer}>
					<StyledToolbar>
						<IconButton
							aria-label='open drawer'
							onClick={(prev) => this.props.handleDrawerOpenOrClose(!prev)}
							edge='start'
							sx={{
								color: 'white',
								marginRight: 5,
								...(this.props.openDrawer && { display: 'none' }),
							}}
						>
							<MenuIcon
								sx={{
									display:
										location.pathname === '/notfound' ? 'none' : 'inline',
								}}
							/>
						</IconButton>

						<LogoContainer>
							<Box sx={{ cursor: 'pointer' }}>
								<img
									src={logo}
									alt='logo'
									width='120'
									onClick={() => this.setState({ redirectTo: '/' })}
								/>
							</Box>
						</LogoContainer>
						{this.isLogged() ? (
							<LinksContainer sx={{ color: 'white' }}>
								<WorkSpace />
								<StyledAvatar onClick={this.handleMenu}>
									<img src={userAvatar} alt='userAvatar' width='40' />
								</StyledAvatar>
								{this.renderUserMenu()}
							</LinksContainer>
						) : null}
					</StyledToolbar>
				</MenuAppBar>
			</AppBarContainer>
		);
	}
}

export default withRouter(NavBar);
