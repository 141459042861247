import React from 'react';
import { Controller } from 'react-hook-form';

import esLocale from 'date-fns/locale/es';

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Grid, Typography } from '@mui/material';

import If from '../Compounds/If/If';
import Then from '../Compounds/If/Then';
import { FormYearPicker } from '../Form/YearPicker';

export const YearDateRange = ({
	names,
	control,
	label,
	labels = ['Desde', 'Hasta'],
	rules = [],
	rangeRule = {},
	componentProps = {},
	inputProps = {},
}) => (
	<Grid item xs={12} container spacing={2} sx={{ alignItems: 'center' }}>
		<Grid item xs={3}>
			{label || null}
		</Grid>
		<Grid item xs={9}>
			<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
				<Grid container spacing={2} sx={{ alignItems: 'center' }}>
					<Grid item xs={5} sx={{ paddingLeft: '0px!important' }}>
						<FormYearPicker
							name={names[0]}
							control={control}
							label={labels[0]}
							componentProps={{ ...componentProps, placeholder: 'aaaa' }}
							inputProps={{ placeholder: 'aaaa', ...inputProps[0] }}
							rules={{ ...rules[0] }}
						/>
					</Grid>
					<Grid item xs={1} sx={{ paddingLeft: '0px!important' }}>
						-
					</Grid>
					<Grid item xs={5} sx={{ paddingLeft: '0px!important' }}>
						<FormYearPicker
							name={names[1]}
							control={control}
							label={labels[1]}
							componentProps={{ ...componentProps }}
							rules={{ ...rules[1] }}
							inputProps={{ placeholder: 'aaaa', ...inputProps[1] }}
						/>
					</Grid>
				</Grid>
			</LocalizationProvider>
		</Grid>
		<Grid container spacing={2}>
			<Grid item xs={3} />
			<Grid item xs={9}>
				<If conditional={rangeRule}>
					<Then>
						<Controller
							name='yearRangeValidation'
							control={control}
							rules={rangeRule}
							render={({ fieldState: { error } }) => (
								<Typography variant='caption' color='error'>
									{error?.message}
								</Typography>
							)}
						/>
					</Then>
				</If>
			</Grid>
		</Grid>
	</Grid>
);
