import React from 'react';

import { Box } from '@mui/material';

import { WhiteTitleContainer } from '../../components';
import AgreementForm from '../../containers/Agreements/AgreementForm';
import UploadAgreementButton from './UploadDownloadButton/UploadAgreementButton';

const CollectiveAgreementForm = ({ match }) => (
	<Box>
		<WhiteTitleContainer
			routes={[
				{ name: 'Inicio', href: '/' },
				{ name: 'Convenios', href: '/agreements' },
			]}
			title=''
			rightSide={
				match.params.agreementId && match.params.agreementId !== ' ' ? (
					<UploadAgreementButton agreementId={match.params.agreementId} />
				) : null
			}
		/>
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: 'background.paper',
				display: 'flex',
				width: '90%',
				m: 'auto',
				mt: '10px',
				p: 2,
			}}
		>
			<Box
				sx={{
					display: 'flex',
					width: '12%',
				}}
			/>
			<Box
				sx={{
					display: 'flex',
					width: '76%',
				}}
			>
				<AgreementForm agreementId={match.params.agreementId} />
			</Box>
		</Box>
	</Box>
);

export default CollectiveAgreementForm;
