import React from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import { Box, Card, CardContent, Grid } from '@mui/material';

import { formatNumber } from '../../utilities/utils';
import LabelInfoBox from './LabelInfoBox';
import Title from './Title';

const AgreementInfo = ({ info }) => {
	const maxSeniorityPercent = (value) =>
		value ? `(máximo ${value}% del SB)` : '';

	const categoriesWithSeniorityBonus = () => {
		let temp = false;
		info.categories.forEach((category) => {
			if (category.seniority_bonus !== null) {
				temp = true;
			}
		});
		return temp;
	};

	return (
		<>
			{Title(`Vigencia: ${info?.start_year}-${info?.end_year}`)}
			{Title('Jornadas y descansos')}
			<Box display='flex' mt={2}>
				<Grid container spacing={2}>
					<Grid item md={4} xs={12}>
						<Card>
							<CardContent>
								<LabelInfoBox
									label='Máximo de horas por día:'
									infoToShow={info.max_hours_per_day}
								/>
								<LabelInfoBox
									label='Máximo de horas por semana:'
									infoToShow={info.max_hours_per_week}
								/>
								<LabelInfoBox
									label='Máximo de horas por mes:'
									infoToShow={info.max_hours_per_month}
								/>
								<LabelInfoBox
									label='Máximo de horas por año:'
									infoToShow={info.max_hours_per_year}
								/>
							</CardContent>
						</Card>
					</Grid>
					<Grid item md={4} xs={12}>
						<Card sx={{ height: '100%' }}>
							<CardContent>
								<LabelInfoBox
									label='Máximo de tramos planificados en un día:'
									infoToShow={info.max_sections_planned_day}
								/>
								<LabelInfoBox
									label='Horas de descanso entre turno y turno:'
									infoToShow={info.rest_hours_between_shifts}
								/>
								<LabelInfoBox
									label='Días de descanso consecutivo:'
									infoToShow={info.consecutive_rest_days}
								/>
								<LabelInfoBox
									label='Asuntos propios/libre disposición:'
									infoToShow={info?.free_day_available || '-'}
								/>
							</CardContent>
						</Card>
					</Grid>
					<Grid item md={4} xs={12}>
						<Card sx={{ height: '100%' }}>
							<CardContent>
								<LabelInfoBox
									label='Días de vacaciones:'
									infoToShow={info.holiday_days}
								/>
								<LabelInfoBox
									label='Intervalo:'
									infoToShow={`${info.holidays_rules_from} - ${info.holidays_rules_until}`}
								/>
								<LabelInfoBox
									label='Tipo:'
									infoToShow={info.holiday_days_types}
								/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
			<Grid container columnSpacing={{ xs: 1, sm: 5, md: 10, lg: 20 }}>
				<Grid item md={6} xs={12}>
					{Title('Antigüedad')}
					<Box mt={2}>
						<Card>
							<CardContent>
								{info.seniority_bonus.length > 0 ? (
									info.seniority_bonus.map((bonus) =>
										bonus.fixed_annual ? (
											<Box key={bonus.id} display='flex' textAlign='left'>
												{`${bonus?.fixed_annual}`}
												<EuroIcon sx={{ mr: 0.5, ml: 0.0, width: '14px' }} />
												{` cada ${formatNumber(
													bonus?.period_bonus
												)} años ${maxSeniorityPercent(bonus?.max_seniority)}`}
											</Box>
										) : (
											<Box key={bonus.id} textAlign='left'>
												{`${formatNumber(
													bonus?.percent_over_salary
												)}% del salario base cada ${
													bonus?.period_bonus
												} años ${maxSeniorityPercent(bonus?.max_seniority)}`}
											</Box>
										)
									)
								) : (
									<Box textAlign='left'>
										{categoriesWithSeniorityBonus()
											? 'Cantidad fija por categoría'
											: '-'}
									</Box>
								)}
							</CardContent>
						</Card>
					</Box>
				</Grid>
				<Grid item md={6} xs={12}>
					{Title('Incremento salarial anual')}
					<Box mt={2}>
						<Card>
							<CardContent>
								{info?.annual_increment?.length > 1 ? (
									info.annual_increment
										.sort((a, b) => a.year - b.year)
										.map((increment) => (
											<Box textAlign='left' key={increment.id}>
												{`${increment.year} = ${formatNumber(
													increment.increment
												)}%`}
											</Box>
										))
								) : (
									<Box textAlign='left'>
										{info?.annual_increment?.length > 0
											? `${formatNumber(info?.annual_increment[0]?.increment)}%`
											: '-'}
									</Box>
								)}
							</CardContent>
						</Card>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};
export default AgreementInfo;
