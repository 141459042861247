import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
	getSolutionEmployees,
	updateEmployeesSS,
} from '../../../Services/Api/Simulation';
import { setSnackbar } from '../../../store/actions/snackbar';
import { sleep } from '../../../utilities/utils';
import { solutionUploadStatusParameters } from '../ApiServices';

const parseEmployeesToSubmit = (selected, employees) => {
	const selectedEmployees = selected.map((select) => ({
		id: select.id,
		social_security_activate: false,
	}));

	const items = employees.filter((tag) => !selected.includes(tag));

	const notSelectedEmployees = items.map((item) => ({
		id: item.id,
		social_security_activate: true,
	}));

	let employeesToSubmit = [];
	employeesToSubmit = employeesToSubmit.concat(
		selectedEmployees,
		notSelectedEmployees
	);

	return employeesToSubmit;
};

export default function useSocialSecurityCost({ params }) {
	const dispatch = useDispatch();
	const { simulationId } = params;

	const [employees, setEmployees] = useState([]);
	const [loadingEmployees, setLoadingEmployees] = useState(false);
	const [selected, setSelected] = useState([]);
	const [reload, setReload] = useState(false);

	const dispatchErrorMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const handleClick = (event, name) => {
		let newSelected = [];
		const selectedIndex = selected.indexOf(name);

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const getSolutionEmployeesList = async (simulationId) => {
		setLoadingEmployees(true);
		try {
			const response = await getSolutionEmployees(simulationId);

			setEmployees(response);
			const selectedEmployees = response.filter(
				(employee) => !employee.social_security_activate
			);
			setSelected(selectedEmployees);
			setLoadingEmployees(false);
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(message);
			setLoadingEmployees(false);
		}
	};

	useEffect(() => {
		if (simulationId) {
			getSolutionEmployeesList(simulationId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reload]);

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = employees.map((n) => n);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const uploadParametersStatus = async () => {
		let response = '';
		let count = 0;
		do {
			count += 1;
			try {
				response = await solutionUploadStatusParameters(simulationId);
			} catch (e) {
				dispatchErrorMessage(e?.response?.data?.message || e?.message);
			}

			await sleep(1000);
			if (response?.data?.status === 'ERROR') {
				count = 300;
				dispatchErrorMessage(response.data.info_message);
				return;
			}
			if (response?.data?.status === 'PENDING' && count >= 300) {
				dispatchErrorMessage('El servidor está tardando mucho en responder');
				return;
			}
			if (response?.data?.status !== 'PENDING' || count >= 300) {
				return;
			}
		} while (response?.data?.status === 'PENDING' && count <= 300);
	};

	const putEmployeesList = async () => {
		setLoadingEmployees(true);
		try {
			const employeesToSubmit = parseEmployeesToSubmit(selected, employees);

			await updateEmployeesSS({
				simulationId,
				payload: { employees: employeesToSubmit },
			});

			await uploadParametersStatus();
			setLoadingEmployees(false);

			setReload((prev) => !prev);
			// window.location.reload(false);
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(message);
			setLoadingEmployees(false);
		}
	};

	return {
		employees,
		loadingEmployees,
		selected,
		handleSelectAllClick,
		isSelected,
		handleClick,
		putEmployeesList,
	};
}
