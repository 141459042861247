import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { v4 as uuidv4 } from 'uuid';

import { plusByDaysOfTheWeek } from '../agreementUtils';

const plusByDays = [
	{
		id: 1,
		label: 'Festivos',
		inputName: 'plusByDayHolidays',
	},
	{
		id: 2,
		label: 'Domingos',
		inputName: 'plusByDaySunday',
	},
	{
		id: 3,
		label: 'Sábados',
		inputName: 'plusByDaySaturday',
	},
];

const parseCategoryToSubmit = (data) => {
	if (
		!(
			data.plusNocturnalStartTime &&
			data.plusNocturnalEndTime &&
			data.plusNocturnalValue
		)
	) {
		data.plusNocturnalStartTime = '';
		data.plusNocturnalEndTime = '';
		data.plusNocturnalValue = '';
	} else {
		data.plusNocturnalStartTime = new Date(data.plusNocturnalStartTime);
		data.plusNocturnalEndTime = new Date(data.plusNocturnalEndTime);
	}
	return data;
};

export default function useAgreementModal({
	setOpenModal,
	categoryToEdit,
	addCategory,
	editCategory,
}) {
	const [showMorePlusOptions, setShowMorePlusOptions] = useState(false);
	const [showAntiquityPlus, setShowAntiquityPlus] = useState(false);

	const defaultValues = {
		id: uuidv4(),
		categoryName: '',
		netSalary: '',
		annualBaseSalary: '',
		fixed_seniority_bonus: '',
		fixed_seniority_bonus_period: '',
		yearRangeValidation: '',
		plusNocturnalStartTime: '',
		plusNocturnalEndTime: '',
		plusNocturnalValue: '',
		plusByDayHolidays: '',
		plusByDaySaturday: '',
		plusByDaySunday: '',
		plusByDayMonday: '',
		plusByDayTuesday: '',
		plusByDayWednesday: '',
		plusByDayThursday: '',
		plusByDayFriday: '',
		free_days: '',
	};

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
		watch,
		getValues,
		setValue,
	} = useForm({
		defaultValues,
	});

	useEffect(() => {
		if (categoryToEdit?.id) {
			reset(categoryToEdit);
			if (categoryToEdit.fixed_seniority_bonus) {
				setShowAntiquityPlus(true);
			} else {
				setShowAntiquityPlus(false);
			}
		}
	}, [categoryToEdit, reset]);

	useEffect(() => {
		if (!showAntiquityPlus) {
			setValue('fixed_seniority_bonus', '');
			setValue('fixed_seniority_bonus_period', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showAntiquityPlus]);

	const resetForm = () => {
		reset(defaultValues);
		setShowMorePlusOptions(false);
		setOpenModal(false);
		setShowAntiquityPlus(false);
	};

	const onSubmit = (data) => {
		const category = parseCategoryToSubmit(data);
		if (categoryToEdit?.id) {
			editCategory(category);
		} else {
			addCategory(category);
		}
		resetForm();
	};

	return {
		plusByDays,
		plusByDaysOfTheWeek,
		handleSubmit,
		onSubmit,
		control,
		reset,
		errors,
		showMorePlusOptions,
		setShowMorePlusOptions,
		showAntiquityPlus,
		setShowAntiquityPlus,
		watch,
		getValues,
		resetForm,
	};
}
