import React from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import { Box } from '@mui/material';

const EuroPerTime = ({ amount, label, time = 'hora' }) => (
	<Box mt={2} sx={{ display: 'flex' }}>
		<Box>{label}</Box>
		<Box ml={1} sx={{ display: 'flex' }}>
			{amount ? (
				<>
					{amount}
					<EuroIcon sx={{ ml: 0.5, width: '14px' }} />
					{`/${time}`}
				</>
			) : (
				'-'
			)}
		</Box>
	</Box>
);
export default EuroPerTime;
