import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { bindActionCreators } from 'redux';

import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import MenuBar from './components/MenuBar/MenuBar';
import NavBar from './components/NavBar/NavBar';
import SnackBar from './components/SnackBar/SnackBar';
import { AppContainer } from './components/StyledContainers';
import WorkspaceLoading from './components/WorkspaceLoading/WorkspaceLoading';
import AppRoutes from './routes';
import ActionCreators from './store/actions';
import { store, persistor, initialState } from './store/storage/store';
import themeMUI from './themeMUI';
import { getTokenFromLocalStorage } from './utilities/utils';

class App extends Component {
	state = {
		logged: false,
		openDrawer: false,
	};

	handleDrawerOpenOrClose = () => {
		this.setState({ openDrawer: !this.state.openDrawer });
	};

	logout = () => {
		// logout is an action available as a callback in props that involves a
		// series of state and storage re-setting actions. Check actions/auth.js
		const { logout } = this.props;
		logout(store, initialState);
	};

	isLoggedIn = () => {
		const {
			auth: { token },
		} = this.props;
		if (token != null) {
			try {
				JSON.parse(atob(token.split('.')[1]));
				return true;
			} catch (e) {
				return false;
			}
		}
		return false;
	};

	isLogged = () => {
		const token = getTokenFromLocalStorage();
		return token ? true : false;
	};

	render() {
		return (
			<ThemeProvider theme={themeMUI}>
				<Router>
					<AppContainer>
						<Box sx={{ display: 'flex' }}>
							<NavBar
								appLogout={this.logout}
								openDrawer={this.state.openDrawer}
								handleDrawerOpenOrClose={this.handleDrawerOpenOrClose}
							/>
							<WorkspaceLoading>
								<MenuBar
									isLoggedIn={this.isLoggedIn}
									handleDrawerOpenOrClose={this.handleDrawerOpenOrClose}
									openDrawer={this.state.openDrawer}
								/>
								<Box
									component='main'
									sx={{
										flexGrow: 1,
										mt: this.isLogged() ? '64px' : '0px',
										width: '100%',
									}}
								>
									<AppRoutes {...this.props} />
								</Box>
							</WorkspaceLoading>
							<SnackBar />
						</Box>
					</AppContainer>
				</Router>
			</ThemeProvider>
		);
	}
}

// convert store state tree into props with the same name (i.e. tasks -> tasks)
const mapStateToProps = (state) => state;
// convert action creators into props with the same name (i.e. getTasksList -> getTasksList)
const mapDispatchToProps = (dispatch) => {
	ActionCreators.logout = (store, state) =>
		ActionCreators.logoutPers(persistor, store, state);
	return { dispatch, ...bindActionCreators(ActionCreators, dispatch) };
};

/*
  connect() returns a connected component class that wraps the original component passed in (App)
  It provides the connected component with the pieces of the data it needs from the store,
  and the functions it can use to dispatch actions to the store
*/

export default connect(mapStateToProps, mapDispatchToProps)(App);
