import {
	getSUbrogateWithErrorCategoriesUrl,
	getSUbrogateWithErrorWorkingDayUrl,
	getSimulationOcrGetUploadUrlUrl,
	getStatusOcrSimulationUrl,
	startOcrExecutionUrl,
	uploadSubrogatesCandidatesUrl,
} from 'utilities/routes';

import { get, post } from 'Services/Api/Api';

export async function getSimulationOcrGetUploadUrl({ simulationId }) {
	const url = getSimulationOcrGetUploadUrlUrl(simulationId);
	const response = await get(url);
	return response;
}

export async function startOcrExecution({ simulationId }) {
	const url = startOcrExecutionUrl(simulationId);
	await post(url);
}

export async function getStatusOcrSimulation({ simulationId }) {
	const url = getStatusOcrSimulationUrl(simulationId);
	const response = await get(url);
	return response;
}

export async function uploadSubrogatesCandidates({ simulationId }) {
	const url = uploadSubrogatesCandidatesUrl(simulationId);
	await post(url);
}

export async function getSubrogateWithErrorWorkingDay({ simulationId }) {
	const url = getSUbrogateWithErrorWorkingDayUrl(simulationId);
	const result = await get(url);
	return result;
}

export async function saveSubrogateWithErrorWorkingDay({
	simulationId,
	payload,
}) {
	const url = getSUbrogateWithErrorWorkingDayUrl(simulationId);
	await post(url, payload);
}

export async function getSubrogateWithErrorCategories({ simulationId }) {
	const url = getSUbrogateWithErrorCategoriesUrl(simulationId);
	const result = await get(url);
	return result;
}

export async function saveSubrogateWithErrorCategories({
	simulationId,
	payload,
}) {
	const url = getSUbrogateWithErrorCategoriesUrl(simulationId);
	await post(url, payload);
}
