import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { mondayToFridayDays } from '../../../../../utilities/const';
import { getHourFromStringTimeHour } from '../../../../../utilities/utils';
import { validEmptyField } from '../../../../../utilities/validations';
import useGetPositionsByCenter from '../../useGetPositionsByCenter';

const parseDateToUpload = (time) =>
	time.map((timeOption) => ({
		id: timeOption.id,
		date_init: moment(timeOption.date_init).format('YYYY-MM-DD'),
		date_end: moment(timeOption.date_end).format('YYYY-MM-DD'),
	}));

export default function useShiftsModal({
	setOpenModal,
	handleUpdateWorkShifts,
	initialValues,
	edit = false,
	setEdit,
	simulationId,
}) {
	const { centers, basicData } = useSelector((state) => state.createSimulation);

	const minDate = basicData?.startDate ? new Date(basicData.startDate) : null;
	const maxDate =
		basicData?.startDate && basicData?.serviceDuration
			? new Date(
					new Date(basicData.startDate).setMonth(
						new Date(basicData.startDate).getMonth() + basicData.serviceDuration
					)
			  )
			: null;
	const [firstEdition, setFirstEdition] = useState(true);
	const [checked, setChecked] = useState(false);
	const [days, setDays] = useState([]);
	const [errorCenters, setErrorCenters] = useState(false);
	const [errorHours, setErrorHours] = useState(false);
	const [errorHoursText, setErrorHoursText] = useState('');
	const [errorPositions, setErrorPositions] = useState(false);
	const [errorServiceDuration, setErrorServiceDuration] = useState(false);
	const [conditionalErrorMessage, setConditionalErrorMessage] = useState('');
	const [firstSplitTurn, setFirstSplitTurn] = useState(null);
	const [firstTurn, setFirstTurn] = useState(null);
	const [holidaysChecked, setHolidaysChecked] = useState(false);
	const [applyOnHolidays, setApplyOnHolidays] = useState(false);
	const [noShiftsChecked, setNoShiftsChecked] = useState(false);
	const [numberOfWorkers, setNumberOfWorkers] = useState('1');
	const [secondSplitTurn, setSecondSplitTurn] = useState(null);
	const [secondTurn, setSecondTurn] = useState(null);
	const [selectedCenter, setSelectedCenter] = useState(
		centers?.data?.length === 1 ? centers.data[0] : null
	);
	const [selectedPosition, setSelectedPosition] = useState(null);
	const [shiftsValue, setShiftsValue] = useState([]);
	const [weekDaysChecked, setWeekDaysChecked] = useState(false);
	const containerRef = useRef(null);

	const [maxWidth, setMaxWidth] = useState('sm');

	const { loading: loadingPositions, data: positions } =
		useGetPositionsByCenter({ center: selectedCenter, simulationId });

	useEffect(() => {
		if (positions?.length >= 1 && selectedCenter) {
			if (edit && firstEdition) {
				setFirstEdition(false);
			} else {
				setSelectedPosition(positions?.length === 1 ? positions[0] : null);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [positions]);

	const clearMessages = () => {
		setErrorCenters(false);
		setErrorHours(false);
		setErrorPositions(false);
		setErrorServiceDuration(false);
		setConditionalErrorMessage('');
		setErrorHoursText('');
	};

	const closeModalActions = () => {
		clearMessages();
		setOpenModal(false);
		setEdit(false);
		setFirstEdition(true);
		setSelectedPosition(null);
		setSelectedCenter(null);
	};

	const initialDataState = ({ initialValues }) => {
		setSelectedPosition(
			(initialValues?.position?.length > 0 && initialValues?.position[0]) ||
				(positions?.data?.length === 1 ? positions.data[0] : null)
		);
		setSelectedCenter(
			(initialValues?.center?.length > 0 && initialValues?.center[0]) ||
				(centers?.data?.length === 1 ? centers.data[0] : null)
		);
		setNoShiftsChecked(initialValues?.periods_without_work?.length > 0);
		setFirstTurn(
			initialValues?.shift_times
				? getHourFromStringTimeHour(initialValues?.shift_times[0]?.time_init)
				: null
		);
		setFirstSplitTurn(
			initialValues?.shift_times && initialValues?.shift_times?.length > 1
				? getHourFromStringTimeHour(initialValues?.shift_times[1]?.time_init)
				: null
		);
		setSecondSplitTurn(
			initialValues?.shift_times && initialValues?.shift_times?.length > 1
				? getHourFromStringTimeHour(initialValues?.shift_times[1]?.time_end)
				: null
		);
		setSecondTurn(
			initialValues?.shift_times
				? getHourFromStringTimeHour(initialValues?.shift_times[0]?.time_end)
				: null
		);
		setDays(initialValues?.weekdays_working || []);
		setChecked(
			initialValues?.shift_times && initialValues?.shift_times.length > 1
				? true
				: false
		);
		setHolidaysChecked(initialValues?.work_in_holidays || false);
		setApplyOnHolidays(initialValues?.weekday_work_in_holidays || false);
		setShiftsValue(initialValues?.periods_without_work || []);
		setWeekDaysChecked(false);
		setNumberOfWorkers(initialValues?.number_of_workers || '1');
	};

	useEffect(() => {
		setMaxWidth(noShiftsChecked ? 'md' : 'sm');
	}, [noShiftsChecked]);

	useEffect(() => {
		initialDataState({ initialValues });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	useEffect(() => {
		if (weekDaysChecked) {
			setDays(mondayToFridayDays);
		}
	}, [weekDaysChecked]);

	useEffect(() => {
		if (!mondayToFridayDays.every((val) => days.includes(val))) {
			setWeekDaysChecked(false);
		}
	}, [days]);

	const ValidateForm = () => {
		let isValid = true;

		if (!validEmptyField(numberOfWorkers, setErrorServiceDuration))
			isValid = false;
		if (!validEmptyField(selectedCenter, setErrorCenters)) isValid = false;
		if (!validEmptyField(selectedPosition, setErrorPositions)) isValid = false;

		if (
			!firstTurn ||
			!secondTurn ||
			(checked && (!firstSplitTurn || !secondSplitTurn))
		) {
			setErrorHours(true);
			setErrorHoursText('Seleccione los horarios.');
			isValid = false;
		} else if (
			moment(firstTurn).format('HH:mm') ===
				moment(firstSplitTurn).format('HH:mm') &&
			checked
		) {
			setErrorHours(true);
			setErrorHoursText('Los horarios no pueden coincidir.');
			isValid = false;
		} else {
			setErrorHours(false);
		}
		if (days.length !== 0 || holidaysChecked) {
			setConditionalErrorMessage('');
		} else {
			isValid = false;
			setConditionalErrorMessage('Selecciona algún día de la semana.');
		}
		if (applyOnHolidays && days.length === 0) {
			isValid = false;
			setConditionalErrorMessage('Selecciona algún día de la semana.');
		} else if (days.length !== 0 || holidaysChecked) {
			setConditionalErrorMessage('');
		}
		return isValid;
	};

	const shiftsTimeFormat = () => {
		const response = [
			{
				id: uuidv4(),
				time_init: moment(firstTurn).format('HH:mm'),
				time_end: moment(secondTurn).format('HH:mm'),
			},
		];
		if (firstSplitTurn && secondSplitTurn && checked) {
			response.push({
				id: uuidv4(),
				time_init: moment(firstSplitTurn).format('HH:mm'),
				time_end: moment(secondSplitTurn).format('HH:mm'),
			});
		}
		return response;
	};

	const handleSave = () => {
		if (ValidateForm()) {
			const value = {
				center_id: selectedCenter?.id,
				id: initialValues?.id ? initialValues.id : uuidv4(),
				number_of_workers: parseInt(numberOfWorkers, 10),
				position_id: selectedPosition?.id,
				shift_times: shiftsTimeFormat(),
				weekdays_working: days,
				work_in_holidays: holidaysChecked,
				weekday_work_in_holidays: applyOnHolidays,
				periods_without_work: parseDateToUpload(shiftsValue),
			};
			handleUpdateWorkShifts(value);
			initialDataState({});
			closeModalActions();
		}
	};

	const handleSaveAndContinue = () => {
		if (ValidateForm()) {
			const value = {
				center_id: selectedCenter?.id,
				id: initialValues?.id ? initialValues.id : uuidv4(),
				number_of_workers: parseInt(numberOfWorkers, 10),
				position_id: selectedPosition?.id,
				shift_times: shiftsTimeFormat(),
				weekdays_working: days,
				work_in_holidays: holidaysChecked,
				weekday_work_in_holidays: applyOnHolidays,
				periods_without_work: parseDateToUpload(shiftsValue),
			};
			handleUpdateWorkShifts(value);
			initialDataState({});
		}
	};

	return {
		centers,
		positions,
		loadingPositions,
		errorCenters,
		days,
		setDays,
		checked,
		setChecked,
		errorHours,
		errorHoursText,
		errorPositions,
		errorServiceDuration,
		conditionalErrorMessage,
		firstSplitTurn,
		setFirstSplitTurn,
		firstTurn,
		setFirstTurn,
		holidaysChecked,
		setHolidaysChecked,
		applyOnHolidays,
		setApplyOnHolidays,
		noShiftsChecked,
		setNoShiftsChecked,
		numberOfWorkers,
		setNumberOfWorkers,
		secondSplitTurn,
		setSecondSplitTurn,
		secondTurn,
		setSecondTurn,
		selectedCenter,
		setSelectedCenter,
		selectedPosition,
		setSelectedPosition,
		shiftsValue,
		setShiftsValue,
		weekDaysChecked,
		setWeekDaysChecked,
		containerRef,
		maxWidth,
		handleSave,
		handleSaveAndContinue,
		clearMessages,
		closeModalActions,
		maxDate,
		minDate,
	};
}
