import React from 'react';

import { DropzoneArea } from 'material-ui-dropzone';

import { Typography } from '@material-ui/core';

import { AppInput } from '../../components';
import './Uploader.css';

const Uploader = ({
	simulationName,
	simulationNameError,
	handleInputChange,
	handleChangeFiles,
	fileError,
	error,
	progress,
}) => {
	if (progress && uploading) {
		return (
			<ProgressContainer>
				<Typography>{progress}</Typography>
				<LinearProgress variant='determinate' value={progress} />
			</ProgressContainer>
		);
	}

	const ErrorSpan = (error) => {
		if (error?.error) {
			return (
				<Typography variant='subtitle1' color='error'>
					No pueden existir dos simulaciones con el mismo nombre en la
					licitación.{' '}
				</Typography>
			);
		} else {
			return null;
		}
	};

	return (
		<div>
			<div className='uploadContainer'>
				<AppInput
					className='flex_1'
					label='Nombre simulación*'
					type='text'
					name='simulationName'
					margin='dense'
					autoFocus
					value={simulationName}
					error={simulationNameError ? true : false}
					helperText={simulationNameError}
					onChange={(event) => handleInputChange(event)}
					style={{ width: 350, marginBottom: 30 }}
				/>
			</div>
			<Typography color='error' variant='subtitle2'>
				{fileError ? fileError : ''}
			</Typography>
			{ErrorSpan(error)}
		</div>
	);
};

export default Uploader;
