import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { PersistGate } from 'redux-persist/integration/react';

import { CircularProgress } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './store/storage/store';
import theme from './theme';

if (process.env.REACT_APP_SENTRY_DNS) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DNS,
		integrations: [new BrowserTracing()],
		environment: process.env.REACT_APP_SENTRY_ENV,
		tracesSampleRate: 1.0,
	});
}

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<PersistGate loading={<CircularProgress />} persistor={persistor}>
				<App persistor={persistor} />
			</PersistGate>
		</ThemeProvider>
	</Provider>,
	document.getElementById('root')
);
serviceWorker.unregister();
