import ApiException from '../../../../Services/Api/ApiException';
import useGetResponseFromApi from '../../../../hooks/useGetResponseFromApi';
import AxiosWrapper from '../../../../utilities/connection/AxiosWrapper';
import {
	deleteMaterialCostUrl,
	createMaterialCostUrl,
	getMaterialCostsUrl,
	updateMaterialCostsUrl,
	getMaterialCostByIdUrl,
} from '../../../../utilities/routes';

export const getMaterialCosts = ({ simulationId, refresh }) =>
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useGetResponseFromApi({
		url: getMaterialCostsUrl(simulationId),
		refresh,
	});

export async function deleteMaterialCostById({ simulationId, materialCostId }) {
	const url = deleteMaterialCostUrl(simulationId, materialCostId);
	try {
		await AxiosWrapper.delete(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getMaterialCostById({ simulationId, materialCostId }) {
	const url = getMaterialCostByIdUrl(simulationId, materialCostId);
	try {
		const materialCostData = await AxiosWrapper.get(url);
		return materialCostData.data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function updateMaterialCosts({
	simulationId,
	materialCostId,
	payload,
}) {
	const url = updateMaterialCostsUrl(simulationId, materialCostId);
	try {
		return await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function createMaterialCost({ simulationId, payload }) {
	const url = createMaterialCostUrl(simulationId);
	try {
		await AxiosWrapper.post(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
