import React from 'react';

import { Box, Dialog, Grid, InputAdornment } from '@mui/material';

import Else from '../../../../components/Compounds/If/Else';
import If from '../../../../components/Compounds/If/If';
import Then from '../../../../components/Compounds/If/Then';
import DialogTitle from '../../../../components/Dialog/DialogTitle';
import { FormAutoComplete } from '../../../../components/Form/AutoComplete';
import { FormInputText } from '../../../../components/Form/InputText';
import IsLoading from '../../../../components/IsLoading/IsLoading';
import IsPreviousStudyEditable from '../../../../components/PreviousStudy/IsPreviousStudyEditable';
import { StyledButton } from '../../../../components/StyledComponents/Button';
import useMaterialCostModal from './useMaterialCostModal';

const MaterialCostModal = ({
	openModal,
	setOpenModal,
	edit,
	setEdit,
	materialCostId,
	materialCostsData,
	setReloadMaterialCosts,
}) => {
	const {
		handleSubmit,
		control,
		onSubmit,
		onSubmitAndContinue,
		resetForm,
		materialCosts,
		loadingMaterialCost,
		watch,
		selectedMaterialCosts,
		amountIcon,
		isValidAmountValue,
		isValidPersentAmountValue,
	} = useMaterialCostModal({
		openModal,
		setOpenModal,
		edit,
		setEdit,
		materialCostId,
		materialCostsData,
		setReloadMaterialCosts,
	});

	const customFormInput = ({ name, testId, label = '' }) => (
		<FormInputText
			name={name}
			control={control}
			label={label}
			type='number'
			rules={{
				required: 'Valor requerido',
				validate: isValidAmountValue,
			}}
			inputProps={{
				'data-testid': testId,
				min: 0,
			}}
		/>
	);

	const monthsInput = () => (
		<Grid container spacing={2} sx={{ alignItems: 'center' }}>
			<Grid item xs={12} sx={{ mb: 1 }}>
				{customFormInput({
					name: 'months_to_apply',
					testId: 'material_months',
					label: 'Nº de meses',
				})}
			</Grid>
		</Grid>
	);

	const unitsInput = () => (
		<Grid container spacing={2} sx={{ alignItems: 'center' }}>
			<Grid item xs={12} sx={{ mb: 1 }}>
				{customFormInput({
					name: 'number_of_units',
					testId: 'material_units',
					label: 'Nº unidades',
				})}
			</Grid>
		</Grid>
	);

	return (
		<Dialog fullWidth open={openModal} onClose={() => resetForm()}>
			<Box p={3}>
				<DialogTitle title='Añadir otros costes' />
				<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<IsLoading conditional={loadingMaterialCost}>
						<Grid item container xs={12}>
							<Grid mt={0} container spacing={2}>
								<Grid item xs={12} sx={{ mb: 1 }}>
									<Grid container spacing={2} sx={{ alignItems: 'center' }}>
										<Grid item xs={12} sx={{ mb: 1 }}>
											<If conditional={edit}>
												<Then>
													<Box sx={{ textAlign: 'center' }}>
														{watch('name')}
													</Box>
												</Then>
												<Else>
													<FormAutoComplete
														name='cost_type'
														control={control}
														label='Concepto: Seleccione un material'
														options={materialCosts}
														optionLabel='name'
														rules={{ required: 'Material requerido' }}
														inputProps={{
															'data-testid': 'material_name',
														}}
													/>
												</Else>
											</If>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} sx={{ mb: 1 }}>
									<Grid
										container
										spacing={2}
										direction={
											edit &&
											selectedMaterialCosts !== 'Por mes' &&
											selectedMaterialCosts !== 'Cada unidad'
												? 'column'
												: 'row'
										}
										alignItems={edit ? 'center' : 'flex-start'}
									>
										<Grid item xs={6} sx={{ mb: 1 }}>
											<Grid container spacing={2} sx={{ alignItems: 'center' }}>
												<Grid item xs={12} sx={{ mb: 1 }}>
													<FormInputText
														name='amount'
														control={control}
														label={selectedMaterialCosts}
														type='number'
														rules={{
															required: 'Valor requerido',
															validate: isValidPersentAmountValue,
														}}
														sx={{
															minWidth: '200px',
														}}
														inputProps={{
															'data-testid': 'material_amount',
															min: 0,
															max:
																selectedMaterialCosts ===
																'Porcentaje del coste salarial'
																	? 100
																	: null,
														}}
														endAdornment={
															<InputAdornment position='end'>
																{amountIcon}
															</InputAdornment>
														}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Grid
											item
											xs={selectedMaterialCosts === 'Cada unidad' ? 6 : 0}
											sx={{
												mb: 1,
												display:
													selectedMaterialCosts === 'Cada unidad'
														? 'block'
														: 'none',
											}}
										>
											<If conditional={selectedMaterialCosts === 'Cada unidad'}>
												<Then>{unitsInput()}</Then>
											</If>
										</Grid>
										<Grid
											item
											xs={
												selectedMaterialCosts === 'Por mes' ||
												selectedMaterialCosts === 'Cada unidad'
													? 6
													: 0
											}
											sx={{ mb: 1 }}
										>
											<If
												conditional={
													selectedMaterialCosts === 'Por mes' ||
													selectedMaterialCosts === 'Cada unidad'
												}
											>
												<Then>{monthsInput()}</Then>
											</If>
										</Grid>
									</Grid>
								</Grid>
							</Grid>

							<Grid
								item
								container
								xs={12}
								sx={{ display: 'flex', justifyContent: 'center' }}
								direction='column'
								alignItems='center'
							>
								<Grid item>
									<IsPreviousStudyEditable>
										<StyledButton
											onClick={handleSubmit(onSubmit)}
											variant='contained'
											sx={{ textTransform: 'inherit' }}
										>
											Añadir y cerrar
										</StyledButton>
									</IsPreviousStudyEditable>
								</Grid>
								<Grid item mt={1}>
									<IsPreviousStudyEditable>
										<StyledButton
											onClick={handleSubmit(onSubmitAndContinue)}
											variant='text'
											sx={{ color: 'primary.main', textTransform: 'inherit' }}
										>
											<u>Continuar añadiendo costes</u>
										</StyledButton>
									</IsPreviousStudyEditable>
								</Grid>
								<Grid item mt={1}>
									<IsPreviousStudyEditable>
										<StyledButton
											onClick={resetForm}
											variant='text'
											sx={{ color: 'text.secondary', textTransform: 'inherit' }}
										>
											Cancelar
										</StyledButton>
									</IsPreviousStudyEditable>
								</Grid>
							</Grid>
						</Grid>
					</IsLoading>
				</Box>
			</Box>
		</Dialog>
	);
};
export default MaterialCostModal;
