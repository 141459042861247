import React from 'react';

import { Grid, Typography } from '@mui/material';

import DialogComponentWithoutEditableOption from '../../../../components/Dialog/DialogWithoutEditableOption';
import { FormDropdown } from '../../../../components/Form/Dropdown';
import IsLoading from '../../../../components/IsLoading/IsLoading';
import useUserRoleModal from './useUserRoleModal';

const UserRoleModal = ({
	openModal = false,
	onClose,
	selectedUser,
	reloadUsers,
}) => {
	const {
		roles,
		control,
		handleSubmit,
		onSubmitRole,
		submittingRole,
		loadingRoles,
	} = useUserRoleModal({ onClose, selectedUser, reloadUsers });

	const modalContent = () => (
		<Grid container justifyContent='center'>
			<Grid container item sx={{ alignItems: 'center' }} mb='15px'>
				<Grid item xs={2}>
					<Typography color='black' fontWeight='700'>
						Usuario:
					</Typography>
				</Grid>
				<Grid item xs={9}>
					{selectedUser.email}
				</Grid>
			</Grid>
			<IsLoading conditional={loadingRoles}>
				<Grid container item sx={{ alignItems: 'center' }}>
					<Grid item xs={2}>
						<Typography color='black' fontWeight='700'>
							Rol:
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<FormDropdown
							name='role'
							control={control}
							options={roles}
							inputProps={{
								'data-testid': 'rolesTestId',
							}}
						/>
					</Grid>
				</Grid>
			</IsLoading>
		</Grid>
	);

	return (
		<DialogComponentWithoutEditableOption
			open={openModal}
			onClose={onClose}
			title='Editar rol de usuario'
			content={modalContent()}
			onAccept={handleSubmit(onSubmitRole)}
			acceptText='Guardar'
			cancelText='Cancelar'
			onCancel={onClose}
			cancelButtonFirst={false}
			disableAccept={submittingRole}
			disableCancel={submittingRole}
			fullWidth
		/>
	);
};

export default UserRoleModal;
