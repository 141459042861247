import React from 'react';

import {
	Box,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const ToggleButtonComponent = styled(ToggleButton)((props) => ({
	'&.Mui-selected, &.Mui-selected:hover': {
		color: 'white',
		backgroundColor: props.theme.palette.primary.main,
	},
	'&:hover': {
		background: props.theme.palette.primary.main,
		color: props.theme.palette.common.white,
	},
}));

const toggleOption = (value, label) => (
	<ToggleButtonComponent
		color='secondary'
		aria-label={value}
		value={value}
		size='small'
		fullWidth
	>
		{label}
	</ToggleButtonComponent>
);

const ToggleWeekOptions = ({ setDays, days }) => (
	<Box>
		<Box>
			<Typography
				sx={{ fontWeight: 'bold', fontSize: '12px', color: 'black' }}
				id='modal-modal-title'
				variant='subtitle2'
				component='div'
			>
				Días de la semana
			</Typography>
		</Box>
		<Box mt='3px'>
			<ToggleButtonGroup
				value={days}
				size='small'
				fullWidth
				onChange={(event, newDays) => {
					setDays(newDays);
				}}
			>
				{toggleOption('monday', 'Lunes')}
				{toggleOption('tuesday', 'Martes')}
				{toggleOption('wednesday', 'Miercoles')}
				{toggleOption('thursday', 'Jueves')}
				{toggleOption('friday', 'Viernes')}
				{toggleOption('saturday', 'Sábado')}
				{toggleOption('sunday', 'Domingo')}
			</ToggleButtonGroup>
		</Box>
	</Box>
);
export default ToggleWeekOptions;
