import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { updateBasicData } from '../../../Services/Api/PreviousStudy';
import { setPreviousStudyStore } from '../../../store/actions/previousStudy';
import { setSnackbar } from '../../../store/actions/snackbar';

const defaultValues = (
	service_date_init = '',
	service_duration = '',
	social_security = '34',
	absenteeism_rate = '0',
	substitution_percentage = '100'
) => ({
	service_date_init,
	service_duration,
	social_security,
	absenteeism_rate,
	substitution_percentage,
});

export default function useBasicData({ previousStudyId }) {
	const dispatch = useDispatch();
	const { previousStudy } = useSelector((state) => state);
	const { gettingPreviousStudyData } = previousStudy;
	const [updatingBasicData, setUpdatingBasicData] = useState(false);

	const { handleSubmit, control, getValues, reset } = useForm({
		defaultValues: defaultValues(),
	});

	useEffect(() => {
		if (previousStudy !== {}) {
			const resetValues = defaultValues(
				previousStudy?.service_date_init || '',
				previousStudy?.service_duration || '',
				previousStudy?.social_security || '34',
				previousStudy?.absenteeism_rate
					? parseFloat(previousStudy.absenteeism_rate)
					: '0',
				previousStudy?.substitution_percentage ||
					previousStudy?.substitution_percentage === 0
					? parseFloat(previousStudy.substitution_percentage)
					: '100'
			);
			reset(resetValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [previousStudy, gettingPreviousStudyData]);

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const handleSubmitForm = async () => {
		setUpdatingBasicData(true);
		try {
			const payload = {
				name: previousStudy?.name ? previousStudy?.name : '7test',
				service_date_init: moment(getValues('service_date_init')).format(
					'YYYY-MM-DD'
				),
				service_duration: parseInt(getValues('service_duration'), 10),
				social_security: parseFloat(getValues('social_security')),
				absenteeism_rate: parseFloat(getValues('absenteeism_rate'), 10),
				substitution_percentage: parseFloat(
					getValues('substitution_percentage'),
					10
				),
			};
			await updateBasicData({
				payload,
				previousStudyId,
			});
			dispatch(setPreviousStudyStore(payload));
			setUpdatingBasicData(false);
		} catch (e) {
			setUpdatingBasicData(false);
			setSnackBarMessage(e.message);
		}
	};

	return {
		updatingBasicData,
		gettingPreviousStudyData,
		handleSubmit,
		handleSubmitForm,
		control,
	};
}
