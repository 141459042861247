import React, { Component, Fragment } from 'react';

import styled from 'styled-components';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
	List,
	ListItem,
	ListItemAvatar,
	IconButton,
	ListItemSecondaryAction,
	ListItemText,
	Avatar,
	Typography,
	Menu,
	MenuItem,
	Card,
} from '@mui/material';

import { withTheme } from '@material-ui/core/styles';
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	MoreVert,
} from '@material-ui/icons';

import Else from '../Compounds/If/Else';
import If from '../Compounds/If/If';
import Then from '../Compounds/If/Then';

const StyledCard = styled(Card)`
	margin-top: 15px;
`;

class AppCardList extends Component {
	state = {
		openMenu: null,
		option: null,
	};

	renderIcon = (element) => {
		let { leftIcon } = this.props;
		leftIcon = element.icon ? element.icon : leftIcon;

		const iconList = leftIcon ? (
			<ListItemAvatar>
				<Avatar>{leftIcon}</Avatar>
			</ListItemAvatar>
		) : null;

		return iconList;
	};

	renderActions = (idx) => {
		const { typeActions, customAction, onDelete, onEdit, onDuplicate } =
			this.props;
		const { openMenu, option } = this.state;

		if ((!typeActions || typeActions === 'delete') && onDelete) {
			return (
				<IconButton
					edge='end'
					aria-label='delete'
					onClick={() => onDelete(idx)}
				>
					<DeleteIcon />
				</IconButton>
			);
		}

		if (typeActions === 'editDelete' && onDelete && onEdit) {
			return (
				<Fragment>
					<IconButton
						edge='end'
						aria-label='more'
						onClick={(event) =>
							this.setState({ openMenu: event.currentTarget, option: idx })
						}
					>
						<MoreVert />
					</IconButton>
					<Menu
						id='simple-menu'
						anchorEl={openMenu}
						keepMounted
						open={Boolean(openMenu)}
						onClose={() => this.setState({ openMenu: null })}
						PaperProps={{
							elevation: 1,
							sx: {
								border: '0px solid',
								boxShadow: '1px 2px 4px -3px rgb(0 0 0 / 8%) !important',
							},
						}}
					>
						<MenuItem
							onClick={() => {
								onDelete(option);
								this.setState({ openMenu: null });
							}}
						>
							<DeleteIcon />
							Borrar
						</MenuItem>
						<MenuItem
							onClick={() => {
								onEdit(option);
								this.setState({ openMenu: null });
							}}
						>
							<EditIcon />
							Editar
						</MenuItem>
						<If conditional={typeof onDuplicate != 'undefined'}>
							<Then>
								<MenuItem
									onClick={() => {
										onDuplicate(option);
									}}
								>
									<ContentCopyIcon />
									Duplicar
								</MenuItem>
							</Then>
						</If>
					</Menu>
				</Fragment>
			);
		}

		if (typeActions === 'none') {
			return null;
		}

		return customAction || null;
	};

	renderItems = () => {
		const { items, onClick, emptyMessage } = this.props;
		const listItems = [];

		if (items.length === 0) {
			return <Typography>{emptyMessage || 'No hay elementos'}</Typography>;
		}

		items.forEach((element, idx) => {
			listItems.push(
				<StyledCard key={idx}>
					<ListItem
						button={onClick ? true : false}
						onClick={onClick ? () => onClick(idx) : null}
					>
						{this.renderIcon(element)}
						<ListItemText
							primary={element.text}
							secondary={element.secondary ? element.secondary : null}
						/>
						<ListItemSecondaryAction>
							<If conditional={element?.update_permissions !== undefined}>
								<Then>
									<If conditional={element?.update_permissions}>
										<Then>{this.renderActions(idx)}</Then>
									</If>
								</Then>
								<Else>{this.renderActions(idx)}</Else>
							</If>
						</ListItemSecondaryAction>
					</ListItem>
				</StyledCard>
			);
		});

		return listItems;
	};

	render() {
		const { dense, maxHeight } = this.props;

		return (
			<List
				dense={dense}
				style={{
					overflow: 'auto',
					paddingLeft: 5,
					paddingRight: 5,
				}}
			>
				{this.renderItems()}
			</List>
		);
	}
}

export default withTheme(AppCardList);
