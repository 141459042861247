import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getWorkSpaces } from '../../../Services/Api/Administration';
import useIsMounted from '../../../hooks/useIsMounted';

export default function useWorkSpaces() {
	const { isMounted } = useIsMounted();
	const { auth } = useSelector((state) => state);
	const [loadingWorkSpace, setLoadingWorkSpace] = useState(false);
	const [workSpaces, setWorkSpaces] = useState([]);
	const [selectedWorkSpace, setSelectedWorkSpace] = useState(null);

	const getWorkSpacesList = async () => {
		setLoadingWorkSpace(true);
		try {
			const pro = await getWorkSpaces();
			if (isMounted) {
				setWorkSpaces(pro);
				if (pro.length > 0) setSelectedWorkSpace(pro[0]);
				setLoadingWorkSpace(false);
			}
		} catch (e) {
			setLoadingWorkSpace(false);
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message: e?.response?.data?.message || e?.message || '',
			});
		}
	};

	useEffect(() => {
		getWorkSpacesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		loadingWorkSpace,
		workSpaces,
		selectedWorkSpace,
		auth,
		setSelectedWorkSpace,
	};
}
