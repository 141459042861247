import { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getSimulationsCenters } from 'Services/Api/Simulation';

import { showSnackbar } from '../../../../store/actions/snackbar';
import { sleep } from '../../../../utilities/utils';
import {
	getSolutionDefaultParametersById,
	getSolutionParametersById,
	solutionUploadStatusParameters,
	updateSolutionParameters,
} from '../../ApiServices';

const groupBy = (array, property) =>
	array.reduce(
		(grouped, element) => ({
			...grouped,
			[element[property]]: [...(grouped[element[property]] || []), element],
		}),
		{}
	);

const formatParameters = (obj) => ({
	...obj,
	increase: Object.entries(groupBy(obj?.increase, 'agreement_id')).reduce(
		(r, [, v]) => [
			...r,
			{
				agreement_id: v[0]?.agreement_id,
				agreement_name: v[0]?.agreement_name,
				values: v
					?.sort((a, b) => a.year - b.year)
					.map(({ year, id, increase }) => ({
						year,
						id,
						value: increase,
					})),
			},
		],
		[]
	),
});

const mergeParametersObjs = (data, dataDefault) =>
	Object.entries(dataDefault).reduce(
		(r, [key, value]) => ({
			...r,
			[key]:
				[null, undefined].includes(data[key]) ||
				(Array.isArray(data[key]) && !data[key].length)
					? value
					: data[key],
		}),
		{}
	);

const parseDataToSubmit = (data) => {
	const response = {
		absenteeism_rate: parseFloat(data.absenteeism_rate, 10),
		benefit: parseFloat(data.benefit, 10),
		material_costs: data.material_costs.map((material) => ({
			...material,
			amount: material?.amount ? parseFloat(material.amount, 10) : 0,
			number_of_units:
				material?.number_of_units != null
					? parseFloat(material.number_of_units, 10)
					: null,
			months_to_apply:
				material?.months_to_apply != null
					? parseFloat(material.months_to_apply, 10)
					: null,
		})),
		increase: data.increase.reduce(
			(r, { agreement_id, agreement_name, values }) => [
				...r,
				// eslint-disable-next-line no-unsafe-optional-chaining
				...values?.map(({ year, id, value }) => ({
					agreement_id,
					agreement_name,
					year,
					id,
					increase: parseFloat(value),
				})),
			],
			[]
		),
	};
	return response;
};

export default function useModifySimulationModal({
	setOpenModal,
	openModal,
	simulationId,
	setRefreshPage,
	setUploadingParameters,
}) {
	const defaultValues = {
		absenteeism_rate: '',
		benefit: '',
		increase: [],
		material_costs: [],
	};

	const [loadingSolution, setLoadingSolution] = useState(false);
	const [loadingDefaults, setLoadingDefaults] = useState(false);
	const [uploadingSolution, setUploadingSolution] = useState(false);

	const [centers, setCenters] = useState([]);

	const dispatch = useDispatch();

	const { handleSubmit, control, reset } = useForm({
		defaultValues,
	});

	const { fields: increaseFields } = useFieldArray({
		control,
		name: 'increase',
	});

	const showSnackBarMessage = ({ message = '' }) => {
		dispatch(
			showSnackbar({
				severity: 'error',
				message: message || 'Error desconocido en el servidor',
			})
		);
	};

	const getCenterBySimulation = async (simulationId) => {
		const response = await getSimulationsCenters({ simulationId });
		setCenters(response);
	};

	const getSimulationParametersFromId = async (simulationId) => {
		const { data } = await getSolutionParametersById({ simulationId });
		const { data: resultDefaultParameters } =
			await getSolutionDefaultParametersById({ simulationId });

		reset(formatParameters(mergeParametersObjs(data, resultDefaultParameters)));
	};

	const load_screen_data = async (simulationId) => {
		try {
			setLoadingSolution(true);
			await getCenterBySimulation(simulationId);
			await getSimulationParametersFromId(simulationId);
			setLoadingSolution(false);
		} catch (e) {
			setLoadingSolution(false);
			setOpenModal(false);
			showSnackBarMessage({ message: e?.message });
		}
	};

	useEffect(() => {
		if (openModal) {
			load_screen_data(simulationId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [simulationId, openModal]);

	const resetForm = () => {
		reset(formatParameters(defaultValues));
		setOpenModal(false);
	};

	const uploadParametersStatus = async () => {
		let response = '';
		let count = 0;
		setUploadingParameters(true);
		do {
			count += 1;
			try {
				response = await solutionUploadStatusParameters(simulationId);
			} catch (e) {
				showSnackBarMessage({
					message: e?.response?.data?.message || e?.message,
				});
			}
			await sleep(1000);
			if (response?.data?.status === 'ERROR') {
				count = 300;
				showSnackBarMessage({ message: response.data.info_message });
				setUploadingParameters(false);
				return;
			}
			if (response?.data?.status === 'PENDING' && count >= 300) {
				showSnackBarMessage({
					message: 'El servidor está tardando mucho en responder',
				});
				setUploadingParameters(false);
				return;
			}
			if (response?.data?.status !== 'PENDING' || count >= 300) {
				setRefreshPage(true);
				setUploadingParameters(false);
				return;
			}
		} while (response?.data?.status === 'PENDING' && count <= 300);
	};

	const SubmitSolutionParameters = async (simulationId, data) => {
		const payload = parseDataToSubmit(data);
		try {
			setUploadingSolution(true);
			await updateSolutionParameters({
				simulationId,
				payload,
			});
			await uploadParametersStatus();
			setUploadingSolution(false);
			resetForm();
		} catch (e) {
			setUploadingSolution(false);
			showSnackBarMessage({ message: e?.message });
		}
	};

	const onSubmit = async (data) => {
		await SubmitSolutionParameters(simulationId, data);
	};

	const onRestoreDefaults = async () => {
		try {
			setLoadingDefaults(true);
			const { data } = await getSolutionParametersById({ simulationId });
			const { data: resultDefaultParameters } =
				await getSolutionDefaultParametersById({ simulationId });

			const result = {
				...resultDefaultParameters,
				increase: resultDefaultParameters?.increase?.map((val, index) => ({
					...val,
					id: data.increase[index].id,
				})),
			};

			reset(formatParameters(result));
			setLoadingDefaults(false);
		} catch (e) {
			setLoadingDefaults(false);
			showSnackBarMessage({ message: e?.message });
		}
	};

	return {
		centers,
		control,
		handleSubmit,
		increaseFields,
		loadingDefaults,
		loadingSolution,
		onRestoreDefaults,
		onSubmit,
		resetForm,
		uploadingSolution,
	};
}
