import React from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import { Box } from '@mui/material';

const NumberWithEuroIcon = ({ amount, time }) => (
	<Box sx={{ display: 'flex', mb: 2 }}>
		<Box>{amount}</Box>
		<EuroIcon sx={{ ml: 0.5, width: '14px' }} />
		<Box>{`/${time}`}</Box>
	</Box>
);
export default NumberWithEuroIcon;
