import React, { useState, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: '30px',
	background: theme.palette.grey.main,
	'&:hover': {
		'& div button': {
			background:
				'linear-gradient(	90deg, rgba(119, 34, 203, 1) -5.58%, rgba(135, 16, 193, 1) -5.57%, rgba(102, 49, 213, 1) 13%, rgba(86, 66, 223, 1) 47.9%,	rgba(68, 82, 231, 1) 65.35%, rgba(52, 98, 242, 1) 83.36%, rgba(36, 114, 252, 1) 100%		)',
			color: theme.palette.common.white,
		},
	},
	marginLeft: 0,
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		marginLeft: theme.spacing(1),
		width: 'auto',
	},
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		'&:hover': {
			color: theme.palette.primary.main,
		},
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch',
			},
		},
	},
}));

const SearchBar = ({ onType = false, onSearch }) => {
	const [searchCriteria, setSearchCriteria] = useState('');

	const handleOnClick = () => {
		onSearch(searchCriteria);
	};

	useEffect(() => {
		if (onType) {
			onSearch(searchCriteria);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchCriteria]);
	return (
		<Search>
			<StyledInputBase
				placeholder='Buscar...'
				inputProps={{ 'aria-label': 'search' }}
				value={searchCriteria}
				onChange={(event) => setSearchCriteria(event.target.value)}
				endAdornment={
					<InputAdornment position='end'>
						<IconButton
							aria-label='toggle password visibility'
							onClick={handleOnClick}
							edge='end'
							sx={{ mr: 1 }}
						>
							<SearchIcon />
						</IconButton>
					</InputAdornment>
				}
			/>
		</Search>
	);
};

export default SearchBar;
