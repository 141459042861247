import React, { useCallback } from 'react';

import {
	InputAdornment,
	TableBody,
	TableCell,
	TableRow,
	TextField,
} from '@mui/material';

import SurrogateDetailContent from './SurrogateDetailContent';

const SurrogateTableBody = ({
	setSubrogates,
	subrogates,
	valueMetricWorkingDay,
}) => {
	const changeValueWorkingDay = useCallback(
		(event, subrogateId) => {
			const value = event.target.value
				? parseFloat(event.target.value)
				: event.target.value;
			setSubrogates((prev) =>
				prev.map((values) => ({
					...values,
					working_day:
						subrogateId === values?.id ? value : values.working_day || 0,
				}))
			);
		},
		[setSubrogates]
	);

	return (
		<TableBody>
			{subrogates?.map((row) => (
				<TableRow key={row?.id}>
					<TableCell>
						<SurrogateDetailContent text={row.name} />
					</TableCell>
					<TableCell>
						<SurrogateDetailContent text={row.agreement_name} />
					</TableCell>
					<TableCell>
						<SurrogateDetailContent text={row.category_name} />
					</TableCell>
					<TableCell sx={{ width: '16.5%' }} align='left'>
						<TextField
							id='outlined-start-adornment'
							onChange={(e) => changeValueWorkingDay(e, row?.id)}
							size='small'
							style={{ minWidth: '48%' }}
							sx={{ width: '50%' }}
							type='number'
							value={row.working_day}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										{valueMetricWorkingDay}
									</InputAdornment>
								),
							}}
						/>
					</TableCell>
					<TableCell>
						<SurrogateDetailContent text={row.first_work_date} />
					</TableCell>
					<TableCell>
						<SurrogateDetailContent text={row.contract_number} />
					</TableCell>
				</TableRow>
			))}
		</TableBody>
	);
};

export default SurrogateTableBody;
