import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getPreviousStudyUrl } from 'utilities/routes';
import useSubscribe from 'utilities/useSubscribe';

import { showSnackbar } from '../../../../../store/actions/snackbar';
import {
	// getSolutionDefaultParametersById,
	getSolutionParametersById,
	updateSolutionParametersAsync,
} from '../../ApiServices';

const parseDataToSubmit = (data) => {
	const response = {
		...data,
		absenteeism_rate: parseFloat(data.absenteeism_rate, 10),
		benefit: parseFloat(data.benefit, 10),
		material_costs: data.material_costs.map((material) => ({
			id: material.id,
			amount: material?.amount ? parseFloat(material.amount, 10) : 0,
			number_of_units: material?.number_of_units
				? parseFloat(material.number_of_units, 10)
				: 0,
			months_to_apply: material?.months_to_apply
				? parseFloat(material.months_to_apply, 10)
				: 0,
		})),
	};
	return response;
};

export default function useModifySimulationModal({
	setOpenModal,
	openModal,
	previousStudyId,
	setRefreshPage,
}) {
	const defaultValues = {
		absenteeism_rate: '',
		benefit: '',
		material_costs: [],
	};

	const [loadingSolution, setLoadingSolution] = useState(false);
	// const [loadingDefaults, setLoadingDefaults] = useState(false);
	const [uploadingSolution, setUploadingSolution] = useState(false);

	const dispatch = useDispatch();

	const { handleCreateSubscription } = useSubscribe();

	const { handleSubmit, control, reset } = useForm({
		defaultValues,
	});

	const showSnackBarMessage = ({ message = '' }) => {
		dispatch(
			showSnackbar({
				severity: 'error',
				message: message || 'Error desconocido en el servidor',
			})
		);
	};

	const getPreviousStudyParametersFromId = async (previousStudyId) => {
		try {
			setLoadingSolution(true);
			const { data } = await getSolutionParametersById({ previousStudyId });
			reset(data);
			setLoadingSolution(false);
		} catch (e) {
			setLoadingSolution(false);
			showSnackBarMessage({ message: e?.message });
		}
	};

	useEffect(() => {
		if (openModal) {
			getPreviousStudyParametersFromId(previousStudyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [previousStudyId, openModal]);

	const resetForm = () => {
		reset(defaultValues);
		setOpenModal(false);
	};

	const onSubmit = async (data) => {
		const payload = parseDataToSubmit(data);
		try {
			setUploadingSolution(true);
			await updateSolutionParametersAsync({
				previousStudyId,
				payload,
			});
			handleCreateSubscription({
				endpoint_url: getPreviousStudyUrl(previousStudyId),
				on_request: (response) => {
					if (response.sub_status === 'FINISHED') {
						resetForm();
						setUploadingSolution(false);
						setRefreshPage(true);
						return true;
					}
					if (response.sub_status === 'ERROR') {
						setUploadingSolution(false);
						showSnackBarMessage({
							message: 'Error al procesar el estudio previo',
						});
						return true;
					}
				},
				on_error: () => {},
				on_timeout: () => {
					setUploadingSolution(false);
					showSnackBarMessage({
						message: 'El servidor esta tardando bastante en responder',
					});
					return true;
				},
				max_timeout: 300,
			});
		} catch (e) {
			setUploadingSolution(false);
			showSnackBarMessage({ message: e?.message });
		}
	};

	// const onRestoreDefaults = async () => {
	// 	try {
	// 		setLoadingDefaults(true);
	// 		const { data } = await getSolutionDefaultParametersById({ simulationId });
	// 		reset(data);
	// 		setLoadingDefaults(false);
	// 	} catch (e) {
	// 		setLoadingDefaults(false);
	// 		showSnackBarMessage({ message: e?.message });
	// 	}
	// };

	return {
		handleSubmit,
		onSubmit,
		// loadingDefaults,
		// onRestoreDefaults,
		control,
		resetForm,
		loadingSolution,
		uploadingSolution,
	};
}
