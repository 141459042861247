import { useEffect, useState } from 'react';

const useIsMounted = () => {
	const [isMounted, setIsMounted] = useState(true);

	useEffect(() => () => setIsMounted(false), []);

	return { isMounted };
};

export default useIsMounted;
