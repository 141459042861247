const getMessage = (message) => {
	switch (message) {
		case 'excel can not be calculated':
			return 'Esta simulación ya no permite modificar parámetros. Hemos implantado una nueva funcionalidad incompatible con esta versión. Por favor, duplica y vuelve a lanzarla para modificar parámetros y probar la nueva funcionalidad.';
		case 'The agreement is already duplicated':
			return 'Ya existe una copia de este convenio.';
		case 'Wrong credentials provided':
			return 'Usuario y/o contraseña incorrecto';
		case 'Previous Study not have initial date':
			return 'No existe fecha inicial en el Estudio Previo';
		case 'Previous Study is solved':
			return 'Ya se ha procesado el Estudio Previo';
		case 'Previous Study not have month duration':
			return 'No existe duración del servicio en el Estudio Previo';
		case 'Previous Study not have social security':
			return 'No existe seguridad social en el Estudio Previo';
		case 'Previous Study not have absenteeism rate':
			return 'No existe porcentaje de absentismo en el Estudio Previo';
		case 'There are subrogates without category':
			return 'Existe subrogado sin categoría';
		case 'User Not Found':
			return 'Usuario incorrecto';
		case 'User does not have access to the workspace':
			return 'Su usuario no tiene acceso al espacio de trabajo';
		default:
			return message;
	}
};

export default function ApiException(message) {
	this.message = getMessage(message);
	this.name = 'ApiException';
	this.severity = 'error';
}
