import React, { useState } from 'react';

import { Box } from '@mui/material';

import { uploadSubrogatesCandidates } from 'Services/Api/SimulationOcr';

import subrogateError from './ScreenErrors';
import WorkingDayErrorTable from './WorkingDay';

const ErrorManager = ({
	simulationId,
	setCheckErrors,
	setReloadSurrogates,
}) => {
	const [errorScreen, setErrorScreen] = useState();

	const changeScreen = (screen) => {
		setErrorScreen(screen);
	};

	const functionWithSwitch = (errorWindow) => {
		switch (errorWindow) {
			case subrogateError.SAVESUBROGATE:
				uploadSubrogatesCandidates({ simulationId }).then(() => {
					setCheckErrors(false);
					setReloadSurrogates();
				});

				break;
			default:
				return (
					<WorkingDayErrorTable
						simulationId={simulationId}
						changeScreen={changeScreen}
						setReloadSurrogates={setReloadSurrogates}
					/>
				);
		}
	};

	return <Box>{functionWithSwitch(errorScreen)}</Box>;
};

export default ErrorManager;
