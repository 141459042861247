import React from 'react';
import { Controller } from 'react-hook-form';

import esLocale from 'date-fns/locale/es';

import { FormControl, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export const FormTimePicker = ({
	name,
	control,
	label,
	rules = {},
	componentProps = {},
	inputProps = {},
	...othersProps
}) => (
	<FormControl size='small' fullWidth>
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
					<TimePicker
						label={label}
						value={value}
						onChange={onChange}
						inputProps={{ ...inputProps }}
						animateYearScrolling
						{...othersProps}
						renderInput={(params) => (
							<TextField
								size='small'
								{...params}
								error={!!error}
								{...componentProps}
								helperText={error ? error.message : null}
							/>
						)}
					/>
				</LocalizationProvider>
			)}
		/>
	</FormControl>
);
