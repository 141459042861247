import React, { useEffect, useMemo } from 'react';

import { StyledButton } from 'components/StyledComponents/Button';
import translator from 'utilities/translator/translator';

import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
	ListItemText,
	List,
	Divider,
	Box,
	Grid,
	ListItem,
	ListItemAvatar,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { DialogActions } from '@material-ui/core';

const DialogValidateContent = ({
	simulationErrors,
	closeDialog,
	handleInitSimulation,
}) => {
	const errorCheck = useMemo(
		() => Object.keys(simulationErrors).length !== 0,
		[simulationErrors]
	);

	useEffect(() => {
		if (!errorCheck) {
			const timeout = setTimeout(() => {
				handleInitSimulation();
			}, 1500);
			return () => clearTimeout(timeout);
		}
		return () => {};
	}, [errorCheck, handleInitSimulation]);

	if (errorCheck) {
		return (
			<>
				<DialogContent sx={{ pl: '0px', pr: '0px' }}>
					<Box>
						<List>
							{simulationErrors.permissive.map((dataError) => (
								<>
									<ListItem>
										<ListItemAvatar>
											<WarningAmberIcon
												color='primary'
												sx={{ fontSize: 25, ml: 1 }}
											/>
										</ListItemAvatar>
										<ListItemText>{translator(dataError.msg)}</ListItemText>
									</ListItem>
									<Divider />
								</>
							))}
						</List>
					</Box>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						justifyContent='center'
						direction='row'
						alignItems='center'
						spacing={1}
					>
						<Grid item>
							<StyledButton
								variant='text'
								sx={{ color: 'text.secondary', textTransform: 'inherit' }}
								disabled={false}
								onClick={closeDialog}
							>
								Cancelar
							</StyledButton>
						</Grid>
						<Grid item>
							<StyledButton
								onClick={handleInitSimulation}
								sx={{ textTransform: 'inherit' }}
							>
								Ignorar y lanzar
							</StyledButton>
						</Grid>
					</Grid>
				</DialogActions>
			</>
		);
	}
	return (
		<DialogContent sx={{ pl: '0px', pr: '0px' }}>
			<Box sx={{ textAlign: 'center' }}>
				<DoneOutlineIcon color='primary' sx={{ fontSize: 70 }} />
			</Box>
		</DialogContent>
	);
};

export default React.memo(DialogValidateContent);
