import React from 'react';

import { Box } from '@mui/material';

import { WhiteTitleContainer } from '../../components';
import { ViewContainer } from '../../components/StyledContainers';
import Inventory from '../../containers/Inventory';

const InventoryView = () => (
	<>
		<WhiteTitleContainer
			routes={[{ name: 'Inicio', href: '/' }, { name: 'Costes materiales' }]}
			title='Costes materiales'
		/>
		<ViewContainer>
			<Box
				sx={{
					flexGrow: 1,
					bgcolor: 'background.paper',
					display: 'flex',
					width: '100%',
					m: 'auto',
					mt: '0px',
					p: 2,
				}}
			>
				<Inventory />
			</Box>
		</ViewContainer>
	</>
);

export default InventoryView;
