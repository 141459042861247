import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import useIsMounted from '../../../../hooks/useIsMounted';
import { setSnackbar } from '../../../../store/actions/snackbar';
import { getRoles, updateUserRole } from '../../ApiServices';

const parseRoles = (roles) =>
	roles.map((rol) => ({
		id: rol.id,
		label: rol.name,
		value: rol.id,
	}));

export default function useUserRoleModal({
	onClose,
	selectedUser,
	reloadUsers,
}) {
	const dispatch = useDispatch();
	const { auth } = useSelector((state) => state);
	const [roles, setRoles] = useState([]);
	const [loadingRoles, setLoadingRoles] = useState(false);
	const [submittingRole, setSubmittingRole] = useState(false);

	const { isMounted } = useIsMounted();
	const defaultValues = { role: '' };
	const { handleSubmit, control, setValue, getValues } = useForm({
		defaultValues,
	});

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const getUsersRoles = async () => {
		setLoadingRoles(true);
		try {
			if (isMounted) {
				const data = await getRoles();
				if (isMounted) {
					const parsed = parseRoles(data);
					const actualRole = parsed.find(
						(role) => role.id === selectedUser.role.id
					);
					setRoles(parsed);
					setValue('role', actualRole.id);
					setLoadingRoles(false);
				}
			}
		} catch (e) {
			setLoadingRoles(false);
			setSnackBarMessage(e?.response?.data?.message || e?.message || '');
		}
	};

	const onSubmitRole = async () => {
		setSubmittingRole(true);
		const payload = { role_id: getValues('role') };
		try {
			await updateUserRole(payload, auth.workSpace.id, selectedUser.id);
			reloadUsers((prev) => !prev);
			onClose();
			setSubmittingRole(false);
		} catch (e) {
			setSubmittingRole(false);
			setSnackBarMessage(e?.response?.data?.message || e?.message || '');
		}
	};

	useEffect(() => {
		if (selectedUser.id) getUsersRoles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser]);

	return {
		roles,
		loadingRoles,
		handleSubmit,
		control,
		onSubmitRole,
		submittingRole,
	};
}
