import React from 'react';

import EventIcon from '@mui/icons-material/Event';
import { Box, Checkbox, FormControlLabel, Tooltip } from '@mui/material';

import DialogLabelTypography from '../../DialogLabelTypography';
import { StyledToggleButton } from '../../StyledComponents/ToggleButton';

const checkLabel = (label) => (
	<DialogLabelTypography
		variant='caption'
		sx={{ fontWeight: 'bold', fontSize: '11px', color: 'black' }}
		label={label}
	/>
);

const FixedShiftsBottom = ({
	holidaysChecked,
	setHolidaysChecked,
	weekDaysChecked,
	setWeekDaysChecked,
	noShiftsChecked,
	setNoShiftsChecked,
	applyOnHolidays,
	setApplyOnHolidays,
}) => (
	<Box mt={2} mb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
		<FormControlLabel
			label={
				<Tooltip
					placement='right'
					title='Se planificarán los días de la semana seleccionados, también cuando caigan en festivo.'
				>
					<span>{checkLabel('Aplica en festivos')}</span>
				</Tooltip>
			}
			control={
				<Checkbox
					checked={applyOnHolidays}
					onChange={(event) => setApplyOnHolidays(event.target.checked)}
					inputProps={{
						'aria-label': 'applyHolidaysCheckbox',
					}}
				/>
			}
		/>
		<FormControlLabel
			label={
				<Tooltip
					placement='right'
					title='Aplica en los 14 festivos del año. De forma adicional, puede aplicar en los días de la semana seleccionados (sean festivos o no).'
				>
					<span sx={{ fontSize: '11px' }}>
						{checkLabel('Todos los festivos')}
					</span>
				</Tooltip>
			}
			control={
				<Checkbox
					checked={holidaysChecked}
					onChange={(event) => setHolidaysChecked(event.target.checked)}
					inputProps={{
						'aria-label': 'applyAllHolidaysCheckbox',
					}}
				/>
			}
		/>
		<FormControlLabel
			label={checkLabel('Lunes-Viernes')}
			control={
				<Checkbox
					checked={weekDaysChecked}
					onChange={(event) => setWeekDaysChecked(event.target.checked)}
				/>
			}
		/>
		<StyledToggleButton
			value='check'
			selected={noShiftsChecked}
			onChange={() => {
				setNoShiftsChecked(!noShiftsChecked);
			}}
			size='small'
			style={{
				textTransform: 'none',
				fontWeight: 800,
				fontSize: '12px',
				paddingLeft: '20px',
				paddingRight: '20px',
			}}
		>
			<EventIcon sx={{ marginRight: 0.4 }} />
			Sin turno
		</StyledToggleButton>
	</Box>
);
export default FixedShiftsBottom;
