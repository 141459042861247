import React from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import { Box } from '@mui/material';

import { formatNumber } from '../../utilities/utils';

const FloatWithEuroIcon = ({ amount, label }) => (
	<Box mt={2} sx={{ display: 'flex' }}>
		<Box>{label}</Box>
		<Box ml={1} sx={{ display: 'flex' }}>
			{amount ? (
				<>
					{formatNumber(amount)}
					<EuroIcon sx={{ ml: 0.0, width: '14px' }} />
				</>
			) : (
				'-'
			)}
		</Box>
	</Box>
);
export default FloatWithEuroIcon;
