import React, { Component } from 'react';

import { Card, Typography } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

class AppDataCard extends Component {
	renderTitle() {
		const { title, theme } = this.props;
		if (!title) return null;
		return (
			<Typography
				variant='h6'
				align='left'
				style={{ fontWeight: 600, color: theme.palette.secondary.light }}
			>
				{title}
			</Typography>
		);
	}

	render() {
		const {
			theme,
			children,
			style = {},
			borderLeft = `5px solid ${theme.palette.primary.main}`,
		} = this.props;

		return (
			<Card
				style={{
					borderLeft,
					...style,
				}}
			>
				<div style={{ margin: 10, marginLeft: 20, marginRight: 20 }}>
					{this.renderTitle()}
					{children}
				</div>
			</Card>
		);
	}
}

export default withTheme(AppDataCard);
