import React from 'react';
import { Controller } from 'react-hook-form';

import { TextField } from '@mui/material';

export const FormInputText = ({
	name,
	control,
	label,
	rules = {},
	...inputProps
}) => (
	<Controller
		name={name}
		control={control}
		rules={rules}
		render={({ field: { onChange, value }, fieldState: { error } }) => (
			/* eslint-disable react/jsx-props-no-spreading */
			<TextField
				helperText={error ? error.message : null}
				size='small'
				error={!!error}
				onChange={onChange}
				value={value}
				fullWidth
				label={label}
				variant='outlined'
				InputProps={{ ...inputProps }}
			/>
		)}
	/>
);
