export default function events(state = null, action) {
	const { type, fetching, events } = action;
	switch (type) {
		case 'SET_EVENTS_LIST':
			return { ...state, list: events };
		case 'RESET_EVENTS_LIST':
			return { fetching: true, list: [] };
		case 'SET_FETCHING_STATE_EVENTS':
			return { ...state, fetching };
		default:
			return state;
	}
}
