import { get } from 'Services/Api/Api';

import useGetResponseFromApi from '../../hooks/useGetResponseFromApi';
import { LaunchResponse } from '../../utilities/class/LaunchResponse';
import AxiosWrapper from '../../utilities/connection/AxiosWrapper';
import {
	getCategoryPositionsUrl,
	getUserAgreementsUrl,
	getLocalitiesUrl,
	getPositionsUrl,
	getSimulationCentersUrl,
	getSimulationUrl,
	putCentersUrl,
	getFixedWorkShiftUrl,
	deleteShiftUrl,
	updateFixedWorkShiftUrl,
	getFixedWorkShiftListUrl,
	getContractTypesUrl,
	getCategoriesUrl,
	launchSimulationUrl,
	duplicateSimulationUrl,
	getSolutionExcelUrl,
	getSolutionEmployeesUrl,
	getValidateSimulationUrl,
	convertToSimulationUrl,
} from '../../utilities/routes';
import { post } from './Api';
import ApiException from './ApiException';

export function getSimulation({ simulationId, reloadSimulation }) {
	return useGetResponseFromApi({
		url: getSimulationUrl(simulationId),
		payload: {},
		refresh: reloadSimulation,
	});
}

export async function getSimulations({ simulationId }) {
	const url = getSimulationUrl(simulationId);
	try {
		const { data } = await AxiosWrapper.get(url);
		return data;
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function updateSimulation({ simulationId, payload }) {
	const url = `simulation/${simulationId}`;
	try {
		const { data } = await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function removeSimulation(simulationId) {
	const url = `simulation/${simulationId}`;
	try {
		await AxiosWrapper.delete(url);
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export function getSimulationCenters({ simulationId, reloadCenters }) {
	return useGetResponseFromApi({
		url: getSimulationCentersUrl(simulationId),
		payload: {},
		refresh: reloadCenters,
	});
}

export async function getSimulationsCenters({ simulationId }) {
	const url = getSimulationCentersUrl(simulationId);
	try {
		const { data } = await AxiosWrapper.get(url);
		return data;
	} catch (e) {
		throw new ApiException(
			e?.response?.data?.message || 'Error desconocido en el servidor'
		);
	}
}

export async function updateSimulationCenters({ simulationId, payload }) {
	const url = putCentersUrl(simulationId);
	const response = await AxiosWrapper.put(url, JSON.stringify(payload));
	return response;
}

export function getPositions(simulationId, refresh) {
	return useGetResponseFromApi({
		url: getPositionsUrl(simulationId),
		refresh: refresh,
	});
}

export async function getPositionsByCenter({ simulationId, center }) {
	const url = getPositionsUrl(simulationId);
	try {
		const positionByCenter = await AxiosWrapper.get(
			`${url}?center_id=${center.id}`
		);
		return positionByCenter.data;
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export function getCategoryPositions(simulationId, refresh) {
	return useGetResponseFromApi({
		url: getCategoryPositionsUrl(simulationId),
		refresh: refresh,
	});
}

export async function updateCategoryPositions({ simulationId, payload }) {
	const url = getCategoryPositionsUrl(simulationId);
	const response = await AxiosWrapper.put(url, JSON.stringify(payload));
	return response;
}

export const getContractTypes = () => {
	return useGetResponseFromApi({
		url: getContractTypesUrl(),
	});
};

export async function deleteShiftById({ simulationId, shiftId }) {
	const url = deleteShiftUrl(simulationId, shiftId);
	const response = await AxiosWrapper.delete(url);
	return response;
}
export const getFixedWorkShiftList = ({ simulationId, refresh }) =>
	useGetResponseFromApi({
		url: getFixedWorkShiftListUrl(simulationId),
		refresh: refresh,
	});

export async function getFixedWorkShift({ simulationId, fixedWorkShiftId }) {
	const url = getFixedWorkShiftUrl(simulationId, fixedWorkShiftId);
	const response = await AxiosWrapper.get(url);
	return response;
}

export async function uploadFixedWorkShift({ simulationId, payload }) {
	const url = getFixedWorkShiftListUrl(simulationId);
	const response = await AxiosWrapper.post(url, JSON.stringify(payload));
	return response;
}

export async function updateFixedWorkShift({
	simulationId,
	fixedWorkShiftId,
	payload,
}) {
	const url = updateFixedWorkShiftUrl(simulationId, fixedWorkShiftId);
	const response = await AxiosWrapper.put(url, JSON.stringify(payload));
	return response;
}

export async function getCategories({ agreementId }) {
	const url = getCategoriesUrl(agreementId);
	const response = await AxiosWrapper.get(url);
	return response;
}

export async function launchSimulation({ simulationId }) {
	const url = launchSimulationUrl(simulationId);
	try {
		return await AxiosWrapper.post(url);
	} catch (e) {
		if (e?.response.status === 460) {
			const message = LaunchResponse.getResponseLabelByCode(
				e?.response.data.error_code
			);
			throw new ApiException(message);
		}
	}
}

export async function duplicateSimulation(simulationId, newSimulationId) {
	const url = duplicateSimulationUrl();
	const payload = {
		origin_simulation: simulationId,
		new_simulation: newSimulationId,
	};
	return await post(url, payload);
}

export async function SolutionExcelDownloadUrl(simulationId) {
	const url = getSolutionExcelUrl(simulationId);
	const response = await AxiosWrapper.get(url);
	return response.data;
}

export async function getSolutionEmployees(simulationId) {
	const url = getSolutionEmployeesUrl(simulationId);
	try {
		const solutionEmployees = await AxiosWrapper.get(url);
		return solutionEmployees.data;
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function updateEmployeesSS({ simulationId, payload }) {
	const url = getSolutionEmployeesUrl(simulationId);
	try {
		const { data } = await AxiosWrapper.put(url, JSON.stringify(payload));
		return data;
	} catch (e) {
		if (e?.response.data.message) {
			throw new ApiException(e?.response.data.message);
		}
	}
}

export async function validateSimulation(simulationId) {
	const url = getValidateSimulationUrl(simulationId);
	const response = await get(url);
	return response;
}
export async function convertPreviousStudyToSimulation(previousStudyId) {
	const url = convertToSimulationUrl(previousStudyId);
	const simulation_id = await post(url);
	return simulation_id;
}
