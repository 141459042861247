import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {
	getWorkSpaceById,
	getWorkSpaces,
} from '../../Services/Api/Administration';
import { getUserPermissions } from '../../Services/Api/App';
import useIsMounted from '../../hooks/useIsMounted';
import {
	setWorkSpace,
	updatePermissions,
	setLoadingWorkSpace,
} from '../../store/actions/auth';
import { setSnackbar } from '../../store/actions/snackbar';
import IsLoading from '../IsLoading/IsLoading';

export const WorkSpace = () => {
	const { auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const history = useHistory();

	const { isMounted } = useIsMounted();
	const [selectedWorkSpace, setSelectedWorkSpace] = useState('');
	const [workSpaces, setWorkSpaces] = useState([]);

	const setSnackBarMessage = useCallback(
		(message) => {
			dispatch(
				setSnackbar({
					openSnackbar: true,
					duration: 6000,
					severity: 'error',
					message,
				})
			);
		},
		[dispatch]
	);

	const getPermissionByWorkSpace = useCallback(
		async (workSpaceId, workSpacesList) => {
			try {
				const newWorkSpace = await getWorkSpaceById(workSpaceId);
				dispatch(setWorkSpace(newWorkSpace));
				const workSpacesRoleId = workSpacesList.find(
					(item) => item?.id === workSpaceId
				);
				if (workSpacesRoleId?.role_id) {
					const { permissions } = await getUserPermissions(
						workSpacesRoleId.role_id
					);
					dispatch(updatePermissions(permissions));
				}
			} catch (e) {
				setSnackBarMessage(e?.response?.data?.message || e?.message || '');
			}
		},
		[dispatch, setSnackBarMessage]
	);

	const handleChange = async (event) => {
		dispatch(setLoadingWorkSpace(true));
		try {
			await getPermissionByWorkSpace(event.target.value, workSpaces);
			setSelectedWorkSpace(event.target.value);
			history.push('/');
			dispatch(setLoadingWorkSpace(false));
		} catch (e) {
			setSnackBarMessage(e?.response?.data?.message || e?.message || '');
			dispatch(setLoadingWorkSpace(false));
		}
	};

	const getWorkSpacesList = useCallback(async () => {
		dispatch(setLoadingWorkSpace(true));
		try {
			const workSpacesList = await getWorkSpaces(false);
			if (isMounted) {
				setWorkSpaces(workSpacesList);
				const [firstWorkSpace] = workSpacesList;
				if (firstWorkSpace) {
					const workSpace = auth?.workSpace?.id
						? auth.workSpace
						: firstWorkSpace;
					await getPermissionByWorkSpace(workSpace.id, workSpacesList);
					setSelectedWorkSpace(workSpace?.id);
				}
			}
			dispatch(setLoadingWorkSpace(false));
		} catch (e) {
			setSnackBarMessage(e?.response?.data?.message || e?.message || '');
			dispatch(setLoadingWorkSpace(false));
		}
	}, [auth, dispatch, getPermissionByWorkSpace, isMounted, setSnackBarMessage]);

	useEffect(() => {
		getWorkSpacesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box mr={auth.is_loading ? '30px' : '20px'}>
			<IsLoading conditional={auth.is_loading} color='white'>
				<FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
					<Select
						value={selectedWorkSpace}
						onChange={handleChange}
						label='Age'
						sx={{
							color: 'white',
							'.MuiSvgIcon-root ': {
								fill: 'white !important',
							},
							'&:before': {
								borderColor: 'white',
							},
							'&:after': {
								borderColor: 'white',
							},
						}}
					>
						{workSpaces.map((workSpace) => (
							<MenuItem key={workSpace.id} value={workSpace.id}>
								{workSpace.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</IsLoading>
		</Box>
	);
};
