import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import { ViewContainer } from '../../components/StyledContainers';
import NewProjectAgreements from './NewProjectAgreements';
import NewProjectBid from './NewProjectBid';
import NewProjectGeneral from './NewProjectGeneral';

function getSteps() {
	return ['General', 'Convenio', 'Pliego'];
}

class NewProject extends Component {
	state = {
		projectName: '',
		projectDuration: '',
		projectLocation: '',
		activeStep: 0,
		projectData: {},
	};

	getStepContent() {
		const { activeStep } = this.state;
		switch (activeStep) {
			case 0:
				return (
					<NewProjectGeneral
						emitData={(formData) => this.collectData(formData)}
					/>
				);
			case 1:
				return (
					<NewProjectAgreements
						emitData={(formData) => this.collectData(formData)}
					/>
				);
			case 2:
				return (
					<NewProjectBid emitData={(formData) => this.collectData(formData)} />
				);
			default:
				return 'Unknown step';
		}
	}

	collectData = (formData) => {
		const { projectData } = this.state;
		this.setState({ projectData: { ...projectData, ...formData } });
	};

	handleInputChange = (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;

		this.setState({
			[name]: value,
		});
	};

	handleNext = () => {
		const { activeStep, projectData } = this.state;
		const { saveProject } = this.props;
		if (activeStep === getSteps().length - 1) {
			if ('projectName' in projectData) {
				saveProject({ name: projectData.projectName });
			}
		} else {
			this.setState({ activeStep: activeStep + 1 });
		}
	};

	handleBack = () => {
		const { activeStep } = this.state;
		this.setState({ activeStep: activeStep - 1 });
	};

	renderSteps = getSteps().map((label, index) => {
		const stepProps = {};
		const labelProps = {};
		return (
			<Step key={label} {...stepProps}>
				<StepLabel {...labelProps}>{label}</StepLabel>
			</Step>
		);
	});

	render() {
		const { activeStep } = this.state;

		return (
			<ViewContainer>
				<Stepper activeStep={activeStep}>{this.renderSteps}</Stepper>
				{this.getStepContent()}
				<Button disabled={activeStep === 0} onClick={this.handleBack}>
					Back
				</Button>
				<Button variant='contained' color='primary' onClick={this.handleNext}>
					{activeStep === getSteps().length - 1 ? 'Finish' : 'Next'}
				</Button>
			</ViewContainer>
		);
	}
}

export default NewProject;
