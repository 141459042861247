const initial = {
	openSnackbar: false,
	duration: 6000,
	severity: 'success',
	message: '',
};
export default function snackbar(state = initial, { type, snackbar } = {}) {
	switch (type) {
		case 'RESET_STATE':
			return { ...initial };
		case 'SET_SNACKBAR':
			return { ...state, ...snackbar };
		case 'SHOW_SNACKBAR':
			return { ...state, ...snackbar };
		case 'CLOSE_SNACKBAR':
			return {
				...state,
				openSnackbar: false,
				duration: 6000,
				severity: 'success',
				message: '',
			};
		default:
			return state;
	}
}
