import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import translator from 'utilities/translator/translator';
import { v4 as uuidv4 } from 'uuid';

import {
	createSurrogate,
	getAgreements,
	getContractTypes,
	getSurrogate,
	updateSurrogate,
} from '../../../../Services/Api/PreviousStudy';
import { getCategories } from '../../../../Services/Api/Simulation';
import { setSnackbar } from '../../../../store/actions/createSimulation';

const journeyOptions = [
	{ id: 1, label: 'Horas/semana', value: 'hourPerWeek' },
	{ id: 2, label: 'Porcentaje', value: 'percent' },
];

const parseDataToSubmit = (data, initialValues) => {
	const values = {
		id: initialValues ? initialValues : uuidv4(),
		category_id: data?.categoryPosition?.id || null,
		working_day: data?.journeyValue ? Number(data.journeyValue) : null,
		value_metric_working_day: data?.journey === 'percent' ? 'PERCENT' : 'FIXED',
		contract_type_id: data?.contractType?.id || null,
		salary_year: data?.salaryYear ? Number(data.salaryYear) : null,
		annual_bonuses: data?.annualBonuses ? Number(data.annualBonuses) : null,
		beginning_year: data?.seniorityDate
			? moment(data?.seniorityDate).format('YYYY-MM-DD')
			: null,
	};
	return values;
};

const defaultsValues = ({ agreement = null, categoryPosition = null }) => ({
	agreement,
	categoryPosition,
	salaryYear: '',
	annualBonuses: '',
	contractType: null,
	seniorityDate: null,
	journey: 'hourPerWeek',
	journeyValue: '',
});

export default function useSurrogateModal({
	setOpenModal,
	setReloadSurrogates,
	surrogateId,
	setSurrogateId,
}) {
	const dispatch = useDispatch();
	const {
		uid: previousStudyId,
		service_date_init,
		service_duration,
	} = useSelector((state) => state.previousStudy);
	const { auth } = useSelector((state) => state);

	const [agreements, setAgreements] = useState({ data: [], loading: false });
	const [edit, setEdit] = useState(false);
	const [uploadingSurrogate, setUploadingSurrogate] = useState(false);
	const [loadingSurrogate, setLoadingSurrogate] = useState(false);
	const [categories, setCategories] = useState([]);
	const [contractTypes, setContractTypes] = useState([]);
	const defaultValues = defaultsValues({});
	const { handleSubmit, control, watch, setValue, reset } = useForm({
		defaultValues,
	});
	const [loadingContractTypes, setLoadingContractTypes] = useState(false);
	const [isPossibleRetirement, setIsPossibleRetirement] = useState(false);

	const dispatchErrorMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const getCategoryList = async (agreementId) => {
		try {
			const response = await getCategories({ agreementId });
			if (response?.status === 200) {
				setCategories(response.data);
			}
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(message);
		}
	};

	const setFormDefaultValues = async (surrogate) => {
		const journeySelector =
			surrogate?.value_metric_working_day === 'PERCENT'
				? 'percent'
				: 'hourPerWeek';

		const defaultContractType = surrogate?.contract_type
			? {
					...surrogate.contract_type,
					label: `${surrogate.contract_type.contract_key}-${surrogate.contract_type.description}`,
			  }
			: null;

		const surrogateAgreement =
			surrogate?.agreement && agreements?.data?.length > 0
				? agreements.data.filter(
						(option) => surrogate.agreement.id === option.uid
				  )[0]
				: null;

		if (surrogate && surrogateAgreement?.uid) {
			getCategoryList(surrogateAgreement.uid);
		} else if (defaultValues?.agreement?.id) {
			getCategoryList(defaultValues.agreement.id);
		}

		setValue(
			'agreement',
			surrogate
				? surrogateAgreement
				: agreements?.data?.length > 0
				? agreements.data[0]
				: null
		);
		setValue(
			'categoryPosition',
			surrogate?.category
				? {
						...surrogate.category,
						uid: surrogate.category.id,
						professional_category: surrogate.category.name,
				  }
				: null
		);
		setValue('salaryYear', surrogate?.salary_year || '');
		setValue('annualBonuses', surrogate?.annual_bonuses || '');
		setValue('contractType', defaultContractType);
		setValue('seniorityDate', surrogate?.beginning_year || null);
		setValue('journey', journeySelector);
		setValue('journeyValue', surrogate?.working_day || '');
	};

	const getAgreementsList = async () => {
		try {
			const response = await getAgreements(auth?.workSpace?.id);
			setAgreements(response);
			if (!surrogateId && response?.data[0]?.uid) {
				getCategoryList(response.data[0].uid);
				setValue('agreement', response.data[0]);
			}
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(message);
		}
	};

	const getContractTypesList = async () => {
		setLoadingContractTypes(true);
		try {
			const { data } = await getContractTypes();
			const contractTemp = data?.map((contract) => {
				contract.label = `${contract.contract_key}-${contract.description}`;
				return contract;
			});
			setContractTypes(contractTemp);
			setLoadingContractTypes(false);
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(message);
			setLoadingContractTypes(false);
		}
	};

	useEffect(() => {
		getAgreementsList();
		getContractTypesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth?.workSpace?.id]);

	const initialDataState = async ({ surrogateId }) => {
		try {
			setLoadingSurrogate(true);

			const response = await getSurrogate({
				previousStudyId,
				surrogateId,
			});

			await setFormDefaultValues(response);

			setLoadingSurrogate(false);
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(message);
			setLoadingSurrogate(false);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		if (watch('agreement')?.uid && !loadingSurrogate) {
			getCategoryList(watch('agreement')?.uid);
			setValue('categoryPosition', null);
		}
		setCategories([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('agreement')]);

	useEffect(() => {
		if (surrogateId) {
			initialDataState({ surrogateId });
			setEdit(true);
		} else {
			setEdit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surrogateId]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		const year = new Date(watch('seniorityDate')).getFullYear();
		const previousStudyDate = new Date(service_date_init);
		previousStudyDate.setMonth(previousStudyDate.getMonth() + service_duration);
		const previousStudyYear = previousStudyDate.getFullYear();

		if (watch('seniorityDate') && previousStudyYear - year > 35) {
			setIsPossibleRetirement(true);
		} else {
			setIsPossibleRetirement(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('seniorityDate')]);

	const closeDialog = () => {
		setFormDefaultValues();
		setOpenModal(false);
		setEdit(false);
		setSurrogateId('');
	};

	const onSubmit = async (data) => {
		const valuesToEdit = parseDataToSubmit(data, surrogateId);
		const valuesToCreate = parseDataToSubmit(data, null);
		setUploadingSurrogate(true);
		try {
			if (edit) {
				await updateSurrogate({
					previousStudyId,
					surrogateId,
					payload: valuesToEdit,
				});
			} else {
				await createSurrogate({
					previousStudyId,
					payload: valuesToCreate,
				});
			}
			setReloadSurrogates();
			closeDialog();
			setUploadingSurrogate(false);
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(translator(message));
			setUploadingSurrogate(false);
		}
	};

	const onSubmitAndContinue = async (data) => {
		const valuesToEdit = parseDataToSubmit(data, surrogateId);
		const valuesToCreate = parseDataToSubmit(data, null);
		setUploadingSurrogate(true);
		try {
			if (edit) {
				await updateSurrogate({
					previousStudyId,
					surrogateId,
					payload: valuesToEdit,
				});
				setEdit(false);
			} else {
				await createSurrogate({
					previousStudyId,
					payload: valuesToCreate,
				});
			}
			setReloadSurrogates();
			const { agreement, categoryPosition } = data;
			const resetDefaultValues = defaultsValues({
				agreement,
				categoryPosition,
			});
			reset(resetDefaultValues);
			setUploadingSurrogate(false);
		} catch (e) {
			const message = e?.response?.data?.message || e?.message;
			dispatchErrorMessage(translator(message));
			setUploadingSurrogate(false);
		}
	};

	return {
		uploadingSurrogate,
		loadingSurrogate,
		control,
		agreements,
		categories,
		journeyOptions,
		loadingContractTypes,
		contractTypes,
		handleSubmit,
		onSubmit,
		onSubmitAndContinue,
		watch,
		closeDialog,
		isPossibleRetirement,
	};
}
