import React from 'react';

import HeaderTableCell from 'components/StyledComponents/HeaderTableCell';

import {
	Button,
	ButtonGroup,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';

const SurrogateTableBody = ({
	changeType,
	colorOnClick,
	valueMetricWorkingDay,
}) => (
	<TableHead>
		<TableRow>
			<HeaderTableCell />
			<HeaderTableCell>Convenio</HeaderTableCell>
			<HeaderTableCell>Categoría</HeaderTableCell>
			<HeaderTableCell align='left'>
				<ButtonGroup
					variant='outlined'
					aria-label='outlined button group'
					size='large'
					sx={{ color: 'primary.main' }}
				>
					<Button
						sx={{
							color: 'primary.main',
							backgroundColor:
								valueMetricWorkingDay === 'h/s' ? colorOnClick : 'transparency',
							textTransform: 'inherit',
						}}
						onClick={() => changeType('FIXED')}
					>
						<Typography sx={{ fontWeight: '800' }}>h/s</Typography>
					</Button>
					<Button
						sx={{
							color: 'primary.main',
							backgroundColor:
								valueMetricWorkingDay === '%' ? colorOnClick : 'transparency',
							textTransform: 'inherit',
						}}
						onClick={() => changeType('PERCENT')}
					>
						<Typography sx={{ fontWeight: '800' }}>%</Typography>
					</Button>
				</ButtonGroup>
			</HeaderTableCell>
			<HeaderTableCell>Antigüedad</HeaderTableCell>
			<HeaderTableCell>Tipo de Contrato</HeaderTableCell>
		</TableRow>
	</TableHead>
);

export default SurrogateTableBody;
