import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import {
	Table,
	TableBody,
	TableHead,
	TableRow,
	Box,
	Tooltip,
} from '@mui/material';

import IsSimulationEditable from '../../../../../components/Simulations/IsSimulationEditable';
import { StyledBoxContainer } from '../../../../../components/StyledComponents/BoxContainer';
import { ContentTableCell } from '../../../../../components/StyledComponents/ContentTableCell';
import HeaderTableCell from '../../../../../components/StyledComponents/HeaderTableCell';
import { StyledIcon } from '../../../../../components/StyledComponents/Icon';
import useTaskByHourTable from './useTaskByHourTable';

const TaskByHourTable = ({ rows = [], deleteTask, editTask }) => {
	const { isValidMessage, tooltipText, Frequency } = useTaskByHourTable();

	return (
		<StyledBoxContainer>
			<Table aria-label='Turnos por horas'>
				<TableHead>
					<TableRow>
						<HeaderTableCell>Centro</HeaderTableCell>
						<HeaderTableCell>Tarea</HeaderTableCell>
						<HeaderTableCell>Horas y frecuencia</HeaderTableCell>
						<HeaderTableCell>Puesto</HeaderTableCell>
						<HeaderTableCell sx={{ width: 1 / 6 }} />
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row?.id}>
							<ContentTableCell>{row?.center?.name || '-'}</ContentTableCell>
							<ContentTableCell>{`${row?.name}` || '-'}</ContentTableCell>
							<ContentTableCell>
								{`${Frequency(row?.frequency, row?.work_time)}` || '-'}
								{isValidMessage(row) && (
									<Tooltip
										placement='top'
										title={tooltipText(
											row?.weekdays,
											row?.exists_recommended_time_slot,
											row?.work_in_holidays,
											row?.exists_period_with_or_wo_work,
											row?.weekday_work_in_holidays
										)}
									>
										<InfoIcon color='primary' />
									</Tooltip>
								)}
							</ContentTableCell>
							<ContentTableCell>
								{`${row?.number_of_workers > 1 ? row?.number_of_workers : ''} `}
								{`${row?.position?.name ? row?.position?.name : '-'}`}
							</ContentTableCell>

							<ContentTableCell sx={{ maxWidth: 10 }}>
								<Box sx={{ width: 1 / 5, display: 'flex' }}>
									<IsSimulationEditable>
										<StyledIcon
											variant='contained'
											color='primary'
											aria-label='delete'
											onClick={() => deleteTask(row?.id)}
										>
											<DeleteIcon />
										</StyledIcon>
									</IsSimulationEditable>
									<StyledIcon
										sx={{ marginLeft: 1 }}
										variant='contained'
										color='primary'
										aria-label='edit'
										onClick={() => editTask(row?.id)}
									>
										<EditIcon />
									</StyledIcon>
								</Box>
							</ContentTableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</StyledBoxContainer>
	);
};

export default TaskByHourTable;
