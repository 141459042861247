import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSnackbar } from '../../../store/actions/createSimulation';
import { deleteMaterialCostById, getMaterialCosts } from './ApiServices';

export default function useMaterialCost() {
	const dispatch = useDispatch();
	const { simulationId } = useSelector((state) => state.createSimulation);
	const [edit, setEdit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [reloadMaterialCosts, setReloadMaterialCosts] = useState(false);
	const [materialCostId, setMaterialCostId] = useState('');
	const {
		loading: loadingMaterialCosts,
		data: materialCostsData,
		error: errorMaterialCosts,
	} = getMaterialCosts({
		simulationId,
		refresh: reloadMaterialCosts,
	});

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	const deleteMaterialCosts = async (materialCostId) => {
		try {
			await deleteMaterialCostById({ simulationId, materialCostId });
			setReloadMaterialCosts((prev) => !prev);
		} catch (e) {
			setSnackBarMessage(e?.message);
		}
	};

	const setErrorMessage = (error) => {
		let errorMessage = error;

		if (error === 'Network Error') {
			errorMessage = 'Error de red';
		}

		setSnackBarMessage(errorMessage);
	};

	useEffect(() => {
		if (!loadingMaterialCosts)
			if (errorMaterialCosts) {
				setErrorMessage(errorMaterialCosts.message);
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorMaterialCosts, loadingMaterialCosts]);

	return {
		openModal,
		setOpenModal,
		edit,
		setEdit,
		materialCostsData,
		materialCostId,
		setMaterialCostId,
		loadingMaterialCosts,
		errorMaterialCosts,
		deleteMaterialCosts,
		setReloadMaterialCosts,
	};
}
