import React, { Component } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
	Avatar,
	CssBaseline,
	FormControlLabel,
	Checkbox,
	Paper,
	Typography,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import { getToken } from '../../Services/Api/App';
import loginBg from '../../assets/imgs/login-bg.png';
import LoginIcon from '../../assets/imgs/loginIcon.svg';
import Logo from '../../assets/imgs/logo.svg';
import { AppInput } from '../../components';
import { StyledLoadingButton } from '../../components/StyledComponents/LoadingButton';
import { clearLocalStorage, sleep } from '../../utilities/utils';

const ButtonContainer = styled(Box)`
	margin-top: 10px;
	align-self: center;
`;
const LoginContainer = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const MainBox = styled(Box)`
	margin-top: 0px;
	background-image: url(${loginBg});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	z-index: -2;
`;
const IconContainer = styled(Avatar)`
	transform: translate(0px, -75%);
	position: absolute;
	width: 70px;
	height: 70px;
	background-color: #fff;
`;

const LogoContainer = styled(Box)`
	padding-bottom: 80px;
`;

const styles = (theme) => ({
	main: {
		width: '100%',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
		paddingTop: theme.spacing(8),
		paddingButton: theme.spacing(0),
		minHeight: '100%',
		height: '100vh',
		position: 'relative',
		[theme.breakpoints.up(400 + theme.spacing(6))]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		zIndex: 1,
	},
	paper: {
		// paddingTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
			3
		)}px`,
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
});

function ErrorSpan(error) {
	if (error.error) {
		return (
			<Typography variant='subtitle1' color='error'>
				{error.error}
			</Typography>
		);
	} else {
		return null;
	}
}

class SignIn extends Component {
	state = {
		email: '',
		password: '',
		gettingToken: false,
		error: '',
	};

	handleInputChange = (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;

		this.setState({
			[name]: value,
		});
	};

	login = async (email, password, device, expires = false) => {
		const { setToken, history } = this.props;
		this.setState({ gettingToken: true });
		const payload = { email, password, device, expires };
		try {
			const response = await getToken(payload);
			await setToken(response);
			await sleep(100);
			this.setState({ gettingToken: false });
			history.push('/projects');
		} catch (e) {
			{
				this.setState({ gettingToken: false, error: e.message });
			}
		}
	};

	componentDidMount() {
		clearLocalStorage();
	}

	handleKeyPress = (event) => {
		const { expires, device } = this.props;
		const { email, password } = this.state;
		if (event.key === 'Enter') {
			this.login(email, password, device, expires ? expires : false);
		}
	};

	render() {
		const { classes, expires, device } = this.props;
		const { email, password } = this.state;

		return (
			<MainBox>
				<main className={classes.main}>
					<CssBaseline />
					<LogoContainer>
						<img src={Logo} />
					</LogoContainer>
					<Paper className={classes.paper}>
						<IconContainer>
							<img src={LoginIcon} />
						</IconContainer>
						<Box mt='15px'>
							<Typography component='h1' variant='h5' color='textPrimary'>
								Sign in
							</Typography>
						</Box>
						<LoginContainer onKeyPress={this.handleKeyPress}>
							<AppInput
								margin='dense'
								label='Email Adress'
								type='text'
								name='email'
								value={email}
								onChange={(event) => this.handleInputChange(event)}
							/>
							<AppInput
								margin='dense'
								label='Password'
								type='password'
								name='password'
								value={password}
								onChange={(event) => this.handleInputChange(event)}
							/>
							<ErrorSpan error={this.state.error} />
							<FormControlLabel
								control={<Checkbox value='remember' color='primary' />}
								label='Remember me'
							/>
							<ButtonContainer>
								<StyledLoadingButton
									loading={this.state.gettingToken}
									type='submit'
									variant='contained'
									onClick={() =>
										this.login(
											email,
											password,
											device,
											expires ? expires : false
										)
									}
								>
									Sign in
								</StyledLoadingButton>
							</ButtonContainer>
						</LoginContainer>
					</Paper>
				</main>
			</MainBox>
		);
	}
}

export default withStyles(styles)(SignIn);
