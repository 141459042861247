import React, { Component } from 'react';

import { Grid } from '@mui/material';

import { Typography } from '@material-ui/core';

import BreadCrumb from '../Breadcrumb/Breadcrumb';
import { ViewContainer, TitleContainer } from '../StyledContainers';

export default class WhiteTitleContainer extends Component {
	render() {
		const { title, routes, rightSide, twoField = false } = this.props;
		return (
			<div>
				<ViewContainer>
					<BreadCrumb routes={routes} />
					<TitleContainer>
						<Grid container item xs={12} justifyContent='space-between'>
							<Grid item md={twoField ? 6 : 9.5} lg={twoField ? 6 : 10}>
								<Typography
									variant='h5'
									style={{ fontWeight: 'bold', color: 'black' }}
								>
									{title}
								</Typography>
							</Grid>
							<Grid item md={twoField ? 6 : 2.5} lg={twoField ? 6 : 2}>
								{rightSide}
							</Grid>
						</Grid>
					</TitleContainer>
				</ViewContainer>
			</div>
		);
	}
}
