export default function profile(state = null, action) {
	const { type, profile, fetching } = action;

	switch (type) {
		case 'RESET_PROFILE':
			return {};
		case 'SET_PROFILE':
			return { ...state, data: profile };
		case 'SET_FETCHING_STATE_PROFILE':
			return { ...state, fetching };
		default:
			return state;
	}
}
