import React, { useEffect, useCallback, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { setSnackbar } from 'store/actions/createSimulation';

import { Box, CircularProgress } from '@mui/material';

import { getCategories } from 'Services/Api/Simulation';

import { FormAutoComplete } from '../../../../../components/Form/AutoComplete';

const CategoriesForm = ({ watch, control, categoriesSelected }) => {
	const dispatch = useDispatch();
	const { fields, replace } = useFieldArray({
		control,
		name: 'categories',
	});

	const agreementSelected = watch('agreement');

	const [categoryOptions, setCategoryOptions] = useState([]);
	const [loadingCategoryOptions, setLoadingCategoryOptions] = useState(false);

	const getCategoryList = useCallback(
		async (agreementId) => {
			if (!agreementId) {
				setCategoryOptions([]);
				return true;
			}
			try {
				setLoadingCategoryOptions(true);
				const response = await getCategories({ agreementId });
				if (response?.status === 200) {
					setCategoryOptions(response?.data || []);

					return response.data;
				}
			} catch (e) {
				dispatch(
					setSnackbar({
						duration: 6000,
						message: e?.response?.data?.message || e?.message,
						openSnackbar: true,
						severity: 'error',
					})
				);
			} finally {
				setLoadingCategoryOptions(false);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		getCategoryList(agreementSelected?.id || null);
	}, [agreementSelected, getCategoryList]);

	useEffect(() => {
		if (categoriesSelected?.length) {
			replace(
				categoriesSelected?.map((value) => ({
					...value,
					category:
						categoryOptions.find((val) => val.id === value?.category_id) ||
						null,
				}))
			);
		}
	}, [categoryOptions, categoriesSelected, replace]);

	return loadingCategoryOptions ? (
		<Box sx={{ textAlign: 'center' }} my={12}>
			<CircularProgress />
		</Box>
	) : (
		<>
			<Box
				sx={{
					fontSize: '18px',
					fontWeight: '800',
					textAlign: 'left',
					my: 3,
				}}
				color='primary.main'
			>
				Categorías
			</Box>
			<Box
				sx={{
					fontSize: '18px',
					fontWeight: '800',
					textAlign: 'left',
					mb: 3,
				}}
				color='common.red'
			>
				Asegúrate de que las categorías encontradas son correctas:
			</Box>
			{fields?.map((val, index) => (
				<Box
					alignItems='center'
					display='inline-flex'
					key={val?.category_id}
					my={1}
					width={1}
				>
					<Box>{val?.label}</Box>
					<Box mx={2} width={250}>
						<FormAutoComplete
							control={control}
							label='Seleccione una categoría'
							name={`categories.${index}.category`}
							optionLabel='professional_category'
							options={categoryOptions || []}
							rules={{ required: 'La categoría es requerida' }}
							inputProps={{
								'data-testid': `${val?.category_id}-test-id`,
								variant: 'outlined',
							}}
						/>
					</Box>
				</Box>
			))}
		</>
	);
};

export default CategoriesForm;
