import React, { Component } from 'react';

import LocationCity from '@material-ui/icons/LocationCity';

import AppInput from '../../components/AppInput/AppInput';
import AppSimpleList from '../../components/AppSimpleList/AppSimpleList';

class NewProjectLocation extends Component {
	state = {
		projectLocation: '',
	};

	componentDidMount() {
		const { listLocations } = this.props;
		this.setState({ listLocations });
	}

	handleInputChange = (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const element = target.type === 'select' ? target.element : null;
		const { name } = target;

		this.setState({
			[name]: value,
			[`${name}Element`]: element,
		});
	};

	render() {
		const { projectLocation, listLocations } = this.state;

		return (
			<div>
				<AppInput
					label='Nombre centro/ubicación'
					type='text'
					name='projectLocation'
					value={projectLocation}
					onChange={(event) => this.handleInputChange(event)}
					onEnter={(value) => {
						listLocations.unshift({ text: value });
						this.setState({ listLocations, projectLocation: '' });
					}}
				/>
				<AppSimpleList
					items={listLocations || [{ text: '' }]}
					leftIcon={<LocationCity />}
				/>
			</div>
		);
	}
}

export default NewProjectLocation;
