import { v4 as uuidv4 } from 'uuid';

export const gridInfo = [
	{
		id: 1,
		label: 'Máximo de horas por día',
		name: 'max_hours_per_day',
		max: 24,
		required: true,
	},
	{
		id: 2,
		label: 'Máximo de tramos planificados en un día',
		name: 'max_planned_sections_day',
		max: 3,
		required: true,
	},
	{
		id: 3,
		label: 'Máximo de horas por semanas',
		name: 'max_hours_per_week',
		max: 40,
		required: true,
	},
	{
		id: 4,
		label: 'Horas de descanso entre turno y turno',
		name: 'hours_rest_between_shifts',
		min: 12,
		required: true,
	},
	{
		id: 5,
		label: 'Máximo de horas por mes (opcional)',
		name: 'max_hours_per_month',
		type: 'number',
		max: 200,
	},
	{
		id: 6,
		label: 'Días de descanso consecutivo',
		name: 'consecutive_rest_days',
		required: true,
	},
	{
		id: 7,
		label: 'Máximo de horas por año*',
		name: 'max_hours_per_year',
		max: 2000,
		required: true,
	},
	{
		id: 8,
		label: 'Asuntos propios / libre disposición',
		name: 'own_affairs_free_disposal',
		required: false,
	},
];

export const periodIntervals = [
	{ id: 1, label: 'enero', value: '01' },
	{ id: 2, label: 'febrero', value: '02' },
	{ id: 3, label: 'marzo', value: '03' },
	{ id: 4, label: 'abril', value: '04' },
	{ id: 5, label: 'mayo', value: '05' },
	{ id: 6, label: 'junio', value: '06' },
	{ id: 7, label: 'julio', value: '07' },
	{ id: 8, label: 'agosto', value: '08' },
	{ id: 9, label: 'septiembre', value: '09' },
	{ id: 10, label: 'octubre', value: '10' },
	{ id: 11, label: 'noviembre', value: '11' },
	{ id: 12, label: 'diciembre', value: '12' },
];

export const timesIntervals = [
	{
		value: 'DAY',
		label: 'max_hours_per_day',
	},
	{
		value: 'WEEK',
		label: 'max_hours_per_week',
	},
	{
		value: 'MONTH',
		label: 'max_hours_per_month',
	},
	{
		value: 'YEAR',
		label: 'max_hours_per_year',
	},
];

export const agreementTypes = [
	{ id: 1, label: 'naturales', value: 'naturales' },
	{ id: 2, label: 'laborables', value: 'laborables' },
	{ id: 3, label: 'laborables + sábados', value: 'laborables+sabados' },
];

export const defaultValues = {
	name: '',
	max_hours_per_day: '',
	max_planned_sections_day: '',
	max_hours_per_week: '',
	hours_rest_between_shifts: '',
	max_hours_per_month: '',
	consecutive_rest_days: '',
	max_hours_per_year: '',
	own_affairs_free_disposal: '',
	monthTo: '',
	monthFrom: '',
	type: 'naturales',
	holidays: '',
	dateValidation: '',
	yearFrom: '',
	yearTo: '',
	yearRangeValidation: '',
	maxAntiquityPercentage: '',
	antiquityYears: '',
	antiquityPercentage: '',
	annualSalaryIncrease: '',
	salaryTablesYear: '',
	applyAllYears: false,
};

export const workersStatutesValues = {
	name: '',
	max_hours_per_day: '9',
	max_planned_sections_day: '2',
	max_hours_per_week: '40',
	hours_rest_between_shifts: '12',
	max_hours_per_month: '',
	consecutive_rest_days: '1.5',
	max_hours_per_year: '',
	own_affairs_free_disposal: '',
	monthTo: '09',
	monthFrom: '06',
	type: 'naturales',
	holidays: '30',
	dateValidation: '',
	yearFrom: '',
	yearTo: '',
	yearRangeValidation: '',
	maxAntiquityPercentage: '',
	antiquityYears: '',
	antiquityPercentage: '',
	annualSalaryIncrease: '',
	salaryTablesYear: '',
	applyAllYears: false,
};

/* Modal */
export const plusByDaysOfTheWeek = [
	{
		id: 1,
		label: 'Lunes',
		inputName: 'plusByDayMonday',
	},
	{
		id: 2,
		label: 'Martes',
		inputName: 'plusByDayTuesday',
	},
	{
		id: 3,
		label: 'Miércoles',
		inputName: 'plusByDayWednesday',
	},
	{
		id: 4,
		label: 'Jueves',
		inputName: 'plusByDayThursday',
	},
	{
		id: 5,
		label: 'Viernes',
		inputName: 'plusByDayFriday',
	},
];

export const plusByDays = [
	{
		id: 1,
		label: 'Festivos',
		inputName: 'plusByDayHolidays',
	},
	{
		id: 2,
		label: 'Domingo',
		inputName: 'plusByDaySunday',
	},
	{
		id: 3,
		label: 'Sábado',
		inputName: 'plusByDaySaturday',
	},
];

const getMonthString = (month) => {
	const months = periodIntervals.filter((item) => item.value === month);
	return months.length > 0 ? months[0].label : '';
};

export const getYearListBetweenDates = (startDate, endDate) => {
	const dates = [];
	const start = new Date(startDate);
	const end = new Date(endDate);
	while (start.getFullYear() <= end.getFullYear()) {
		dates.push({ value: start.getFullYear(), id: start.getFullYear() });
		start.setFullYear(start.getFullYear() + 1);
	}
	return dates;
};

const getAnnualIncrements = (data, begin, end) => {
	const annualIncrements = [];
	const listOfYears = getYearListBetweenDates(begin, end);
	listOfYears.forEach((year) => {
		if (data[`annualSalaryIncrease${year.value}`]) {
			annualIncrements.push({
				year: year.value,
				increment:
					data[`annualSalaryIncrease${year.value}`] !== '0'
						? parseFloat(data[`annualSalaryIncrease${year.value}`])
						: 0,
			});
		}
	});

	return annualIncrements;
};

const getPLuses = (category) => {
	const pluses = [];

	plusByDays.forEach((day) => {
		if (category[`${day.inputName}`]) {
			pluses.push({
				day: day.label
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, ''),
				price_hour: parseFloat(category[`${day.inputName}`]),
			});
		}
	});

	plusByDaysOfTheWeek.forEach((day) => {
		if (category[`${day.inputName}`]) {
			pluses.push({
				day: day.label
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, ''),
				price_hour: parseFloat(category[`${day.inputName}`]),
			});
		}
	});

	return pluses;
};

const getDateFormatHM = (date) => {
	const dateObj = new Date(date);
	const hours = dateObj.getHours();
	const minutes = dateObj.getMinutes();
	const dateString = `${hours}:${minutes}`;
	return dateString;
};

const getDateFromFormatHM = (date) => {
	if (date) {
		const time = date.split(':');
		const dateObj = new Date();
		dateObj.setHours(time[0]);
		dateObj.setMinutes(time[1]);
		return dateObj;
	}
	return '';
};

const getPLusesSchedule = (category) => {
	const pluses = [];
	if (
		category.plusNocturnalStartTime &&
		category.plusNocturnalEndTime &&
		category.plusNocturnalValue
	) {
		pluses.push({
			time_init: getDateFormatHM(category.plusNocturnalStartTime),
			time_end: getDateFormatHM(category.plusNocturnalEndTime),
			price_hour: parseFloat(category.plusNocturnalValue),
		});
	}
	return pluses;
};

const getParsedCategories = (categories) => {
	const parsedCategories = [];
	categories.forEach((category) => {
		const parsedCategory = {
			id: category.id,
			professional_category: category.categoryName,
			salary_year: parseFloat(category.netSalary) || 0,
			base_salary_year: parseFloat(category.annualBaseSalary) || 0,
			pluses_by_weekday: getPLuses(category),
			pluses_by_schedule: getPLusesSchedule(category),
			free_days: parseInt(category?.free_days, 10),
			seniority_bonus: category.fixed_seniority_bonus
				? {
						amount: parseFloat(category.fixed_seniority_bonus),
						period_bonus: parseInt(category.fixed_seniority_bonus_period, 10),
				  }
				: null,
		};
		parsedCategories.push(parsedCategory);
	});
	return parsedCategories;
};

const getTimeLimits = (data) => {
	const timeLimits = [];
	timesIntervals.forEach((period) => {
		if (data[`${period.label}`]) {
			timeLimits.push({
				time_period: period.value,
				max_hour: parseFloat(data[`${period.label}`]) || '',
			});
		}
	});
	return timeLimits;
};

export const parseDataToSubmit = (data, categoriesList, agreementBaseId) => {
	let fixed_seniority_bonus = false;
	categoriesList.forEach((category) => {
		if (category.fixed_seniority_bonus !== '') fixed_seniority_bonus = true;
	});
	const inputsValues = {
		id: uuidv4(),
		agreement_base_id: agreementBaseId || null,
		name: data.name,
		max_sections_planned_day: parseInt(data.max_planned_sections_day, 10),
		holidays: {
			days: parseInt(data.holidays, 10),
			type: data.type,
			period: {
				from_month: getMonthString(data.monthFrom),
				until_month: getMonthString(data.monthTo),
			},
		},
		rest_hours_between_shifts: parseInt(data.hours_rest_between_shifts, 10),
		consecutive_rest_days: parseFloat(data.consecutive_rest_days),
		free_day_available: parseInt(data.own_affairs_free_disposal, 10),
		start_year: new Date(data.yearFrom).getFullYear(),
		end_year: new Date(data.yearTo).getFullYear(),
		creation_year: new Date(data.salaryTablesYear).getFullYear(),
		time_limits: getTimeLimits(data),
		annual_increments: getAnnualIncrements(data, data.yearFrom, data.yearTo),
		seniority_bonuses:
			data.antiquityYears && data.antiquityPercentage && !fixed_seniority_bonus
				? [
						{
							max_percent_date: null,
							period_bonus: parseInt(data.antiquityYears, 10) || null,
							percent_over_salary: parseFloat(data.antiquityPercentage) || null,
							max_percent: parseFloat(data.maxAntiquityPercentage) || null,
						},
				  ]
				: [],
		categories: getParsedCategories(categoriesList),
	};
	return inputsValues;
};

const getMonthFromString = (month) => {
	const months = periodIntervals.filter((item) => item.label === month);
	return months.length > 0 ? months[0].value : '';
};

const getAnnualIncreaseObject = (id, value, increment) => ({
	id,
	value,
	increment,
});

const getSalaryIncreaseObject = (date, value = '') => ({
	[`annualSalaryIncrease${date}`]: value,
});

const getRange = (agreement) => {
	const range = {
		yearFrom: '',
		yearTo: '',
		yearRangeValidation: [],
		annualSalaryIncrease: [],
	};
	if (agreement.annual_increment.length > 0) {
		agreement.annual_increment.sort((a, b) => a.year - b.year);

		let startTempDate = agreement.start_year + 1;
		agreement.annual_increment.forEach((increment, index) => {
			while (startTempDate < increment?.year) {
				range.yearRangeValidation.push(
					getAnnualIncreaseObject(startTempDate, startTempDate, '')
				);
				range.annualSalaryIncrease.push(getSalaryIncreaseObject(startTempDate));
				startTempDate += 1;
			}
			startTempDate += 1;

			range.yearRangeValidation.push(
				getAnnualIncreaseObject(
					index,
					increment?.year ? increment?.year : '',
					increment?.increment
				)
			);

			range.annualSalaryIncrease.push(
				getSalaryIncreaseObject(increment.year, increment.increment)
			);

			if (index === agreement.annual_increment.length - 1) {
				const lastYear = agreement?.annual_increment[index]?.year;
				let tempYear = lastYear + 1;
				while (tempYear <= agreement.end_year) {
					range.yearRangeValidation.push(
						getAnnualIncreaseObject(tempYear, tempYear, '')
					);
					range.annualSalaryIncrease.push(getSalaryIncreaseObject(tempYear));
					tempYear += 1;
				}
			}
		});
	} else {
		let tempYear = agreement.start_year + 1;
		while (tempYear <= agreement.end_year) {
			range.yearRangeValidation.push(
				getAnnualIncreaseObject(tempYear, tempYear, '')
			);
			range.annualSalaryIncrease.push(getSalaryIncreaseObject(tempYear));
			tempYear += 1;
		}
	}
	range.yearFrom = range?.yearRangeValidation[0]?.value;
	range.yearTo =
		range.yearRangeValidation[range.yearRangeValidation.length - 1]?.value;
	return range;
};

const getPlusByWeekDay = (plus, label) => {
	const plusByWeekDay = plus.filter((day) => day.day === label);
	return plusByWeekDay.length > 0 ? plusByWeekDay[0].price_hour : '';
};

const parseCategoryFromFetch = (categories) => {
	const parsedCategory = [];
	categories.forEach((category) => {
		parsedCategory.push({
			id: uuidv4(),
			categoryName: category.professional_category,
			netSalary: category.salary_year,
			annualBaseSalary: category.base_salary_year,
			yearRangeValidation: '',
			fixed_seniority_bonus: category?.seniority_bonus?.amount || '',
			fixed_seniority_bonus_period:
				category?.seniority_bonus?.period_bonus || '',
			plusNocturnalStartTime: getDateFromFormatHM(
				category?.pluses_by_schedule[0]?.time_init
			),
			plusNocturnalEndTime: getDateFromFormatHM(
				category?.pluses_by_schedule[0]?.time_end
			),
			plusNocturnalValue: category?.pluses_by_schedule[0]?.price_hour || '',
			plusByDayHolidays:
				getPlusByWeekDay(category?.pluses_by_weekday, 'festivos') || '',
			plusByDaySunday:
				getPlusByWeekDay(category?.pluses_by_weekday, 'domingo') || '',
			plusByDaySaturday:
				getPlusByWeekDay(category?.pluses_by_weekday, 'sabado') || '',
			plusByDayMonday:
				getPlusByWeekDay(category?.pluses_by_weekday, 'lunes') || '',
			plusByDayTuesday:
				getPlusByWeekDay(category?.pluses_by_weekday, 'martes') || '',
			plusByDayWednesday:
				getPlusByWeekDay(category?.pluses_by_weekday, 'miercoles') || '',
			plusByDayThursday:
				getPlusByWeekDay(category?.pluses_by_weekday, 'jueves') || '',
			plusByDayFriday:
				getPlusByWeekDay(category?.pluses_by_weekday, 'viernes') || '',
			free_days: category?.free_days,
		});
	});
	return parsedCategory;
};

export const parseDataFromFetch = (agreement) => {
	const yearRange = getRange(agreement);
	const inputsValues = {
		id: agreement?.uid || '',
		agreement_base_id: agreement?.agreement_base_id || null,
		name: agreement?.name || '',
		max_hours_per_day: agreement?.max_hours_per_day || '',
		max_planned_sections_day: agreement?.max_sections_planned_day || '',
		max_hours_per_week: agreement?.max_hours_per_week || '',
		hours_rest_between_shifts: agreement?.rest_hours_between_shifts || '',
		max_hours_per_month: agreement?.max_hours_per_month || '',
		consecutive_rest_days: agreement?.consecutive_rest_days || '',
		max_hours_per_year: agreement?.max_hours_per_year || '',
		own_affairs_free_disposal: agreement?.free_day_available || '',
		monthTo: getMonthFromString(agreement?.holidays_rules_until),
		monthFrom: getMonthFromString(agreement?.holidays_rules_from),
		type: agreement?.holiday_days_types || '',
		holidays: agreement?.holiday_days || '',
		dateValidation: '',
		yearFrom: agreement?.start_year
			? new Date().setFullYear(agreement.start_year)
			: '',
		yearTo: agreement?.end_year
			? new Date().setFullYear(agreement.end_year)
			: '',
		datesInterval: yearRange.yearRangeValidation,
		maxAntiquityPercentage: agreement?.seniority_bonus[0]
			? agreement.seniority_bonus[0].max_seniority
			: '',
		antiquityYears: agreement?.seniority_bonus[0]
			? agreement.seniority_bonus[0].period_bonus
			: '',
		antiquityPercentage: agreement?.seniority_bonus[0]
			? agreement.seniority_bonus[0].percent_over_salary
			: '',
		salaryTablesYear: new Date().setFullYear(
			agreement?.salary_year ? agreement.salary_year : ''
		),
		applyAllYears: false,
		categories: agreement?.categories
			? parseCategoryFromFetch(agreement.categories)
			: [],
		...Object.assign({}, ...yearRange.annualSalaryIncrease),
	};
	return inputsValues;
};

export const defaultModalValues = {
	id: uuidv4(),
	categoryName: '',
	netSalary: '',
	annualBaseSalary: '',
	yearRangeValidation: '',
	plusNocturnalStartTime: '',
	plusNocturnalEndTime: '',
	plusNocturnalValue: '',
	plusByDayHolidays: '',
	plusByDaySaturday: '',
	plusByDaySunday: '',
	plusByDayMonday: '',
	plusByDayTuesday: '',
	plusByDayWednesday: '',
	plusByDayThursday: '',
	plusByDayFriday: '',
};
