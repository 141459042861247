import ApiException from '../../../../Services/Api/ApiException';
import AxiosWrapper from '../../../../utilities/connection/AxiosWrapper';
import {
	createPreviousStudyMaterialCostUrl,
	deleteMaterialCostPreviousStudyUrl,
	getPreviousStudyMaterialCostByIdUrl,
} from '../../../../utilities/routes';

export async function deleteMaterialCostById({
	previousStudyId,
	materialCostId,
}) {
	const url = deleteMaterialCostPreviousStudyUrl(
		previousStudyId,
		materialCostId
	);
	try {
		await AxiosWrapper.delete(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getMaterialCostById({ previousStudyId, materialCostId }) {
	const url = getPreviousStudyMaterialCostByIdUrl(
		previousStudyId,
		materialCostId
	);
	try {
		const materialCostData = await AxiosWrapper.get(url);
		return materialCostData.data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function updateMaterialCosts({
	previousStudyId,
	materialCostId,
	payload,
}) {
	const url = getPreviousStudyMaterialCostByIdUrl(
		previousStudyId,
		materialCostId
	);
	try {
		return await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function createMaterialCost({ previousStudyId, payload }) {
	const url = createPreviousStudyMaterialCostUrl(previousStudyId);
	try {
		await AxiosWrapper.post(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
