export default function projects(state = null, project) {
	const { type, fetching, projects, currentProject } = project;
	switch (type) {
		case 'SET_PROJECTS_LIST':
			return { ...state, list: projects };
		case 'RESET_PROJECTS_LIST':
			return { fetching: true, list: [] };
		case 'SET_CURRENT_PROJECT':
			return { ...state, currentProject };
		case 'RESET_CURRENT_PROJECT':
			return { ...state, currentProject: {} };
		case 'SET_FETCHING_STATE_PROJECTS':
			return { ...state, fetching };
		default:
			return state;
	}
}
