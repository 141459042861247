import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DialogValidate from 'components/Dialog/DialogValidate/DialogValidate';

import {
	Tooltip,
	Snackbar,
	Alert,
	Stepper,
	Step,
	Grid,
	Typography,
	StepLabel,
} from '@mui/material';
import Box from '@mui/material/Box';

import {
	getCategoryPositions,
	getPositions,
	getSimulation,
	getSimulationCenters,
	launchSimulation,
} from '../../Services/Api/Simulation';
import launchSimulationIcon from '../../assets/imgs/launchSimulation.svg';
import { WhiteTitleContainer } from '../../components';
import IsSimulationEditable from '../../components/Simulations/IsSimulationEditable';
import { StyledButton } from '../../components/StyledComponents/Button';
import { StyledSemicircleOnTop } from '../../components/StyledComponents/SemicircleOnTop';
import { StyledStepButton } from '../../components/StyledComponents/StepButton';
import {
	TitleContainer,
	ViewContainer,
} from '../../components/StyledContainers';
import BasicDataTab from '../../containers/Simulation/BasicData';
import MaterialCost from '../../containers/Simulation/MaterialCost';
import Surrogates from '../../containers/Simulation/Surrogate/SurrogatesTab';
import Workload from '../../containers/Simulation/Workload/Workload';
import {
	closeSnackBar,
	resetState,
	setCategoryPositions,
	setCenters,
	setPositions,
	setSimulationId,
	setSimulationInfo,
	setSnackbar,
	updatingBasicData,
} from '../../store/actions/createSimulation';
import { simulationLaunchedUrl } from '../../utilities/routes';
import './createSimulation.css';

const CreateSimulation = (props) => {
	const { projectId, simulationId } = props.match.params;

	const projects = useSelector((state) => state.projects);
	const { createSimulation } = useSelector((state) => state);
	const [value, setValue] = useState('1');
	const [project, setProject] = useState({});
	const [basicDataSuccess, setBasicDataSuccess] = useState(true);
	const [workloadSuccess, setWorkloadSuccess] = useState(true);
	const [surrogateSuccess, setSurrogateSuccess] = useState(true);
	const dispatch = useDispatch();
	const history = useHistory();
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = useState({});
	const [openModalValidate, setOpenModalValidate] = useState(false);

	useEffect(() => {
		dispatch(setSimulationId(simulationId));
	}, [simulationId]);

	const {
		loading: loadingCenters,
		data: centersData,
		error: errorCenters,
	} = getSimulationCenters({
		simulationId,
		reloadCenters: createSimulation?.refreshCenters,
	});

	useEffect(() => {
		dispatch(
			setCenters({
				data: centersData,
				loading: loadingCenters,
				error: errorCenters,
			})
		);
		if (!loadingCenters) dispatch(updatingBasicData(false));
	}, [centersData, loadingCenters, errorCenters]);

	const {
		data: positionsData,
		loading: loadingPositions,
		error: errorPositions,
	} = getPositions(simulationId, createSimulation?.refreshPositions);

	useEffect(() => {
		dispatch(
			setPositions({
				data: positionsData,
				loading: loadingPositions,
				error: errorPositions,
			})
		);
	}, [positionsData, loadingPositions, errorPositions]);

	const {
		loading: loadingCategoryPositions,
		data: categoryPositionsData,
		error: categoryPositionsError,
	} = getCategoryPositions(
		simulationId,
		createSimulation?.refreshCategoryPositions
	);

	useEffect(() => {
		if (!loadingCategoryPositions)
			dispatch(
				setCategoryPositions({
					loading: loadingCategoryPositions,
					data: categoryPositionsData.categories,
					error: categoryPositionsError,
					anyPosition: categoryPositionsData.disabled,
				})
			);
	}, [loadingCategoryPositions, categoryPositionsData, categoryPositionsError]);

	const { loading: loadingSimulation, data: simulation } = getSimulation({
		simulationId,
		reloadSimulation: createSimulation?.refreshSimulation,
	});

	useEffect(() => {
		dispatch(setSimulationInfo(simulation));
	}, [loadingSimulation]);

	useEffect(() => {
		setProject(projects.list.filter((item) => item.uid === projectId)[0]);
	}, []);

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	const handleInitSimulation = async () => {
		try {
			await launchSimulation({ simulationId });
			history.push(simulationLaunchedUrl(projectId, simulationId));
			dispatch(resetState());
		} catch (e) {
			const message = e.message;
			dispatch(
				setSnackbar({
					openSnackbar: true,
					duration: 6000,
					severity: 'error',
					message,
				})
			);
		}
	};

	const header = () => (
		<WhiteTitleContainer
			routes={[
				{ name: 'Inicio', href: '/' },
				{ name: 'Licitaciones', href: '/projects' },
				{ name: project?.name, href: `/projects/${projectId}` },
				{
					name:
						createSimulation?.simulation?.name?.length > 80
							? `${createSimulation?.simulation?.name?.substring(0, 80)}...`
							: createSimulation?.simulation?.name,
				},
			]}
			title=''
		/>
	);

	const disableTabsAndButton = createSimulation?.updatingBasicData;
	const steps = [
		'Datos Básicos',
		'Carga de Trabajo',
		'Subrogados',
		'Otros Costes',
	];

	const stepComponent = [
		<BasicDataTab
			simulationId={simulationId}
			success={(value) => setBasicDataSuccess(value)}
		/>,
		<Workload />,
		<Surrogates />,
		<MaterialCost />,
	];

	const stepsDraw = () => (
		<Stepper nonLinear activeStep={activeStep} alternativeLabel>
			{steps.map((label, index) => (
				<Step key={label} completed={completed[index]}>
					<StyledStepButton color='inherit' onClick={handleStep(index)}>
						<StepLabel>
							<Box>{label}</Box>
						</StepLabel>
					</StyledStepButton>
				</Step>
			))}
		</Stepper>
	);

	return (
		<>
			{project?.name && header()}
			<ViewContainer>
				<Box sx={{ width: '100%' }}>{stepsDraw()}</Box>
				<DialogValidate
					openModal={openModalValidate}
					setOpenModal={setOpenModalValidate}
					simulationId={simulationId}
					handleInitSimulation={handleInitSimulation}
				></DialogValidate>
				<TitleContainer>
					<Grid
						xs={12}
						container
						item
						direction='row'
						justifyContent='space-between'
					>
						<Grid item md={9.5} lg={10}>
							<Typography
								variant='h5'
								color='textPrimary'
								style={{ fontWeight: 'bold' }}
							>
								{createSimulation?.simulation?.name}
							</Typography>
						</Grid>
						<Grid item md={2.5} lg={2} mt={0}>
							<IsSimulationEditable>
								<Box sx={{ width: '100%', margin: 'auto' }}>
									<Tooltip
										title={
											!basicDataSuccess || !workloadSuccess || !surrogateSuccess
												? 'Complete los datos de la simulación'
												: ''
										}
										arrow
									>
										<span>
											<StyledButton
												sx={{
													textTransform: 'inherit',
													maxHeight: '50px',
													height: '42px',
													width: '200px',
													color: 'secondary.main',
												}}
												variant='outlined'
												disabled={disableTabsAndButton}
												onClick={() => setOpenModalValidate(true)}
												startIcon={<img src={launchSimulationIcon} />}
											>
												<Typography sx={{ fontWeight: '800' }}>
													Lanzar simulación
												</Typography>
											</StyledButton>
										</span>
									</Tooltip>
								</Box>
							</IsSimulationEditable>
						</Grid>
					</Grid>
				</TitleContainer>

				<Box
					sx={{
						flexGrow: 1,
						bgcolor: 'background.paper',
						display: 'block',
						width: '100%',
						m: 'auto',
						mt: '50px',
						p: 2,
						boxShadow: `0px 10px 40px rgba(68, 82, 231, 0.11)`,
						borderRadius: '6px',
					}}
				>
					<Grid container justifyContent='center'>
						<Grid item>
							<StyledSemicircleOnTop
								sx={{ boxShadow: '0px -10px 10px 0px #4452e71c' }}
							>
								<Box
									sx={{
										height: '36px',
										width: '36px',
										borderRadius: '50%',
										backgroundColor: 'primary.main',
										paddingTop: '10px',
										fontSize: '16px',
										fontWeight: '700',
									}}
								>
									{activeStep + 1}
								</Box>
							</StyledSemicircleOnTop>
						</Grid>
					</Grid>

					<Grid container>
						<Grid item xs={12} sx={{ ml: 3, mr: 3, mt: -6 }}>
							{stepComponent[activeStep]}
						</Grid>
					</Grid>
				</Box>
			</ViewContainer>
			<Snackbar
				open={createSimulation?.snackbar?.openSnackbar}
				autoHideDuration={createSimulation?.snackbar?.duration}
				onClose={() => dispatch(closeSnackBar())}
			>
				<Alert
					severity={createSimulation?.snackbar?.severity}
					sx={{ width: '100%' }}
				>
					{createSimulation?.snackbar?.message}
				</Alert>
			</Snackbar>
		</>
	);
};
export default CreateSimulation;
