/*
 * Reducers specify how the application's state changes in response to actions sent to the store.
 * Remember that actions only describe what happened, but don't describe how the application's state changes
 */

export default function error(state = null, action) {
	// This catches any action with an error key in it and updates state
	const { type, error } = action;

	switch (type) {
		case 'RESET_ERROR':
			// Return null as next value for "error" in the state (default value)
			return null;
		case 'SET_ERROR':
			// If the action creator sends error data as payload, return the error data as next value for "error" in the state
			return error;
		default:
			// Simply return the previous state value if there are no matches
			return state;
	}
}
