import React from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, CircularProgress } from '@mui/material';

import Else from '../../../components/Compounds/If/Else';
import If from '../../../components/Compounds/If/If';
import Then from '../../../components/Compounds/If/Then';
import IsPreviousStudyEditable from '../../../components/PreviousStudy/IsPreviousStudyEditable';
import { StyledButton } from '../../../components/StyledComponents/Button';
import MaterialCostModal from './Modal';
import MaterialCostTable from './Table';
import useMaterialCost from './useMaterialCost';

const MaterialCost = () => {
	const {
		openModal,
		setOpenModal,
		edit,
		setEdit,
		materialCostId,
		setMaterialCostId,
		materialCostsData,
		loadingMaterialCosts,
		deleteMaterialCosts,
		setReloadMaterialCosts,
	} = useMaterialCost();
	return (
		<Box width='100%'>
			<Box
				sx={{
					width: '100%',
					fontSize: '18px',
					fontWeight: '800',
					textAlign: 'left',
					mb: 3,
				}}
				color='primary.main'
			>
				Otros Costes
			</Box>
			<MaterialCostModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				edit={edit}
				setEdit={setEdit}
				materialCostId={materialCostId}
				setReloadMaterialCosts={setReloadMaterialCosts}
				materialCostsData={materialCostsData}
			/>
			<IsPreviousStudyEditable>
				<Box sx={{ display: 'block' }}>
					<StyledButton
						onClick={() => setOpenModal(true)}
						variant='text'
						aria-label='Añadir Concepto Económico'
						sx={{
							mb: 3,
							color: 'primary.main',
							textTransform: 'inherit',
							fontSize: '18px',
							fontWeight: '800',
						}}
						startIcon={<AddCircleOutlineIcon color='primary' />}
					>
						Añadir Concepto Económico
					</StyledButton>
				</Box>
			</IsPreviousStudyEditable>
			<Box mt={4}>
				<If conditional={loadingMaterialCosts}>
					<Then>
						<CircularProgress />
					</Then>
					<Else>
						<If conditional={materialCostsData?.length > 0}>
							<Then>
								<MaterialCostTable
									rows={materialCostsData}
									setEdit={setEdit}
									setMaterialCostId={setMaterialCostId}
									setOpenModal={setOpenModal}
									deleteMaterialCosts={deleteMaterialCosts}
								/>
							</Then>
							<Else>No existen datos para mostrar</Else>
						</If>
					</Else>
				</If>
			</Box>
		</Box>
	);
};
export default MaterialCost;
