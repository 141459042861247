import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setSnackbar } from '../../../store/actions/snackbar';
import { createAgreement, getAgreementById } from '../ApiServices';
import {
	gridInfo,
	periodIntervals,
	agreementTypes,
	defaultValues,
	workersStatutesValues,
	parseDataToSubmit,
	getYearListBetweenDates,
	parseDataFromFetch,
} from './agreementUtils';

export default function useAgreementForm({ agreementId }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { auth } = useSelector((state) => state);

	const [agreementBaseId, setAgreementBaseId] = useState(null);
	const [loading, setLoading] = useState(agreementId !== ' ' ? true : false);
	const [updateYearsInterval, setUpdateYearsInterval] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [categoryToEdit, setCategoryToEdit] = useState(null);
	const [datesInterval, setDatesInterval] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedFixedSeniorityBonus, setSelectedFixedSeniorityBonus] =
		useState(false);
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
		watch,
		getValues,
		setValue,
	} = useForm({
		defaultValues,
	});

	const setSnackBarMessage = (message) => {
		dispatch(
			setSnackbar({
				openSnackbar: true,
				duration: 6000,
				severity: 'error',
				message,
			})
		);
	};

	useEffect(() => {
		if (!openModal) {
			setCategoryToEdit(null);
		}
	}, [openModal]);

	useEffect(() => {
		if (
			getValues('yearFrom') &&
			getValues('yearTo') &&
			!(!agreementId && updateYearsInterval) &&
			new Date(getValues('yearTo')).getFullYear() -
				new Date(getValues('yearFrom')).getFullYear() <
				20
		) {
			const from = new Date(getValues('yearFrom'));
			from.setFullYear(from.getFullYear() + 1);
			const to = new Date(getValues('yearTo'));
			if (to.getDate() === 1 && to.getMonth() === 0) {
				to.setMonth(from.getMonth());
				to.setDate(from.getDate() + 1);
			}
			const dates = getYearListBetweenDates(from, to);
			setDatesInterval(dates);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('yearFrom'), watch('yearTo'), agreementId]);

	useEffect(() => {
		if (getValues('applyAllYears') && datesInterval?.length > 0) {
			const applyAllYearsValue = getValues(
				`annualSalaryIncrease${datesInterval[0].value}`
			);
			datesInterval.forEach((date) => {
				setValue(`annualSalaryIncrease${date.value}`, applyAllYearsValue);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// eslint-disable-next-line react-hooks/exhaustive-deps
		watch('applyAllYears'),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		watch('yearFrom'),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		watch('yearTo'),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		watch(`annualSalaryIncrease${datesInterval[0]?.value}`),
	]);

	const getAgreementsFromId = async (agreementId) => {
		try {
			const { data } = await getAgreementById({ agreementId });
			const response = parseDataFromFetch(data);
			reset(response);
			setAgreementBaseId(response.agreement_base_id);
			setDatesInterval(response.datesInterval);
			const categoriesList = response.categories.map((category) => {
				const tempCategory = { ...category };
				if (tempCategory?.fixed_seniority_bonus) {
					setSelectedFixedSeniorityBonus(true);
				}
				return tempCategory;
			});
			setCategories(categoriesList);
			setLoading(false);
			setUpdateYearsInterval(true);
		} catch (e) {
			setLoading(false);
			// alert('Error desconocido en el servidor');
		}
	};

	useEffect(() => {
		if (agreementId !== ' ') {
			getAgreementsFromId(agreementId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [agreementId]);

	const createOrUpdate = async (data) => {
		const payload = parseDataToSubmit(data, categories, agreementBaseId);
		payload.workspace_id = auth?.workSpace?.id;
		try {
			await createAgreement({ payload });
			history.push('/agreements');
		} catch (e) {
			setSnackBarMessage(e?.response?.data?.message || e?.message || '');
		}
	};

	const onSubmit = (data) => {
		createOrUpdate(data);
	};

	const setETValues = () => {
		const resetValues = workersStatutesValues;
		resetValues.name = getValues('name');
		resetValues.yearTo = getValues('yearTo');
		resetValues.yearFrom = getValues('yearFrom');
		reset(resetValues);
	};

	const updateFixedSeniorityBonus = (categoriesList) => {
		let flag = false;
		categoriesList.forEach((category) => {
			if (category.fixed_seniority_bonus) {
				flag = true;
			}
		});
		setSelectedFixedSeniorityBonus(flag);
	};

	const addCategory = (data) => {
		const tempCategories = [...categories, data];
		updateFixedSeniorityBonus(tempCategories);
		setCategories(tempCategories);
	};

	const deleteCategory = (categoryId) => {
		const newCategories = categories.filter(
			(category) => category.id !== categoryId
		);
		updateFixedSeniorityBonus(newCategories);
		setCategories(newCategories);
	};

	const selectCategoryToEdit = (categoryId) => {
		const categoryToEdit = categories.filter(
			(category) => category.id === categoryId
		);
		if (categoryToEdit.length > 0) {
			const category = categoryToEdit[0];
			setCategoryToEdit(category);
			setOpenModal(true);
		}
	};

	const editCategory = (categoryData) => {
		const categoryToEdit = categories.map((category) =>
			category.id === categoryData.id ? categoryData : category
		);
		if (categoryToEdit.length > 0) {
			setCategories(categoryToEdit);
		}
		updateFixedSeniorityBonus(categoryToEdit);
		setCategoryToEdit(null);
	};

	return {
		gridInfo,
		control,
		agreementTypes,
		periodIntervals,
		errors,
		openModal,
		setOpenModal,
		categories,
		datesInterval,
		loading,
		setCategories,
		addCategory,
		deleteCategory,
		categoryToEdit,
		selectCategoryToEdit,
		selectedFixedSeniorityBonus,
		editCategory,
		watch,
		handleSubmit,
		onSubmit,
		setETValues,
	};
}
