import * as agreements from './agreements';
import * as auth from './auth';
import * as content from './content';
import * as createSimulation from './createSimulation';
import * as error from './error';
import * as events from './events';
import * as inventoryStore from './inventory';
import * as profile from './profile';
import * as projects from './projects';
import * as simulations from './simulations';
import * as snackbar from './snackbar';

const ActionCreators = Object.assign(
	{},
	auth,
	error,
	events,
	projects,
	simulations,
	content,
	profile,
	agreements,
	createSimulation,
	inventoryStore,
	snackbar
);

export default ActionCreators;
