import React, { Component } from 'react';

import AppSelect from '../../components/AppSelect/AppSelect';

class NewProjectCalendar extends Component {
	state = {
		suggestions: [
			{ label: 'Madrid' },
			{ label: 'Barcelona' },
			{ label: 'Caceres' },
			{ label: 'Sevilla' },
			{ label: 'Valencia' },
			{ label: 'Andorra' },
			{ label: 'Angola' },
			{ label: 'Anguilla' },
			{ label: 'Bosnia and Herzegovina' },
			{ label: 'Botswana' },
			{ label: 'Bouvet Island' },
			{ label: 'Brazil' },
			{ label: 'British Indian Ocean Territory' },
			{ label: 'Brunei Darussalam' },
		].map((suggestion) => ({
			value: suggestion.label,
			label: suggestion.label,
		})),
	};

	handleInputChange = (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const element = target.type === 'select' ? target.element : null;
		const { name } = target;

		this.setState({
			[name]: value,
			[`${name}Element`]: element,
		});
	};

	render() {
		const { suggestions, municipalityElement } = this.state;

		return (
			<AppSelect
				suggestions={suggestions}
				placeholder='Selecciona un municipio'
				selectedValue={municipalityElement}
				handleChangeSelected={this.handleInputChange}
				name='municipality'
			/>
		);
	}
}

export default NewProjectCalendar;
