import React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ButtonComponent = styled(Button)((props) => ({
	color: 'white',
	borderRadius: '60px',
	'&:hover': {
		background: props.theme.palette.primary.main,
		color: props.theme.palette.common.white,
		'& span svg': {
			color: props.theme.palette.common.white,
		},
		'& span img': {
			filter: 'brightness(0) invert(1)',
		},
	},
}));

export const StyledButton = ({
	onClick,
	color = 'primary',
	children,
	...rest
}) => (
	<ButtonComponent
		variant='contained'
		color={color}
		onClick={onClick}
		{...rest}
	>
		{children}
	</ButtonComponent>
);
