import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import IsSimulationEditable from '../../../components/Simulations/IsSimulationEditable';
import { StyledBoxContainer } from '../../../components/StyledComponents/BoxContainer';
import HeaderTableCell from '../../../components/StyledComponents/HeaderTableCell';
import { StyledIcon } from '../../../components/StyledComponents/Icon';
import { formatNumber } from '../../../utilities/utils';

const compareObjects = (obj1, obj2) =>
	obj1.id !== obj2.id ||
	obj1.convenio.uid !== obj2.convenio.uid ||
	obj1.localidad.id !== obj2.localidad.id ||
	obj1.centro !== obj2.centro ||
	obj1.center_group !== obj2.center_group;

const numberRest = (number) => (number % 1 > 0 ? 2 : 0);

const BasicDataTable = ({ rows, updateInfo, setInitialInfo }) => {
	const deleteRow = (row) => {
		const del = rows.filter((option) => compareObjects(option, row));
		updateInfo(del);
	};

	return (
		<StyledBoxContainer>
			<Table sx={{ minWidth: 650 }} aria-label='simple table'>
				<TableHead>
					<TableRow>
						<HeaderTableCell>Centro</HeaderTableCell>
						<HeaderTableCell>Localidad</HeaderTableCell>
						<HeaderTableCell>Convenio</HeaderTableCell>
						<HeaderTableCell>
							<Tooltip title='Agrupación' placement='top'>
								<span>Agrup.</span>
							</Tooltip>
						</HeaderTableCell>
						<HeaderTableCell>
							<Tooltip title='Cobertura de vacaciones' placement='top'>
								<span>Cobertura Vac.</span>
							</Tooltip>
						</HeaderTableCell>
						<HeaderTableCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow
							key={row.id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell>{row?.name}</TableCell>
							<TableCell>{row?.localidad?.name}</TableCell>
							<TableCell>{row?.convenio?.name}</TableCell>
							<TableCell>{row?.center_group}</TableCell>
							<TableCell>
								{formatNumber(
									row?.vacation_coverage,
									numberRest(row?.vacation_coverage)
								)}
								%
							</TableCell>
							<TableCell sx={{ width: 1 / 8 }}>
								<IsSimulationEditable>
									<Box sx={{ width: 1 / 5, marginLeft: 1, display: 'flex' }}>
										<StyledIcon
											onClick={() => {
												deleteRow(row);
											}}
											variant='contained'
											label='delete'
										>
											<DeleteIcon />
										</StyledIcon>
										<StyledIcon
											sx={{ marginLeft: 1 }}
											onClick={() => {
												setInitialInfo(row);
											}}
											variant='contained'
											label='edit'
										>
											<EditIcon />
										</StyledIcon>
									</Box>
								</IsSimulationEditable>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</StyledBoxContainer>
	);
};

export default BasicDataTable;
