import { v4 as uuidv4 } from 'uuid';

export const parsingCentersToUpload = (centros) => {
	const responce = centros.map(
		({ name, localidad, convenio, center_group, id, vacation_coverage }) => ({
			name: name,
			center_id: id || uuidv4(),
			municipality_id: localidad?.id,
			agreement_id: convenio?.uid,
			center_group: center_group,
			vacation_coverage: vacation_coverage,
		})
	);
	return responce;
};

export const parsingCentersToShowInTable = ({
	centers,
	localities,
	convenios,
}) => {
	return centers.map((center) => {
		var new_convenio = {
			name: center.agreement.name,
			uid: center.agreement.id,
		};
		return {
			localidad: localities.find((v) => v.id == center.municipality_id),
			convenio: new_convenio,
			...center,
		};
	});
};
