import ApiException from '../../../Services/Api/ApiException';
import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import {
	getSolutionDefaultParametersByIdUrl,
	getSolutionParametersByIdUrl,
	solutionUploadStatusParametersUrl,
	updateSolutionParametersUrl,
} from '../../../utilities/routes';

export async function getSolutionParametersById({ simulationId }) {
	const url = getSolutionParametersByIdUrl(simulationId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function getSolutionDefaultParametersById({ simulationId }) {
	const url = getSolutionDefaultParametersByIdUrl(simulationId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function updateSolutionParameters({ simulationId, payload }) {
	const url = updateSolutionParametersUrl(simulationId);
	try {
		await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function solutionUploadStatusParameters(simulationId) {
	const url = solutionUploadStatusParametersUrl(simulationId);
	try {
		return await AxiosWrapper.get(url);
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
