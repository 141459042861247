export default function content(state = null, action) {
	const { type, progress, contentUploadUrl, uploading } = action;
	switch (type) {
		case 'SET_CONTENT_UPLOAD_URL':
			return { ...state, contentUploadUrl, uploading, progress };
		case 'SET_PROGRESS':
			return { ...state, progress };
		case 'SET_UPLOADING':
			return { ...state, uploading };
		default:
			return state;
	}
}
