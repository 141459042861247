import Api from '../../utilities/api';

// GET
export function getEventsList() {
	return (dispatch, getState) => {
		dispatch(resetEventsList()); // Clean fav state old data
		dispatch(setFetchingState(true)); // Api call will now begin, fetching data
		return Api.get(
			'event', // Endpoint
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then((response) => {
			dispatch(setEventsList(response)); // Data is received from the api call. Save it in the state
			dispatch(setFetchingState(false)); // The Api call finished
		});
	};
}

// POST
export function saveEvent(eventDict) {
	return (dispatch, getState) =>
		Api.post(
			'event', // Endpoint
			{ search: eventDict }, // "Body" of the request (POST)
			getState().auth.token, // Current user token
			dispatch, // store.dispatch.
			'user' // Keyword to set the api host config (flowtag-user)
		).then(() => dispatch(getEventsList())); // Once the new fav has been saved, retrieve the updated list of events
}

// PUT
export function updateEvent(eventDict, eventId) {
	return (dispatch, getState) =>
		Api.put(
			`event/${eventId}`, // Endpoint
			{ search: eventDict }, // "Body" of the request (PUT)
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then(() => dispatch(getEventsList())); // Once the fav has been updated, retrieve the updated list of events
}

// DELETE
export function removeFromEvents(eventId) {
	return (dispatch, getState) =>
		Api.delete(
			`event/${eventId}`, // Endpoint
			getState().auth.token, // Current user token
			dispatch, // store.dispatch
			'user' // Keyword to set the api host config (flowtag-user)
		).then(() => dispatch(getEventsList())); // Once the fav has been deleted, retrieve the updated list of events
}

export function setEventsList(events) {
	return {
		type: 'SET_EVENTS_LIST',
		events,
	};
}

export function resetEventsList() {
	return { type: 'RESET_EVENTS_LIST' };
}

export function setFetchingState(fetching) {
	return {
		type: 'SET_FETCHING_STATE_EVENTS',
		fetching,
	};
}
