import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import Else from '../../../components/Compounds/If/Else';
import If from '../../../components/Compounds/If/If';
import Then from '../../../components/Compounds/If/Then';
import IsLoading from '../../../components/IsLoading/IsLoading';
import UserInfoTable from './Table';
import UserRoleModal from './UserRoleModal';
import useUserInfo from './useUserInfo';

const UserInfo = () => {
	const {
		users,
		loadingUsersByWorkSpace,
		openModal,
		onClose,
		selectedUser,
		setReloadUsers,
	} = useUserInfo();

	return (
		<Box
			sx={{
				textAlign: 'left',
				marginLeft: '5px',
				mt: '45px',
			}}
		>
			<Typography
				sx={{
					fontWeight: '700',
					textTransform: 'uppercase',
					fontSize: '16px',
					color: 'primary.main',
				}}
				variant='body2'
				color='primary'
			>
				Gestión de usuarios
			</Typography>
			<IsLoading conditional={loadingUsersByWorkSpace}>
				<Grid container spacing={2} sx={{ alignItems: 'center', mt: '0px' }}>
					<Grid item md={12}>
						<If conditional={users.length > 0}>
							<Then>
								<UserInfoTable rows={users} />
							</Then>
							<Else>
								<Box
									sx={{
										color: 'black',
										fontSize: '11px',
										fontWeight: '700',
										mt: 2,
										mb: 3,
									}}
								>
									No existen datos para mostrar
								</Box>
							</Else>
						</If>
					</Grid>
				</Grid>
			</IsLoading>
			{selectedUser?.id && (
				<UserRoleModal
					openModal={openModal}
					onClose={onClose}
					selectedUser={selectedUser}
					reloadUsers={setReloadUsers}
				/>
			)}
		</Box>
	);
};
export default UserInfo;
