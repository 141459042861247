import React from 'react';

import moment from 'moment';

import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import {
	IconButton,
	Table,
	TableBody,
	TableHead,
	TableRow,
	Box,
} from '@mui/material';

import { StyledBoxContainer } from '../../StyledComponents/BoxContainer';
import { ContentTableCell } from '../../StyledComponents/ContentTableCell';
import HeaderTableCell from '../../StyledComponents/HeaderTableCell';

const ShiftsTable = ({ rows, deleteShiftsRow }) => (
	<StyledBoxContainer>
		<Table size='small' padding='none' aria-label='simple table'>
			<TableHead>
				<TableRow>
					<HeaderTableCell>
						<Box sx={{ ml: 1 }}>Desde</Box>
					</HeaderTableCell>
					<HeaderTableCell>Hasta</HeaderTableCell>
					<HeaderTableCell sx={{ width: 1 / 8 }} />
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row, index) => (
					<TableRow key={`${row?.date_init}${row?.date_end}`}>
						<ContentTableCell>
							<Box sx={{ ml: 1 }}>
								{moment(row?.date_init).format('DD-MM-YYYY') || '-'}
							</Box>
						</ContentTableCell>
						<ContentTableCell>
							{moment(row?.date_end).format('DD-MM-YYYY') || '-'}
						</ContentTableCell>
						<ContentTableCell>
							<IconButton
								variant='contained'
								color='primary'
								aria-label='delete'
								onClick={() => deleteShiftsRow(index)}
							>
								<HighlightOffRoundedIcon />
							</IconButton>
						</ContentTableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</StyledBoxContainer>
);
export default ShiftsTable;
