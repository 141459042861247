import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { WhiteTitleContainer } from '../../components';
import {
	TitleContainer,
	ViewContainer,
} from '../../components/StyledContainers';
import { SocialSecurityCost } from '../../containers/SimulationInfo/SocialSecurityCost';
import './createSimulation.css';

const SocialSecurity = ({ match }) => {
	const { projectId, simulationId } = match.params;
	const projects = useSelector((state) => state.projects);
	const simulations = useSelector((state) => state.simulations);
	const [project, setProject] = useState({});

	const [simulation, setSimulation] = useState({});

	useEffect(() => {
		setProject(projects.list.filter((item) => item.uid === projectId)[0]);
		setSimulation(
			simulations.list.filter((item) => item.uid === simulationId)[0]
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const header = () => (
		<WhiteTitleContainer
			routes={[
				{ name: 'Inicio', href: '/' },
				{ name: 'Licitaciones', href: '/projects' },

				{ name: project?.name, href: `/projects/${projectId}` },
				{
					name: 'Solución',
					href: `/projects/${projectId}/simulations/${simulationId}`,
				},
				{
					name: simulation?.name,
				},
			]}
			title=''
		/>
	);

	return (
		<>
			{project?.name && header()}
			<ViewContainer>
				<TitleContainer>
					<Grid
						sx={{ width: '100%' }}
						container
						alignItems='center'
						justifyContent='space-between'
					>
						<Grid item>
							<Typography
								variant='h5'
								color='textPrimary'
								style={{ fontWeight: 'bold' }}
							>
								{simulation?.name}
							</Typography>
						</Grid>
					</Grid>
				</TitleContainer>
				<Box
					sx={{
						flexGrow: 1,
						bgcolor: 'background.paper',
						width: '100%',
						m: 'auto',
						mt: '50px',
						p: 2,
						boxShadow: `0px 10px 40px rgba(68, 82, 231, 0.11)`,
						borderRadius: '6px',
					}}
				>
					<SocialSecurityCost match={match} />
				</Box>
			</ViewContainer>
		</>
	);
};
export default SocialSecurity;
