import React, { Component, Fragment } from 'react';

import styled from 'styled-components';

import AppSimpleList from '../../components/AppSimpleList/AppSimpleList';
import AppTabComponent from '../../components/AppTabComponent/AppTabComponent';
import { StyledButton } from '../../components/StyledComponents/Button';

const OuterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
`;
const ViewContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 90%;
`;

class NewProjectBid extends Component {
	state = {
		listTasks: [{ text: 'Fregar Suelos' }, { text: 'Limpiar paredes' }],
		listShifts: [
			{ text: 'Turno mañana limpieza' },
			{ text: 'Turno partido limpieza' },
		],
		modalStatus: false,
	};

	handleInputChange = (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;

		this.setState({
			[name]: value,
		});
	};

	modalOpen = (type) => {
		this.setState({ [`${type}ModalStatus`]: true });
	};

	modalClose = (type) => {
		this.setState({ [`${type}ModalStatus`]: false });
	};

	renderListTasksShifts = (tasks) => {
		const { listTasks, listShifts } = this.state;

		if (tasks) {
			return (
				<Fragment>
					<AppSimpleList
						items={listTasks}
						emtpyMessage='No hay tareas, añade nuevas tareas'
						typeActions='editDelete'
						onDelete={(idx) => {
							listTasks.splice(idx);
							this.setState({ listTasks });
						}}
						onEdit={(idx) => console.log(idx)}
					/>
					<Button
						variant='contained'
						color='primary'
						onClick={() => console.log('Añadir tarea')}
					>
						Añadir tarea
					</Button>
				</Fragment>
			);
		}

		return (
			<Fragment>
				<AppSimpleList
					items={listShifts}
					emtpyMessage='No hay turnos, añade nuevos turnos'
					typeActions='editDelete'
					onDelete={(idx) => {
						listShifts.splice(idx);
						this.setState({ listShifts });
					}}
					onEdit={(idx) => console.log(idx)}
				/>
				<StyledButton
					variant='contained'
					color='primary'
					onClick={() => console.log('Añadir turno')}
				>
					Añadir turno
				</StyledButton>
			</Fragment>
		);
	};

	render() {
		return (
			<OuterContainer>
				<ViewContainer>
					<AppTabComponent
						listTitles={['Tareas', 'Puestos con turnos/horarios a cubrir']}
						listContents={[
							this.renderListTasksShifts(true),
							this.renderListTasksShifts(false),
						]}
					/>
				</ViewContainer>
			</OuterContainer>
		);
	}
}

export default NewProjectBid;
