import React from 'react';
import { Controller } from 'react-hook-form';

import { TextField } from '@mui/material';

export const FormInputNumber = ({
	name,
	control,
	label = null,
	min = 0,
	max = 10000000,
	required = false,
	requiredText = 'Valor requerido',
	validateText = 'Valor incorrecto',
	endAdornment = null,
	disabled = false,
	...inputProps
}) => (
	<Controller
		name={name}
		control={control}
		rules={{
			required: required && requiredText,
			validate: (amount) =>
				(amount >= min && amount <= max) ||
				(!required && !amount) ||
				validateText,
		}}
		render={({ field: { onChange, value }, fieldState: { error } }) => (
			<TextField
				helperText={error ? error.message : null}
				size='small'
				disabled={disabled}
				error={!!error}
				onChange={onChange}
				value={value}
				fullWidth
				label={label}
				variant='outlined'
				InputProps={{
					inputProps: { min, max, ...inputProps },
					type: 'number',
					endAdornment,
				}}
			/>
		)}
	/>
);
