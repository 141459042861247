import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Table, TableBody, TableHead, TableRow, Box } from '@mui/material';

import IsPreviousStudyEditable from '../../../../components/PreviousStudy/IsPreviousStudyEditable';
import { StyledBoxContainer } from '../../../../components/StyledComponents/BoxContainer';
import { ContentTableCell } from '../../../../components/StyledComponents/ContentTableCell';
import HeaderTableCell from '../../../../components/StyledComponents/HeaderTableCell';
import { StyledIcon } from '../../../../components/StyledComponents/Icon';
import useMaterialCostTable from './useInventoryTable';

const MaterialCostTable = ({
	rows = [],
	deleteMaterialCosts,
	setMaterialCostId,
	setEdit,
	setOpenModal,
}) => {
	const { getTypeByText, getAmountAndIconByType } = useMaterialCostTable();

	return (
		<StyledBoxContainer>
			<Table aria-label='Turnos fijos'>
				<TableHead>
					<TableRow>
						<HeaderTableCell>Concepto</HeaderTableCell>
						<HeaderTableCell>Tipo</HeaderTableCell>
						<HeaderTableCell>Importe</HeaderTableCell>
						<HeaderTableCell sx={{ width: 1 / 6 }} />
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row?.id}>
							<ContentTableCell>{row?.name || '-'}</ContentTableCell>
							<ContentTableCell>{`${
								row?.cost_type ? getTypeByText(row.cost_type) : '-'
							}`}</ContentTableCell>
							<ContentTableCell>
								<Box ml={1} sx={{ display: 'flex' }}>
									{row?.amount && row?.cost_type
										? getAmountAndIconByType(row?.cost_type, row?.amount)
										: '-'}
								</Box>
							</ContentTableCell>
							<ContentTableCell sx={{ maxWidth: 10 }}>
								<Box sx={{ width: 1 / 5, display: 'flex' }}>
									<IsPreviousStudyEditable>
										<StyledIcon
											variant='contained'
											color='primary'
											aria-label='delete'
											onClick={() => deleteMaterialCosts(row?.id)}
										>
											<DeleteIcon />
										</StyledIcon>
									</IsPreviousStudyEditable>
									<StyledIcon
										sx={{ marginLeft: 1 }}
										variant='contained'
										color='primary'
										aria-label='edit'
										onClick={() => {
											setMaterialCostId(row?.id);
											setEdit(true);
											setOpenModal(true);
										}}
									>
										<EditIcon />
									</StyledIcon>
								</Box>
							</ContentTableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</StyledBoxContainer>
	);
};

export default MaterialCostTable;
