import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { v4 as uuidv4 } from 'uuid';

function vacation_coverage(vacation_coverage) {
	if (Number.isNaN(vacation_coverage) || vacation_coverage === '') {
		return 100;
	}
	return vacation_coverage;
}

export default function useCenterForm({
	updateInfo,
	initialInfo,
	setInitialInfo,
	agreements,
	closeCenterFormModal,
}) {
	const defaultValues = {
		id: uuidv4(),
		name: '',
		agreement: null,
		locality: null,
		vacation_coverage: 100,
	};
	const { handleSubmit, control, reset, getValues } = useForm({
		defaultValues,
	});
	const [selectedAgreements, setSelectedAgreements] = useState(null);
	const [selectedLocality, setSelectedLocality] = useState(null);
	const agreementId = agreements.map((agreement) => ({
		id: agreement.uid,
		...agreement,
	}));

	useEffect(() => {
		reset({
			id: initialInfo.id,
			name: initialInfo.name,
			agreement: initialInfo?.convenio !== null ? initialInfo.convenio : null,
			locality: initialInfo?.localidad !== null ? initialInfo.localidad : null,
			center_group:
				initialInfo?.center_group !== null ? initialInfo.center_group : '',
			vacation_coverage: initialInfo.vacation_coverage,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialInfo]);

	const clearForm = (data) => {
		reset({
			id: uuidv4(),
			name: '',
			center_group: '',
			agreement: data?.agreement ? data.agreement : null,
			locality: data?.locality ? data.locality : null,
			vacation_coverage: 100,
		});
	};

	const parseData = (data) => ({
		...data,
		id: data?.id || uuidv4(),
		center_group: data?.center_group || null,
		convenio: data.agreement,
		localidad: data.locality,
		vacation_coverage: parseFloat(vacation_coverage(data?.vacation_coverage)),
	});

	const onSubmit = (data) => {
		updateInfo(parseData(data));
		clearForm(data);
	};

	const submitAndClose = (data) => {
		updateInfo(parseData(data));
		clearForm(data);
		closeCenterFormModal();
	};

	const closeModal = () => {
		clearForm({
			agreement: getValues('agreement'),
			locality: getValues('locality'),
		});
		setInitialInfo({
			id: uuidv4(),
			center_group: null,
			convenio: getValues('agreement'),
			localidad: getValues('locality'),
			vacation_coverage: 100,
		});
		closeCenterFormModal();
	};

	return {
		agreementId,
		selectedLocality,
		setSelectedLocality,
		selectedAgreements,
		setSelectedAgreements,
		onSubmit,
		submitAndClose,
		handleSubmit,
		control,
		closeModal,
	};
}
