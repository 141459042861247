import React, { Component } from 'react';
import {
	Calendar as ReactCalendar,
	CalendarControls,
} from 'react-yearly-calendar';

import moment from 'moment';

class Calendar extends Component {
	constructor(props) {
		super(props);

		const today = moment();

		this.state = {
			year: today.year(),
			selectedDay: today,
			selectedRange: [today, moment(today).add(15, 'day')],
			showDaysOfWeek: true,
			showTodayBtn: true,
			showWeekSeparators: true,
			selectRange: false,
			firstDayOfWeek: 0, // sunday
		};
	}

	onPrevYear() {
		this.setState((prevState) => ({
			year: prevState.year - 1,
		}));
	}

	onNextYear() {
		this.setState((prevState) => ({
			year: prevState.year + 1,
		}));
	}

	static onDatePicked(date) {
		alert(date);
	}

	goToToday() {
		const today = moment();

		this.setState({
			selectedDay: today,
			selectedRange: [today, moment(today).add(15, 'day')],
			year: today.year(),
		});
	}

	datePicked(date) {
		this.setState({
			selectedDay: date,
			selectedRange: [date, moment(date).add(15, 'day')],
		});
	}

	rangePicked(start, end) {
		this.setState({
			selectedRange: [start, end],
			selectedDay: start,
		});
	}

	toggleShowDaysOfWeek() {
		this.setState((prevState) => ({
			showDaysOfWeek: !prevState.showDaysOfWeek,
		}));
	}

	toggleForceFullWeeks() {
		this.setState((prevState) => ({
			showDaysOfWeek: true,
			forceFullWeeks: !prevState.forceFullWeeks,
		}));
	}

	toggleShowTodayBtn() {
		this.setState((prevState) => ({
			showTodayBtn: !prevState.showTodayBtn,
		}));
	}

	toggleShowWeekSeparators() {
		this.setState((prevState) => ({
			showWeekSeparators: !prevState.showWeekSeparators,
		}));
	}

	toggleSelectRange() {
		this.setState((prevState) => ({
			selectRange: !prevState.selectRange,
		}));
	}

	selectFirstDayOfWeek(event) {
		this.setState({
			firstDayOfWeek: parseInt(event.target.value, 10),
		});
	}

	render() {
		const {
			year,
			showTodayBtn,
			selectedDay,
			showDaysOfWeek,
			forceFullWeeks,
			showWeekSeparators,
			firstDayOfWeek,
			selectRange,
			selectedRange,
		} = this.state;

		return (
			<div>
				<CalendarControls
					year={year}
					showTodayButton={showTodayBtn}
					onPrevYear={() => this.onPrevYear()}
					onNextYear={() => this.onNextYear()}
					goToToday={() => this.goToToday()}
				/>
				<ReactCalendar
					year={year}
					selectedDay={selectedDay}
					showDaysOfWeek={showDaysOfWeek}
					forceFullWeeks={forceFullWeeks}
					showWeekSeparators={showWeekSeparators}
					firstDayOfWeek={firstDayOfWeek}
					selectRange={selectRange}
					selectedRange={selectedRange}
					onPickDate={(date) => this.datePicked(date)}
					onPickRange={(start, end) => this.rangePicked(start, end)}
				/>
			</div>
		);
	}
}

export default Calendar;
