import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import CollectiveAgreementsDataView from './screens/CollectiveAgreements/CollectiveAgreementDataView';
import CollectiveAgreementForm from './screens/CollectiveAgreements/CollectiveAgreementForm';
import CollectiveAgreements from './screens/CollectiveAgreements/CollectiveAgreements';
import NotFoundPage from './screens/Error/NotFoundPage';
import ServerNotFound from './screens/Error/ServerNotFound';
import Inventory from './screens/Inventory/Inventory';
import SignIn from './screens/Login/login';
import NewProject from './screens/NewProject/NewProject';
import PreviousStudy from './screens/PreviousStudy/PreviousStudy';
import PreviousStudySolution from './screens/PreviousStudy/PreviousStudySolution';
import Profile from './screens/Profile/Profile';
import Project from './screens/Projects/Project';
import Projects from './screens/Projects/Projects';
import CreateSimulation from './screens/Simulations/CreateSimulation';
import Simulation from './screens/Simulations/Simulation';
import SocialSecurity from './screens/Simulations/SocialSecurity';
import AdministrationPanel from './screens/User/AdministrationPanel/AdministrationPanel';
import Calendar from './screens/calendar';
import { PublicRoute, PrivateRoute } from './utilities/router/routeTypes';

class AppRoutes extends Component {
	render() {
		const {
			auth: { device, token },
			setToken,
			error,
			getProjectsList,
			projects,
			content,
			updateProjects,
			requestUploadUrl,
			uploadContent,
			simulations,
			getSimulation,
			requestAgreementUploadUrl,
			updateAgreement,
		} = this.props;

		const commonProps = {
			token,
			error,
		};

		return (
			<Switch>
				<PublicRoute
					{...commonProps}
					getProps={this.props}
					component={ServerNotFound}
					path='/notfound'
				/>
				<PublicRoute
					{...commonProps}
					restricted
					path='/login'
					device={device}
					setToken={setToken}
					component={SignIn}
				/>
				<PrivateRoute
					{...commonProps}
					component={Simulation}
					simulations={simulations}
					projects={projects}
					getSimulation={getSimulation}
					requestUploadUrl={requestUploadUrl}
					uploadContent={uploadContent}
					content={content}
					path='/projects/:id/simulations/:simulationId'
				/>
				<PrivateRoute
					{...commonProps}
					component={SocialSecurity}
					simulations={simulations}
					getSimulation={getSimulation}
					requestUploadUrl={requestUploadUrl}
					uploadContent={uploadContent}
					content={content}
					path='/social_security_cost/:projectId/:simulationId'
				/>
				<PrivateRoute
					{...commonProps}
					component={Project}
					path='/projects/:id'
				/>
				<PrivateRoute
					{...commonProps}
					component={Projects}
					getProjects={getProjectsList}
					updateProject={updateProjects}
					projects={projects}
					path='/projects'
				/>
				<PrivateRoute {...commonProps} component={Profile} path='/profile' />
				<PrivateRoute
					{...commonProps}
					component={NewProject}
					// saveProject={saveProjects}
					saveProject={() => 'asd'}
					path='/newproject'
				/>
				<PrivateRoute {...commonProps} component={Calendar} path='/calendar' />
				<PrivateRoute
					{...commonProps}
					component={CollectiveAgreements}
					requestUploadUrl={requestAgreementUploadUrl}
					updateAgreement={updateAgreement}
					path='/agreements'
				/>
				<PrivateRoute
					{...commonProps}
					component={CreateSimulation}
					path='/simulation/:projectId/:simulationId/form'
				/>
				<PrivateRoute
					{...commonProps}
					component={CollectiveAgreementsDataView}
					path='/agreement/:agreementId'
				/>
				<PrivateRoute
					{...commonProps}
					component={Inventory}
					path='/inventory'
				/>
				<PrivateRoute
					{...commonProps}
					component={CollectiveAgreementForm}
					path='/agreement-form/:agreementId'
				/>
				<PrivateRoute exact path='/'>
					<Redirect to='/projects' />
				</PrivateRoute>
				<PrivateRoute
					{...commonProps}
					component={AdministrationPanel}
					path='/admin'
				/>
				<PrivateRoute
					{...commonProps}
					component={PreviousStudy}
					path='/previous/:projectId/:previousStudyId'
				/>
				<PrivateRoute
					{...commonProps}
					component={PreviousStudySolution}
					simulations={simulations}
					getSimulation={getSimulation}
					requestUploadUrl={requestUploadUrl}
					uploadContent={uploadContent}
					content={content}
					path='/previous_solution/:projectId/:previousStudyId'
				/>
				<PublicRoute
					{...commonProps}
					getProps={this.props}
					component={NotFoundPage}
					path='/404'
				/>
				<PrivateRoute exact path='*'>
					<Redirect to='/404' />
				</PrivateRoute>
			</Switch>
		);
	}
}

export default AppRoutes;
