import React from 'react';

import { styled } from '@mui/material/styles';

import { Avatar } from '@material-ui/core';

const StyledAvatar = styled(Avatar)`
	transform: translate(0px, -75%);
	width: 70px;
	height: 70px;
	background-color: #fff;
`;

export const StyledSemicircleOnTop = ({ children, ...rest }) => (
	<StyledAvatar {...rest}>{children}</StyledAvatar>
);
