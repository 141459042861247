import React from 'react';

import { DropzoneArea } from 'material-ui-dropzone';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudDownloadOutlinedIcon from '@mui/icons-material/DownloadForOffline';
import { Box, Typography } from '@mui/material';

import DialogComponent from '../../../../components/Dialog/Dialog';
import IsSimulationEditable from '../../../../components/Simulations/IsSimulationEditable';
import { StyledButton } from '../../../../components/StyledComponents/Button';
import useCreateUploadDownload from './useCreateUploadDownload';

export const CreateUploadDownload = ({
	setOpenCenterFormModal,
	simulationId,
	setReloadCenters,
	setUploadingFile,
	uploadingFile,
}) => {
	const {
		downloadingFile,
		downloadCenters,
		handleUploadCenters,
		openUploadCentersModal,
		setOpenUploadCentersModal,
		centerUploadError,
		setCentersFile,
	} = useCreateUploadDownload({
		simulationId,
		setUploadingFile,
		setReloadCenters,
	});
	return (
		<>
			<IsSimulationEditable>
				<Box
					sx={{
						display: 'block',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
						height: '50px',
					}}
				>
					<StyledButton
						size='large'
						variant='text'
						sx={{
							mr: 2,
							color: 'primary.main',
							textTransform: 'inherit',
							fontSize: '18px',
							fontWeight: '700',
						}}
						onClick={() => setOpenCenterFormModal(true)}
						startIcon={<AddCircleOutlineIcon color='primary' mr='-4px' />}
						data-testid='addCenter'
					>
						Añadir Centro
					</StyledButton>
					<StyledButton
						size='large'
						variant='text'
						sx={{
							mr: 2,
							color: 'primary.main',
							textTransform: 'inherit',
							fontSize: '18px',
							fontWeight: '700',
						}}
						onClick={downloadCenters}
						startIcon={<CloudDownloadOutlinedIcon />}
						disabled={downloadingFile}
					>
						Descargar Plantilla
					</StyledButton>
					<StyledButton
						size='large'
						variant='text'
						sx={{
							color: 'primary.main',
							textTransform: 'inherit',
							fontSize: '18px',
							fontWeight: '700',
						}}
						onClick={() => setOpenUploadCentersModal(true)}
						startIcon={
							<CloudDownloadOutlinedIcon sx={{ transform: 'scaleY(-1)' }} />
						}
						disabled={downloadingFile}
					>
						Subir Plantilla de Centros
					</StyledButton>
				</Box>
			</IsSimulationEditable>
			<DialogComponent
				open={openUploadCentersModal}
				onClose={() => setOpenUploadCentersModal(false)}
				title='Cargar Centros'
				disableAccept={uploadingFile}
				disableCancel={uploadingFile}
				content={
					<Box>
						<DropzoneArea
							onChange={setCentersFile}
							dropzoneText='Arrastra el excel de centros'
							filesLimit={1}
							showFileNames={false}
							useChipsForPreview
							showPreviewsInDropzone={false}
							showPreviews
							dropzoneParagraphClass='DropZoneAreaText'
						/>
						<Typography variant='subtitle1' color='error'>
							{centerUploadError}
						</Typography>
					</Box>
				}
				onAccept={handleUploadCenters}
				acceptText='Aceptar'
				cancelText='Cancelar'
				onCancel={() => setOpenUploadCentersModal(false)}
				cancelButtonFirst={false}
			/>
		</>
	);
};
