const checkSubrogate = (subrogates) => {
	const working_day_cell = subrogates.map((values) => values.working_day);
	return (
		working_day_cell.includes(0) ||
		working_day_cell.includes(undefined) ||
		working_day_cell.includes('')
	);
};

const parseSubrogateToSave = (subrogates) =>
	subrogates.map((values) => ({
		id: values?.id,
		value_metric_working_day: values.value_metric_working_day,
		working_day: values.working_day,
	}));

export { checkSubrogate, parseSubrogateToSave };
