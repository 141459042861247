import React from 'react';

import EuroIcon from '@mui/icons-material/Euro';
import InfoIcon from '@mui/icons-material/Info';
import {
	Box,
	Dialog,
	Grid,
	InputAdornment,
	Tooltip,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { AddCircleOutline } from '../../../../components/Button/AddCircleOutline';
import { TimeDateRange } from '../../../../components/Date/TimeDateRange';
import DialogTitle from '../../../../components/Dialog/DialogTitle';
import { FormInputNumber } from '../../../../components/Form/FormInputNumber';
import { FormInputText } from '../../../../components/Form/InputText';
import { StyledButton } from '../../../../components/StyledComponents/Button';
import useAgreementModal from './useAgreementModal';

const Label = styled(Box)`
	margin-top: 8px;
`;

export const AgreementModal = ({
	openModal,
	setOpenModal,
	setCategories,
	addCategory,
	categoryToEdit,
	editCategory,
}) => {
	const {
		resetForm,
		control,
		plusByDays,
		plusByDaysOfTheWeek,
		showMorePlusOptions,
		setShowMorePlusOptions,
		showAntiquityPlus,
		setShowAntiquityPlus,
		handleSubmit,
		onSubmit,
		watch,
	} = useAgreementModal({
		setOpenModal,
		setCategories,
		addCategory,
		categoryToEdit,
		editCategory,
	});
	return (
		<Dialog
			fullWidth
			maxWidth='md'
			open={openModal}
			onClose={() => resetForm()}
		>
			<Box p={3}>
				<DialogTitle title='Añadir categoría' />
				<Box sx={{ justifyContent: 'center', mt: '32px' }}>
					<Grid container spacing={2}>
						<Grid container item xs={12} sx={{ alignItems: 'center' }}>
							<Grid item xs={3}>
								Nombre categoría:
							</Grid>
							<Grid item xs={5} ml={-0.5}>
								<FormInputText
									name='categoryName'
									rules={{ required: 'Valor requerido' }}
									control={control}
									inputProps={{
										'data-testid': 'categoryName',
									}}
								/>
							</Grid>
						</Grid>
						<Grid container item xs={12} spacing={2}>
							<Grid container item xs={6} sx={{ alignItems: 'center' }}>
								<Grid container item xs={6}>
									<Grid item>Salario bruto anual</Grid>
									<Grid item ml={1}>
										<Tooltip
											placement='right'
											title='Salario bruto anual de la categoría a jornada 
                  completa, incluyendo pagas extras, pluses fijos, etc.'
										>
											<InfoIcon color='primary' />
										</Tooltip>
									</Grid>
								</Grid>
								<Grid item xs={5}>
									<FormInputNumber
										name='netSalary'
										control={control}
										label=''
										data-testid='netSalary'
										required
									/>
								</Grid>
							</Grid>
							<Grid container item xs={6} sx={{ alignItems: 'center' }}>
								<Grid container item xs={6}>
									<Grid item>Salario base anual</Grid>
									<Grid item ml={1}>
										<Tooltip
											placement='right'
											title='Salario base anual de la categoría incluyendo pagas 
                    extras. Se usa para calcular la antigüedad.'
										>
											<InfoIcon color='primary' />
										</Tooltip>
									</Grid>
								</Grid>
								<Grid item xs={5}>
									<FormInputNumber
										name='annualBaseSalary'
										control={control}
										label=''
										data-testid='annualBaseSalary'
										required
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							{watch('netSalary') < 15876 && watch('netSalary') !== '' && (
								<Typography variant='caption' color='error'>
									¡Ojo! Has introducido un salario inferior al SMI.
								</Typography>
							)}
						</Grid>
						<Grid container item xs={12} sx={{ alignItems: 'center' }}>
							<Grid item>
								<Typography
									sx={{ fontWeight: 'bold' }}
									id='modal-modal-title'
									variant='subtitle1'
									component='div'
								>
									Añadir plus fijo de antigüedad
								</Typography>
							</Grid>
							<Grid item sx={{ ml: 2 }}>
								<AddCircleOutline
									ariaLabel='fixed_seniority_bonus_pluses'
									onClick={() => setShowAntiquityPlus((prev) => !prev)}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={12}
							sx={{ display: showAntiquityPlus ? 'inline' : 'none', mt: -2 }}
						>
							<Grid container sx={{ mt: 2 }} item xs={12}>
								<Grid container item xs={6}>
									<Grid container item xs={6} sx={{ alignItems: 'center' }}>
										<Grid item>Plus antigüedad</Grid>
										<Grid item ml={1}>
											<Tooltip
												placement='right'
												title='Cumplimentar únicamente cuando va por cantidad 
												fija anual / categoría. Si no, dejar en blanco y 
												utilizar el plus por % del formulario.'
											>
												<InfoIcon color='primary' />
											</Tooltip>
										</Grid>
									</Grid>
									<Grid container item xs={5} sx={{ alignItems: 'center' }}>
										<FormInputNumber
											name='fixed_seniority_bonus'
											control={control}
											data-testid='fixed_seniority_bonus'
											required={showAntiquityPlus ? true : false}
											endAdornment={
												<InputAdornment position='end'>
													<EuroIcon />
												</InputAdornment>
											}
										/>
									</Grid>
								</Grid>
								<Grid item>
									<Label>cada</Label>
								</Grid>
								<Grid item xs={2} ml={2}>
									<FormInputNumber
										name='fixed_seniority_bonus_period'
										data-testid='fixed_seniority_bonus_period'
										control={control}
										sx={{ width: '100px' }}
										min={0}
										required={showAntiquityPlus ? true : false}
									/>
								</Grid>
								<Grid item ml={2}>
									<Label>años</Label>
								</Grid>
							</Grid>
						</Grid>

						<Grid container item xs={12} mt={2} sx={{ alignItems: 'center' }}>
							<Grid item>
								<Typography
									sx={{ fontWeight: 'bold' }}
									id='modal-modal-title'
									variant='subtitle1'
									component='div'
								>
									Plus nocturnidad
								</Typography>
							</Grid>
							<Grid item ml={1}>
								<Tooltip
									placement='right'
									title='Precio por hora. El trabajador cobrará el día normal 
										más el plus indicado. Este importe será multiplicado por el 
										nº de horas que tenga planificadas en este horario/día.'
								>
									<InfoIcon color='primary' />
								</Tooltip>
							</Grid>
						</Grid>

						<Grid container item xs={12}>
							<Grid item xs={6}>
								<TimeDateRange
									names={['plusNocturnalStartTime', 'plusNocturnalEndTime']}
									control={control}
									inputProps={[
										{ 'data-testid': 'plusNocturnalStartTime' },
										{ 'data-testid': 'plusNocturnalEndTime' },
									]}
								/>
							</Grid>
							<Grid container item xs={6} sx={{ alignItems: 'center' }}>
								<Grid item>
									<FormInputNumber
										name='plusNocturnalValue'
										control={control}
										data-testid='plusNocturnalValue'
										endAdornment={
											<InputAdornment position='end'>
												<EuroIcon />
											</InputAdornment>
										}
									/>
								</Grid>
								<Grid item ml={2}>
									€/hora
								</Grid>
							</Grid>
						</Grid>
						<Grid container item xs={12} sx={{ alignItems: 'center' }}>
							<Grid container item>
								<Typography
									sx={{ fontWeight: 'bold' }}
									id='modal-modal-title'
									variant='subtitle1'
									component='div'
								>
									Pluses por días (domingos, festivos, etc.)
								</Typography>

								<Grid item ml={1}>
									<Tooltip
										placement='right'
										title='Precio por hora. El trabajador cobrará el día normal 
										más el plus indicado. Este importe será multiplicado por el 
										nº de horas que tenga planificadas en este horario/día.'
									>
										<InfoIcon color='primary' />
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
						{plusByDays.map((plus, index) => (
							<Grid
								container
								key={plus.id}
								item
								xs={12}
								sx={{ alignItems: 'center' }}
							>
								<Grid container item xs={6}>
									<Grid container item xs={6} sx={{ alignItems: 'center' }}>
										{plus.label}
									</Grid>
									<Grid item xs={5} sx={{ alignItems: 'center' }}>
										<FormInputNumber
											name={plus.inputName}
											control={control}
											data-testid={plus.inputName}
											endAdornment={
												<InputAdornment position='end'>
													<EuroIcon />
												</InputAdornment>
											}
										/>
									</Grid>
								</Grid>
								<Grid item ml={2} xs={1}>
									€/hora
								</Grid>
								{index === 0 && (
									<Grid
										container
										item
										xs={4}
										ml={3}
										sx={{ alignItems: 'center' }}
									>
										<Grid item xs={3}>
											días libres
										</Grid>
										<Grid item xs={1} ml={1} mr={1} mt={1}>
											<Tooltip
												placement='right'
												title='Número de días libres si trabaja en festivos.'
											>
												<InfoIcon color='primary' />
											</Tooltip>
										</Grid>
										<Grid item xs={7}>
											<FormInputNumber
												name='free_days'
												control={control}
												data-testid={plus.inputName}
												max={5}
											/>
										</Grid>
									</Grid>
								)}
							</Grid>
						))}
						<Grid container item xs={12} sx={{ alignItems: 'center' }}>
							<Grid item>
								<Typography
									sx={{ fontWeight: 'bold' }}
									id='modal-modal-title'
									variant='subtitle1'
									component='div'
								>
									Añadir más
								</Typography>
							</Grid>
							<Grid item sx={{ ml: 2 }}>
								<AddCircleOutline
									ariaLabel='morePluses'
									onClick={() => setShowMorePlusOptions((prev) => !prev)}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={12}
							sx={{ display: showMorePlusOptions ? 'inline' : 'none', mt: -2 }}
						>
							{plusByDaysOfTheWeek.map((plus) => (
								<Grid container key={plus.id} sx={{ mt: 2 }} item xs={12}>
									<Grid container item xs={6}>
										<Grid container item xs={6} sx={{ alignItems: 'center' }}>
											{plus.label}
										</Grid>
										<Grid container item xs={5} sx={{ alignItems: 'center' }}>
											<FormInputNumber
												name={plus.inputName}
												control={control}
												data-testid={plus.inputName}
												required={false}
												endAdornment={
													<InputAdornment position='end'>
														<EuroIcon />
													</InputAdornment>
												}
											/>
										</Grid>
									</Grid>
									<Grid item ml={2}>
										€/hora
									</Grid>
								</Grid>
							))}
						</Grid>
						<Grid
							item
							xs={12}
							sx={{ display: 'flex', justifyContent: 'center' }}
						>
							<StyledButton
								onClick={handleSubmit(onSubmit)}
								variant='contained'
								data-testid='categorySaveButton'
							>
								Guardar
							</StyledButton>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Dialog>
	);
};
