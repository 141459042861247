import React from 'react';
import { Controller } from 'react-hook-form';

import { FormControl } from '@material-ui/core';

import AppAutoComplete from '../AppAutoComplete/AppAutoComplete';

export const FormAutoComplete = ({
	autoCompleteProps = {},
	control,
	inputProps = {},
	label,
	name,
	optionLabel,
	options,
	rules = {},
}) => (
	<FormControl size='small' fullWidth>
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<AppAutoComplete
					autoCompleteProps={autoCompleteProps}
					error={!!error}
					errorText={error ? error.message : null}
					inputProps={inputProps}
					label={label}
					optionLabel={optionLabel}
					options={options}
					setValue={onChange}
					value={value}
				/>
			)}
		/>
	</FormControl>
);
