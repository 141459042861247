import React from 'react';
import { Controller } from 'react-hook-form';

import { Grid, Typography } from '@mui/material';

import If from '../Compounds/If/If';
import Then from '../Compounds/If/Then';
import { FormTimePicker } from '../Form/TimePicker';

export const TimeDateRange = ({
	names,
	control,
	label,
	labels = ['', ''],
	rules = [],
	rangeRule = {},
	componentProps = {},
	inputProps = [{}, {}],
}) => (
	<Grid container items xs={12} spacing={2}>
		{label && (
			<Grid item xs={3}>
				{label}
			</Grid>
		)}
		<Grid item xs={label ? 9 : 12}>
			<Grid container spacing={2}>
				<Grid item xs={5.5}>
					<FormTimePicker
						name={names[0]}
						control={control}
						label={labels[0]}
						componentProps={{ ...componentProps }}
						inputProps={{ placeholder: 'hh:mm', ...inputProps[0] }}
						rules={{ ...rules[0] }}
					/>
				</Grid>
				<Grid item xs={0.5}>
					-
				</Grid>
				<Grid item xs={5.5}>
					<FormTimePicker
						name={names[1]}
						control={control}
						label={labels[1]}
						componentProps={{ ...componentProps }}
						rules={{ ...rules[1] }}
						inputProps={{ placeholder: 'hh:mm', ...inputProps[1] }}
					/>
				</Grid>
			</Grid>
		</Grid>
		<Grid container spacing={2}>
			<Grid item xs={3} />
			<Grid item xs={9}>
				<If conditional={rangeRule}>
					<Then>
						<Controller
							name='yearRangeValidation'
							control={control}
							rules={rangeRule}
							render={({ fieldState: { error } }) => (
								<Typography variant='caption' color='error'>
									{error?.message}
								</Typography>
							)}
						/>
					</Then>
				</If>
			</Grid>
		</Grid>
	</Grid>
);
