import React from 'react';
import { useFieldArray } from 'react-hook-form';

import { Grid, InputAdornment, Typography } from '@mui/material';

import { FormInputNumber } from '../../../../../components/Form/FormInputNumber';

export const MaterialCost = ({ control }) => {
	const { fields } = useFieldArray({
		control,
		name: 'material_costs',
	});

	const getLastLabelByType = (type) => {
		switch (type) {
			case 'MONTHLY_AMOUNT':
				return 'Por mes';
			case 'AMOUNT_BY_WORKER':
				return 'Por trabajador';
			case 'MONTHLY_UNIT_COST':
				return 'Nº de unidades';
			case 'SALARY_PERCENT':
				return 'Coste salarial';
			default:
				return 'Coste salarial';
		}
	};

	return (
		<Grid item container xs={12} spacing={2}>
			<Grid item container xs={12} mt={2}>
				<Typography
					variant='subtitle1'
					component='div'
					sx={{ fontWeight: 'bold' }}
				>
					Costes materiales:
				</Typography>
			</Grid>
			<Grid item container spacing={2}>
				{fields.map((material, index) => (
					<Grid key={material.id} container item xs={12}>
						<Grid
							container
							item
							xs={7}
							spacing={2}
							sx={{ alignItems: 'center' }}
						>
							<Grid item xs={4}>
								{material.name}
							</Grid>
							<Grid item container xs={4}>
								<FormInputNumber
									name={`material_costs.${index}.amount`}
									control={control}
									aria-label={material.name}
									min={
										material.type === 'SALARY_PERCENT'
											? 0
											: Number.NEGATIVE_INFINITY
									}
									endAdornment={
										<InputAdornment position='end'>
											{material.type === 'SALARY_PERCENT' ? '%' : '€'}
										</InputAdornment>
									}
									required
								/>
							</Grid>
							<Grid item xs={4}>
								{getLastLabelByType(material.type)}
							</Grid>
						</Grid>
						<Grid item container xs={5} spacing={2}>
							<Grid item xs={3}>
								{material.type === 'MONTHLY_UNIT_COST' ? (
									<FormInputNumber
										name={`material_costs.${index}.number_of_units`}
										control={control}
										aria-label={`${material.name}NumberOfUnits`}
										required
									/>
								) : null}
							</Grid>
							{material.type === 'MONTHLY_AMOUNT' ||
							material.type === 'MONTHLY_UNIT_COST' ? (
								<Grid item container xs={8} sx={{ alignItems: 'center' }}>
									<Grid item xs={6}>
										Nº de meses
									</Grid>
									<Grid item xs={6}>
										<FormInputNumber
											name={`material_costs.${index}.months_to_apply`}
											control={control}
											aria-label={`${material.name}MonthsToApply`}
											required
										/>
									</Grid>
								</Grid>
							) : null}
						</Grid>
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};
