import ApiException from '../../../Services/Api/ApiException';
import AxiosWrapper from '../../../utilities/connection/AxiosWrapper';
import {
	getProfileUrl,
	updatePasswordUrl,
	updateProfileUrl,
} from '../../../utilities/routes';

export async function getProfileData() {
	const url = getProfileUrl();
	try {
		const inventoryData = await AxiosWrapper.get(url);
		return inventoryData.data;
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function updateProfileData({ payload }) {
	const url = updateProfileUrl();
	try {
		return await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}

export async function updatePasswordData({ payload }) {
	const url = updatePasswordUrl();
	try {
		return await AxiosWrapper.put(url, JSON.stringify(payload));
	} catch (e) {
		throw new ApiException(e?.response?.data?.message || 'unknown error');
	}
}
