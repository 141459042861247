import { persistStore } from 'redux-persist';

import Api from '../../utilities/api';

export function password(password, oldPassword, token) {
	return (dispatch, getState) =>
		Api.put(
			'user/change_password',
			{
				old_password: oldPassword,
				password,
			},
			token || getState().auth.token,
			dispatch,
			'user'
		);
}

export function recoverPassword(email) {
	return (dispatch, getState) =>
		Api.post(
			'auth/password/recover',
			{ email },
			getState().auth.token,
			dispatch,
			'user'
		);
}

export function register(email, password) {
	return (dispatch, getState) =>
		Api.post(
			'auth/register',
			{ email, password },
			getState().auth.token,
			dispatch,
			'user'
		);
}

export function token(email, password, device, expires) {
	return (dispatch, getState) =>
		Api.post(
			'auth/token',
			{ email, password, device, expires },
			getState().auth.token,
			dispatch,
			'user'
		).then((res) => dispatch(setToken(res)));
}

export function refresh() {
	return (dispatch, getState) =>
		Api.post(
			'auth/refresh',
			{ token: getState().auth.token },
			getState().auth.token,
			dispatch,
			'user'
		).then((res) => dispatch(setToken(res)));
}

export function revoke() {
	return (dispatch, getState) =>
		Api.post(
			'auth/revoke',
			{ token: getState().auth.token },
			getState().auth.token,
			dispatch,
			'user'
		);
}

export function setToken(token) {
	return {
		type: 'SET_TOKEN',
		token,
	};
}

export function setWorkSpace(workSpace) {
	return {
		type: 'SET_WORKSPACE',
		workSpace,
	};
}

export function updatePermissions(permissions) {
	return {
		type: 'UPDATE_PERMISSIONS',
		permissions,
	};
}

export function logoutPers(persistor, store, state) {
	return (dispatch) =>
		Promise.all([
			persistor.purge(),
			dispatch({ type: 'RESET', state }),
			persistStore(store, state), // re-persist store with a given state
		]);
}

export function setLoadingWorkSpace(is_loading) {
	return {
		type: 'SET_LOADING_WORKSPACE',
		is_loading,
	};
}
